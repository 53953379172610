<div class="home">
  <br />
  <div class="row">
    <div class="bemVindo">
      Olá <b>{{ usuario?.nomeExibicao }}.</b>
    </div>
  </div>
  <div class="row">
    <div class="bemVindo">Bem-vindo à Gestão Credipontal.</div>
  </div>
  <div class="separator">
    <h1>{{ tituloDashboard }}</h1>
  </div>
  <div class="row">
    <mat-form-field class="grow1" appearance="outline">
      <mat-label>Data Inicial</mat-label>
      <input
        id="dataInicial"
        matInput
        [matDatepicker]="picker1"
        [(ngModel)]="dataInicial"
        (keyup)="appConfigService.onKeyUpEventData($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="grow1" appearance="outline">
      <mat-label>Data Final</mat-label>
      <input
        id="dataFinal"
        matInput
        [matDatepicker]="picker2"
        [(ngModel)]="dataFinal"
        (keyup)="appConfigService.onKeyUpEventData($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-checkbox
      id="todoPeriodoCheck"
      [(ngModel)]="todoPeriodo"
      (ngModelChange)="todoPeriodoChanged($event)"
      >Todo Período</mat-checkbox
    >
    <mat-checkbox
      id="todoPACheck"
      [(ngModel)]="todoPA"
      (ngModelChange)="todoPAChanged($event)"
      *ngIf="usuario?.nivel === 'OPERADOR'"
      >Dados do PA</mat-checkbox
    >
    <button mat-button class="btnGrid" (click)="obterResumos()">Buscar</button>
    <div class="dashboardInfo">
      <div class="infoMiniRight">
        * Dados atualizados em {{ ultimaAtualizacao }}
      </div>
      <mat-checkbox
        id="pararTimerCheck"
        [(ngModel)]="stopTimer"
        (ngModelChange)="pararTimerChanged($event)"
        >Parar atualização</mat-checkbox
      >
    </div>
  </div>
  <div class="regua mat-elevation-z6">
    <div class="itemRegua">
      <div class="row">Abertas</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : (statusDashboard?.qtdeAbertos | number)
        }}
      </div>
    </div>
    <div class="linhaVertical"></div>
    <div class="itemRegua" *ngIf="usuario?.nivel !== 'ASSESSOR'">
      <div class="row">Aguard. Vistoria</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : statusDashboard?.qtdeAguardandoVistoria
        }}
      </div>
    </div>
    <div class="itemRegua" *ngIf="usuario?.nivel === 'ASSESSOR'">
      <div class="row">Cotando Interno</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : statusDashboard?.qtdeCotandoInterno
        }}
      </div>
    </div>
    <div class="linhaVertical"></div>
    <div class="itemRegua">
      <div class="row">Sol. de Fechamento</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : statusDashboard?.qtdeSolicitandoFechamento
        }}
      </div>
    </div>
    <div class="linhaVertical"></div>
    <div class="itemRegua">
      <div class="row">Sol. Alteração</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : statusDashboard?.qtdeSolicitandoAlteracao
        }}
      </div>
    </div>
    <div class="linhaVertical"></div>
    <div class="itemRegua">
      <div class="row">Aguard. Boleto</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : statusDashboard?.qtdeAguardandoBoleto
        }}
      </div>
    </div>
    <div class="linhaVertical"></div>
    <div class="itemRegua" *ngIf="usuario?.nivel !== 'ATENDENTE'">
      <div class="row">Fechadas</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : (statusDashboard?.qtdeFechados | number : "1.0-0" : "BRL")
        }}
      </div>
    </div>
    <div class="itemRegua" *ngIf="usuario?.nivel === 'ATENDENTE'">
      <div class="row">Pendências</div>
      <div class="row valor">
        {{
          statusDashboard === undefined
            ? "..."
            : statusDashboard?.qtdePendencias
        }}
      </div>
    </div>
  </div>
  <div class="grafico" *ngIf="permissaoADM || permissaoAssessor">
    <div class="dashboardGrafico">
      <div class="tituloGrafico">
        <strong>Tempo Médio da Abertura ao Fechamento</strong>
      </div>
      <div
        class="container-tempo"
        *ngIf="tempoMedioChamados !== null && tempoMedioChamados !== undefined"
      >
        <div class="card-tempo">
          <a
            matTooltip="Visualiza o tempo médio por chamado"
            *ngIf="tempoMedioChamados?.qtdeChamados > 0"
            (click)="visualizarTempoChamado()"
          >
            <mat-icon>visibility</mat-icon>
          </a>
          <h1>Chamados</h1>
          <h3>{{ tempoMedioChamados?.qtdeChamados | number : "1.0-0" }}</h3>
        </div>
        <div class="card-tempo">
          <h1>Tempo</h1>
          <h3>{{ tempoMedioChamados?.totalChamados }}</h3>
        </div>
        <div class="card-tempo">
          <h1>Média Atual</h1>
          <h3>{{ tempoMedioChamados?.mediaGeral }}</h3>
        </div>
      </div>
      <br />
      <div class="tabela">
        <div class="th-flex">
          <th class="left">Tipo</th>
          <th class="center">Quantidade</th>
          <th class="right">Tempo</th>
          <th class="right">Média</th>
        </div>
        <tr>
          <td class="left">Cotação</td>
          <td class="center">
            {{ tempoMedioChamados?.qtdeCotacoes | number }}
          </td>
          <td class="right">
            {{ tempoMedioChamados?.totalCotacoes }}
          </td>
          <td class="right">
            {{ tempoMedioChamados?.mediaCotacoes }}
          </td>
        </tr>
        <tr>
          <td class="left">Endosso</td>
          <td class="center">
            {{ tempoMedioChamados?.qtdeEndossos | number }}
          </td>
          <td class="right">
            {{ tempoMedioChamados?.totalEndossos }}
          </td>
          <td class="right">
            {{ tempoMedioChamados?.mediaEndossos }}
          </td>
        </tr>
      </div>
      <br />
      <div class="card-tempo">
        <h1>Tempo Médio Aberto x Aguardando Retorno</h1>
        <h3>{{ mediaAguardandoRetorno?.mediaGeral }}</h3>
      </div>
    </div>
    <div class="dashboardGrafico">
      <div class="tituloGrafico"><strong>Top Prioridades</strong></div>
      <br />
      <app-top-prioridades></app-top-prioridades>
      <br />
    </div>
  </div>
  <div class="grafico">
    <div class="dashboardGrafico">
      <div class="tituloGrafico"><strong>Fechamentos Diários</strong></div>
      <div
        [ngStyle]="{ margin: getMargin() }"
        *ngIf="barChartData5 === null || barChartData5 === undefined"
      ></div>
      <canvas
        baseChart
        [datasets]="barChartData5"
        *ngIf="barChartData5"
        [labels]="barChartLabels5"
        [options]="barChartOptions5"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType5"
      >
      </canvas>
      <div>
        <mat-paginator
          #matPaginator5
          [(length)]="qtdeRegistrosCotacoesDiarias"
          [showFirstLastButtons]="true"
          [hidePageSize]="!(qtdeRegistrosCotacoesDiarias > 1)"
          [pageSize]="myPaginator3.pageSize"
          [pageSizeOptions]="pageSizeGrafico"
          (page)="getPaginaCotacoesDiarias($event, todoPA)"
        >
        </mat-paginator>
        <div class="qtdeRegistros" *ngIf="qtdeRegistrosCotacoesDiarias > 0">
          {{ qtdeRegistrosCotacoesDiarias }} Registro(s) encontrado(s).
        </div>
      </div>
    </div>
  </div>
  <div class="grafico">
    <div class="dashboardGrafico" *ngIf="permissaoADM || permissaoAssessor">
      <div class="tituloGrafico"><strong>Fechamento x P.A</strong></div>
      <canvas
        baseChart
        [datasets]="barChartData1"
        *ngIf="barChartData1"
        [labels]="barChartLabels1"
        [options]="barChartOptions1"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType1"
      >
      </canvas>
    </div>
    <div class="dashboardGrafico" *ngIf="permissaoADM || permissaoAssessor">
      <div class="tituloGrafico"><strong>Fechamento x Gerente</strong></div>
      <canvas
        baseChart
        [datasets]="barChartData2"
        *ngIf="barChartData2"
        [labels]="barChartLabels2"
        [options]="barChartOptions2"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType2"
      >
      </canvas>
    </div>
    <div
      class="dashboardGrafico"
      *ngIf="permissaoADM || permissaoAssessor || (permissaoGerente && todoPA)"
    >
      <div class="tituloGrafico">
        <strong>Top 10 Produtos</strong
        ><strong *ngIf="permissaoGerente"> - {{ usuario?.codPA }}</strong>
      </div>
      <canvas
        baseChart
        [datasets]="barChartData3"
        *ngIf="barChartData3"
        [labels]="barChartLabels3"
        [options]="barChartOptions3"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType3"
      >
      </canvas>
    </div>
    <div
      class="dashboardGrafico"
      *ngIf="permissaoADM || permissaoAssessor || (permissaoGerente && todoPA)"
    >
      <div class="tituloGrafico">
        <strong>Top 10 Atendentes </strong
        ><strong *ngIf="permissaoGerente"> - {{ usuario?.codPA }}</strong>
      </div>
      <canvas
        baseChart
        [datasets]="barChartData4"
        *ngIf="barChartData4"
        [labels]="barChartLabels4"
        [options]="barChartOptions4"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType4"
      >
      </canvas>
    </div>
  </div>
  <br />
  <div class="separator">
    <h1>{{ tituloDashboard1 }}</h1>
  </div>
  <div>
    <div class="containerGrid">
      <div class="tituloGrafico">
        <strong>{{ tituloDashboard2 }}</strong>
      </div>
      <br />
      <table mat-table [dataSource]="dsMinhasCotacoes">
        <!-- Coluna Id -->
        <ng-container matColumnDef="id">
          <th
            class="columnGrid growGrid"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            ID
          </th>
          <td class="columnGrid growGrid" mat-cell *matCellDef="let row">
            {{ row?.id }}
          </td>
        </ng-container>

        <!-- Coluna Data -->
        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
          <td mat-cell *matCellDef="let row">
            {{ row?.data | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <!-- Coluna nomeCliente -->
        <ng-container matColumnDef="nomeCliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
          <td mat-cell *matCellDef="let row">{{ row?.nomeCliente }}</td>
        </ng-container>

        <!-- Coluna Status -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">
            <div
              class="colunaStatus"
              [ngStyle]="{ 'background-color': getCellColor(row) }"
            >
              {{ row?.status?.descricao }}
            </div>
          </td>
        </ng-container>

        <!-- Coluna Visualizar -->
        <ng-container matColumnDef="visualizar">
          <th
            class="center columnGrid"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          ></th>
          <td
            mat-cell
            *matCellDef="let row"
            (click)="$event.stopPropagation()"
            class="center"
          >
            <button
              mat-button
              class="btnGrid columnGrid"
              (click)="abrirCotacao(row)"
            >
              Ver <mat-icon> remove_red_eye</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colunasMeusChamados"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: colunasMeusChamados"
          [ngClass]="{ selectedRow: row == itemCotacaoSelecionado }"
          (click)="rowClick(row)"
        ></tr>
      </table>
      <div>
        <mat-paginator
          #matPaginator3
          [(length)]="qtdeRegistrosMinhasCotacoes"
          [showFirstLastButtons]="true"
          [hidePageSize]="!(qtdeRegistrosMinhasCotacoes > 1)"
          [pageSize]="myPaginator1.pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="getPaginaCotacoes($event)"
        >
        </mat-paginator>
        <div class="qtdeRegistros" *ngIf="qtdeRegistrosMinhasCotacoes > 0">
          {{ qtdeRegistrosMinhasCotacoes }} Registro(s) encontrado(s).
        </div>
      </div>
    </div>
    <div class="containerGrid" *ngIf="permissaoADM || permissaoAssessor">
      <div class="tituloGrafico"><strong>Controle de 1º Parcela</strong></div>
      <br />
      <table mat-table [dataSource]="dsPrimeiraParcela">
        <!-- Coluna Id -->
        <ng-container matColumnDef="id">
          <th
            class="growGrid columnGrid"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            ID
          </th>
          <td class="growGrid columnGrid" mat-cell *matCellDef="let row">
            <div [ngStyle]="{ color: getStyle(row) }">
              {{ row?.id }}
            </div>
          </td>
        </ng-container>

        <!-- Coluna Vencimento -->
        <ng-container matColumnDef="vencimento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vencimento</th>
          <td mat-cell *matCellDef="let row">
            <div [ngStyle]="{ color: getStyle(row) }">
              {{ row?.vencimento | date : "dd/MM/yyyy" }}
            </div>
          </td>
        </ng-container>

        <!-- Coluna nomeCliente -->
        <ng-container matColumnDef="nomeCliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
          <td mat-cell *matCellDef="let row">
            <div [ngStyle]="{ color: getStyle(row) }">
              {{ row?.nomeCliente }}
            </div>
          </td>
        </ng-container>

        <!-- Coluna codPA -->
        <ng-container matColumnDef="pa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>PA</th>
          <td mat-cell *matCellDef="let row">
            <div [ngStyle]="{ color: getStyle(row) }">
              {{ row?.codPA }}
            </div>
          </td>
        </ng-container>

        <!-- Coluna Valor -->
        <ng-container matColumnDef="valor">
          <th class="right" mat-header-cell *matHeaderCellDef mat-sort-header>
            Valor
          </th>
          <td mat-cell *matCellDef="let row" class="right">
            <div [ngStyle]="{ color: getStyle(row) }">
              {{ row?.valor | reais }}
            </div>
          </td>
        </ng-container>

        <!-- Coluna Visualizar -->
        <ng-container matColumnDef="visualizar">
          <th
            class="center columnGrid"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          ></th>
          <td
            mat-cell
            *matCellDef="let row"
            (click)="$event.stopPropagation()"
            class="center"
          >
            <button
              mat-button
              class="btnGrid columnGrid"
              (click)="avisarParcela(row)"
            >
              Notificar <mat-icon> email</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colunasPrimeiraParcela"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: colunasPrimeiraParcela"
          [ngClass]="{ selectedRow: row == itemParcelaSelecionado }"
          (click)="rowClickParcela(row)"
        ></tr>
      </table>
      <div>
        <mat-paginator
          #matPaginator4
          [(length)]="qtdeRegistrosPrimeiraParcela"
          [showFirstLastButtons]="true"
          [hidePageSize]="!(qtdeRegistrosPrimeiraParcela > 1)"
          [pageSize]="myPaginator2.pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="getPaginaParcelas($event)"
        >
        </mat-paginator>
        <div class="qtdeRegistros" *ngIf="qtdeRegistrosPrimeiraParcela > 0">
          {{ qtdeRegistrosPrimeiraParcela }} Registro(s) encontrado(s).
        </div>
      </div>
    </div>
  </div>
  <div class="separator">
    <h1>A Credipontal</h1>
  </div>
  <div class="visao">
    <img src="../../../assets/imagens/proposito.jpg" class="imgValores" />
    <img src="../../../assets/imagens/visão.jpg" class="imgValores" />
    <img src="../../../assets/imagens/missão.jpg" class="imgValores" />
    <img src="../../../assets/imagens/valores.jpg" class="imgValores" />
  </div>
</div>
