import { TermoAdesaoComponent } from './../termo-adesao/termo-adesao.component';
import { CupomRoutingModuleModule } from './cupom-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DataFormModule } from 'src/app/shared/data-form.module';
import { CupomComponent } from './cupom.component';



@NgModule({
  declarations: [CupomComponent, TermoAdesaoComponent],
  imports: [
    SharedModule,
    DataFormModule,
    CupomRoutingModuleModule
  ]
})
export class CupomModule { }
