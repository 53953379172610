import { LogDetailComponent } from './log-detail/log-detail.component';
import { AppConfigService } from './../../services/app/app-config.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { Log } from 'src/app/entidades/log';
import { Paginator } from 'src/app/entidades/paginator';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Pagina } from 'src/app/entidades/pagina';
import { LogService } from 'src/app/services/log/log.service';
import { FiltroLog } from 'src/app/enum/filtroLog';
import { enumToArray } from 'src/app/helper/enumToArray';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { FiltroService } from 'src/app/services/filtro/filtro.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css', '../../app.component.css']
})
export class LogComponent implements OnInit {
  pagina: Pagina<Log>;
  logs: Log[];
  dataSource: MatTableDataSource<Log>;
  displayedColumns: string[] = ['id', 'data', 'usuario', 'descricao'];
  qtdeRegistros: number = 0;
  logSelecionado: Log;
  filtros = enumToArray(FiltroLog);
  filtroAtual: number;
  textoFiltroAtual: string;
  myPaginator: Paginator;
  dataInicial: Date;
  dataFinal: Date;
  filtroPaginator = {
    length: 0,
    pageIndex: 0,
    pageSize: 50,
    previousPageIndex: 0
  };

  constructor(private appConfigService: AppConfigService,
    private paginatorService: PaginatorService,
    public logService: LogService,
    public filtroService: FiltroService,
    private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    this.paginatorService.paginatorEmitter = this.paginatorService.getPaginatorEmitter();
    this.myPaginator = {
      length: 0,
      pageIndex: 0,
      pageSize: 50,
      previousPageIndex: 0
    };
    await this.obterLogs();
    this.paginatorService.paginatorEmitter.subscribe(async result => {
      this.myPaginator = JSON.parse(result);
      await this.obterLogs();
    });
    /* Emitter´s do componente de Filtros */
    this.logService.filtroLogEmitter = this.logService.getFiltroLogEmitter();
    this.logService.filtroLogEmitter.subscribe(res => {
      this.filtroAtual = res;
      this.myPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: 50,
        previousPageIndex: 0
      };
      switch (this.filtroAtual) {
        case 0:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
          this.filtroService.exibirFiltroDataEmitter.emit(false);
          break;
        case 1:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
          this.filtroService.exibirFiltroDataEmitter.emit(true);
          break;
        case 2:
        case 3:
        case 4:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
          this.filtroService.exibirFiltroDataEmitter.emit(false);
          break;
      }
    });

    this.filtroService.filtrarPorDescricaoEmitter = this.filtroService.getFiltrarPorDescricaoEmitter();
    this.filtroService.filtrarPorDataEmitter = this.filtroService.getFiltrarPorDataEmitter();
    this.filtroService.filtrarPorDescricaoEmitter.subscribe(res => {
      if (res === null || res === undefined) {
        this.filtroAtual = 0;
      }
      this.textoFiltroAtual = res[0];
      this.filtroPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: 50,
        previousPageIndex: 0
      };
      this.filtrarPorDescricao(this.filtroPaginator);
    });
    this.filtroService.filtrarPorDataEmitter.subscribe(res => {
      this.dataInicial = res.dataInicial;
      this.dataFinal = res.dataFinal;
      this.filtroPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: 50,
        previousPageIndex: 0
      };
      this.filtrarPorData(this.filtroPaginator);
    });
    this.paginatorService.paginatorEmitter.subscribe(result => {
      this.filtroPaginator = JSON.parse(result);
      this.myPaginator = this.filtroPaginator;
      if (this.filtroAtual > 0) {
        if (this.filtroAtual === 1) {
          this.filtrarPorData(this.myPaginator);
        } else if (this.filtroAtual === 2 || this.filtroAtual === 3 || this.filtroAtual === 4) {
          this.filtrarPorDescricao(this.myPaginator);
        } else {
          this.logService.obter(this.myPaginator).then(res => {
            this.configurarPagina(res);
          });
        }
      } else {
        this.obterLogs();
      }
    });
  }

  filtrarPorDescricao(filtroPaginator: Paginator) {
    if (this.filtroAtual > 0) {
      const dto = { texto: this.textoFiltroAtual } as FiltroDTO;
      this.logService.filtrar(this.filtroAtual, dto, filtroPaginator)
        .then(result => { this.configurarPagina(result); });
    } else {
      this.obterLogs();
    }
  }

  filtrarPorData(filtroPaginator: Paginator): void {
    if (this.filtroAtual > 0) {
      const dto = { dataInicial: this.dataInicial, dataFinal: this.dataFinal } as FiltroDTO;
      this.logService.filtrar(this.filtroAtual, dto, filtroPaginator)
        .then(result => { this.configurarPagina(result); });
    }
  }

  async obterLogs(): Promise<void> {
    await this.logService.obter(this.myPaginator).then(res => {
      this.configurarPagina(res);
    });
  }

  configurarPagina(pagina: Pagina<Log>): void {
    if (pagina !== null && pagina !== undefined) {
      this.logs = pagina.dados as Log[];
      this.dataSource = new MatTableDataSource(this.logs);
      this.pagina = pagina;
      this.qtdeRegistros = this.pagina.quantidadeRegistros;
    }
    else {
      this.logs = null;
    }
  }

  rowClick(row: Log): void {
    this.logSelecionado = row;
    let dialog: MatDialogRef<any>;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.logSelecionado;
    dialog = this.dialog.open(LogDetailComponent, dialogConfig);
  }

  getUsuario(row: Log): string {
    return row.usuario?.nome;
  }
}
