<div class="container margin-nav mat-elevation-z8">
  <app-titulo titulo="Logs do Sistema"></app-titulo>
  <br />
  <div class="filtros">
    <app-filtro
      [filtros]="filtros"
      [dataSource]="dataSource"
      [filtroEmitter]="logService.filtroLogEmitter"
      [descricaoEmitter]="logService.descricaoFiltroEmitter"
      [periodoDataEmitter]="filtroService.filtrarPorDataEmitter"
    ></app-filtro>
  </div>
  <table mat-table [dataSource]="dataSource">
    <!-- Coluna Id -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <!-- Coluna Data -->
    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell *matCellDef="let row">
        {{ row.data | date : "dd/MM/yyyy HH:mm:ss" }}
      </td>
    </ng-container>

    <!-- Coluna Usuário -->
    <ng-container matColumnDef="usuario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuário</th>
      <td mat-cell *matCellDef="let row">{{ getUsuario(row) }}</td>
    </ng-container>

    <!-- Coluna Descrição -->
    <ng-container matColumnDef="descricao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
      <td mat-cell *matCellDef="let row">{{ row.descricao }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ selectedRow: row == logSelecionado }"
      (click)="rowClick(row)"
    ></tr>
  </table>
  <app-paginator
    [(length)]="qtdeRegistros"
    [(pageSize)]="myPaginator.pageSize"
  ></app-paginator>
</div>
