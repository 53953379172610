import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Campanha } from 'src/app/entidades/campanha';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { Usuario } from 'src/app/entidades/usuario';
import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { CampanhaService } from 'src/app/services/campanha/campanha.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RelatoriosComponent } from '../relatorios/relatorios.component';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-campanhas',
  templateUrl: './campanha.component.html',
  styleUrls: ['./campanha.component.css', '../../app.component.css']
})
export class CampanhaComponent implements OnInit {
  sorteando = false;
  pagina: Pagina<Campanha>;
  campanhas: Campanha[];
  myPaginator: Paginator;
  displayedColumns: string[] = ['id', 'nome', 'descricao', 'data_inicio', 'data_fim', 'encerrada', 'sorteio'];
  dataSource: MatTableDataSource<Campanha>;
  qtdeRegistros: number;
  campanhaSelecionada: Campanha;
  usuario: Usuario;
  campanhasVigentes: boolean;
  titulo: string;

  constructor(private router: Router,
    private apiService: ApiService,
    private campanhaService: CampanhaService,
    private paginatorService: PaginatorService,
    private appConfigService: AppConfigService,
    private mensagemService: MensagemService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.paginatorService.paginatorEmitter = this.paginatorService.getPaginatorEmitter();
    this.usuario = this.apiService.UsuarioLogado;
    this.myPaginator = {
      length: 0,
      pageIndex: 0,
      pageSize: this.appConfigService.itensPorPagina,
      previousPageIndex: 0
    };
    const path = this.activatedRoute.snapshot.url.map(u => u.path)[0];
    this.campanhasVigentes = path === 'vigentes';
    this.campanhaService.setcampanhaVigente(this.campanhasVigentes);
    this.titulo = this.campanhasVigentes ? "Campanhas Vigentes" : "Campanhas";
    if (this.campanhasVigentes) {
      this.campanhaService.obterVigentes(this.myPaginator).then(res => {
        this.configurarPagina(res);
      });
      this.paginatorService.paginatorEmitter.subscribe(result => {
        this.myPaginator = JSON.parse(result);
        this.campanhaService.obterVigentes(this.myPaginator).then(res => {
          this.configurarPagina(res);
        });
      });
    } else {
      this.campanhaService.obter(this.myPaginator).then(res => {
        this.configurarPagina(res);
      });
      this.paginatorService.paginatorEmitter.subscribe(result => {
        this.myPaginator = JSON.parse(result);
        this.campanhaService.obter(this.myPaginator).then(res => {
          this.configurarPagina(res);
        });
      });
    }
  }

  configurarPagina(pagina: Pagina<Campanha>): void {
    if (pagina !== null && pagina !== undefined) {
      this.campanhas = pagina.dados as Campanha[];
      this.dataSource = new MatTableDataSource(this.campanhas);
      this.pagina = pagina;
      this.qtdeRegistros = this.pagina.quantidadeRegistros;
    }
    else {
      this.campanhas = null;
    }
  }

  rowClick(row: Campanha): void {
    this.campanhaSelecionada = row;
  }

  novaCampanha(): void {
    this.router.navigate(['/Campanhas/nova']);
  }

  editarCampanha(): void {
    if (this.campanhaSelecionada === null || this.campanhaSelecionada === undefined) {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
    else {
      const id = this.campanhaSelecionada.id;
      if (this.campanhaSelecionada.encerrada) {
        this.mensagemService.showMessage('Campnha está encerrada, portando não pode ser alterada.', 'Ok', 5000, 'error');
        return;
      }
      this.router.navigate([`/Campanhas/${id}`]);
    }
  }

  novoCupom(): void {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      if (!this.campanhaSelecionada.encerrada) {
        this.router.navigate([`/NovoCupom/${this.campanhaSelecionada.id}`]);
      } else {
        this.mensagemService.showMessage('Campanha está encerrada.', 'Ok', 5000, 'error');
      }
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }

  visualizarCupons(): void {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      this.router.navigate([`/Cupom/${this.campanhaSelecionada.id}`]);
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }

  novoSorteio(): void {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      if (!this.campanhaSelecionada.encerrada) {
        this.sorteando = true;
      }
      else {
        this.mensagemService.showMessage('Campanha está encerrada.', 'Ok', 5000, 'error');
      }
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }

  sortear(): void {
    this.campanhaService.Sortear(this.campanhaSelecionada.id).then(res => {
      if (res !== null && res !== undefined) {
        this.mensagemService.showMessage('Sorteio realizado com sucesso!.', 'Ok', 5000, 'success');
      } else {
        this.mensagemService.showMessage('Erro ao gerar sorteio.', 'Ok', 5000, 'error');
      }
      this.sorteando = false;
      this.campanhaSelecionada.encerrada = true;
    });;
  }

  cancelarSorteio(): void {
    this.sorteando = false;
  }

  relatorioCSV(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { temMenu: false, titulo: 'Relatório da Campanha', retornarPeriodo: true }; //item;
    let tDialog = this.dialog.open(RelatoriosComponent, dialogConfig);
    tDialog.afterClosed().subscribe(periodo => {
      if (periodo === null || periodo === undefined) return;
      this.campanhaService.GerarRelatorioCampanha(periodo, this.campanhaSelecionada.id).then(
        result => {
          if (result !== null && result !== undefined) {
            this.mensagemService.showMessage('Relatório gerado com sucesso.', 'Ok', 5000, 'success');

            let fileName = 'relatorio.csv';
            let file = new Blob([result], { type: 'application/ms-excel' });
            let fileUrl = URL.createObjectURL(file);
            let anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = fileUrl;
            anchor.click();
          }
          else {
            this.mensagemService.showMessage('Erro ao gerar relatório da Campanha.', 'Ok', 5000, 'error');
          };
        });
    });
  }

  posicaoCampanha(): void {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      this.router.navigate([`/Posicao/${this.campanhaSelecionada.id}`]);
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }

  sorteio(row: Campanha): void {
    let campanha = row as Campanha;
    this.router.navigate([`/Sorteio/${campanha.id}`]);
  }

  parcelamentoCupons(): void {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        "id": 19,
        "campanhaId": this.campanhaSelecionada.id,
        "temMenu": true
      };
      let tDialog = this.dialog.open(RelatoriosComponent, dialogConfig);
      tDialog.afterClosed().subscribe(result => { });
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }

  detalhesCampanha(): void {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        "id": 15,
        "titulo": "Detalhamento da Campanha",
        "modulo": "relatorios",
        "link": "/Reports/Campanha/Detalhamento/{param}",
        "informarCampanha": false,
        "informarSeRenovacao": false,
        "informarPAeAtendente": false,
        "informarAtendente": false,
        "informarAssessor": false,
        "informarPA": true,
        "informarTipoEndosso": false,
        "informarPaOuAtendenteOuAssessor": false,
        "informarOrdem": true,
        "enumOrdem": "OrdemDetalheCampanha",
        "campanhaId": this.campanhaSelecionada.id
      };
      let tDialog = this.dialog.open(RelatoriosComponent, dialogConfig);
      tDialog.afterClosed().subscribe(result => { });
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }

  cuponsCancelados(): void {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        "id": 17,
        "campanhaId": this.campanhaSelecionada.id,
        "temMenu": true
      };
      let tDialog = this.dialog.open(RelatoriosComponent, dialogConfig);
      tDialog.afterClosed().subscribe(result => { });
    }
  }

  termoAdesao(): void {
    if (this.campanhaSelecionada.encerrada) {
      this.mensagemService.showMessage(`Campanha encerrada em ${new Date(this.campanhaSelecionada.dataFim).toLocaleDateString()}`, "Ok", 5000, 'error');
      return;
    }
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      this.router.navigate([`/Campanha/Termo/${this.campanhaSelecionada.id}/${this.campanhasVigentes}`]);
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }

  parcelamentoCupom() {
    if (this.campanhaSelecionada !== null && this.campanhaSelecionada !== undefined) {
      this.router.navigate([`/Campanha/Parcelamentos/${this.campanhaSelecionada.id}`]);
    }
    else {
      this.mensagemService.showMessage('Selecione uma campanha.', 'Ok', 5000, 'error');
    }
  }
}
