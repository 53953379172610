<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="titulo"></app-titulo>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="codPA">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Código PA</th>
      <td mat-cell *matCellDef="let row">
        {{ row.codPA }} - {{ row.agencia.cidade }}
      </td>
    </ng-container>
    <ng-container matColumnDef="meta">
      <th class="center-text" mat-header-cell *matHeaderCellDef mat-sort-header>
        Meta
      </th>
      <td class="center-text" mat-cell *matCellDef="let row">
        {{ getMeta(row) }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="td-valor">
        <span>{{ getTotalMeta() | reais }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="cupons">
      <th class="center-text" mat-header-cell *matHeaderCellDef mat-sort-header>
        Cupons Gerados
      </th>
      <td class="center-text" mat-cell *matCellDef="let row">
        {{ row.qtdeGerada }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="td-valor">
        <span>{{ getTotalCupons() }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="valor">
      <th class="right" mat-header-cell *matHeaderCellDef mat-sort-header>
        Valor
      </th>
      <td class="right" mat-cell *matCellDef="let row">
        {{ row.valorVenda | reais }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="td-valor">
        <span>{{ getTotalValorVenda() | reais }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="percentual">
      <th class="center-text" mat-header-cell *matHeaderCellDef mat-sort-header>
        Percentual (%)
      </th>
      <td class="center-text" mat-cell *matCellDef="let row">
        {{ getPercentual(row) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="bomRuim">
      <th class="center-text" mat-header-cell *matHeaderCellDef mat-sort-header>
        Como está?
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        <mat-label class="center-text" *ngIf="row.percentualMeta < 50"
          ><img class="img-smile" src="../../../../assets/imagens/triste.png"
        /></mat-label>
        <mat-label
          class="center-text"
          *ngIf="row.percentualMeta >= 51 && row.percentualMeta <= 79"
          ><img class="img-smile" src="../../../../assets/imagens/neutro.png"
        /></mat-label>
        <mat-label class="center-text" *ngIf="row.percentualMeta >= 80"
          ><img class="img-smile" src="../../../../assets/imagens/feliz.png"
        /></mat-label>
      </td>
    </ng-container>

    <ng-container matColumnDef="labelMetas">
      <td mat-footer-cell *matFooterCellDef colspan="5" class="td-valor">
        <span>Total das Metas: </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="labelcupons">
      <td mat-footer-cell *matFooterCellDef colspan="5" class="td-valor">
        <span>Total dos Cupons: </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="labelValor">
      <td mat-footer-cell *matFooterCellDef colspan="5" class="td-valor">
        <span>Total das Vendas: </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ selectedRow: row == posicaoCampanhaSelecionada }"
      (click)="rowClick(row)"
    ></tr>
    <div>
      <tr
        mat-footer-row
        *matFooterRowDef="['labelMetas', 'meta']; sticky: true"
      ></tr>
      <tr
        class="center-text"
        mat-footer-row
        *matFooterRowDef="['labelcupons', 'cupons']; sticky: true"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['labelValor', 'valor']; sticky: true"
      ></tr>
    </div>
  </table>
  <br />
  <div class="item-right" fxLayout="column" fxLayoutAlign="end end">
    <button mat-button class="btnCancel" type="button" (click)="voltar()">
      Voltar
    </button>
  </div>
</div>
