import { DatePipe } from '@angular/common';
import { CupomAgrupadoDto } from './../../dto/cupomAgrupadoDto';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { TipoPagamentoCupom } from './../../enum/tipoPagamentoCupom';
import { enumToArray } from 'src/app/helper/enumToArray';
import { Component, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cupom } from 'src/app/entidades/cupom';
import { DadosAdesao } from 'src/app/entidades/dadosAdesao';
import { ApiService } from 'src/app/services/api/api.service';
import { CupomService } from 'src/app/services/cupom/cupom.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { Validador } from 'src/app/validadores/validador';
import { CampanhaService } from 'src/app/services/campanha/campanha.service';
import { Campanha } from 'src/app/entidades/campanha';
import { Subject } from 'rxjs';
import { DefinicaoColuna } from 'src/app/entidades/definicaoColuna';
import { Cliente } from 'src/app/entidades/cliente';
import { PesquisaService } from 'src/app/services/pesquisa/pesquisa.service';
import { ClienteService } from 'src/app/services/cliente/cliente.service';
import { FiltroCliente } from 'src/app/enum/filtroCliente';
import { Produto } from 'src/app/entidades/produto';
import { Agencia } from 'src/app/entidades/agencia';
import { Usuario } from 'src/app/entidades/usuario';
import { ContaBancaria } from 'src/app/entidades/contaBancaria';
import { AgenciaService } from 'src/app/services/agencia/agencia.service';
import { CpfPipe } from 'src/app/pipes/cpf/cpf.pipe';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { RealPipe } from 'src/app/pipes/real/real.pipe';


@Component({
  selector: 'app-termo-adesao',
  templateUrl: './termo-adesao.component.html',
  styleUrls: ['../../app.component.css', './termo-adesao.component.css']
})
export class TermoAdesaoComponent implements OnInit, OnDestroy {
  private textoCooperadoNome = new Subject<string>();
  step = 0;
  clientes: Cliente[];
  mascaraCNPJCPF: string;
  produtos: Produto[];
  usuarios: Usuario[];
  agencias: Agencia[];
  contasDoCooperado: ContaBancaria[];
  dadosAdesao: DadosAdesao;
  tiposPagamento = enumToArray(TipoPagamentoCupom);
  titulo: string;
  cupomAtual: Cupom;
  campanha: Campanha;
  formulario: UntypedFormGroup;
  pagamentoParcelado: boolean;
  cupomAgrupadoDto: CupomAgrupadoDto;
  gerarTermoCampanha: boolean;
  campanhasVigentes: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
    private cupomService: CupomService,
    private mensagemService: MensagemService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private apiService: ApiService,
    private campanhaService: CampanhaService,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private pesquisaService: PesquisaService,
    private agenciaService: AgenciaService,
    private datePipe: DatePipe,
    private realPipe: RealPipe,
    private clienteService: ClienteService) { }

  ngOnDestroy(): void {}

  async ngOnInit(): Promise<void> {
    this.mascaraCNPJCPF = '000.000.000-00||00.000.000/0000-00';
    this.formulario = this.formBuilder.group({
      nomeCliente: [null, Validators.required],
      cpfCNPJ: [null, [Validators.required, Validador.isValidCpfCnpj()]],
      email: [null, [Validators.required, Validators.email]],
      telefone: [null, Validators.required],
      agencia: [null, Validators.required],
      produtoId: [null, Validators.required],
      colaborador: [this.apiService.UsuarioLogado, Validators.required],
      conta: [null, Validators.required],
      qtdeCupons: [1, Validators.required],
      valorPago: [null, Validators.required],
      tipoPagamento: [null, Validators.required],
      parcelas: [0],
      valorParcela: [0],
      dataDebito: [null]
    });
    this.carregarListas();
    this.pesquisaService.resultadoEmitter.subscribe((res: Cliente) => {
      this.adicionarCooperado(res);
    });
    const path = this.activatedRoute.snapshot.url.map(u => u.path)[0];
    if (path !== 'Cupom') this.campanhasVigentes = this.activatedRoute.snapshot.url.map(u => u.path)[3].toString() === 'false' ? false : true;
    this.gerarTermoCampanha = path === 'Campanha';
    if (!this.gerarTermoCampanha) {
      const id = Number(this.activeRoute.snapshot.paramMap.get('id'));
      await this.cupomService.ObterCupom(id).then(async result => {
        this.cupomAtual = result as Cupom;
        await this.cupomService.obterCupomAgrupado(id).then(ca => {
          this.cupomAgrupadoDto = ca as CupomAgrupadoDto;
        });
        await this.obterCampanha(this.cupomAtual.idCampanha);
        /* Verifica se já possui termo gerado */
        await this.campanhaService.obterTermoAdesao(this.cupomAtual.agrupamento).then(res => {
          this.dadosAdesao = res as DadosAdesao;
        });
        this.popularForm();
      });
    }
    else {
      let idCampanha = Number(this.activatedRoute.snapshot.url.map(u => u.path)[2]);
      await this.obterCampanha(idCampanha);
    }
    this.titulo = this.dadosAdesao !== null && this.dadosAdesao !== undefined ? 'Alterar Termo de Adesão' : 'Gerar Termo de Adesão';
    this.titulo += ` - CAMPANHA: ${this.campanha.nome}`;
    this.formulario.get('valorPago').setValue(this.getValor());
  }

  getValor(): number {
    let quantidade = Number(this.formulario.get('qtdeCupons').value);
    return this.campanha.valorCupom * quantidade;
  }

  atualizaValor(): void {
    this.formulario.get('valorPago').setValue(this.getValor());
  }

  async obterCampanha(id: number): Promise<void> {
    await this.campanhaService.ObterCampanha(id).then(res => {
      this.campanha = res as Campanha;
    });
  }

  popularForm(): void {
    this.mascaraCNPJCPF = null;
    this.formulario.patchValue({
      nomeCliente: this.cupomAtual.nome,
      cpfCNPJ: this.cupomAtual.cpF_CNPJ,
      conta: this.cupomAtual.conta,
      email: this.cupomAtual.email,
      telefone: this.cupomAtual.telefone,
      agencia: this.cupomAtual.agencia,
      produtoId: this.cupomAtual.produtoId,
      colaborador: this.usuarios.filter(x => x.id === this.cupomAtual.idColaborador)[0],
      qtdeCupons: this.cupomAgrupadoDto !== null && this.cupomAgrupadoDto !== undefined ? this.cupomAgrupadoDto.quantidadeCupons : this.cupomAtual.quantidade,
      valorPago: this.cupomAgrupadoDto !== null && this.cupomAgrupadoDto !== undefined ? this.cupomAgrupadoDto.valorCupons : this.cupomAtual?.quantidade * this.campanha?.valorCupom,
      tipoPagamento: this.dadosAdesao !== null && this.dadosAdesao !== undefined ? this.dadosAdesao.tipoPagamento : null,
      parcelas: this.dadosAdesao !== null && this.dadosAdesao !== undefined ? this.dadosAdesao.parcelas : 0,
      valorParcela: this.dadosAdesao !== null && this.dadosAdesao !== undefined ? this.dadosAdesao.valorParcela : 0,
      dataDebito: this.dadosAdesao !== null && this.dadosAdesao !== undefined ? this.dadosAdesao.dataDebito : null
    });
    this.mascaraCNPJCPF = this.cupomAtual.cpF_CNPJ.length === 11 ? '000.000.000-00' : '00.000.000/0000-00';
    this.formulario.markAsPristine();
  }

  cancelar(): void {
    if (!this.gerarTermoCampanha)
      this.router.navigate([`/Cupom/${this.cupomAtual.idCampanha}`]);

    if (this.campanhasVigentes || (this.apiService.UsuarioLogado.nivel !== 'ADMINISTRADOR' && this.apiService.UsuarioLogado.nivel !== 'ASSESSOR')) this.router.navigate(['/campanhas/vigentes']);
    else this.router.navigate(['/Campanhas']);
  }

  async onSubmit(): Promise<void> {
    try {
      /* Preenche o novo cupom  */
      this.cupomAtual = {
        id: this.cupomAtual?.id > 0 ? this.cupomAtual?.id : 0,
        agrupamento: this.cupomAtual?.agrupamento,
        nome: this.formulario.get('nomeCliente').value,
        cpF_CNPJ: this.formulario.get('cpfCNPJ').value,
        email: this.formulario.get('email').value,
        telefone: this.formulario.get('telefone').value,
        agencia: this.formulario.get('agencia').value,
        conta: this.formulario.get('conta').value,
        ehCooperado: false,
        dataEmissao: new Date(),
        idColaborador: ((this.formulario.get('colaborador').value) as Usuario).id,
        idCampanha: this.campanha.id,
        produtoId: this.formulario.get('produtoId').value,
        quantidade: this.formulario.get('qtdeCupons').value,
        foiParcelado: this.formulario.get('tipoPagamento').value === TipoPagamentoCupom.À_Vista ? false : true,
        foiQuitado: this.formulario.get('tipoPagamento').value === TipoPagamentoCupom.À_Vista ? true : false,
        paColaborador: ((this.formulario.get('colaborador').value) as Usuario).codPA
      } as Cupom;
      this.dadosAdesao = {
        id: this.dadosAdesao === null || this.dadosAdesao === undefined ? 0 : this.dadosAdesao.id,
        idCampanha: this.cupomAtual.idCampanha,
        idColaborador: ((this.formulario.get('colaborador').value) as Usuario).id,
        nomeCliente: this.formulario.get('nomeCliente').value,
        cpfCNPJ: this.formulario.get('cpfCNPJ').value,
        conta: this.formulario.get('conta').value,
        qtdeCupons: this.formulario.get('qtdeCupons').value,
        agrupamento: this.cupomAtual.agrupamento !== null && this.cupomAtual.agrupamento !== undefined ? this.cupomAtual.agrupamento : '',
        valorPago: this.formulario.get('valorPago').value,
        tipoPagamento: this.formulario.get('tipoPagamento').value,
        parcelas: this.formulario.get('parcelas').value,
        valorParcela: this.formulario.get('valorParcela').value !== null ? Number(this.formulario.get('valorParcela').value?.toString().replace(',', '.')) : 0,
        dataDebito: this.formulario.get('dataDebito').value,
        cupom: this.cupomAtual
      } as DadosAdesao;
      await this.cupomService.adicionarTermoAdesao(this.dadosAdesao).then(res => {
        this.dadosAdesao = res as DadosAdesao;
      });
      await this.imprimirTermo();
      //await this.imprimirCupom();
      this.cancelar();
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage(`Erro ao gerar Termo de Adesão.`, 'Ok', 5000, 'error');
    }
  }

  limparFormulario(): void {
    this.formulario.patchValue({
      nomeCliente: null,
      cpfCNPJ: null,
      conta: null,
      qtdeCupons: 0,
      valorPago: 0,
      tipoPagamento: 0,
      parcelas: 1,
      valorParcela: 0,
      dataDebito: null
    });
  }

  tipoPagamentoChange(event: any): void {
    if (event === 2) {
      this.pagamentoParcelado = true;
      this.formulario.get('parcelas').setValidators(Validators.required);
      this.formulario.get('valorParcela').setValidators(Validators.required);
      this.formulario.get('dataDebito').setValidators(Validators.required);
      this.verificarParcela();
    } else {
      this.pagamentoParcelado = false;
      this.formulario.get('parcelas').clearValidators();
      this.formulario.get('valorParcela').clearValidators();
      this.formulario.get('dataDebito').clearValidators();
    }
    this.formulario.get('parcelas').updateValueAndValidity();
    this.formulario.get('valorParcela').updateValueAndValidity();
    this.formulario.get('dataDebito').updateValueAndValidity();
  }

  verificarParcela(): void {
    let valorPago = Number(this.formulario.get('valorPago').value);
    let parcelas = Number(this.formulario.get('parcelas').value);
    let qtdeMaxParcelas = valorPago / 100;
    let dataAtual = new Date();
    let anoAtual = dataAtual.getFullYear();
    let anoFim = new Date(this.campanha.dataFim).getFullYear();
    let mesFimCampanha = new Date(this.campanha.dataFim).getMonth() + 1;

    if (anoAtual == anoFim) {
      let mesAtual = dataAtual.getMonth() + 1;
      if (mesAtual === mesFimCampanha) {
        qtdeMaxParcelas = 1;
        this.mensagemService.showMessage(`Atenção! Data limite para o pagamento é de ${this.datePipe.transform(this.campanha.dataFim, 'dd/MM/yyyy')}.`, 'Ok', 5000, 'warning');
        this.formulario.get('parcelas').setValue(qtdeMaxParcelas);
        this.formulario.get('parcelas').updateValueAndValidity();
        return;
      }
      qtdeMaxParcelas = mesAtual === 1 ? 12 : 12 - mesAtual;
      if (parcelas > qtdeMaxParcelas) {
        this.mensagemService.showMessage(`Parcelamento máximo ${qtdeMaxParcelas}x.`, 'Ok', 5000, 'warning');
        this.formulario.get('parcelas').setValue(qtdeMaxParcelas);
        this.formulario.get('parcelas').updateValueAndValidity();
        return;
      }
    }
    if (valorPago === 0) valorPago = 1;
    if (parcelas === 0) {
      parcelas = 1;
    }
    let valorParcela = valorPago / parcelas;
    let valorMinimo = this.campanha.valorCupom / 12;
    if (valorParcela < valorMinimo) {
      this.mensagemService.showMessage(`Parcela não pode ser menor que ${this.realPipe.transform(valorMinimo)}`, 'Ok', 5000, 'warning');
      parcelas = valorPago / valorMinimo;
      valorParcela = valorPago / parcelas;
    }

    this.formulario.get('parcelas').setValue(parcelas);
    this.formulario.get('parcelas').updateValueAndValidity();
    this.formulario.get('valorParcela').setValue(valorParcela);
    this.formulario.get('valorParcela').disable();
    this.formulario.get('valorParcela').updateValueAndValidity();
  }

  async imprimirTermo(): Promise<void> {
    await this.cupomService.imprimirTermoAdesao(this.dadosAdesao).then(
      async result => {
        if (result !== null && result !== undefined) {
          let file = new File([result], "Termo de Adesão.pdf", { type: (<any>result).type });
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.apiService.StrDocument = (reader.result as string);
            this.apiService.pdfViewerEmitter.emit(true);
          };
        }
        else {
          this.mensagemService.showMessage('Erro ao gerar Termo de Adesão.', 'Ok', 5000, 'error');
        }
      });
  }

  async imprimirCupom(): Promise<void> {
    if (this.dadosAdesao.cupom !== null && this.dadosAdesao.cupom !== undefined) {
      if (!this.dadosAdesao.cupom?.foiParcelado) {
        this.aguardaImpressao();
      }
    }
  }

  aguardaImpressao(): void {
    setTimeout(() => {
      if (!this.apiService.PdfViewerIsShowing) {
        this.campanhaService.emitirCupom(this.dadosAdesao?.cupom?.id).then(
          result => {
            if (result !== null && result !== undefined) {
              this.mensagemService.showMessage('Cupom obtido com sucesso.', 'Ok', 5000, 'success');
              let file = new File([result], "Cupom.pdf", { type: (<any>result).type });
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                this.apiService.StrDocument = (reader.result as string);
                this.apiService.pdfViewerEmitter.emit(true);
              };
            }
            else {
              this.mensagemService.showMessage('Erro ao obter Cupom.', 'Ok', 5000, 'error');
            };
          });
      }
      if (this.apiService.PdfViewerIsShowing) this.aguardaImpressao();
    }, 1000);
  };

  searchCooperado(valorDigitado: string) {
    this.textoCooperadoNome.next(valorDigitado);
  }

  async pesquisaCooperado(): Promise<void> {
    if (this.isReadOnly()) return;
    const definicaoColunas: DefinicaoColuna[] = [
      { definicao: 'id', exibicao: 'Id' },
      { definicao: 'associado', exibicao: 'Nome' },
      { definicao: 'cpF_CNPJ', exibicao: 'CPF / CNPJ' },
      { definicao: 'conta', exibicao: 'Conta' }
    ];
    const colunas = this.clientes?.map(c => {
      return {
        id: c.id,
        associado: c.associado,
        cpF_CNPJ: c.cpF_CNPJ,
        conta: c.conta
      }
    });
    this.pesquisaService.pesquisaEventEmitter.emit(true);
    this.pesquisaService.tituloEventEmitter.emit('Adicionar Cooperado');
    this.pesquisaService.mensagemEventEmitter.emit('Selecione o Cooperado desejado');
    this.pesquisaService.colunasEmitter.emit(colunas);
    this.pesquisaService.functionEmitter.emit(this.clienteService.obter);
    this.pesquisaService.entidadeServiceEmitter.emit(this.clienteService);
    this.pesquisaService.filtrosEmitter.emit(enumToArray(FiltroCliente));
    this.pesquisaService.filtroEmitter.emit(this.clienteService.filtroClienteEmitter);
    this.pesquisaService.descricaoFEmitter.emit(this.clienteService.descricaoFiltroEmitter);
    this.pesquisaService.columnsHeadersEmitter.emit(definicaoColunas);
    this.pesquisaService.moduloCotacaoEmitter.emit(true);
  }

  async carregarListas(): Promise<void> {
    this.produtos = [{ id: 1, descricao: "QUOTAS DE CAPITAL", grupo: null, grupoId: 0, exigirAnexoMCI: false, geraComissao: false, inativo: false, ramoAtividadeId: 0, ramoAtividade: null },
    //{ id: 2, descricao: "PREVIDÊNCIA", grupo: null, grupoId: 0, exigirAnexoMCI: false, geraComissao: false },
    { id: 3, descricao: "PONTOS SICOOB CARD", grupo: null, grupoId: 0, exigirAnexoMCI: false, geraComissao: false, inativo: false, ramoAtividadeId: 0, ramoAtividade: null }]
    this.agenciaService.todas().then(result => {
      this.agencias = this.apiService.verificaSeResult<Agencia[]>(result);
    });

    /* Clientes Obtém o primeiro Cliente para gerar as colunas da consulta */
    await this.clienteService.obter({
      length: 0,
      pageIndex: 0,
      pageSize: 1,
      previousPageIndex: 0
    }, true).then(res => {
      this.clientes = this.apiService.verificaSeResult<Cliente[]>(res.dados);
    });
    /* Usuários */
    this.usuarioService.ObterColaboradores().then(result => {
      this.usuarios = result;
      this.formulario.patchValue({
        colaborador: this.apiService.UsuarioLogado
      });
      this.formulario.markAsPristine();
    });
  }

  adicionarCooperado(cooperado: Cliente): void {
    let contas = cooperado.contas;
    let conta = '';
    let codPA = '';
    if (contas.length > 0) {
      conta = contas !== null ? contas[0].numeroConta : cooperado.conta;
      codPA = contas !== null ? contas[0].codPA : '';
    }
    else {
      conta = cooperado.conta;
    }
    let inputCooperado = document.getElementById('inputCooperado');
    if (inputCooperado !== null && inputCooperado !== undefined) {
      (<any>inputCooperado).value = cooperado.associado;
    }
    this.formulario.patchValue({
      clienteId: cooperado.id
    });
    this.mascaraCNPJCPF = null;
    let documento = new CpfPipe().transform(cooperado.cpF_CNPJ);
    this.formulario.get('cpfCNPJ').setValue(documento);
    this.formulario.get('nomeCliente').setValue(cooperado.associado);
    this.formulario.get('telefone').setValue(cooperado.telefoneCelular);
    this.formulario.get('agencia').setValue(codPA);
    this.formulario.get('conta').setValue(conta);
    this.contasDoCooperado = contas?.filter(x => x.ativa);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  isReadOnly(): boolean {
    if (this.dadosAdesao === null || this.dadosAdesao === undefined) return;
    return this.apiService.UsuarioLogado.nivel !== 'ADMINISTRADOR' && this.apiService.UsuarioLogado.nivel !== 'ASSESSOR';
  }
}
