<div class="white-container margin-nav mat-elevation-z8">
    <br>
    <div class="title1">{{titulo}}</div>
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="sorteados" *ngIf="sorteados !== null && sorteados !== undefined">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Posição </th>
            <td mat-cell *matCellDef="let row; let i = index">{{i + 1}}º</td>
        </ng-container>
        
        <ng-container matColumnDef="cupom">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cupom </th>
          <td mat-cell *matCellDef="let row"> {{row.strCupom}} </td>
        </ng-container>

        <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
            <td mat-cell *matCellDef="let row"> {{row.nome}} </td>
        </ng-container>

        <ng-container matColumnDef="agencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Agência </th>
            <td mat-cell *matCellDef="let row"> {{row.agencia}} </td>
        </ng-container>

        <ng-container matColumnDef="emissao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Emitido em: </th>
            <td mat-cell *matCellDef="let row"> {{row.dataEmissao |  date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="colaborador">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
            <td mat-cell *matCellDef="let row"> {{row.colaborador?.nome}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>    
    <br>
    <br>
    <mat-divider></mat-divider>
    <div class="item-right" fxLayout="column" fxLayoutAlign="end end">
        <button mat-button class="btnReturn" type="button" (click)="voltar()">Retornar</button>
      </div>
</div>
