<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="titulo"></app-titulo>
  <app-filtro
    [filtros]="filtros"
    [dataSource]="dataSource"
    [filtroEmitter]="parcelamentoCupomService.filtroParcelamentoCupomEmitter"
    [descricaoEmitter]="parcelamentoCupomService.descricaoFiltroEmitter"
    [periodoDataEmitter]="parcelamentoCupomService.dataFiltroEmitter"
  ></app-filtro>
  <div class="legenda">
    <mat-label><strong>Legenda: </strong></mat-label>
    <div class="icon-verde">
      <mat-icon>thumb_up</mat-icon><p>Concluído</p>
    </div>
    <div class="icon-amarelo">
      <mat-icon>warning</mat-icon><p>Pendente</p>
    </div>
    <div class="icon-blue">
      <mat-icon>edit</mat-icon><p>Parou aqui em {{dataUltimaAtualizacao | date: 'dd/MM/yyyy HH:mm' }}</p>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource">
    <!-- Coluna Icones -->
    <ng-container matColumnDef="icones">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
      </th>
      <td mat-cell *matCellDef="let row" class="center" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">
        <mat-icon *ngIf="obterIconeQuitados(row) !== null" class="icon-verde cursor">{{obterIconeQuitados(row)}}</mat-icon>
        <mat-icon *ngIf="obterIconePendentes(row) !== null" class="icon-amarelo cursor">{{obterIconePendentes(row)}}</mat-icon>
        <mat-icon *ngIf="obterIconeUltimaAtualizacao(row) !== null" class="icon-blue cursor">{{obterIconeUltimaAtualizacao(row)}}</mat-icon>
      </td>
    </ng-container>
    <!-- Coluna Id -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        Id
      </th>
      <td mat-cell *matCellDef="let row" class="center" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.id }}</td>
    </ng-container>
    <!-- Coluna DataEmissao -->
    <ng-container matColumnDef="dataEmissao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        Data de Emissão
      </th>
      <td mat-cell *matCellDef="let row" class="center" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.dataEmissao | date: 'dd/MM/yyyy' }}</td>
    </ng-container>

    <!-- Coluna cooperado -->
    <ng-container matColumnDef="cooperado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cooperado</th>
      <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.nomeCliente }}</td>
    </ng-container>
    <!-- Coluna CPFCNPJ -->
    <ng-container matColumnDef="cpf_cnpj">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF/CNPJ</th>
      <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.cpfCNPJ  | CPF}}</td>
    </ng-container>
    <!-- Coluna Conta -->
    <ng-container matColumnDef="conta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Conta</th>
      <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row?.conta }}</td>
    </ng-container>
    <!-- Coluna quantidade -->
    <ng-container matColumnDef="pa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">PA da venda</th>
      <td mat-cell *matCellDef="let row" class="center" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.paColaborador }}</td>
    </ng-container>
    <!-- Coluna quantidade -->
    <ng-container matColumnDef="quantidade">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">Qtde Cupons</th>
      <td mat-cell *matCellDef="let row" class="center" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.qtdeCupons }}</td>
    </ng-container>
     <!-- Coluna Colaborador -->
     <ng-container matColumnDef="colaborador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Colaborador</th>
      <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ getColaborador(row.idColaborador) }}</td>
    </ng-container>
     <!-- Coluna valor -->
     <ng-container matColumnDef="valor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
      <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.valorPago | reais}}</td>
    </ng-container>
    <!-- Coluna Parcelas -->
    <ng-container matColumnDef="parcelas">
     <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">Parcelas</th>
     <td mat-cell *matCellDef="let row" class="center" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{row.parcelas}}x</td>
   </ng-container>
   <!-- Coluna ValorParcela -->
   <ng-container matColumnDef="valorParcela">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
    <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">{{ row.valorParcela | reais}}</td>
  </ng-container>
  <!-- Coluna QuitarParcela -->
  <ng-container matColumnDef="quitarParcela">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"></th>
    <td mat-cell *matCellDef="let row" (click)="parcelamento(row.agrupamento)" class="center" [ngStyle]="{'border-bottom': styleUltimaAtualizacao(row)}">
      <a matTooltip="Informa a quitação da parcela" matTooltipClass="tooltip-green" class="btnGridMin center">
        <mat-icon class="center">attach_money</mat-icon>
      </a>
    </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ selectedRow: row == termoSelecionado }"
      (click)="rowClick(row)"
    ></tr>
  </table>
  <app-paginator [(length)]="qtdeRegistros"></app-paginator>
  <div class="mat-dialog-actions item-right">
    <button
      mat-button
      class="btnCancel mat-elevation-z8"
      (click)="voltar()"
    >
      Voltar
    </button>
  </div>
</div>
