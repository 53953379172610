import { TopProdutosDto } from './../../dto/topProdutosDto';
import { TopAtendentesDto } from './../../dto/topAtendentesDto';
import { MciUsuario } from './../../entidades/mciUsuario';
import { Paginator } from './../../entidades/paginator';
import { ApiService } from './../api/api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { MCI } from 'src/app/entidades/mci';
import { httpVerb } from 'src/app/enum/httpVerb';
import { Pagina } from 'src/app/entidades/pagina';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Periodo } from 'src/app/entidades/periodo';
import { ResumoMCIPorPADto } from 'src/app/dto/resumoMCIPorPADto';
import { PeriodoDto } from 'src/app/dto/periodoDto';
import { ResumoMCIPorGerenteDto } from 'src/app/dto/resumoMCIPorGerenteDto';
import { MinhasCotacoesDto } from 'src/app/dto/minhasCotacoesDto';

@Injectable({
  providedIn: 'root'
})
export class MciService {
  filtroMciEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();
  dataFiltroEmitter = new EventEmitter<Periodo>();

  getFiltroMciEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getDescricaoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getDataFiltroEmitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  constructor(private apiService: ApiService) { }

  async obter(paginator: Paginator): Promise<Pagina<MCI>> {
    try {
      let paginaAtual = null;
      const complemento = `/Mci?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request(complemento, httpVerb.get).then(result => {
        paginaAtual = this.apiService.verificaSeResult<Pagina<MCI>>(result);
      });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterUsuariosDaMCI(mciId: number): Promise<MciUsuario[]> {
    try {
      let mu: MciUsuario[];
      await this.apiService.request<MciUsuario[]>(`/Mci/Usuarios/${mciId}`, httpVerb.get).then(result => {
        mu = this.apiService.verificaSeResult<MciUsuario[]>(result);
      });
      return mu;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async todas(): Promise<MCI[]> {
    try {
      let mcis: MCI[];
      await this.apiService.request('/Mci', httpVerb.get).then(result => {
        mcis = this.apiService.verificaSeResult<MCI[]>(result);
      });
    } catch (error) {
      console.log(error);
      return null
    }
  }

  async obterMCI(id: number): Promise<MCI> {
    try {
      let mci: MCI;
      await this.apiService.request(`/Mci/${id}`, httpVerb.get).then(result => {
        mci = this.apiService.verificaSeResult<MCI>(result);
      });
      return mci;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterMCIdaCotacao(cotacaoId: number): Promise<MCI> {
    try {
      let mci: MCI;
      await this.apiService.request(`/Mci/Cotacao/${cotacaoId}`, httpVerb.get).then(result => {
        mci = this.apiService.verificaSeResult<MCI>(result);
      });
      return mci;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async delete(id: number, motivoExclusao: string): Promise<boolean> {
    try {
      let excluiu: boolean;
      await this.apiService.request<boolean>(`/Mci/${id}`, httpVerb.put, JSON.stringify(motivoExclusao)).then(result => {
        excluiu = this.apiService.verificaSeResult<boolean>(result);
      });
      return excluiu;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<MCI>> {
    try {
      let paginaAtual = null;
      const complemento = `/Mci/filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<MCI>>(complemento, httpVerb.post, dto)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<MCI>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditar(novaMCI: MCI): Promise<MCI> {
    try {
      let mci: MCI;
      await this.apiService.request('/Mci', httpVerb.put, novaMCI).then(result => {
        mci = this.apiService.verificaSeResult<MCI>(result);
      });
      return mci;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterProdutividadePorPA(periodo: PeriodoDto): Promise<ResumoMCIPorPADto[]> {
    try {
      let resumo: ResumoMCIPorPADto[];
      await this.apiService.request<ResumoMCIPorPADto[]>(`/Mci/ProdutividadePorPA`, httpVerb.post, periodo).then(res => {
        resumo = this.apiService.verificaSeResult<ResumoMCIPorPADto[]>(res);
      });
      return resumo;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterProdutividadePorGerente(periodo: PeriodoDto): Promise<ResumoMCIPorGerenteDto[]> {
    try {
      let resumo: ResumoMCIPorGerenteDto[];
      await this.apiService.request<ResumoMCIPorGerenteDto[]>(`/Mci/ProdutividadePorGerente`, httpVerb.post, periodo).then(res => {
        resumo = this.apiService.verificaSeResult<ResumoMCIPorGerenteDto[]>(res);
      });
      return resumo;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async topProdutos(periodo: PeriodoDto): Promise<TopProdutosDto[]> {
    try {
      let resumo: TopProdutosDto[];
      await this.apiService.request<TopProdutosDto[]>(`/Mci/TopProdutos`, httpVerb.post, periodo).then(res => {
        resumo = this.apiService.verificaSeResult<TopProdutosDto[]>(res);
      });
      return resumo;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async topAtendentes(periodo: PeriodoDto): Promise<TopAtendentesDto[]> {
    try {
      let resumo: TopAtendentesDto[];
      await this.apiService.request<TopAtendentesDto[]>(`/Mci/TopAtendentes`, httpVerb.post, periodo).then(res => {
        resumo = this.apiService.verificaSeResult<TopAtendentesDto[]>(res);
      });
      return resumo;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
