import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';
import { ApiToken } from './../../entidades/token';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Credenciais } from 'src/app/entidades/credenciais';
import { RealPipe } from 'src/app/pipes/real/real.pipe';
import { httpVerb } from 'src/app/enum/httpVerb';
import { firstValueFrom } from 'rxjs';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: any;
  private token: string;
  private expirationTime: Date;
  credenciais: Credenciais;
  obteveConfig: boolean;
  itensPorPagina: number;
  idCampanhaAtual: number;

  sidNavClickEmitter = new EventEmitter<boolean>();
  pararTimerEmitter = new EventEmitter<boolean>();
  menuClearEmitter = new EventEmitter<boolean>();
  monitorDadosEmitter = new EventEmitter<boolean>();
  atualizarSiteEmiiter = new EventEmitter<string>();
  limparFiltroEmitter = new EventEmitter<boolean>();
  minhasAtividadesEmitter = new EventEmitter<boolean>();

  getLimparFiltroEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  constructor(private http: HttpClient) { }

  async loadConfig(): Promise<any> {
    this.appConfig = environment;
    return this.appConfig;
  }

  obterEstadosBrasileiros(): Promise<any> {
    return firstValueFrom(this.http.get('../../../assets/json/estados.json'));
  }

  obterBancos(): Promise<any> {
    return firstValueFrom(this.http.get('../../../assets/json/bancos.json'));
  }

  async obterVersao(): Promise<any> {
    return require('../../../../package.json').version;
  }

  getAppConfig(): any {
    return JSON.parse(sessionStorage.getItem("AppConfig"));
  }


  setAppConfig(val: any): any {
    this.appConfig = val;
    this.obteveConfig = true;
    this.itensPorPagina = 10;
    this.idCampanhaAtual = this.appConfig.idCampanhaAtual;
  }


  get apiBaseUrl(): any {
    return this.appConfig.headerDict.apiBaseUrl;
  }

  get requestOptionsForBlob(): any {
    if (!this.appConfig) this.appConfig = JSON.parse(sessionStorage.getItem("AppConfig"));
    const requestOptions = {
      headers: new HttpHeaders(this.appConfig.headerDict),
      responseType: 'Blob'
    };
    return requestOptions;
  }

  get requestOptionsForFormData(): any {
    if (!this.appConfig) this.appConfig = JSON.parse(sessionStorage.getItem("AppConfig"));
    const requestOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Authorization': 'Bearer ' + this.token
      })
    };
    return requestOptions;
  }

  get requestOptions(): any {
    if (!this.appConfig) this.appConfig = JSON.parse(sessionStorage.getItem("AppConfig"));
    const requestOptions = {
      headers: new HttpHeaders(this.appConfig.headerDict)
    };
    return requestOptions;
  }

  async setAuthorization(token: string) {
    if (!this.appConfig) this.appConfig = JSON.parse(sessionStorage.getItem("AppConfig"));
    this.token = token;
    this.appConfig.headerDict.Authorization = 'Bearer ' + this.token;
  }

  async getToken(): Promise<ApiToken> {
    if (!this.appConfig) this.appConfig = JSON.parse(sessionStorage.getItem("AppConfig"));
    let result: any;
    result = await firstValueFrom(this.http.post(this.apiBaseUrl + '/token', JSON.stringify(this.credenciais), this.requestOptions));
    return result;
  }


  isNullAppConfig(): any {
    if (!this.appConfig) {
      throw Error('Arquivo de configurações não foi carregado.');
    }
  }

  onKeyUpEventData(event: any): void {
    var texto = event.target.value;
    if (texto.length === 2) {
      texto += '/';
      event.target.value = texto;
    }
    if (texto.length === 5) {
      texto += '/';
      event.target.value = texto;
    }
  }

  onKeyUpEventMoney(event: any): void {
    let texto = event.target.value;
    if (texto.indexOf('R$') === 0) {
      texto = texto.substring(3, texto.length).replace('NaN', '');
    }
    event.target.value = new RealPipe().transform(texto);
  }
}
