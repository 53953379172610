<div class="page-auth"></div>
<div class="back-login">
  <!-- <div class="img-login"> -->
  <!-- <img
    src="../../../assets/imagens/background.png"
    alt="Imagem da Sicoob Credipontal"
  /> -->
  <!-- <img
    src="../../../assets/imagens/gestao_background.jpg"
    alt="Imagem da Sicoob Credipontal"
  /> -->

  <!-- </div> -->
  <!-- </div> -->
  <main>
    <h1>Gestão Credipontal</h1>
    <h2>Controle, agilidade, gestão...</h2>
    <div class="outline">
      <img
        class="logoSicoob"
        src="../../../assets/icons/icon-128x128.png"
        alt="Logotipo do Sicoob"
      />
      <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
        <div>
          <div class="separator">Login</div>
          <div class="row-login">
            <mat-form-field
              class="margin-top"
              appearance="outline"
              fxFlex="100"
            >
              <input matInput #inputNomeUsuario formControlName="nomeusuario" />
            </mat-form-field>
          </div>
          <div class="row-login">
            <mat-form-field appearance="outline" fxFlex="100">
              <input
                type="password"
                matInput
                id="inputSenha"
                formControlName="senha"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="passwordVisibility($event)"
              >
                <mat-icon>{{ iconePassword }}</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="waiting">
          <button
            *ngIf="isBusy === false"
            mat-button
            class="btnLogin mat-elevation-z8"
            [disabled]="formulario.status == 'INVALID' || formulario.pristine"
            type="submit"
          >
            Entrar
          </button>
        </div>
      </form>
      <!-- <div class="versao" *ngIf="versao !== null && versao !== undefined">
        Versão {{ versao }}
      </div> -->
    </div>
    <div class="somos-feitos">
      <div class="faixa-verde"></div>
      <h5>Somos feitos de</h5>
      <h3>VALORES</h3>
      <div class="faixa-verde"></div>
    </div>
    <div class="logo-credipontal">
      <img
        src="../../../assets/imagens/credipontal_white.png"
        alt="Imagem da Sicoob Credipontal"
      />
    </div>
  </main>
</div>
