<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="'Cadastro de Campanhas'"></app-titulo>
  <form class="formContainer" [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput #inputNome formControlName="nome" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Descrição</mat-label>
        <input matInput #inputDescricao formControlName="descricao" />
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Data Inicial</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          formControlName="dataInicio"
          (keyup)="appConfigService.onKeyUpEventData($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Data Final</mat-label>
        <input
          matInput
          [matDatepicker]="picker2"
          formControlName="dataFim"
          (keyup)="appConfigService.onKeyUpEventData($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Tipo de Campanha</mat-label>
        <mat-select
          formControlName="tipoCampanhaId"
          (ngModelChange)="tipoCampanhaChanged()"
        >
          <mat-option *ngFor="let tipo of tiposCampanha" [value]="tipo?.id">{{
            tipo?.tipo
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row" *ngIf="tipoCampanhaGeral">
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Valor do Cupom ($) </mat-label>
        <input
          currencyMask
          matInput
          #inputValorCupom
          formControlName="valorCupom"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Template</mat-label>
        <mat-select formControlName="template">
          <mat-option *ngFor="let t of templates" value="{{ t }}">{{
            t
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Regulamento</mat-label>
        <mat-select formControlName="regulamento">
          <mat-option *ngFor="let r of regulamentos" value="{{ r }}">{{
            r
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Data do Sorteio</mat-label>
        <input
          matInput
          [matDatepicker]="picker3"
          formControlName="dataSorteio"
          (keyup)="appConfigService.onKeyUpEventData($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker3"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Hora Sorteio</mat-label>
          <input matInput formControlName="hora" type="time" />
      </mat-form-field>
    </div>
    <div class="row-top" *ngIf="tipoCampanhaGeral">
      <div class="row">
        <mat-checkbox formControlName="realizaSorteio">
          Realiza Sorteio?
        </mat-checkbox>
        <mat-form-field class="grow1" appearance="outline">
          <mat-label>Quantidade de Prêmios</mat-label>
          <input
            type="number"
            matInput
            #inputQtdeSorteados
            formControlName="qtdeSorteados"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Logotipo</mat-label>
          <input matInput #inputLogotipo formControlName="logotipo" />
        </mat-form-field>
      </div>
      <!-- <div class="row"> -->
      <div class="grid-box img-logo">
        <img src="{{ logo }}" />
      </div>
      <!-- </div> -->
      <mat-form-field appearance="outline">
        <mat-label>Url Arte dos Cupons</mat-label>
        <input matInput #inputUrlArteCupom formControlName="urlArteCupom" />
      </mat-form-field>
      <!-- <div class="row"> -->
      <div class="grid-box img-logo">
        <img src="{{ arteCupom }}" />
      </div>
      <!-- </div> -->
    </div>
    <br />
    <div #produtosCampanha class="grid-box">
      <mat-label>Associar Produtos</mat-label>
      <div class="divider"></div>
      <br />
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Produto</mat-label>
          <div class="busca">
            <input
              matInput
              id="inputProduto"
              type="text"
              [value]="produtoSelecionado?.descricao"
              (keyup)="searchProduto($event.target.value)"
            />
            <mat-icon (click)="abrirPesquisa()">search</mat-icon>
          </div>
        </mat-form-field>
      </div>
      <br />
      <table #tableProdutos mat-table [dataSource]="dsProdutosCampanha">
        <!-- Coluna Id -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let row">{{ row?.produto?.id }}</td>
        </ng-container>

        <!-- Coluna Descrição  -->
        <ng-container matColumnDef="descricao">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
          <td mat-cell *matCellDef="let row">{{ row?.produto.descricao }}</td>
        </ng-container>

        <ng-container matColumnDef="excluir">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="item-center"
          >
            Remover
          </th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
            <a (click)="removeProduto(row)"
              ><img class="imgRow" src="../../../assets/imagens/Lixeira.png"
            /></a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colunasProdutos"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: colunasProdutos"
          [ngClass]="{ selectedRow: row == produtoSelecionado }"
          (click)="rowClick(row)"
        ></tr>
      </table>
      <app-paginator [(length)]="qtdeRegistros"></app-paginator>
    </div>
    <div class="item-right">
      <button
        mat-button
        class="btnOK mat-elevation-z8"
        [disabled]="formulario.status == 'INVALID' || formulario.pristine"
        type="submit"
      >
        Salvar
      </button>
      <button
        mat-button
        class="btnCancel mat-elevation-z8"
        type="button"
        (click)="cancelar()"
      >
        Cancelar
      </button>
    </div>
  </form>
</div>
