import { FiltroProdutos } from './../../../enum/filtroProdutos';
import { TipoCampanha } from './../../../entidades/tipoCampanha';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Campanha } from 'src/app/entidades/campanha';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { CampanhaService } from 'src/app/services/campanha/campanha.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { MatTableDataSource } from '@angular/material/table';
import { CampanhaProduto } from 'src/app/entidades/campanhaProduto';
import { MatDialog } from '@angular/material/dialog';
import { DialogoComponent } from '../../dialogo/dialogo/dialogo.component';
import { DefinicaoColuna } from 'src/app/entidades/definicaoColuna';
import { Produto } from 'src/app/entidades/produto';
import { PesquisaService } from 'src/app/services/pesquisa/pesquisa.service';
import { enumToArray } from 'src/app/helper/enumToArray';
import { ProdutoService } from 'src/app/services/produto/produto.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BuscaDTO } from 'src/app/dto/buscaDTO';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-campanha-detalhe',
  templateUrl: './campanha-detalhe.component.html',
  styleUrls: ['../../../app.component.css', './campanha-detalhe.component.css']
})
export class CampanhaDetalheComponent implements OnInit {
  campanha: Campanha;
  isBusy = false;
  formulario: UntypedFormGroup;
  dataInicio: Date;
  dataFim: Date;
  tiposCampanha: TipoCampanha[];
  campanhasProdutos: CampanhaProduto[] = [];
  colunasProdutos: string[] = ['id', 'descricao', 'excluir'];
  dsProdutosCampanha: MatTableDataSource<CampanhaProduto>;
  qtdeRegistros: number;
  produtoSelecionado: Produto;
  produtos: Produto[];
  templates: string[];
  regulamentos: string[];
  logo: string;
  arteCupom: string;
  tipoCampanhaGeral: boolean;

  private textoProdutoDescricao = new Subject<string>();

  constructor(private formBuilder: UntypedFormBuilder,
    public appConfigService: AppConfigService,
    private campanhaService: CampanhaService,
    private apiService: ApiService,
    private mensagemService: MensagemService,
    private router: Router,
    private dialog: MatDialog,
    private pesquisaService: PesquisaService,
    private produtoService: ProdutoService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService) { }

  async ngOnInit(): Promise<void> {
    this.criarFormulario();
    this.campanhaService.obterTipos().then(result => {
      this.tiposCampanha = this.apiService.verificaSeResult<TipoCampanha[]>(result);
    });
    await this.campanhaService.obterTemplates().then(async res => {
      this.templates = res;
      await this.campanhaService.obterRegulamentos().then(reg => {
        this.regulamentos = reg;
      });
    });

    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    if (id > 0) {
      this.campanhaService.ObterCampanha(id).then(result => {
        this.campanha = result as Campanha;
        this.logo = this.campanha.logotipo;
        this.arteCupom = this.campanha.urlArteCupom;
        this.popularForm();
        this.popularProdutosCampanha();
      });
    }

    this.textoProdutoDescricao.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(async valorDigitado => {
        if (valorDigitado) {
          const busca: BuscaDTO = {
            filtro: FiltroProdutos.Descricao,
            texto: valorDigitado,
          }
          await this.abrirPesquisa().then(async res => {
            setTimeout(() => {
              this.pesquisaService.buscaEmitter.emit(busca);
            }, 250);
          });
        }
        return valorDigitado;
      })).subscribe((text: string) => { return text; });
    await this.obterTabelas();

    this.pesquisaService.resultadoEmitter.subscribe((res: Produto) => {
      this.adicionarProduto(res);
    });
  }

  adicionarProduto(produto: Produto): void {
    let inputProduto = document.getElementById('inputProduto');
    if (inputProduto !== null && inputProduto !== undefined) {
      (<any>inputProduto).value = '';
    }
    let campanhaProduto: CampanhaProduto = {
      campanhaId: this.campanha?.id,
      campanha: this.campanha,
      produto: produto,
      produtoId: produto?.id
    };
    this.campanhasProdutos.push(campanhaProduto);
    this.atualizaGridProdutos();
  }

  criarFormulario(): void {
    this.formulario = this.formBuilder.group({
      id: [0, Validators.required],
      nome: [null, Validators.required],
      descricao: [null, Validators.required],
      dataInicio: [null, Validators.required],
      dataFim: [null, Validators.required],
      realizaSorteio: [false],
      qtdeSorteados: [0],
      tipoCampanhaId: [0, Validators.required],
      valorCupom: [0],
      template: [null],
      logotipo: [null],
      regulamento: [null],
      urlArteCupom: [null],
      dataSorteio: [null],
      hora: [null]
    });
  }

  async obterTabelas(): Promise<void> {
    await this.produtoService.todos().then(result => {
      this.produtos = this.apiService.verificaSeResult<Produto[]>(result);
    });
  }

  popularForm(): void {
    this.formulario.patchValue({
      id: this.campanha?.id,
      nome: this.campanha?.nome,
      descricao: this.campanha?.descricao,
      dataInicio: this.campanha?.dataInicio,
      dataFim: this.campanha?.dataFim,
      realizaSorteio: this.campanha?.realizaSorteio,
      qtdeSorteados: this.campanha?.qtdeSorteados,
      tipoCampanhaId: this.campanha?.tipo.id,
      valorCupom: this.campanha?.valorCupom,
      template: this.campanha?.template,
      logotipo: this.campanha?.logotipo,
      regulamento: this.campanha?.regulamento,
      urlArteCupom: this.campanha?.urlArteCupom,
      dataSorteio: this.campanha?.dataSorteio,
      hora: this.utilService.getStrTimeFromDate(this.campanha?.dataSorteio, 'hh:mm')
    });
    this.formulario.markAsPristine();
  }



  popularProdutosCampanha(): void {
    if (this.campanha?.produtos !== null || this.campanha?.produtos !== undefined) {
      this.campanhasProdutos = this.campanha.produtos;
      this.atualizaGridProdutos();
    }
  }

  onSubmit(): void {
    this.isBusy = true;
    this.campanha = this.formulario.value as Campanha;
    const editando = this.campanha.id === 0 ? false : true;
    const msg = editando ? 'alterada' : 'adicionada';
    this.campanha.produtos = this.dsProdutosCampanha?.data;
    let ano = new Date(this.formulario.get('dataSorteio').value).getFullYear();
    let mes = new Date(this.formulario.get('dataSorteio').value).getMonth()+1;
    let dia = new Date(this.formulario.get('dataSorteio').value).getDate();
    let data = `${String(ano).padStart(4, '0')}-${String(mes).padStart(2, '0')}-${String(dia).padStart(2,'0')}`;
    let hora = this.formulario.get('hora').value;
    this.campanha.dataSorteio = new Date(`${data}T${hora}:00`);
    console.log(new Date(`${data}T${hora}:00`));
    /* Evita erro de Inserção indesejada */
    this.campanha?.produtos?.forEach(element => {
      element.campanha = null;
      element.produto = null;
    });
    this.campanhaService.AdicionarOuEditar(this.campanha, editando).then(result => {
      if (result !== null && result !== undefined) {
        this.mensagemService.showMessage(`Campanha ${msg} com sucesso.`, 'Ok', 5000, 'success');
        this.cancelar();
      } else {
        this.mensagemService.showMessage(`Campanha não pode ser ${msg}!`, 'Ok', 5000, 'error');
      }
    });
  }

  cancelar(): void {
    this.router.navigate(['/Campanhas']);
  }

  removeProduto(row: CampanhaProduto): void {
    const dialogRef = this.dialog.open(DialogoComponent, {
      data: {
        titulo: 'Remover Produto',
        pergunta: `Deseja relamente remover o Produto ${row.produto.descricao}?`
      },
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.campanhasProdutos.splice(this.campanhasProdutos.indexOf(row), 1);
        this.atualizaGridProdutos();
        this.formulario.markAsDirty();
      }
    });
  }

  atualizaGridProdutos(): void {
    this.dsProdutosCampanha = new MatTableDataSource(this.campanhasProdutos);
    this.qtdeRegistros = this.campanhasProdutos?.length;
  }

  async abrirPesquisa(): Promise<void> {
    const definicaoColunas: DefinicaoColuna[] = [
      { definicao: 'id', exibicao: 'Id' },
      { definicao: 'descricao', exibicao: 'Descrição' }
    ];
    const colunas = this.produtos.map(p => {
      return {
        id: p.id,
        descricao: p.descricao
      }
    });
    this.pesquisaService.pesquisaEventEmitter.emit(true);
    this.pesquisaService.tituloEventEmitter.emit('Adicionar Produto');
    this.pesquisaService.mensagemEventEmitter.emit('Selecione o Produto desejado');
    this.pesquisaService.colunasEmitter.emit(colunas);
    this.pesquisaService.functionEmitter.emit(this.produtoService.todos);
    this.pesquisaService.entidadeServiceEmitter.emit(this.produtoService);
    this.pesquisaService.filtrosEmitter.emit(enumToArray(FiltroProdutos));
    this.pesquisaService.filtroEmitter.emit(this.produtoService.filtroProdutosEmitter);
    this.pesquisaService.descricaoFEmitter.emit(this.produtoService.descricaoFiltroEmitter);
    this.pesquisaService.columnsHeadersEmitter.emit(definicaoColunas);
  }

  searchProduto(valorDigitado: string) {
    this.textoProdutoDescricao.next(valorDigitado);
  }

  tipoCampanhaChanged(): void {
    let tipoId = Number(this.formulario.get('tipoCampanhaId').value);
    let tipoCampanha = this.tiposCampanha.filter(x => x.id === tipoId)[0] as TipoCampanha;
    this.tipoCampanhaGeral = tipoCampanha.geral;
    this.ajustarCamposRequired();
  }

  ajustarCamposRequired(): void {
    if (this.tipoCampanhaGeral) {
      this.formulario.get('realizaSorteio').setValidators(Validators.required);
      this.formulario.get('qtdeSorteados').setValidators(Validators.required);
      this.formulario.get('valorCupom').setValidators(Validators.required);
      this.formulario.get('template').setValidators(Validators.required);
    }
    else {
      this.formulario.get('realizaSorteio').clearValidators();
      this.formulario.get('qtdeSorteados').clearValidators();
      this.formulario.get('valorCupom').clearValidators();
      this.formulario.get('template').clearValidators();
    }
    this.formulario.get('realizaSorteio').updateValueAndValidity();
    this.formulario.get('qtdeSorteados').updateValueAndValidity();
    this.formulario.get('valorCupom').updateValueAndValidity();
    this.formulario.get('template').updateValueAndValidity();
  }
}
