<div class="container margin-nav mat-elevation-z8">
  <app-titulo
    *ngIf="campanhaSelecionada !== null && campanhaSelecionada !== undefined"
    [titulo]="titulo"
  ></app-titulo>
  <form class="formContainer" [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Nome do Cooperado</mat-label>
        <div class="busca">
          <input
            matInput
            id="inputCooperado"
            formControlName="nome"
            (keyup)="searchCooperado($event.target.value)"
          />
          <mat-icon (click)="pesquisaCooperado()">search</mat-icon>
        </div>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>CPF / CNPJ</mat-label>
        <input
          matInput
          #inputCPF
          type="text"
          formControlName="cpf_cnpj"
          [mask]="mascaraCNPJCPF"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>e-mail</mat-label>
        <input matInput #inputEmail formControlName="email" />
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Telefone</mat-label>
        <input
          matInput
          #inputTelefone
          type="tel"
          [mask]="'(00) 00000-0000'"
          formControlName="telefone"
          maxlength="15"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Código PA</mat-label>
        <mat-select formControlName="agencia">
          <mat-option
            *ngFor="let agencia of agencias"
            value="{{ agencia?.codPA }}"
            >{{ agencia?.codPA }} - {{ agencia?.cidade }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Produto</mat-label>
        <mat-select formControlName="produtoId">
          <mat-option *ngFor="let produto of produtos" [value]="produto.id">{{
            produto.descricao
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Conta</mat-label>
        <input matInput #inputConta formControlName="conta" />
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Quantidade</mat-label>
        <input
          matInput
          #inputQuantidade
          type="number"
          formControlName="quantidade"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Colaborador</mat-label>
        <mat-select formControlName="idColaborador">
          <mat-option *ngFor="let usuario of usuarios" [value]="usuario.id">{{
            usuario.nome
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-accordion
        class="headers-align mat-elevation-z8"
        *ngIf="contasDoCooperado?.length >= 1"
      >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Contas Bancárias </mat-panel-title>
            <mat-panel-description>
              Contas ativas do Cooperado
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngFor="let conta of contasDoCooperado" class="cupom-contas">
            <label>Número: {{ conta.numeroConta }}</label>
            <label>PA: {{ conta.codPA }}</label>
            <label>Modalidade: {{ conta.modalidade }}</label>
            <label>Tipo: {{ conta.tipo }}</label>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="item-right">
      <button
        mat-button
        class="btnOK mat-elevation-z8"
        [disabled]="formulario.status == 'INVALID' || formulario.pristine"
        type="submit"
      >
        Salvar
      </button>
      <button
        mat-button
        class="btnCancel mat-elevation-z8"
        type="button"
        (click)="cancelar()"
      >
        Cancelar
      </button>
    </div>
  </form>
</div>
