import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'markBold'
})
export class MarkBoldPipe implements PipeTransform {
 transform(textValue: string, subTextValue: string): string {
     return textValue.replace(subTextValue, '<strong>' + subTextValue + '</strong>');
 }
}
