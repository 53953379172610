import { NgModule } from '@angular/core';
import { DataFormModule } from 'src/app/shared/data-form.module';
import { SharedModule } from './../../shared/shared.module';
import { UsuarioDetalheComponent } from './usuario-detalhe/usuario-detalhe.component';
import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioComponent } from './usuario.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha/alterar-senha.component';

@NgModule({
  declarations: [UsuarioComponent, UsuarioDetalheComponent, AlterarSenhaComponent],
  imports: [
    SharedModule,
    DataFormModule,
    UsuarioRoutingModule
  ]
})
export class UsuarioModule { }
