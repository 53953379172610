import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, NavigationEnd, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';
import { Usuario } from '../entidades/usuario';
import { httpVerb } from '../enum/httpVerb';
import { Parametros } from '../entidades/parametro';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate, CanLoad {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private appConfigService: AppConfigService,
    private apiService: ApiService,
    private location: PlatformLocation
  ) {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/login') {
          this.loginService.exibirNavBarEmitter.emit(false);
          let chave = sessionStorage.getItem('AuthenticateKey');
          if (chave !== null && chave !== undefined)
            this.loginService.logOut();
        } else if (event.urlAfterRedirects === '/') {
          await this.verificarAcesso();
        }
      } else if (this.router.url === '/login') {
        this.loginService.exibirNavBarEmitter.emit(false);
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verificarAcesso();
  }

  private async verificarAcesso(): Promise<boolean> {
    let codigoAutorizacao = this.loginService.codigoAutorizacao;
    let codigoAutenticacao = this.loginService.codigoAutenticacao;
    await this.appConfigService.loadConfig().then(async res => {
      this.appConfigService.setAppConfig(res);
      let nomeUsuario = sessionStorage.getItem("Usuário");
      if (nomeUsuario) {
        await this.apiService.request<Usuario>(`/usuario/nome/${nomeUsuario}`, httpVerb.get).then(async result => {
          const usuario = result;
          this.apiService.UsuarioLogado = usuario;
        });
        await this.apiService.request<Parametros>('/Parametros/Todos', httpVerb.get).then(result => {
          this.apiService.Parametros = this.apiService.verificaSeResult<Parametros>(result)[0] as Parametros;
        });
      }
      if (this.router.url !== '/login') this.loginService.exibirNavBarEmitter.emit(true);
    });
    if (this.loginService.usuarioEstaAutenticado() || (this.loginService.getSessionVariables("AuthorizedKey") === codigoAutorizacao && this.loginService.getSessionVariables("AuthenticateKey") === codigoAutenticacao)) {
      return true;
    }


    this.router.navigate(['/login']);
    return false;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verificarAcesso();
  }
}
