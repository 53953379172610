<div class="margin-nav item" fxLayout="column" fxLayout="start center"></div>
<div class="container margin-nav mat-elevation-z8">
  <app-titulo
    *ngIf="campanhaSelecionada !== null && campanhaSelecionada !== undefined"
    [titulo]="titulo"
  ></app-titulo>
  <br />
  <div class="row">
    <app-filtro
      [filtros]="filtros"
      [dataSource]="dataSource"
      [filtroEmitter]="cupomService.filtroCupomEmitter"
      [periodoDataEmitter]="filtroService.filtrarPorDataEmitter"
      [descricaoEmitter]="cupomService.descricaoFiltroEmitter"
    ></app-filtro>
    <button mat-button
      class="btnCancel"
      type="button"
      (click)="habilitarSelecao()"
      *ngIf="textoFiltroAtual !== '16' && textoFiltroAtual !== '15' && textoFiltroAtual !== '13' && (apiService.UsuarioLogado.nivel === 'ADMINISTRADOR' || apiService.UsuarioLogado.nivel === 'ASSESSOR')">
      <mat-icon>attach_money</mat-icon>{{ getNomeBotaoQuitacao() }}
    </button>
  </div>
  <table mat-table [dataSource]="dataSource" fxLayout="start center">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        <mat-checkbox (change)="selectAll($event)" *ngIf="multiSelecao" [checked]="selection?.selected?.length > 0"
          [indeterminate]="selection?.selected?.length > 0 && selection?.selected?.length < cupons?.length">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="select">
        <mat-checkbox (change)="selectHandler($event, row)" [checked]="selection?.isSelected(row.id)" *ngIf="habilitarCheckbox(row)"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Coluna Cupom -->
    <ng-container matColumnDef="strCupom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        Cupom
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        class="center"
        [ngStyle]="{ color: getColumnColor(row) }"
      >
      {{row.strCupom}}
      </td>
    </ng-container>

    <!-- Coluna Cooperado -->
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cooperado</th>
      <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
    </ng-container>

    <!-- Coluna CPF_CNPJ -->
    <ng-container matColumnDef="cpF_CNPJ">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF / CNPJ</th>
      <td mat-cell *matCellDef="let row">{{ row.cpF_CNPJ | CPF }}</td>
    </ng-container>

    <!-- Coluna Colaborador -->
    <ng-container matColumnDef="colaborador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Colaborador</th>
      <td mat-cell *matCellDef="let row">
        {{ row.colaborador?.nome }}
      </td>
    </ng-container>

    <!-- Coluna PA -->
    <ng-container matColumnDef="pa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        PA da venda
      </th>
      <td mat-cell *matCellDef="let row" class="center">
        {{ row.paColaborador }}
      </td>
    </ng-container>

    <!-- Coluna Emitido em -->
    <ng-container matColumnDef="dataEmissao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Emitido em</th>
      <td mat-cell *matCellDef="let row">
        {{ row.dataEmissao | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- Coluna Telefone -->
    <ng-container matColumnDef="telefone" *ngIf="usuario.nivel === 'OPERADOR'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefone</th>
      <td mat-cell *matCellDef="let row">{{ row.telefone }}</td>
    </ng-container>

    <!-- Coluna e-mail -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <ng-container matColumnDef="termo">
      <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header>
        Obter Cuppom
      </th>
      <td
        class="center"
        mat-cell
        *matCellDef="let row"
        (click)="$event.stopPropagation()"
      >
        <a
          (click)="emitirCupom(row?.id)"
          matTooltip="Obtém o cupom gerado para visualização ou impressão."
          *ngIf="habilitaCupom(row)"
          ><img class="imgRow" src="../../../assets/imagens/dinheiro.png"
        /></a>
        <label mat-label class="red" *ngIf="row.cancelado">Cancelado em {{ row.dataCancelamento | date : "dd/MM/yyyy HH:mm" }}</label>
        <!-- <button
          mat-fab
          class="btnGridMin"
          (click)="quitarCupom(row)"
          *ngIf="habilitaQuitacao(row)"
          matTooltip="Informa a quitação do Cupom e gera um novo número!"
        >
          <mat-icon>attach_money</mat-icon>
        </button> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="cupom">
      <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header>
        Adesão
      </th>
      <td
        class="center"
        mat-cell
        *matCellDef="let row"
        matTooltip="Obtém o Termo de Adesão gerado para edição, visualização ou impressão."
        (click)="$event.stopPropagation()"
      >
        <a (click)="termoAdesao(row)" *ngIf="!row.cancelado"
          ><img class="imgRow" src="../../../assets/imagens/termo.png"
        /></a>
      </td>
    </ng-container>

    <ng-container matColumnDef="send_email">
      <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header>
        Email
      </th>
      <td
        class="center"
        mat-cell
        *matCellDef="let row"
        matTooltip="Envia o cupom e o regulamento da campanha para o cooperado via e-mail."
        (click)="$event.stopPropagation()"
      >
        <a
          (click)="sendEmail(row)"
          *ngIf="habilitaEmail(row)"
          ><img class="imgRow" src="../../../assets/imagens/Email.png"/></a>
      </td>
    </ng-container>

    <ng-container matColumnDef="remove">
      <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header>
        <div
          *ngIf="
            usuario.nivel === 'ADMINISTRADOR' || usuario.nivel === 'ASSESSOR'
          "
        >
          Cancelar
        </div>
      </th>
      <td
        class="center"
        mat-cell
        *matCellDef="let row"
        (click)="$event.stopPropagation()"
      >
        <div
          *ngIf="
            (usuario.nivel === 'ADMINISTRADOR' || usuario.nivel === 'ASSESSOR') && !row.cancelado
          "
        >
          <a (click)="cancelar(row)" matTooltip="Cancela o cupom selecionado!" class="red">
            <mat-icon class="rounded-icon-red">cancel</mat-icon>
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
  <app-paginator [(length)]="qtdeRegistros" [(multiSelecao)]="multiSelecao"></app-paginator>
  <div *ngIf="multiSelecao" class="selection">{{selection.selected.length}} cupom(s) selecionado(s).</div>
  <div class="item-right" fxLayout="column" fxLayoutAlign="end end">
    <button mat-button class="btnCancel" type="button" (click)="voltar()" *ngIf="!multiSelecao">
      Voltar
    </button>
    <button mat-button class="btnCancel"
      type="button"
      (click)="quitarCupons()" *ngIf="multiSelecao"
      [disabled]="!selection.hasValue()">
      <mat-icon>attach_money</mat-icon> Confirmar
    </button>
  </div>
</div>

