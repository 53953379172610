import { TipoCampanha } from './../../../entidades/tipoCampanha';
import { RealPipe } from './../../../pipes/real/real.pipe';
import { MetaCampanha } from './../../../entidades/metaCampanha';
import { TipoMetrica } from 'src/app/enum/tipoMetrica';
import { MetasCampanhaService } from './../../../services/metas-campanha/metas-campanha.service';
import { Component, OnInit, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Campanha } from 'src/app/entidades/campanha';
import { PosicaoCampanha } from 'src/app/entidades/posicaoCampanha';
import { ApiService } from 'src/app/services/api/api.service';
import { CampanhaService } from 'src/app/services/campanha/campanha.service';

@Component({
  selector: 'app-posicao-campanha',
  templateUrl: './posicao-campanha.component.html',
  styleUrls: ['./posicao-campanha.component.css', '../../../app.component.css']
})
export class PosicaoCampanhaComponent implements OnInit {
  isBusy = false;
  titulo: string;
  campanhaSelecionada: Campanha;
  posicaoCampanhaSelecionada: PosicaoCampanha;
  posicoes: PosicaoCampanha[];
  displayedColumns: string[] = ['codPA', 'meta', 'cupons', 'valor', 'percentual', 'bomRuim'];
  dataSource: MatTableDataSource<PosicaoCampanha>;
  metaCampanha: MetaCampanha;

  constructor(private router: Router,
    private metasCampanhaService: MetasCampanhaService,
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private campanhaService: CampanhaService) { }

  async ngOnInit(): Promise<void> {
    this.isBusy = true;
    const id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.campanhaService.ObterCampanha(id).then(async result => {
      this.campanhaSelecionada = result as Campanha;
      this.titulo = `Posição da Campanha: ${this.campanhaSelecionada.nome}`;
      await this.metasCampanhaService.PosicaoCampanha(id).then(async res => {
        this.posicoes = res as PosicaoCampanha[];
        this.dataSource = new MatTableDataSource(this.posicoes);
        await this.metasCampanhaService.obterMetaCampanha(this.campanhaSelecionada.id).then(m => {
          this.metaCampanha = m as MetaCampanha;
        });
      });
    });
  }

  rowClick(row: PosicaoCampanha): void {
    this.posicaoCampanhaSelecionada = row;
  }

  voltar(): void {
    if (this.campanhaService.campanhaVigente)
      this.router.navigate(['/campanhas/vigentes']);
    else
      this.router.navigate(['/campanhas']);
  }

  getMeta(row: PosicaoCampanha): string {
    if (this.metaCampanha?.tipoMetrica === TipoMetrica.PorValor) return new RealPipe().transform(row.metaValor);
    else return row.meta.toString();
  }

  getTotalValorVenda(): number {
    if (this.posicoes === null || this.posicoes === undefined) return 0;
    return this.posicoes?.map(p => p?.valorVenda)?.reduce((acc, value) => acc + value, 0);
  }

  getTotalCupons(): string {
    if (this.posicoes === null || this.posicoes === undefined) return "0";
    return this.posicoes?.map(p => p?.qtdeGerada)?.reduce((acc, value) => acc + value, 0).toLocaleString('pt-BR');
  }

  getTotalMeta(): number {
    if (this.posicoes === null || this.posicoes === undefined) return 0;
    return this.posicoes?.map(p => p?.metaValor)?.reduce((acc, value) => acc + value, 0);
  }

  getPercentual(row: PosicaoCampanha): string {
    return (row.percentualMeta).toLocaleString('pt-BR', { maximumFractionDigits: 2 });
  }
}
