import { Component, OnInit } from '@angular/core';
import { Aviso } from 'src/app/entidades/aviso';
import { Prioridade } from 'src/app/enum/prioridade';
import { ApiService } from 'src/app/services/api/api.service';
import { AvisoService } from 'src/app/services/aviso/aviso.service';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.css', '../../app.component.css']
})
export class AvisoComponent implements OnInit {
  avisos: Aviso[];
  podeExibir: boolean;
  constructor(private apiService: ApiService,
              private avisoService: AvisoService) { }

  async ngOnInit(): Promise<void> {
    this.avisoService.avisosEmitter.subscribe(async (exibir) => {
      if (exibir) {
        await this.avisoService.avisosNaoLidos(this.apiService.UsuarioLogado.id).then(res => {
          this.avisos = (res as Aviso[]).sort((a,b) => a.prioridade - b.prioridade);
        });
        if (this.avisos.length > 0) this.podeExibir = exibir;
      }
    });
  }

  getClassAviso(prioridade: Prioridade): string {
    switch (prioridade) {
      case Prioridade.Alta:
        return 'prioridade-alta';
      case Prioridade.Média:
        return 'prioridade-media';
      default:
        return 'prioridade-baixa';
    }
  }

  async confirmarLeitura(idAviso: number): Promise<void> {
    let idUsuario = this.apiService.UsuarioLogado.id;
    await this.avisoService.marcarLeitura(idAviso, idUsuario).then(res => {
      let avisos = res as Aviso[];
      if (avisos.length === 0) {
        this.podeExibir = false;
      } else {
        this.avisos = avisos;
      }
    });
  }
}
