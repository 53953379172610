import { Component, Input, OnInit } from '@angular/core';
import { Paginator } from 'src/app/entidades/paginator';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  @Input() length = 0;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() multiSelecao = false;
  pageSizeOptions: number[];
  paginator: Paginator;

  constructor(private paginatorService: PaginatorService,
    private appService: AppConfigService) { }

  ngOnInit(): void {
    this.pageSizeOptions = [5, 10, 15, 25, 50, 100];
    if (this.pageSize === 0 || this.pageSize === undefined)
      this.pageSize = this.appService.itensPorPagina;
  }

  getPaginatorData(event: any): void {
    sessionStorage.setItem('_pagina', JSON.stringify(event));
    this.paginatorService.emitirEvento(event);
  }
}
