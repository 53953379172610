import { Injectable } from '@angular/core';
import { MonitorDto } from 'src/app/dto/monitorDto';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  constructor(private apiService: ApiService) { }

  async obterDados(): Promise<MonitorDto> {
    try {
      let monitor = null;
      await this.apiService.request<MonitorDto>(`/Monitor`, httpVerb.get).then(res => {
        monitor = this.apiService.verificaSeResult<MonitorDto>(res);
      });
      return monitor;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
