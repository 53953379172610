import { OrdemDetalheCampanha } from './../../enum/ordemDetalheCampanha';
import { enumToArray } from 'src/app/helper/enumToArray';
import { TipoEndossoService } from './../../services/tipo-endosso/tipo-endosso.service';
import { TipoEndosso } from 'src/app/entidades/tipoEndosso';
import { AgenciaService } from './../../services/agencia/agencia.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, SecurityContext } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PeriodoDto } from 'src/app/dto/periodoDto';
import { Agencia } from 'src/app/entidades/agencia';
import { Campanha } from 'src/app/entidades/campanha';
import { MenuRelatorio } from 'src/app/entidades/menusRelatorio';
import { Usuario } from 'src/app/entidades/usuario';
import { ApiService } from 'src/app/services/api/api.service';
import { CampanhaService } from 'src/app/services/campanha/campanha.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { RelatorioService } from 'src/app/services/relatorio/relatorio.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { DialogoComponent } from '../dialogo/dialogo/dialogo.component';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { Produto } from 'src/app/entidades/produto';
import { ProdutoService } from 'src/app/services/produto/produto.service';
import { OpcaoRelatorio } from 'src/app/entidades/opcaoRelatorio';
import { ClienteService } from 'src/app/services/cliente/cliente.service';
import { Cliente } from 'src/app/entidades/cliente';
import { Status } from 'src/app/entidades/status';
import { StatusService } from 'src/app/services/status/status.service';
import { firstValueFrom } from 'rxjs';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.css', '../../app.component.css']
})
export class RelatoriosComponent implements OnInit {
  isBusy = false;
  formulario: UntypedFormGroup;
  titulo: string;
  dataInicial: Date;
  dataFinal: Date;
  informarPeriodo: boolean = true;
  informarSeRenovacao: boolean;
  informarAtendente: boolean;
  informarAssessor: boolean;
  informarColaborador: boolean;
  informarCampanha: boolean;
  informarPAeAtendente: boolean;
  informarPaOuAtendenteOuAssessor: boolean;
  informarPA: boolean;
  informarTipoEndosso: boolean;
  informarOrdem: boolean;
  informarProduto: boolean;
  exportarExcel = false;
  ehRenovacao: boolean = false;
  todoPeriodo: boolean;
  idRelatorio: number;
  usuarios: Usuario[];
  usuariosFiltrados: Usuario[];
  assessores: Usuario[];
  assessoresFiltrados: Usuario[];
  colaboradores: Usuario[];
  colaboradoresFiltrados: Usuario[];
  tiposEndosso: TipoEndosso[];
  tiposEndossoFiltrados: TipoEndosso[];
  campanha: Campanha;
  campanhas: Campanha[];
  campanhasFiltradas: Campanha[];
  agencias: Agencia[];
  agenciasFiltradas: Agencia[];
  produtos: Produto[];
  produtosFiltrados: Produto[];
  clientes: Cliente[];
  clientesFiltrados: Cliente[];
  status: Status[];
  statusFiltrados: Status[];
  menus: MenuRelatorio[];
  ordenacao: any;
  cancelamento: boolean;
  campanhaId: number = -1;
  opcoes: OpcaoRelatorio[];
  atendentes: Usuario[];
  atendentesFiltrados: Usuario[];
  radioSelected: number;
  templateOption: any;
  templateStr: any;
  radios: any;

  constructor(private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mensagemService: MensagemService,
    private relatorioService: RelatorioService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private campanhaService: CampanhaService,
    private agenciaService: AgenciaService,
    private tipoEndossoService: TipoEndossoService,
    private produtoService: ProdutoService,
    private clienteService: ClienteService,
    private statusService: StatusService,
    private sanitizer: DomSanitizer) { }

  async ngOnInit(): Promise<void> {
    this.isBusy = true;
    await this.obterAtendentes();
    await this.obterAssessores();
    await this.obterColaboradores();
    await this.obterCampanhas();
    await this.obterAgencias();
    await this.obterTiposEndosso();
    await this.obterProdutos();
    await this.obterClientes();
    await this.obterStatus();
    await this.obterMenus().then(res => {
      this.menus = res;
    });
    this.verificarSelecao();
    if (this.informarPaOuAtendenteOuAssessor) {
      this.informarPA = false;
      this.informarAtendente = false;
      this.informarAssessor = false;
    }
    this.campanhaId = this.data.campanhaId;
    if (this.data.temMenu) {
      this.campanhaId = this.data.campanhaId;
      this.data = this.menus.filter(x => x.id === this.data.id)[0];
    }
    this.activatedRoute.params.subscribe(params => {
      this.atualizaRelatorio();
      this.criarFormulario();
    });
    this.templateStr = this.data?.radioGroup.template;
    this.templateOption = this.sanitizer.sanitize(SecurityContext.HTML, this.templateStr);
    this.radios = [{
      inputLabel: "Todos",
      formControlName: "ehPlantao",
      value: "-1"
     },
     {
      inputLabel: "Sem Plantão",
      formControlName: "ehPlantao",
      value: "0"
     },
     {
      inputLabel: "Somente Plantão",
      formControlName: "ehPlantao",
      value: "1"
     }] as any;
  }

  obterMenus(): Promise<any> {
    return firstValueFrom(this.http.get('../../../assets/json/relatorios.json'));
  }

  setDataPadrao(): void {
    this.dataInicial = new Date();
    this.dataFinal = new Date();
    this.dataInicial.setDate(this.dataInicial.getDate() - 7);
  }

  criarFormulario(): void {
    this.formulario = undefined;
    switch (this.idRelatorio) {
      case 3:
        this.formulario = this.formBuilder.group({
          codPA: ["null"],
          atendenteId: [-1],
          cooperado: [null],
          clienteId: [-1],
        });
        break;
      case 4:
        this.formulario = this.formBuilder.group({
          atendenteId: [-1, Validators.required]
        });
        break;
      case 6:
        this.formulario = this.formBuilder.group({
          produtoId: [-1, Validators.required],
          codPA: ["null", Validators.required]
        });
        break;
      case 7:
        this.formulario = this.formBuilder.group({
          codPA: ["null", Validators.required]
        });
        break;
      case 8:
      case 9:
        this.formulario = this.formBuilder.group({
          campanhaId: [this.campanhaId, Validators.required]
        });
        break;
      case 12:
        this.cancelamento = true;
        this.formulario = this.formBuilder.group({
          atendenteId: [-1, Validators.required],
          colaboradorId: [-1, Validators.required],
          codPA: ["null", Validators.required]
        });
        break;
      case 13:
      case 14:
        this.formulario = this.formBuilder.group({
          atendenteId: [-1],
          assessorId: [-1],
          codPA: ["null"],
          tipoEndossoId: [0]
        });
        break;
      case 15:
        this.formulario = this.formBuilder.group({
          campanhaId: [this.campanhaId, Validators.required],
          codPA: ["null"],
          ordem: [0]
        });
        if (this.data.campanhaId !== null) this.formulario.patchValue({
          campanhaId: this.data.campanhaId
        });
        break;
      case 16:
        this.formulario = this.formBuilder.group({
          codPA: ["null"],
          ehPlantao: [-1],
          statusId: [-1]
        });
        this.status = this.status.filter(x => x.tipo === 11 || x.tipo == 17);
        break;
      case 17:
        this.formulario = this.formBuilder.group({
          campanhaId: [this.campanhaId, Validators.required],
          codPA: ["null"]
        });
        break;
      case 18:
        this.formulario = this.formBuilder.group({
          codPA: ["null"]
        });
        break;
      case 19:
        this.formulario = this.formBuilder.group({
          campanhaId: [this.campanhaId],
          codPA: ["null"],
          cooperado: [null]
        });
        break;
      case 20:
        this.formulario = this.formBuilder.group({
          statusId: [-1],
          cooperado: [null],
          clienteId: [-1],
          codPA: [null],
          atendenteId: [-1]
        });
        break;
      default:
        this.formulario = this.formBuilder.group({
          atendenteId: [-1],
          assessorId: [-1],
          codPA: ["null"],
          tipoEndossoId: [0]
        });
    }
  }

  getControlName(): any {
    return 'clienteId';
  }

  atualizaRelatorio(): void {
    this.idRelatorio = this.data?.id;
    this.informarPeriodo = this.data.opcoes !== undefined ? this.data.opcoes[0]?.informarPeriodo != undefined ? this.data.opcoes[0]?.informarPeriodo : true : true;
    this.informarSeRenovacao = false;
    this.informarAtendente = false;
    this.informarAssessor = false
    this.informarCampanha = false;
    this.informarPAeAtendente = false;
    this.informarPA = false;
    this.informarTipoEndosso = false;
    this.informarProduto = false;
    this.setDataPadrao();
    this.titulo = this.data.titulo;
    this.informarCampanha = this.data.informarCampanha;
    this.informarAtendente = this.data.informarAtendente;
    this.informarAssessor = this.data.informarAssessor;
    this.informarColaborador = this.data.informarColaborador;
    this.informarSeRenovacao = this.data.informarSeRenovacao;
    this.informarPAeAtendente = this.data.informarPAeAtendente;
    this.informarPA = this.data.informarPA;
    this.informarTipoEndosso = this.data.informarTipoEndosso;
    this.informarPaOuAtendenteOuAssessor = this.data.informarPaOuAtendenteOuAssessor;
    this.informarProduto = this.data.informarProduto;
    this.informarOrdem = this.data.informarOrdem;
    this.opcoes = this.data.opcoes;
    if (this.informarOrdem) {
      let enumOrdem = this.getEnumByName(this.data.enumOrdem);
      this.ordenacao = enumToArray(enumOrdem);
    }
  }

  getEnumByName(name: string): any {
    switch (name) {
      case 'OrdemDetalheCampanha':
        return OrdemDetalheCampanha;
      default:
        return undefined;
    }
  }

  containInputInData(): boolean {
    if (this.data !== undefined && this.data?.inputs !== undefined) {
        return true;
    } else {
      return false;
    }
  }

  containComboInData(): boolean {
    if (this.data !== undefined && this.data?.combos !== undefined) {
        return true;
    } else {
      return false;
    }
  }
  containComboSelectInData(): boolean {
    if (this.data !== undefined && this.data?.comboSelect !== undefined) {
        return true;
    } else {
      return false;
    }
  }

  radioGroupInData(): boolean {
    if (this.data !== undefined && this.data?.radioGroup !== undefined) {
        return true;
    } else {
      return false;
    }
  }

  containCheckTextInData(): boolean {
    if (this.data !== undefined && this.data?.opcoes !== undefined) {
      if (this.data?.opcoes[0]?.checkText !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  informaPeriodo(): boolean {
    return this.informarPeriodo;
  }

  async open(): Promise<void> {
    let params = undefined;

    if (this.informarSeRenovacao) {
      params = this.ehRenovacao;
    }
    if (this.informarPA && this.idRelatorio === 3) {
      params =  [`${this.formulario.get('codPA').value}/${this.formulario.get('atendenteId').value}/${this.formulario.get('clienteId').value}/${this.exportarExcel}`];
    };
    if (this.informarAtendente && !this.informarPAeAtendente && !this.informarTipoEndosso && this.idRelatorio !== 12 && this.idRelatorio !== 20) {
      this.idRelatorio = 4.1;
      this.data = this.menus.filter(x => x.id === this.idRelatorio)[0];
      params = this.formulario.get('atendenteId').value;
    }
    if (this.informarPA && this.idRelatorio === 7) {
      params =  [`${this.formulario.get('codPA').value}/${this.exportarExcel}`];
    };
    if (this.idRelatorio === 8 || this.idRelatorio === 9) {
      params = this.formulario.get('campanhaId').value;
    }
    if (this.idRelatorio === 6) {
      params = [`${this.formulario.get('produtoId').value}/${this.formulario.get('codPA').value}`];
    }
    if (this.idRelatorio === 11) {
      params = [`${this.exportarExcel}`];
    }
    if (this.idRelatorio === 12) {
      this.data = this.menus.filter(x => x.id === this.idRelatorio)[0];
      params = [`${this.formulario.get('codPA').value}/${this.formulario.get('atendenteId').value}/${this.formulario.get('colaboradorId').value}/${this.exportarExcel}`];
    }
    if (this.idRelatorio === 13) {
      params = [`${this.formulario.get('tipoEndossoId').value}/${this.formulario.get('codPA').value}/${this.formulario.get('atendenteId').value}/${this.exportarExcel}`];
    }
    if (this.informarPaOuAtendenteOuAssessor) {
      this.idRelatorio = 14;
      this.data = this.menus.filter(x => x.id === this.idRelatorio)[0];
      params = [`${this.formulario.get('codPA').value}/${this.formulario.get('atendenteId').value}/${this.formulario.get('assessorId').value}`];
    }
    if (this.idRelatorio == 15) {
      const campanhaId = this.data.campanhaId !== null && this.data.campanhaId !== undefined ? this.data.campanhaId : this.formulario.get('campanhaId').value;
      params = [`${campanhaId}/${this.formulario.get('codPA').value}/${this.formulario.get('ordem').value}`];
    }
    if (this.idRelatorio == 16) {
      params = [`${this.formulario.get('codPA').value}/${this.formulario.get('statusId').value}/${this.formulario.get('ehPlantao').value}/${this.exportarExcel}`];
    }
    if (this.idRelatorio === 17) {
      const campanhaId = this.campanhaId !== null && this.campanhaId !== undefined ? this.campanhaId : this.formulario.get('campanhaId').value;
      params = [`${campanhaId}/${this.formulario.get('codPA').value}/${this.exportarExcel}`];
    }
    if (this.idRelatorio === 18) {
      params = [`${this.formulario.get('codPA').value}/${this.exportarExcel ? 'true' : 'false'}`];
    }
    if (this.idRelatorio === 19) {
      params = [`${this.formulario.get('campanhaId').value}/${this.formulario.get('cooperado').value}/${this.formulario.get('codPA').value}`];
    }
    if (this.idRelatorio === 20) {
      params = [`${this.formulario.get('codPA').value}/${this.formulario.get('atendenteId').value}/${this.formulario.get('clienteId').value}/${this.formulario.get('statusId').value}/${this.exportarExcel ? 'true' : 'false'}`];
    }
    let periodo = await this.obterPeriodo();
    if (this.informarPeriodo && !this.data.retornarPeriodo) {
      await this.relatorioService.obterRelatorioPorPeriodo(periodo, this.data?.link, params).then(res => {
        if (res !== null && res !== undefined) {
          if (this.exportarExcel) {
            let fileName = 'relatorio.csv';
            let file = new Blob([res], { type: 'application/ms-excel' });
            let fileUrl = URL.createObjectURL(file);
            let anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = fileUrl;
            anchor.click();
          }
          else {
            let file = new File([res], "Documento.pdf", { type: (<any>res).type });
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.apiService.StrDocument = (reader.result as string);
              this.apiService.pdfViewerEmitter.emit(true);
            };
          }
        }
        else {
          this.mensagemService.showMessage('Erro ao obter Relatório.', 'Ok', 5000, 'error');
        };
      });
    } else {
      if (this.data.retornarPeriodo) {
        this.dialogRef.close(periodo);
        return;
      }
      await this.relatorioService.obterRelatorio(this.data?.link, params).then(res => {
        if (res !== null && res !== undefined) {
          if (this.exportarExcel) {
            let fileName = 'relatorio.csv';
            let file = new Blob([res], { type: 'application/ms-excel' });
            let fileUrl = URL.createObjectURL(file);
            let anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = fileUrl;
            anchor.click();
          }
          else {
            let file = new File([res], "Documento.pdf", { type: (<any>res).type });
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.apiService.StrDocument = (reader.result as string);
              this.apiService.pdfViewerEmitter.emit(true);
            };
          }
        }
        else {
          this.mensagemService.showMessage('Erro ao obter Relatório.', 'Ok', 5000, 'error');
        };
      });
    }
    this.dialogRef.close();
  }

  async obterPeriodo(): Promise<PeriodoDto> {
    let dtInicial = this.dataInicial;
    let dtFinal = this.dataFinal;
    if (this.todoPeriodo) {
      dtInicial = await this.apiService.DataBase();
      dtFinal = await this.apiService.DataBase();
    }
    return { dataInicial: dtInicial, dataFinal: dtFinal } as PeriodoDto;
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  todoPeriodoChanged(event: boolean) {
    if (event) {
      this.dataInicial = undefined;
      this.dataFinal = undefined;
    }
  }

  verificarSelecao(): boolean {
    if ((this.idRelatorio === 8 || this.idRelatorio === 9) && this.formulario?.get('campanhaId')?.value === 0) {
      return true;
    }
    if ((this.dataInicial === null || this.dataInicial === undefined) || (this.dataFinal === null || this.dataFinal === undefined)) {
      if (!this.todoPeriodo)
        return true;

      return false;
    }
    return false;
  }

  async obterAtendentes(): Promise<void> {
    /* Usuários */
    await this.usuarioService.ObterColaboradores().then(res => {
      this.atendentes = res.filter(x => x.nivel === 'OPERADOR');
      this.atendentesFiltrados = this.atendentes.slice();
    });
  }
  async obterClientes(): Promise<void> {
    /* Clientes */
    await this.clienteService.todos().then(res => {
      this.clientes = res;
      this.clientesFiltrados = this.clientes.slice();
    });
  }
  async obterStatus(): Promise<void> {
    /* Status */
    await this.statusService.todos().then(res => {
      this.status = res;
      this.statusFiltrados = this.status.slice();
    });
  }
  async obterAssessores(): Promise<void> {
    /* Assessores */
    await this.usuarioService.ObterColaboradores().then(res => {
      this.assessores = res.filter(x => x.nivel === 'ASSESSOR' || x.nivel === 'ADMINISTRADOR');
      this.assessoresFiltrados = this.assessores.slice();
    });
  }

  async obterColaboradores(): Promise<void> {
    await this.usuarioService.ObterColaboradores().then(res =>{
      this.colaboradores = res as Usuario[];
      this.colaboradoresFiltrados = this.colaboradores.slice();
    });
  }

  async obterCampanhas(): Promise<void> {
    await this.campanhaService.todas().then(res => {
      this.campanhas = this.apiService.verificaSeResult<Campanha[]>(res);
      this.campanhasFiltradas = this.campanhas.slice();
    });
  }

  async obterAgencias(): Promise<void> {
    await this.agenciaService.todas().then(res => {
      this.agencias = this.apiService.verificaSeResult<Agencia[]>(res);
      this.agenciasFiltradas = this.agencias.slice();
    });
  }

  async obterProdutos(): Promise<void> {
    await this.produtoService.todos().then(res => {
      this.produtos = this.apiService.verificaSeResult<Produto[]>(res);
      this.produtosFiltrados = this.produtos.slice();
    });
  }

  async obterTiposEndosso(): Promise<void> {
    await this.tipoEndossoService.todos().then(res => {
      this.tiposEndosso = this.apiService.verificaSeResult<TipoEndosso[]>(res);
      this.tiposEndossoFiltrados = this.tiposEndosso.slice();
    });
  }

  atendenteClick(): void {
    this.informarAtendente = true;
    this.informarAssessor = false;
    this.informarPA = false;
  }

  assessorClick(): void {
    this.informarAtendente = false;
    this.informarAssessor = true;
    this.informarPA = false;
  }

  paClick(): void {
    this.informarAtendente = false;
    this.informarAssessor = false;
    this.informarPA = true;
  }

  getLista(combo: any): object {
    if (combo.lista === 'status') {
      return this.status;
    }
    if (combo.lista === 'atendentes') {
      return this.atendentes;
    }
    if (combo.lista === 'clientes') {
      return this.clientes;
    }
  }

  getComboSelectList(combo: any): object {
    return combo.lista;
  }

  getFilteredReturn(combo: any, event: any): string {
    if (combo.lista === 'status') {
      return 'statusFiltrados';
    }
    if (combo.lista === 'atendentes') {
      return 'atendentesFiltrados';
    }
    if (combo.lista === 'clientes') {
      return 'clientesFiltrados';
    }
  }

  getFilteredList(combo: any, event: any): object {
    if (combo.lista === 'status') {
      event = this.statusFiltrados;
      return event;
    }
    if (combo.lista === 'atendentes') {
      return this.atendentesFiltrados;
    }
    if (combo.lista === 'clientes') {
      return this.clientesFiltrados;
    }
  }

  getDisplayMember(combo: any): string {
    if (combo.lista === 'status') {
      return 'descricao';
    }
    if (combo.lista === 'atendentes') {
      return 'nome';
    }
    if (combo.lista === 'clientes') {
      return 'associado';
    }
  }

  getFormControlName(componente: any): any {
    let nome = componente.formControlName;
    return nome;
  }

  getItemDisplay(combo: any, item: any): object {
    if (combo.lista === 'status') {
      return item.descricao;
    }
    if (combo.lista === 'atendentes') {
      return item.nome;
    }
    if (combo.lista === 'clientes') {
      return item.associado;
    }
  }

  async filtrar(serviceName: string, filtro: string) {
    let paginator = {
      length: 0,
      pageIndex: 0,
      pageSize: 1,
      previousPageIndex: 0
    };
    switch (serviceName) {
      case "clienteService":
        {
          await this.clienteService.filtrar(Number(filtro), { texto: this.formulario.get('cooperado').value } as FiltroDTO, paginator).then(res => {
            let cliente = res.dados as Cliente[];
            this.formulario.patchValue({
              cooperado: cliente[0].associado,
              clienteId: cliente[0].id
            });
          });
        }
        break;
      default:
        break;
    }
  }
}
