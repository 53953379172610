import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  document: SafeResourceUrl;
  titulo: string;
  show: boolean;

  constructor(private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.titulo = 'Visualizar documento';
    this.apiService.pdfViewerEmitter.subscribe((show) => {
      this.show = show;
      this.apiService.PdfViewerIsShowing = this.show;
      this.cd.detectChanges();
    });
  }

  getPDF(): SafeResourceUrl {
    if (this.document !== null && this.document !== undefined) {
      return this.document;
    }
    this.document = this.sanitizer.bypassSecurityTrustResourceUrl(this.apiService.StrDocument);
    return this.document;
  }

  close(): void {
    this.document = undefined;
    this.apiService.StrDocument = undefined;
    this.show = false;
    this.apiService.PdfViewerIsShowing = this.show;
    this.cd.detectChanges();
  }
}
