import { LoginService } from './../../services/login/login.service';
import { Usuario } from './../../entidades/usuario';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css', '../../app.component.css']
})
export class FooterComponent implements OnInit {
  usuarioAutenticado: boolean;

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    this.loginService.exibirNavBarEmitter.subscribe(result => {
      this.usuarioAutenticado = result as boolean;
    });    
  }
}
