<mat-sidenav-container class="sidenav-container" (backdropClick)="close()">
  <mat-sidenav
    #sidenav
    mode="over"
    (keydown.escape)="close()"
    disableClose
    class="side-nav mat-elevation-z8"
    [fixedInViewport]="true"
    [fixedTopGap]="76"
  >
    <mat-nav-list>
      <div *ngFor="let m of menus">
        <a
          *ngIf="m.tipo === 2"
          class="sidenav-menu"
          [routerLink]="[m.link]"
          (click)="close()"
        >
          <div class="marcador">
            <span class="material-icons">{{ m.icone }}</span
            >{{ m.descricao }}
          </div>
        </a>
        <mat-accordion *ngIf="m.tipo === 1 && m.subMenus.length > 0">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="marcador">
                  <span class="material-icons">{{ m.icone }}</span>
                  {{ m.descricao }}
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span *ngFor="let sm of m.subMenus">
              <mat-list-item
                class="sidenav-menu"
                [routerLink]="[sm.link]"
                (click)="close()"
              >
                <div class="marcador">{{ sm.descricao }}</div>
              </mat-list-item>
            </span>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
