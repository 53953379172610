<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="'Ponto de Atendimento - PA'"></app-titulo>
  <form class="formContainer" [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="row">
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Código do PA</mat-label>
        <input matInput #inputCodPA formControlName="codPA" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>CEP</mat-label>
        <input
          matInput
          #inputCEP
          formControlName="cep"
          maxlength="10"
          mask="00.000-000"
        />
      </mat-form-field>
      <button mat-button type="button" (click)="consultarCEP()">
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field appearance="outline">
        <mat-label>Endereço</mat-label>
        <input matInput #inputEndereco formControlName="logradouro" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Número</mat-label>
        <input matInput #inputNumero formControlName="numero" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Bairro</mat-label>
        <input matInput #inputBairro formControlName="bairro" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Cidade</mat-label>
        <input matInput #inputCidade formControlName="cidade" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>UF</mat-label>
        <mat-select formControlName="uf">
          <mat-option
            *ngFor="let estado of estados"
            value="{{ estado?.sigla }}"
            >{{ estado?.nome }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Telefone</mat-label>
        <input
          matInput
          #inputTelefone
          formControlName="telefone"
          mask="(00) 0000-0000"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Celular</mat-label>
        <input
          matInput
          #inputCelular
          formControlName="celular"
          mask="(00) 0000-0000 || (00) 00000-0000"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Fax</mat-label>
        <input matInput #inputFax formControlName="fax" mask="(00) 0000-0000" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>e-mail</mat-label>
        <input matInput #inputEmail formControlName="emailGeral" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Gerente</mat-label>
        <mat-select formControlName="gerenteId">
          <mat-option *ngFor="let gerente of gerentes" [value]="gerente.id">{{
            gerente.nome
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>e-mail do gerente</mat-label>
        <input matInput #inputEmailGerente formControlName="emailGerente" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Observações</mat-label>
        <textarea
          matInput
          #observacao
          formControlName="observacao"
          cdkAutosizeMinRows="15"
          cdkAutosizeMaxRows="15"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="item-right">
      <button
        mat-button
        class="btnOK mat-elevation-z8"
        [disabled]="formulario.status == 'INVALID' || formulario.pristine"
        type="submit"
      >
        Salvar
      </button>
      <button
        mat-button
        class="btnCancel mat-elevation-z8"
        type="button"
        (click)="cancelar()"
      >
        Cancelar
      </button>
    </div>
  </form>
</div>
