<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="'Cadastro Pontos de Atendimento - PA'"></app-titulo>
  <div class="item-center" fxLayout="column" fxLayoutAlign="start center">
    <button
      mat-fab
      class="btnNew"
      (click)="novaAgencia()"
      matTooltip="Adiciona nova Agência - PA"
      matTooltipClass="tooltip-blue"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-fab
      class="btnEdit"
      (click)="editarAgencia()"
      matTooltip="Edita os dados da Agência - PA"
      matTooltipClass="tooltip-blue"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-fab
      class="btnDelete"
      (click)="removerAgencia()"
      matTooltip="Remove a Agência - PA selecionada"
      matTooltipClass="tooltip-blue"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <table mat-table [dataSource]="dataSource">
    <!-- Coluna CodPA -->
    <ng-container matColumnDef="codPA">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        Código do PA
      </th>
      <td mat-cell *matCellDef="let row" class="center">{{ row.codPA }}</td>
    </ng-container>

    <!-- Coluna Cidade -->
    <ng-container matColumnDef="cidade">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cidade</th>
      <td mat-cell *matCellDef="let row">{{ row.cidade }}</td>
    </ng-container>

    <!-- Coluna UF -->
    <ng-container matColumnDef="uf">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>UF</th>
      <td mat-cell *matCellDef="let row">{{ row.uf }}</td>
    </ng-container>

    <!-- Coluna Telefone -->
    <ng-container matColumnDef="telefone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefone</th>
      <td mat-cell *matCellDef="let row">
        {{ row.telefone | mask : "(00) 0000-0000" }}
      </td>
    </ng-container>

    <!-- Coluna Gerente -->
    <ng-container matColumnDef="gerente">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Gerente</th>
      <td mat-cell *matCellDef="let row">{{ row?.gerente?.nome }}</td>
    </ng-container>

    <!-- Coluna email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>email Geral</th>
      <td mat-cell *matCellDef="let row">{{ row.emailGeral }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ selectedRow: row == agenciaSelecionada }"
      (click)="rowClick(row)"
    ></tr>
  </table>
  <app-paginator [(length)]="qtdeRegistros"></app-paginator>
</div>
