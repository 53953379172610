<div class="report">
  <app-titulo [(titulo)]="titulo"></app-titulo>
  <div class="container-report">
    <div class="row" *ngIf="informaPeriodo()">
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Data Inicial</mat-label>
        <input
          id="dataInicial"
          matInput
          [matDatepicker]="picker1"
          [(ngModel)]="dataInicial"
          (keyup)="appConfigService.onKeyUpEventData($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Data Final</mat-label>
        <input
          id="dataFinal"
          matInput
          [matDatepicker]="picker2"
          [(ngModel)]="dataFinal"
          (keyup)="appConfigService.onKeyUpEventData($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="row center">
      <mat-checkbox
        [(ngModel)]="todoPeriodo"
        (ngModelChange)="todoPeriodoChanged($event)"
        >Todo Período
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="ehRenovacao" *ngIf="informarSeRenovacao"
        >Renovação</mat-checkbox
      >
      <mat-checkbox [(ngModel)]="informarAtendente" *ngIf="idRelatorio === 4"
        >Informar Atendente</mat-checkbox
      >
      <mat-checkbox [(ngModel)]="exportarExcel" *ngIf="data?.exportarExcel">Exportar (.csv)</mat-checkbox>
    </div>
    <div class="opcoes-relatorio" *ngIf="containCheckTextInData()">
      <mat-label>Outras Opções:</mat-label>
      <div class="opcoes-check">
        <mat-checkbox *ngFor="let opc of data?.opcoes" [(ngModel)]="opc.value">
          {{opc.checkText}}
        </mat-checkbox>
      </div>
    </div>
  </div>
    <div class="container-report">
      <mat-form-field
        appearance="outline"
        *ngIf="informarPAeAtendente || informarPaOuAtendenteOuAssessor"
        class="grow1"
      >
        <mat-label>Filtro</mat-label>
        <mat-select>
          <mat-option [value]="0" (click)="atendenteClick()"
            >Atendente</mat-option
          >
          <mat-option [value]="1" (click)="assessorClick()"
            >Assessor</mat-option
          >
          <mat-option [value]="2" (click)="paClick()">PA</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="item-right" *ngIf="idRelatorio === null || idRelatorio === undefined">
        <button
          [disabled]="formulario?.status === 'INVALID'"
          mat-button
          class="btnOK mat-elevation-z8"
          type="button"
          (click)="open()"
        >
          Ok
        </button>
        <button
          mat-button
          class="btnCancel mat-elevation-z8"
          type="button"
          (click)="cancelar()"
        >
          Cancelar
        </button>
      </div>
      <form
        [formGroup]="formulario"
        (ngSubmit)="open()"
        *ngIf="idRelatorio !== null && idRelatorio !== undefined"
      >
        <mat-form-field appearance="outline" *ngIf="informarPA">
          <mat-label>Agencia</mat-label>
          <mat-select formControlName="codPA">
            <mat-select-filter
              [displayMember]="'codPA'"
              [placeholder]="'Procurando..'"
              [array]="agencias"
              (filteredReturn)="agenciasFiltradas = $event"
            >
            </mat-select-filter>
            <mat-option *ngFor="let ag of agenciasFiltradas" [value]="ag.codPA"
              >{{ ag.codPA }}-{{ ag.cidade }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="informarProduto">
          <mat-label>Produto</mat-label>
          <mat-select formControlName="produtoId">
            <mat-select-filter
              [displayMember]="'descricao'"
              [placeholder]="'Procurando..'"
              [array]="produtos"
              (filteredReturn)="produtosFiltrados = $event"
            >
            </mat-select-filter>
            <mat-option *ngFor="let prod of produtosFiltrados" [value]="prod.id"
              >{{ prod.id }}-{{ prod.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="informarAtendente">
          <mat-label>Atendente</mat-label>
          <mat-select formControlName="atendenteId">
            <mat-select-filter
              [displayMember]="'nome'"
              [placeholder]="'Procurando..'"
              [array]="atendentes"
              (filteredReturn)="atendentesFiltrados = $event"
            >
            </mat-select-filter>
            <mat-option
              *ngFor="let usuario of atendentesFiltrados"
              [value]="usuario.id"
              >{{ usuario.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="informarAssessor">
          <mat-label>Assessor</mat-label>
          <mat-select formControlName="assessorId">
            <mat-select-filter
              [displayMember]="'nome'"
              [placeholder]="'Procurando..'"
              [array]="assessores"
              (filteredReturn)="assessoresFiltrados = $event"
            >
            </mat-select-filter>
            <mat-option
              *ngFor="let assessor of assessoresFiltrados"
              [value]="assessor.id"
              >{{ assessor.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="informarColaborador">
          <mat-label *ngIf="cancelamento">Quem cancelou</mat-label>
          <mat-label *ngIf="!cancelamento">Colaborador</mat-label>
          <mat-select formControlName="colaboradorId">
            <mat-select-filter
              [displayMember]="'nome'"
              [placeholder]="'Procurando..'"
              [array]="colaboradores"
              (filteredReturn)="colaboradoresFiltrados = $event"
            >
            </mat-select-filter>
            <mat-option
              *ngFor="let colaborador of colaboradoresFiltrados"
              [value]="colaborador.id"
              >{{ colaborador.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="informarTipoEndosso">
          <mat-label>Tipo de Endosso</mat-label>
          <mat-select formControlName="tipoEndossoId">
            <mat-select-filter
              [displayMember]="'descricao'"
              [placeholder]="'Procurando..'"
              [array]="tiposEndosso"
              (filteredReturn)="tiposEndossoFiltrados = $event"
            >
            </mat-select-filter>
            <mat-option
              *ngFor="let tipo of tiposEndossoFiltrados"
              [value]="tipo.id"
              >{{ tipo.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="informarCampanha">
          <mat-label>Campanha</mat-label>
          <mat-select formControlName="campanhaId">
            <mat-select-filter
              [displayMember]="'descricao'"
              [placeholder]="'Procurando..'"
              [array]="campanhas"
              (filteredReturn)="campanhasFiltradas = $event"
            >
            </mat-select-filter>
            <mat-option
              *ngFor="let campanha of campanhasFiltradas"
              [value]="campanha.id"
              >{{ campanha.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="informarOrdem">
          <mat-label>Ordenar por</mat-label>
          <mat-select formControlName="ordem">
            <mat-option *ngFor="let o of ordenacao" [value]="o.id"
              >{{ o.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- CONTROLES INPUT -->
        <div *ngIf="containInputInData()">
          <mat-form-field appearance="outline" *ngFor="let input of data?.inputs">
            <mat-label>{{input.inputLabel}}</mat-label>
            <div class="row">
              <input matInput #inputCooperado formControlName="cooperado" />
              <mat-icon (click)="filtrar(input.serviceName, input.filtro)">search</mat-icon>
            </div>
          </mat-form-field>
        </div>
        <!-- COMBOS DOS RELATÓRIOS -->
         <div *ngIf="containComboInData()">
           <mat-form-field appearance="outline" *ngFor="let combo of data?.combos">
             <mat-label>{{combo.comboLabel}}</mat-label>
             <mat-select formControlName="{{getFormControlName(combo)}}"
                 [displayMember]="'getDisplayMember(combo)'"
                 [placeholder]="'Procurando..'"
                 [array]=getLista(combo)>
               <ng-template ngFor let-item [ngForOf]=getLista(combo)>
                <mat-option [value]="item.id">{{getItemDisplay(combo, item)}}</mat-option>
               </ng-template>
             </mat-select>
           </mat-form-field>
         </div>
         <!-- COMBO SELECT -->
         <div *ngIf="containComboSelectInData()">
          <mat-form-field appearance="outline" *ngFor="let combo of data?.comboSelect">
            <mat-label>{{combo.comboLabel}}</mat-label>
            <mat-select formControlName="{{getFormControlName(combo)}}"
                [displayMember]="'getDisplayMember(combo)'"
                [placeholder]="'Procurando...'"
                [array]=getComboSelectList(combo)>
              <ng-template ngFor let-item [ngForOf]=getComboSelectList(combo)>
               <mat-option [value]="item.id">{{item.descricao}}</mat-option>
              </ng-template>
            </mat-select>
          </mat-form-field>
         </div>
        <div class="item-right">
          <button
            [disabled]="formulario?.status === 'INVALID'"
            mat-button
            class="btnOK mat-elevation-z8"
            type="button"
            (click)="open()"
          >
            Ok
          </button>
          <button
            mat-button
            class="btnCancel mat-elevation-z8"
            type="button"
            (click)="cancelar()"
          >
            Cancelar
          </button>
        </div>
      </form>
  </div>
</div>
