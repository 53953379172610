import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {
  // Criando EventEmitter para notificar as mudanças de páginas
  paginatorEmitter: EventEmitter<any>;
  paginator1Emitter = new EventEmitter<any>();
  paginator2Emitter = new EventEmitter<any>();
  paginator3Emitter = new EventEmitter<any>();
  paginatorPesquisaEmitter = new EventEmitter<any>();

    constructor() { }

    getPaginatorEmitter() {
      return new EventEmitter<any>();
    }

    getPaginator1Emitter() {
      return new EventEmitter<any>();
    }

    getPaginator2Emitter() {
      return new EventEmitter<any>();
    }

    getPaginator3Emitter() {
      return new EventEmitter<any>();
    }

    getPaginatorPesquisaEmitter() {
      return new EventEmitter<any>();
    }

  emitirEvento(obj: any): void {
    let tab = sessionStorage.getItem('tab');
    switch (tab) {
      case '1':
        this.paginator2Emitter.emit(JSON.stringify(obj));
        break;
      case '2':
        this.paginator3Emitter.emit(JSON.stringify(obj));
      break;
      case null:
        this.paginatorEmitter.emit(JSON.stringify(obj));
        break;
      default:
        this.paginator1Emitter.emit(JSON.stringify(obj));
        break;
    }
  }
}
