import { MatTableDataSource } from '@angular/material/table';
import { AgenciaService } from './../../services/agencia/agencia.service';
import { Paginator } from './../../entidades/paginator';
import { Pagina } from './../../entidades/pagina';
import { Component, OnInit } from '@angular/core';
import { Agencia } from 'src/app/entidades/agencia';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { DialogoComponent } from '../dialogo/dialogo/dialogo.component';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';

@Component({
  selector: 'app-agencia',
  templateUrl: './agencia.component.html',
  styleUrls: ['../../app.component.css', './agencia.component.css']
})
export class AgenciaComponent implements OnInit {
  isBusy = false;
  pagina: Pagina<Agencia>;
  agencias: Agencia[];
  displayedColumns: string[] = ['codPA', 'cidade', 'uf', 'telefone', 'gerente', 'email'];
  dataSource: MatTableDataSource<Agencia>;
  qtdeRegistros: number;
  agenciaSelecionada: Agencia;
  myPaginator: Paginator = {
    length: 0,
    pageIndex: 0,
    pageSize: this.appConfigService.itensPorPagina,
    previousPageIndex: 0
  };

  constructor(private router: Router,
    private apiService: ApiService,
    private agenciaService: AgenciaService,
    private paginatorService: PaginatorService,
    private appConfigService: AppConfigService,
    private dialog: MatDialog,
    private mensagemService: MensagemService) { }

  ngOnInit(): void {
    this.paginatorService.paginatorEmitter = this.paginatorService.getPaginatorEmitter();
    this.obterAgencias();
    this.paginatorService.paginatorEmitter.subscribe(result => {
      this.myPaginator = JSON.parse(result);
      this.agenciaService.Obter(this.myPaginator).then(res => {
        this.configurarPagina(res);
      });
    });
  }

  obterAgencias(): void {
    this.agenciaService.Obter(this.myPaginator).then(res => {
      this.configurarPagina(res);
    });
  }

  configurarPagina(pagina: Pagina<Agencia>): void {
    if (pagina !== null && pagina !== undefined) {
      this.agencias = pagina.dados as Agencia[];
      this.dataSource = new MatTableDataSource(this.agencias);
      this.pagina = pagina;
      this.qtdeRegistros = this.pagina.quantidadeRegistros;
    }
    else {
      this.agencias = null;
    }
  }

  rowClick(row: Agencia): void {
    this.agenciaSelecionada = row;
  }

  novaAgencia(): void {
    this.router.navigate(['/agencia/nova']);
  }

  editarAgencia(): void {
    if (this.agenciaSelecionada !== null && this.agenciaSelecionada !== undefined) {
      this.router.navigate([`/agencia/${this.agenciaSelecionada.codPA}`]);
    }
    else {
      this.mensagemService.showMessage('Selecione uma Agência.', 'Ok', 5000, 'error');
    }
  }

  removerAgencia(): void {
    if (this.agenciaSelecionada !== null && this.agenciaSelecionada !== undefined) {
      const dialogRef = this.dialog.open(DialogoComponent, {
        data: {
          titulo: 'Remover Agência',
          pergunta: `A Agência ${this.agenciaSelecionada.codPA} será removida. Confirma?`
        },
        width: '550px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.agenciaService.delete(this.agenciaSelecionada.codPA).then(result => {
            if (result) {
              this.mensagemService.showMessage(`Agência: ${this.agenciaSelecionada.codPA} removida com sucesso.`, 'Ok', 5000, 'success');
              this.obterAgencias();
            } else {
              this.mensagemService.showMessage(`Erro ao excluir a Agência ${this.agenciaSelecionada.codPA}.`, 'Ok', 5000, 'error');
            }
          });
        }
      });
    }
    else {
      this.mensagemService.showMessage('Selecione uma Agência.', 'Ok', 5000, 'error');
    }
  }
}
