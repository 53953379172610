import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { Router } from '@angular/router';
import { StatusService } from 'src/app/services/status/status.service';
import { ClienteService } from 'src/app/services/cliente/cliente.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Parametros } from 'src/app/entidades/parametro';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefinicaoColuna } from 'src/app/entidades/definicaoColuna';
import { Cliente } from 'src/app/entidades/cliente';
import { PesquisaService } from 'src/app/services/pesquisa/pesquisa.service';
import { enumToArray } from 'src/app/helper/enumToArray';
import { FiltroCliente } from 'src/app/enum/filtroCliente';
import { Status } from 'src/app/entidades/status';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css', '../../app.component.css']
})
export class ParametrosComponent implements OnInit {
  isBusy = false;
  formulario: UntypedFormGroup;
  editando = false;
  titulo: string;
  clientes: Cliente[];
  status: Status[];
  statusFiltrados: Status[];
  statusFinaliza: Status[];
  statusFinalizaFiltrados: Status[];

  constructor(private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private clienteService: ClienteService,
    private pesquisaService: PesquisaService,
    private statusService: StatusService,
    private mensagemService: MensagemService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.titulo = 'Cadastro de Parâmetros';
    this.criarFormulario();
    await this.obterListas();
    this.popularFormulario();
    this.pesquisaService.resultadoEmitter.subscribe((res: Cliente) => {
      this.adicionarCooperado(res);
    });
  }

  adicionarCooperado(cooperado: Cliente): void {
    let inputCooperado = document.getElementById('inputCooperado');
    if (inputCooperado !== null && inputCooperado !== undefined) {
      (<any>inputCooperado).value = cooperado.associado;
    }
    this.formulario.patchValue({
      clienteId: cooperado.id
    });
  }

  async obterListas(): Promise<void> {
    /* Clientes Obtém o primeiro Cliente para gerar as colunas da consulta */
    await this.clienteService.todos().then(res => {
      this.clientes = this.apiService.verificaSeResult<Cliente[]>(res);
    });
    /* Status */
    await this.statusService.todos().then(res => {
      this.status = this.apiService.verificaSeResult<Status[]>(res);
      this.statusFiltrados = this.status.slice();
      this.statusFinaliza = this.status.slice();
      this.statusFinalizaFiltrados = this.statusFinaliza.slice();
    });
  }

  criarFormulario() {
    this.formulario = this.formBuilder.group({
      id: [0],
      idClienteSemRegistro: [0, Validators.required],
      idStatusNovaCotacao: [null, Validators.required],
      qtdeChamadosAbertosPorAssessor: [null],
      qtdeClientesGeraVisita: [false, Validators.required],
      qtdeDiasAbrirCotacaoFutura: [0, Validators.required],
      diasLembreteParcelas: [0, Validators.required],
      pesquisaClienteAgendaPorCarteira: [false, Validators.required],
      habilitarComissionadoNaCotacao: [false, Validators.required],
      statusIdFinalizaChamado: [null, Validators.required]
    });
  }

  popularFormulario() {
    this.formulario.patchValue({
      id: this.apiService.Parametros.id,
      idClienteSemRegistro: this.apiService.Parametros.idClienteSemRegistro,
      idStatusNovaCotacao: this.apiService.Parametros.idStatusNovaCotacao,
      qtdeChamadosAbertosPorAssessor: this.apiService.Parametros.qtdeChamadosAbertosPorAssessor,
      qtdeClientesGeraVisita: this.apiService.Parametros.qtdeClientesGeraVisita,
      qtdeDiasAbrirCotacaoFutura: this.apiService.Parametros.qtdeDiasAbrirCotacaoFutura,
      diasLembreteParcelas: this.apiService.Parametros.diasLembreteParcelas,
      pesquisaClienteAgendaPorCarteira: this.apiService.Parametros.pesquisaClienteAgendaPorCarteira,
      habilitarComissionadoNaCotacao: this.apiService.Parametros.habilitarComissionadoNaCotacao,
      statusIdFinalizaChamado: this.apiService.Parametros.statusIdFinalizaChamado
    });
    this.formulario.markAsPristine();
    let semRegistro = this.clientes.find(x => x.id === this.apiService.Parametros.idClienteSemRegistro);
    let inputCooperado = document.getElementById('inputCooperado');
    if (inputCooperado !== null && inputCooperado !== undefined) {
      (<any>inputCooperado).value = semRegistro.associado;
    }
  }

  async pesquisaCooperado(): Promise<void> {
    const definicaoColunas: DefinicaoColuna[] = [
      { definicao: 'id', exibicao: 'Id' },
      { definicao: 'associado', exibicao: 'Nome' },
      { definicao: 'cpF_CNPJ', exibicao: 'CPF / CNPJ' },
      { definicao: 'conta', exibicao: 'Conta' }
    ];
    const colunas = [{
      id: this.clientes[0].id,
      associado: this.clientes[0].associado,
      cpF_CNPJ: this.clientes[0].cpF_CNPJ,
      conta: this.clientes[0].conta
    }]
    this.pesquisaService.pesquisaEventEmitter.emit(true);
    this.pesquisaService.tituloEventEmitter.emit('Obter Cooperado');
    this.pesquisaService.mensagemEventEmitter.emit('Selecione o Cooperado desejado');
    this.pesquisaService.colunasEmitter.emit(colunas);
    this.pesquisaService.functionEmitter.emit(this.clienteService.obter);
    this.pesquisaService.entidadeServiceEmitter.emit(this.clienteService);
    this.pesquisaService.filtrosEmitter.emit(enumToArray(FiltroCliente));
    this.pesquisaService.filtroEmitter.emit(this.clienteService.filtroClienteEmitter);
    this.pesquisaService.descricaoFEmitter.emit(this.clienteService.descricaoFiltroEmitter);
    this.pesquisaService.columnsHeadersEmitter.emit(definicaoColunas);
  }

  async onSubmit(): Promise<void> {
    let parametros = this.formulario.value as Parametros;
    await this.apiService.adicionarOuEditarParametros(parametros).then(async result => {
      if (!result) {
        await this.mensagemService.showMessage('Não foi possível atualizar os Parâmetros.', 'Ok', 5000, 'error');
      } else {
        await this.mensagemService.showMessage('Parâmetros alterados com sucesso.', 'Ok', 5000, 'success');
        this.popularFormulario();
      }
    });
  }
  8
  cancelar(): void {
    this.router.navigate(['/']);
  }
}
