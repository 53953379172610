import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TermoAdesaoDto } from 'src/app/dto/termoAdesaoDto';
import { ParcelamentoCupom } from 'src/app/entidades/parcelamentoCupom';
import { Usuario } from 'src/app/entidades/usuario';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { DialogoComponent } from '../../dialogo/dialogo/dialogo.component';
import { RealPipe } from 'src/app/pipes/real/real.pipe';
import { ParcelamentoCupomService } from 'src/app/services/parcelamento-cupom/parcelamento-cupom.service';
import { QuitarParcelaDto } from 'src/app/dto/quitarParcelaDto';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';

@Component({
  selector: 'app-parcelamento-cupom-detalhe',
  templateUrl: './parcelamento-cupom-detalhe.component.html',
  styleUrls: [
    './parcelamento-cupom-detalhe.component.css',
    '../../../app.component.css',
  ],
})
export class ParcelamentoCupomDetalheComponent implements OnInit {
  titulo: string;
  camposInvalidos: string = '';
  termoAdesaoDto: TermoAdesaoDto;
  btnState: [number, boolean][] = [];
  usuarios: Usuario[];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<any>,
    public pergunta: MatDialog,
    private usuarioService: UsuarioService,
    private mensageService: MensagemService,
    private parcelamentoCupomService: ParcelamentoCupomService
  ) {}

  async ngOnInit(): Promise<void> {
    this.titulo = this.data.titulo;
    this.termoAdesaoDto = this.data.row[0].termo;
    this.usuarioService.Todos().then(res =>{
      this.usuarios = res as Usuario[];
    });
  }

  voltarClick(): string {
    this.dialog.close();
    return 'cancel';
  }

  onSubmit(): void {}

  editarChanged(event: any, parcela: ParcelamentoCupom): void {
      parcela.editando = event.checked;
  }

  getUsuarioQuitacao(idUsuarioQuitacao: number): string {
    let nomeUsuario = this.usuarios?.filter(u => u.id === idUsuarioQuitacao)[0].nome;
    let arrayNome = nomeUsuario?.split(' ');
    return  arrayNome?.length > 0 ? `${arrayNome[0]} ${arrayNome[1]}` : '';
  }

  async quitarParcela(parcela: ParcelamentoCupom): Promise<void> {
    const dialogRef = this.pergunta.open(DialogoComponent, {
      data: {
        titulo: `Confirmar débito da parcela [ ${parcela.parcela} ]`,
        pergunta: `Confirma que o débito da parcela [ ${parcela.parcela} ] novo valor de ${new RealPipe().transform(parcela.valorParcela)} foi efetuado?`
      },
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'yes') {
        var dto = {
          agrupamento: parcela.agrupamento,
          idCampanha: parcela.idCampanha,
          parcela: parcela.parcela
        } as QuitarParcelaDto;
        await this.parcelamentoCupomService.quitarParcela(dto).then(async res => {
          if (res) {
            this.mensageService.showMessage('Confirmar Débito', 'Confirmado com sucesso!', 2000, 'success');
            await this.parcelamentoCupomService.obterParcelamento(parcela.idCampanha, parcela.agrupamento).then(res => {
              this.data.row = res as ParcelamentoCupom[];
            });
          } else {
            this.mensageService.showMessage('Confirmar Débito', 'Erro ao Confirmar débito.', 2000, 'error');
          }
        });
      }
    });
  }

  async reverterParcela(parcela: ParcelamentoCupom): Promise<void> {
    const dialogRef = this.pergunta.open(DialogoComponent, {
      data: {
        titulo: `Reverter débito da parcela [ ${parcela.parcela} ]`,
        pergunta: `Deseja reverter a confirmação do débito da [ ${parcela.parcela} ] novo valor de ${new RealPipe().transform(parcela.valorParcela)}?`
      },
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'yes') {
        var dto = {
          agrupamento: parcela.agrupamento,
          idCampanha: parcela.idCampanha,
          parcela: parcela.parcela
        } as QuitarParcelaDto;
        await this.parcelamentoCupomService.reverterParcela(dto).then(async res => {
          if (res) {
            this.mensageService.showMessage('Reverter confirmação de débito', 'Executado com sucesso!', 2000, 'Ok');
            await this.parcelamentoCupomService.obterParcelamento(parcela.idCampanha, parcela.agrupamento).then(res => {
              this.data.row = res as ParcelamentoCupom[];
            });
          } else {
            this.mensageService.showMessage('Reverter confirmação de débito', 'Erro ao reverter confirmação de débito.', 2000, 'Ok');
          }
        });
      }
    });
  }
}
