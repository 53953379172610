import { NgModule } from '@angular/core';
import { DataFormModule } from 'src/app/shared/data-form.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    DataFormModule
  ]
})
export class LoginModule { }
