import { MetaCampanha } from './../../entidades/metaCampanha';
import { UsuarioService } from './../usuario/usuario.service';
import { Injectable } from '@angular/core';
import { Campanha } from 'src/app/entidades/campanha';
import { Cupom } from 'src/app/entidades/cupom';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';
import { MensagemService } from '../mensagem/mensagem.service';
import { TipoCampanha } from 'src/app/entidades/tipoCampanha';
import { DadosAdesao } from 'src/app/entidades/dadosAdesao';
import { PeriodoDto } from 'src/app/dto/periodoDto';

@Injectable({
  providedIn: 'root',
})
export class CampanhaService {
  campanhaSelecionada: Campanha;
  campanhaVigente: boolean;

  constructor(
    private apiService: ApiService,
    private mensagemService: MensagemService,
    private usuarioService: UsuarioService
  ) { }

  getCampanhaSelecionada(): Campanha {
    return this.campanhaSelecionada;
  }

  setCampanhaSelecionada(campanha: Campanha): void {
    this.campanhaSelecionada = campanha;
  }

  setcampanhaVigente(vigente: boolean): void {
    this.campanhaVigente = vigente;
  }

  async todas(): Promise<Campanha[]> {
    try {
      let campanhas = null;
      await this.apiService.request<Campanha[]>(`/Campanha/Todas`, httpVerb.get).then(result => {
        campanhas = this.apiService.verificaSeResult<Campanha[]>(result);
      });
      return campanhas;
    }
    catch (error) {
      console.log(error);
      return null;
    }
  }

  async workshop(): Promise<Campanha[]> {
    try {
      let campanhas = null;
      await this.apiService.request<Campanha[]>(`/Campanha/WorkShop`, httpVerb.get).then(result => {
        campanhas = this.apiService.verificaSeResult<Campanha[]>(result);
      });
      return campanhas;
    }
    catch (error) {
      console.log(error);
      return null;
    }
  }

  async gerais(): Promise<Campanha[]> {
    try {
      let campanhas = null;
      await this.apiService.request<Campanha[]>(`/Campanha/Gerais`, httpVerb.get).then(result => {
        campanhas = this.apiService.verificaSeResult<Campanha[]>(result);
      });
      return campanhas;
    }
    catch (error) {
      console.log(error);
      return null;
    }
  }

  async AdicionarOuEditar(novaCampanha: Campanha, editando: boolean): Promise<Campanha> {
    try {
      let campanha = null;
      await this.apiService
        .request<Campanha>('/Campanha', httpVerb.post, novaCampanha)
        .then((result) => {
          if (result !== null && result !== undefined) {
            campanha = result as Campanha;
          }
        });
      return campanha;
    } catch (error) {
      console.log(error);
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<Campanha>> {
    try {
      let paginaAtual = null;
      const complemento = `/campanha?Pagina=${paginator.pageIndex + 1
        }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<Campanha>>(complemento, httpVerb.get)
        .then((result) => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Campanha>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage(
        'Erro ao obter campanhas.',
        'Ok',
        5000,
        'error'
      );
    }
  }

  async obterVigentes(paginator: Paginator): Promise<Pagina<Campanha>> {
    try {
      let paginaAtual = null;
      const complemento = `/campanha/Vigentes?Pagina=${paginator.pageIndex + 1
        }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<Campanha>>(complemento, httpVerb.get)
        .then((result) => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Campanha>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage(
        'Erro ao obter campanhas.',
        'Ok',
        5000,
        'error'
      );
    }
  }

  async ObterCampanha(id: number): Promise<Campanha> {
    try {
      let ganhadores = null;
      await this.apiService
        .request<Campanha>(`/campanha/${id}`, httpVerb.get)
        .then((result) => {
          if (result !== null && result !== undefined) {
            ganhadores = result as Campanha;
          }
        });
      return ganhadores;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage(
        'Erro ao obter campanha.',
        'Ok',
        5000,
        'error'
      );
    }
  }

  async obterTermoAdesao(agrupamento: string): Promise<DadosAdesao> {
    try {
      let dados = null;
      await this.apiService
        .request<DadosAdesao>(`/campanha/termoadesao/${agrupamento}`, httpVerb.get)
        .then((result) => {
          if (result !== null && result !== undefined) {
            dados = result as DadosAdesao;
          }
        });
      return dados;
    } catch (error) {
      console.log(error);
    }
  }

  async ObterSorteados(id: number): Promise<Cupom[]> {
    try {
      let ganhadores = null;
      await this.apiService
        .request<Cupom[]>(`/campanha/ganhadores/${id}`, httpVerb.get)
        .then((result) => {
          if (result !== null && result !== undefined) {
            ganhadores = result as Cupom[];
            ganhadores.forEach(element => {
              this.usuarioService.ObterUsuario(element.idColaborador).then(res => {
                element.colaborador = res;
              });
            });
          }
        });
      return ganhadores;
    } catch (error) {
      console.log(error);
    }
  }

  async Sortear(id: number): Promise<any> {
    try {
      let ganhadores = null;
      await this.apiService
        .request<any>(`/campanha/sorteio/${id}`, httpVerb.put)
        .then((result) => {
          if (result !== null && result !== undefined) {
            ganhadores = result;
          }
        });
      return ganhadores;
    } catch (error) {
      console.log(error);
    }
  }

  async ObterPDFCupom(id: number): Promise<any> {
    try {
      let pdf = null;
      await this.apiService
        .request<any>(`/campanha/cupom/${id}`, httpVerb.get, null, true)
        .then((result) => {
          if (result !== null && result !== undefined) {
            pdf = result;
          }
        });
      return pdf;
    } catch (error) {
      console.log(error);
    }
  }

  async emitirCupom(id: number): Promise<any> {
    try {
      let pdf = null;
      await this.apiService
        .request<any>(`/campanha/EmitirCupom/${id}`, httpVerb.get, null, true)
        .then((result) => {
          if (result !== null && result !== undefined) {
            pdf = result;
          }
        });
      return pdf;
    } catch (error) {
      console.log(error);
    }
  }

  async GerarRelatorioCampanha(periodo: PeriodoDto, id: number): Promise<Blob> {
    try {
      let retorno = null;
      await this.apiService.request<Blob>(`/campanha/relatorio/${id}`, httpVerb.post, periodo, true)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async obterTipos(): Promise<TipoCampanha[]> {
    try {
      let retorno = null;
      await this.apiService.request<TipoCampanha[]>(`/TipoCampanha/Todos`, httpVerb.get, null)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async obterTemplates(): Promise<string[]> {
    try {
      let retorno = null;
      await this.apiService.request<string[]>(`/Campanha/TemplatesCupons`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async obterRegulamentos(): Promise<string[]> {
    try {
      let retorno = null;
      await this.apiService.request<string[]>(`/Campanha/Regulamentos`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async obterParcelamentosCupons(id: number): Promise<Blob> {
    try {
      let retorno = null;
      await this.apiService.request<Blob>(`/Reports/Campanha/Parcelamentos/${id}`, httpVerb.get, null, true)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async enviarMeta(meta: MetaCampanha, email: string): Promise<boolean> {
    try {
      let retorno = null;
      await this.apiService.request<boolean>(`/Campanha/Meta/${email}`, httpVerb.post, meta)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }
}
