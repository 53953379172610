import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { httpVerb } from 'src/app/enum/httpVerb';
import { Agencia } from 'src/app/entidades/agencia';
import { Paginator } from 'src/app/entidades/paginator';
import { Pagina } from 'src/app/entidades/pagina';

@Injectable({
  providedIn: 'root'
})
export class AgenciaService {

  constructor(private apiService: ApiService) { }

  async todas(): Promise<Agencia[]> {
    try {
      let agencias: Agencia[];
      await this.apiService.request<Agencia[]>(`/Agencia/Todas`, httpVerb.get).then(result => {
        agencias = this.apiService.verificaSeResult<Agencia[]>(result);
      });
      return agencias;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditar(novaAgencia: Agencia): Promise<Agencia> {
    try {
      let agencia = null;
      await this.apiService.request<Agencia>('/Agencia', httpVerb.put, novaAgencia)
        .then(result => {
          if (result !== null && result !== undefined) {
            agencia = result as Agencia;
          }
        });
      return agencia;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async Obter(paginator: Paginator): Promise<Pagina<Agencia>> {
    try {
      let paginaAtual = null;
      const complemento = `/Agencia?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Agencia>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Agencia>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async delete(codPA: string): Promise<boolean> {
    try {
      let excluiu = false;
      await this.apiService.request<boolean>(`/Agencia/${codPA}`, httpVerb.delete)
        .then(result => {
          if (result !== null && result !== undefined) {
            excluiu = result;
          }
        });
      return excluiu;
    } catch (error) {
      console.log(error);
    }
  }

  async obterAgencia(codPA: string): Promise<Agencia> {
    try {
      let agencia = null;
      await this.apiService.request<Agencia>(`/Agencia/${codPA}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            agencia = (result as Agencia);
          }
        });
      return agencia;
    } catch (error) {
      console.log(error);
    }
  }
}
