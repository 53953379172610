<div class="tituloDialog">
  <h1 mat-dialog-title>{{ data.titulo }}</h1>
</div>
<div mat-dialog-content>
  <br />
  <br />
  <div class="textoDialogo label-wrap-detail">{{ data.pergunta }}</div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" class="btn-fit-green-outline" cdkFocusInitial>
    Não
  </button>
  <button mat-button [mat-dialog-close]="onYesClick()" class="btn-fit-green-outline">    
    Sim
  </button>
</div>
