import { IapServiceService } from 'src/app/services/iap/iap-service.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogoComponent } from '../dialogo/dialogo/dialogo.component';

@Component({
  selector: 'app-iap',
  templateUrl: './iap.component.html',
  styleUrls: ['./iap.component.css', '../../app.component.css']
})
export class IapComponent implements OnInit {
  titulo: string;
  arquivos: string[];


  constructor(private iapService: IapServiceService,
    private pergunta: MatDialog,) { }

  async ngOnInit(): Promise<void> {
    this.titulo = 'Validação e Update de Arquivos';
    this.obterArquivosIAP();
  }

  async obterArquivosIAP(): Promise<void> {
    this.iapService.obterArquivosIAP().then(res => {
      this.arquivos = res;
    });
  }

  async validarArquivo(item: string): Promise<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      titulo: 'Validar .csv',
      pergunta: `Confirma a validação do arquivo ${item}?`,
      componente: null
    };
    let tDialog = this.pergunta.open(DialogoComponent, dialogConfig);
    tDialog.afterClosed().subscribe(async res => {
      if (res === 'yes') {
        console.log(item);
      }
    });
  }
}
