import { subscribeOn } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css', '../../app.component.css']
})
export class SpinnerComponent implements OnInit {
  isBusy: boolean;
  constructor(private apiService: ApiService, private cd: ChangeDetectorRef) { }
 
  ngOnInit(): void {    
    this.apiService.isBusyEmitter.subscribe((isBusy) => 
    { 
      this.isBusy = isBusy; 
      this.cd.detectChanges();
    });
  }
}
