<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="'Cadastro de Usuários'"></app-titulo>
  <div class="item-center" fxLayout="column" fxLayoutAlign="start center">
    <button mat-fab 
            class="btnNew" 
            (click)="novoUsuario()"
            matTooltip="Adiciona novo usuário"
            matTooltipClass="tooltip-blue">
            <mat-icon>person_add</mat-icon>
    </button>    
    <button mat-fab class="btnEdit" (click)="editarUsuario()" 
            matTooltip="Edita os dados do usuário"
            matTooltipClass="tooltip-blue"><mat-icon>edit</mat-icon></button>
    <button mat-fab 
            class="btnEdit" 
            (click)="alterarSenha()"
            matTooltip="Altera senha do usuário"
            matTooltipClass="tooltip-blue"><mat-icon>security</mat-icon>
    </button>
    <button mat-fab 
      class="btnDelete" 
      (click)="ativarDesativar()"
      matTooltip="Torna o usuário inativo!"
      matTooltipClass="tooltip-blue"><mat-icon>thumbs_up_down</mat-icon></button>    
  </div>
  <app-filtro [filtros]="filtros" [dataSource]="dataSource" [filtroEmitter]="usuarioService.filtroUsuarioEmitter" [descricaoEmitter]="usuarioService.descricaoFiltroEmitter"></app-filtro>  
  <table mat-table [dataSource]="dataSource">
    <!-- Coluna Ativo -->
    <ng-container matColumnDef="ativo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ativo </th>
      <td mat-cell *matCellDef="let row">
        <mat-icon aria-hidden="false" class="red" *ngIf="row.inativo">thumb_down</mat-icon>
        <mat-icon aria-hidden="false" class="green "*ngIf="!row.inativo">thumb_up</mat-icon>
      </td>
    </ng-container>

    <!-- Coluna Id -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let row"> {{row.id}} </td>
    </ng-container>

    <!-- Coluna Nome -->
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
      <td mat-cell *matCellDef="let row"> {{row.nome}} </td>
    </ng-container>

    <!-- Coluna NomeUsuario -->
    <ng-container matColumnDef="login">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Login </th>
      <td mat-cell *matCellDef="let row"> {{row.nomeUsuario}} </td>
    </ng-container>

    <!-- Coluna PA -->
    <ng-container matColumnDef="pa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Código PA </th>
      <td mat-cell *matCellDef="let row"> {{row.codPA}} </td>
    </ng-container>

    <!-- Coluna Nivel -->
    <ng-container matColumnDef="nivel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nível </th>
      <td mat-cell *matCellDef="let row"> {{row.nivel}} </td>
    </ng-container>

    <!-- Coluna email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> email </th>
      <td mat-cell *matCellDef="let row"> {{row.email}} </td>
    </ng-container>

     <!-- Coluna reset -->
     <ng-container matColumnDef="reset">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Reset de Senha </th>
      <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
        <button mat-button class="btnDelete"
              (click)="resetarSenha(row)"
              matTooltip="Reseta a senha do usuário"
              matTooltipClass="tooltip-blue"
              aria-label="Resetar Senha"><mat-icon>password</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'selectedRow': row == usuarioSelecionado }"
      (click)="rowClick(row)"></tr>
  </table>
  <app-paginator [(length)]="qtdeRegistros"></app-paginator>
</div>