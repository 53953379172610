import { UsuarioService } from './../../../services/usuario/usuario.service';
import { Estado } from './../../../entidades/estado';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { ApiService } from './../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MensagemService } from './../../../services/mensagem/mensagem.service';
import { AgenciaService } from './../../../services/agencia/agencia.service';
import { Agencia } from './../../../entidades/agencia';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Endereco } from 'src/app/entidades/endereco';
import { Usuario } from 'src/app/entidades/usuario';
import { Validador } from 'src/app/validadores/validador';

@Component({
  selector: 'app-agencia-detalhe',
  templateUrl: './agencia-detalhe.component.html',
  styleUrls: ['../../../app.component.css', './agencia-detalhe.component.css']
})
export class AgenciaDetalheComponent implements OnInit {
  agencia: Agencia;
  isBusy = false;
  formulario: UntypedFormGroup;
  estados: Estado[];
  gerentes: Usuario[];

  constructor(private formBuilder: UntypedFormBuilder,
    private agenciaService: AgenciaService,
    private apiService: ApiService,
    private usuarioService: UsuarioService,
    private appService: AppConfigService,
    private mensagemService: MensagemService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.appService.obterEstadosBrasileiros().then(result => {
      this.estados = this.apiService.verificaSeResult<Estado[]>(result);
    });
    this.criarFormulario();
    /* Obtém os gerentes */
    await this.usuarioService.Todos().then(res => {
      this.gerentes = this.apiService.verificaSeResult<Usuario[]>(res).filter(x => x.nivel === 'OPERADOR');
    });
    const codPA = this.activatedRoute.snapshot.paramMap.get('codPA');
    if (codPA !== null && codPA !== undefined) {
      this.agenciaService.obterAgencia(codPA).then(result => {
        this.agencia = result as Agencia;
        this.popularForm();
      });
    }
  }

  criarFormulario(): void {
    this.formulario = this.formBuilder.group({
      codPA: [null, Validators.required],
      cidade: [null, Validators.required],
      logradouro: [null, Validators.required],
      numero: [null, Validators.required],
      bairro: [null, Validators.required],
      uf: [null, Validators.required],
      cep: [null, Validators.required],
      telefone: [null, Validador.minimumTelephoneDigits()],
      celular: [null, Validador.minimumTelephoneDigits()],
      fax: [null, Validador.minimumTelephoneDigits()],
      emailGeral: [null, [Validators.email]],
      observacao: [null],
      gerenteId: [null, [Validators.required]],
      emailGerente: [null]
    });
  }

  popularForm(): void {
    this.formulario.patchValue({
      codPA: this.agencia?.codPA,
      cidade: this.agencia?.cidade,
      logradouro: this.agencia?.logradouro,
      numero: this.agencia?.numero,
      bairro: this.agencia?.bairro,
      uf: this.agencia?.uf,
      cep: this.agencia?.cep,
      telefone: this.agencia?.telefone,
      celular: this.agencia?.celular,
      fax: this.agencia?.fax,
      emailGeral: this.agencia?.emailGeral,
      observacao: this.agencia?.observacao,
      gerenteId: this.agencia?.gerenteId,
      emailGerente: this.agencia?.gerente?.email
    });
    this.formulario.markAsPristine();
  }

  consultarCEP(): void {
    const cep = this.formulario.get('cep').value;
    if (cep === null || cep === undefined) {
      this.mensagemService.showMessage('Informe um CEP.', 'Ok', 5000, 'error');
      return;
    }
    this.isBusy = true;
    this.apiService.obterEnderecoCEP(cep).then(result => {
      if (result !== null && result !== undefined && !result.erro) {
        const endereco: Endereco = result;
        this.formulario.patchValue({
          logradouro: endereco.logradouro,
          bairro: endereco.bairro,
          cidade: endereco.localidade,
          uf: endereco.uf
        });
        this.formulario.markAsPristine();
      } else {
        this.mensagemService.showMessage('CEP inválido ou não encontrado.', 'Ok', 5000, 'error');
      }
      this.isBusy = false;
    });
  }

  onSubmit(): void {
    this.isBusy = true;
    this.agencia = this.formulario.value as Agencia;
    this.agenciaService.adicionarOuEditar(this.agencia).then(result => {
      if (result !== null && result !== undefined) {
        this.mensagemService.showMessage('Agência (PA) cadastrada com sucesso.', 'Ok', 5000, 'success');
        this.cancelar();
      } else {
        this.mensagemService.showMessage('Agência (PA) não pode ser cadastrada. Contate o suporte.', 'Ok', 5000, 'error');
      }
      this.isBusy = false;
    });
  }

  cancelar(): void {
    this.router.navigate(['/agencia']);
  }
}
