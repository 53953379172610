import { ApiService } from 'src/app/services/api/api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Cliente } from 'src/app/entidades/cliente';
import { httpVerb } from 'src/app/enum/httpVerb';
import { Paginator } from 'src/app/entidades/paginator';
import { Pagina } from 'src/app/entidades/pagina';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { ContaBancaria } from 'src/app/entidades/contaBancaria';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  filtroClienteEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();
  filtrandoGerente = false;

  getFiltroClienteEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  geDescricaoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  constructor(private apiService: ApiService) {
    sessionStorage.removeItem("gerente");
  }

  setFiltrandoGerente(value: boolean) {
    this.filtrandoGerente = value;
  }

  async todos(): Promise<Cliente[]> {
    try {
      let clientes: Cliente[];
      await this.apiService.request<Cliente[]>(`/Cliente/Todos`, httpVerb.get).then(result => {
        clientes = this.apiService.verificaSeResult<Cliente[]>(result);
      });
      return clientes;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obter(paginator: Paginator, moduloCotacao = false): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cliente?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}&moduloCotacao=${moduloCotacao}`;
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.get)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Cliente>>(result);
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterAgenda(paginator: Paginator): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cliente?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.get)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Cliente>>(result);
          paginaAtual.dados = paginaAtual.dados.map(cli => {
            return {
              id: cli.id,
              associado: cli.associado,
              cpF_CNPJ: cli.cpF_CNPJ,
              conta: cli.conta,
              gerente: `${cli.gerente.nome.split(' ')[0]} - ${cli.gerente.codPA}`,
              iap: cli.iapProdutoCliente?.quantidadeIAP === null ? 0 : cli.iapProdutoCliente?.quantidadeIAP
            }
          });
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterCarteiraAgenda(paginator: Paginator): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cliente/Carteira?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.get)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Cliente>>(result);
          paginaAtual.dados = paginaAtual.dados?.map(cli => {
            return {
              id: cli.id,
              associado: cli.associado,
              cpF_CNPJ: cli.cpF_CNPJ,
              conta: cli.conta,
              gerente: `${cli.gerente.nome.split(' ')[0]} - ${cli.gerente.codPA}`,
              iap: cli.iapProdutoCliente?.quantidadeIAP === null ? 0 : cli.iapProdutoCliente?.quantidadeIAP
            }
          });
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrarPorGerente(paginator: Paginator, gerenteId: number): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      let complemento = null;
      let filtrando = Boolean(sessionStorage.getItem("Filtrando")?.toString());
      if (filtrando && gerenteId > 0) {
        let filtro = Number(sessionStorage.getItem("FiltroSel"));
        complemento = `/Cliente/filtrar/Gerente/${gerenteId}/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}&moduloCotacao=${false}`;
      } else {
        complemento = `/Cliente/FiltraGerente/${gerenteId}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      }
      sessionStorage.setItem("gerente", gerenteId.toString());
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.get).then(res => {
        paginaAtual = this.apiService.verificaSeResult<Pagina<Cliente>>(res);
        paginaAtual.dados = paginaAtual.dados?.map(cli => {
          return {
            id: cli.id,
            associado: cli.associado,
            cpF_CNPJ: cli.cpF_CNPJ,
            conta: cli.conta,
            gerente: `${cli.gerente.nome.split(' ')[0]} - ${cli.gerente.codPA}`,
            iap: cli.iapProdutoCliente?.quantidadeIAP === null ? 0 : cli.iapProdutoCliente?.quantidadeIAP
          }
        });
      });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterCliente(clienteId: number): Promise<Cliente> {
    try {
      let cliente = undefined;
      await this.apiService.request<Cliente>(`/Cliente/${clienteId}`, httpVerb.get).then(result => {
        cliente = this.apiService.verificaSeResult<Cliente>(result);
      });
      return cliente;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterContasBancarias(clienteId: number, paginator: Paginator): Promise<Pagina<ContaBancaria>> {
    try {
      let paginaAtual = null;
      const complemento = `/ContaBancaria/Correntista/${clienteId}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<ContaBancaria>>(complemento, httpVerb.get).then(result => {
        paginaAtual = this.apiService.verificaSeResult<Pagina<ContaBancaria>>(result);
      });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterCarteira(paginator: Paginator): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cliente/Carteira?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.get)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Cliente>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator, moduloCotacao = false): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      let complemento = null;
      let gerenteId = Number(sessionStorage.getItem("gerente"));
      if (this.filtrandoGerente && gerenteId > 0) {
        complemento = `/Cliente/filtrar/Gerente/${gerenteId}/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}&moduloCotacao=${moduloCotacao}`;
      } else {
        complemento = `/Cliente/filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}&moduloCotacao=${moduloCotacao}`;
      }
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.post, dto)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Cliente>);
            if (this.filtrandoGerente && gerenteId > 0) {
              paginaAtual.dados = paginaAtual.dados?.map(cli => {
                return {
                  id: cli.id,
                  associado: cli.associado,
                  cpF_CNPJ: cli.cpF_CNPJ,
                  conta: cli.conta,
                  gerente: `${cli.gerente.nome.split(' ')[0]} - ${cli.gerente.codPA}`,
                  iap: cli.iapProdutoCliente?.quantidadeIAP === null ? 0 : cli.iapProdutoCliente?.quantidadeIAP
                }
              });
            }
          }
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async aniversariantesDoMes(): Promise<Cliente[]> {
    try {
      let clientes: Cliente[];
      await this.apiService.request<Cliente[]>(`/Cliente/Aniversariantes`, httpVerb.get).then(result => {
        clientes = this.apiService.verificaSeResult<Cliente[]>(result);
      });
      return clientes;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterCarteiraPF(paginator: Paginator): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cliente/PF?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.get)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Cliente>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterCarteiraPJ(paginator: Paginator): Promise<Pagina<Cliente>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cliente/PJ?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Cliente>>(complemento, httpVerb.get)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Cliente>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
