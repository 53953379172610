<div class="pdf-viewer mat-elevation-z8" *ngIf="show">
  <div class="title">
    <img
      id="logoSicoob"
      src="../../../assets/imagens/sicoob.ico"
      alt="Logotipo do Sicoob"
    />{{ titulo }}
    <div class="spacer"></div>
    <a class="right btnDelete" (click)="close()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <embed [src]="getPDF()" type="application/pdf" width="100%" height="100%" />
</div>
