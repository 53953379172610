import { EndossoAssessor } from 'src/app/entidades/endossoAssessor';
import { AndamentoEndosso } from 'src/app/entidades/andamentoEndosso';
import { ApiService } from 'src/app/services/api/api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Cotacao } from 'src/app/entidades/cotacao';
import { Pagina } from 'src/app/entidades/pagina';
import { Endosso } from 'src/app/entidades/endosso';
import { httpVerb } from 'src/app/enum/httpVerb';
import { Paginator } from 'src/app/entidades/paginator';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Periodo } from 'src/app/entidades/periodo';
import { Andamento } from 'src/app/entidades/andamento';
import { TabCotacao } from 'src/app/enum/tabCotacao';
import { Fechamento } from 'src/app/entidades/fechamento';
import { CancelamentoDto } from 'src/app/dto/cancelamentoDto';
import { Desistencia } from 'src/app/entidades/desistencia';
import { FiltroEspecialDto } from 'src/app/dto/filtroEspecialDto';

@Injectable({
  providedIn: 'root'
})
export class EndossoService {
  cotacao: Cotacao;
  endossoEventEmitter = new EventEmitter<Cotacao>();
  endossoTabEmitter = new EventEmitter<TabCotacao>();
  filtroEndossoEmitter = new EventEmitter<number>();
  descricaoEndossoFiltroEmitter = new EventEmitter<string>();
  dataEndossoFiltroEmitter = new EventEmitter<Periodo>();
  updateEndossosEmitter = new EventEmitter<boolean>();
  updateFiltrosEmitter = new EventEmitter<boolean>();
  motivoCancelamentoEmitter = new EventEmitter<any>();
  motivoDesistenciaEmitter = new EventEmitter<any>();
  reverterCancelamentoEmitter = new EventEmitter<any>();

  getEndossoEventEmitter(): EventEmitter<Cotacao> {
    return new EventEmitter<Cotacao>();
  }
  getUpdateEndossoEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  getEndossoTabEmitter(): EventEmitter<TabCotacao> {
    return new EventEmitter<TabCotacao>();
  }

  getFiltroEndossoEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getDescricaoEndossoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getDataEndossoFiltroEmitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  getUpdateEndossosEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  getUpdateFiltrosEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  getMotivoCancelamentoEmitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getMotivoDesistenciaEmitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getReverterCancelamentoEmitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  constructor(private apiService: ApiService) {
    this.endossoEventEmitter.subscribe(async (cotacao) => {
      this.cotacao = cotacao;
    });
  }

  async obter(paginator: Paginator): Promise<Pagina<Endosso>> {
    try {
      let pagina = null;
      const complemento = `/Endosso?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Endosso>>(complemento, httpVerb.get).then(res => {
        pagina = this.apiService.verificaSeResult<Pagina<Endosso>>(res);
      });
      return pagina;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async todos(): Promise<Endosso[]> {
    try {
      let dados = null;
      await this.apiService.request<Endosso[]>(`/Endosso/Todos`, httpVerb.get).then(res => {
        dados = this.apiService.verificaSeResult<Endosso[]>(res);
      });
      return dados;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterEndosso(id: number): Promise<Endosso> {
    try {
      let endosso = null;
      await this.apiService.request<Endosso>(`/Endosso/${id}`, httpVerb.get).then(res => {
        endosso = this.apiService.verificaSeResult<Endosso>(res);
      });
      return endosso;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<Endosso>> {
    try {
      let paginaAtual = null;
      const complemento = `/Endosso/Filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Endosso>>(complemento, httpVerb.post, dto)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Endosso>);
          }
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      let excluiu = false;
      await this.apiService.request<boolean>(`/Endosso/${id}`, httpVerb.delete).then(res => {
        excluiu = this.apiService.verificaSeResult<boolean>(res);
      });
      return excluiu;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async adicionarOuEditar(endosso: Endosso): Promise<Endosso> {
    try {
      let endossoAdicionado = null;
      await this.apiService.request<Endosso>(`/Endosso`, httpVerb.put, endosso).then(res => {
        endossoAdicionado = this.apiService.verificaSeResult<Endosso>(res);
      });
      return endossoAdicionado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async alterarStatus(endossoId: number, statusId: number): Promise<boolean> {
    try {
      let alterou = false;
      await this.apiService.request<boolean>(`/Endosso/Status/${endossoId}/${statusId}`, httpVerb.patch).then(result => {
        alterou = this.apiService.verificaSeResult<boolean>(result);
      });
      return alterou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async obterAndamentos(endossoId: number): Promise<Andamento[]> {
    try {
      let andamentos = null;
      await this.apiService.request<Andamento[]>(`/Endosso/Andamentos/${endossoId}`, httpVerb.get).then(result => {
        andamentos = this.apiService.verificaSeResult<Andamento[]>(result);
      });
      return andamentos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterAndamento(andamentoId: number): Promise<AndamentoEndosso[]> {
    try {
      let andamentos = null;
      await this.apiService.request<AndamentoEndosso[]>(`/Endosso/Andamento/${andamentoId}`, httpVerb.get).then(result => {
        andamentos = this.apiService.verificaSeResult<AndamentoEndosso[]>(result);
      });
      return andamentos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditarAndamento(andamento: AndamentoEndosso): Promise<AndamentoEndosso> {
    try {
      let result = null;
      await this.apiService.request<AndamentoEndosso>(`/Endosso/Andamento`, httpVerb.put, andamento).then(res => {
        result = this.apiService.verificaSeResult<AndamentoEndosso>(res);
      });
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterAssessorias(endossoId: number): Promise<EndossoAssessor[]> {
    try {
      let resultado = null;
      await this.apiService.request<EndossoAssessor[]>(`/Endosso/Assessores/Todos/${endossoId}`, httpVerb.get).then(result => {
        resultado = this.apiService.verificaSeResult<EndossoAssessor[]>(result);
      });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterFechamento(endossoId: number): Promise<Fechamento> {
    try {
      let fechamento = null;
      await this.apiService.request<Fechamento>(`/Endosso/Fechamento/${endossoId}`, httpVerb.get).then(res => {
        fechamento = this.apiService.verificaSeResult<Fechamento>(res);
      });
      return fechamento;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async fechamento(fechamento: Fechamento): Promise<Fechamento> {
    try {
      let result = null;
      await this.apiService.request<Fechamento>(`/Fechamento`, httpVerb.put, fechamento).then(res => {
        result = this.apiService.verificaSeResult<Fechamento>(res);
      });
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async qtdeEndossosAlterados(): Promise<number> {
    try {
      let total = 0;
      await this.apiService.request<number>(`/Endosso/Alterados`, httpVerb.get, null, false, false).then(res => {
        total = res;
      });
      return total;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async cancelar(cancelamento: CancelamentoDto): Promise<boolean> {
    try {
      let cancelou = false;
      await this.apiService.request<boolean>(`/Endosso/Cancelar`, httpVerb.put, cancelamento).then(res => {
        cancelou = this.apiService.verificaSeResult<boolean>(res);
      });
      return cancelou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async recusar(cancelamento: CancelamentoDto): Promise<boolean> {
    try {
      let recusou = false;
      await this.apiService.request<boolean>(`/Endosso/Recusar`, httpVerb.put, cancelamento).then(res => {
        recusou = this.apiService.verificaSeResult<boolean>(res);
      });
      return recusou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async desistencia(desistencia: Desistencia): Promise<Desistencia> {
    try {
      let result = null;
      await this.apiService.request<Desistencia>(`/Desistencia`, httpVerb.put, desistencia).then(res => {
        result = this.apiService.verificaSeResult<Desistencia>(res);
      });
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtroEspecial(filtro: FiltroEspecialDto, paginator: Paginator): Promise<Pagina<Endosso>> {
    try {
      let paginaAtual = null;
      const complemento = `/Endosso/FiltroEspecial?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Endosso>>(complemento, httpVerb.post, filtro)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Endosso>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

}
