import { Injectable } from '@angular/core';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { Status } from 'src/app/entidades/status';
import { StatusNivel } from 'src/app/entidades/statusNivel';
import { httpVerb } from 'src/app/enum/httpVerb';
import { TipoStatus } from 'src/app/enum/tipoStatus';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private apiService: ApiService) { }

  // async todos(): Promise<Status[]> {
  //   try {
  //     let status: Status[];
  //     await this.apiService.request<Status[]>(`/Status/Todos`, httpVerb.get).then(result => {
  //       if (result !== null && result !== undefined) {
  //         status = result as Status[];
  //       }
  //     });
  //     return status;
  //   } catch (error) {
  //     console.log(error);
  //     return null;
  //   }
  // }

  async todos(): Promise<Status[]> {
    try {
      let status: Status[];
      await this.apiService.request<Status[]>(`/Status/Todos`, httpVerb.get).then(result => {
        if (result !== null && result !== undefined) {
          status = result as Status[];
        }
      });
      return status;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditar(novoStatus: Status): Promise<Status> {
    try {
      let status = null;
      await this.apiService.request<Status>('/Status', httpVerb.put, novoStatus)
        .then(result => {
          if (result !== null && result !== undefined) {
            status = (result as Status);
          }
        });
      return status;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<Status>> {
    try {
      let paginaAtual = null;
      const complemento = `/Status?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Status>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Status>);
          }
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterStatus(id: number): Promise<Status> {
    try {
      let statusRetorno = null;
      await this.apiService.request<Status>(`/Status/${id}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            statusRetorno = (result as Status);
          }
        });
      return statusRetorno;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      let excluiu = false;
      await this.apiService.request<boolean>(`/Status/${id}`, httpVerb.delete)
        .then(result => {
          excluiu = result;
        });
      return excluiu;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrarPorTipo(tipo: TipoStatus, paginator: Paginator): Promise<Pagina<Status>> {
    try {
      let status: any;
      const complemento = `/Status/Filtrar/${tipo}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Status>>(`${complemento}`, httpVerb.get).then(result => {
        status = this.apiService.verificaSeResult<Pagina<Status[]>>(result);
      });
      return status;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterStatusPorNivel(): Promise<Status[]> {
    try {
      let status = [];
      if (this.apiService.UsuarioLogado.nivel === 'ADMINISTRADOR' || this.apiService.UsuarioLogado.nivel === 'ASSESSOR') {
        return await this.todos();
      }
      let nivel = this.apiService.UsuarioLogado.nivel;
      await this.apiService.request<StatusNivel[]>(`/Cotacao/Status/${nivel}`, httpVerb.get).then(result => {
        let statusNivel = this.apiService.verificaSeResult<StatusNivel[]>(result);
        statusNivel.forEach(element => {
          status.push(element.status);
        });
      });
      return status;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
