import { ItemFechamentosDiariosDto } from './../../dto/itemFechamentosDiariosDto';
import { TempoMedioChamadoDto } from './../../entidades/tempoMedioChamado';
import { TabCotacao } from 'src/app/enum/tabCotacao';
import { ItemCotacoesDiariasDto } from '../../dto/itemCotacoesDiariasDto';
import { httpVerb } from 'src/app/enum/httpVerb';
import { EventEmitter, Injectable } from '@angular/core';
import { Cotacao } from 'src/app/entidades/cotacao';
import { Periodo } from 'src/app/entidades/periodo';
import { ApiService } from '../api/api.service';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Status } from 'src/app/entidades/status';
import { Andamento } from 'src/app/entidades/andamento';
import { UsuarioCotacao } from 'src/app/entidades/usuarioCotacao';
import { CotacaoAssessor } from 'src/app/entidades/cotacaoAssessor';
import { StatusNivel } from 'src/app/entidades/statusNivel';
import { Desistencia } from 'src/app/entidades/desistencia';
import { Fechamento } from 'src/app/entidades/fechamento';
import { CancelamentoDto } from 'src/app/dto/cancelamentoDto';
import { ResumoStatusDashboardDto } from 'src/app/dto/resumoStatusDashboardDto';
import { PeriodoDto } from 'src/app/dto/periodoDto';
import { MinhasCotacoesDto } from 'src/app/dto/minhasCotacoesDto';
import { emitWarning } from 'process';
import { TopPrioridadesDto } from 'src/app/dto/topPrioridadesDto';
import { CotacaoDto } from 'src/app/dto/cotacaoDto';
import { FiltroEspecialDto } from 'src/app/dto/filtroEspecialDto';
import { ReverterCancelamentoDto } from 'src/app/dto/reverterCancelamentoDto';

@Injectable({
  providedIn: 'root',
})
export class CotacaoService {
  filtroCotacaoEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();
  dataFiltroEmitter = new EventEmitter<Periodo>();
  filtroCotacaoFuturaEmitter = new EventEmitter<number>();
  descricaoCotFuturaFiltroEmitter = new EventEmitter<string>();
  dataFiltroCotFuturaEmitter = new EventEmitter<Periodo>();
  updateCotacoesEmitter = new EventEmitter<boolean>();
  updateCotacoesFuturaEmitter = new EventEmitter<boolean>();
  tabCotacaoEmitter = new EventEmitter<TabCotacao>();
  updateFiltrosEmitter = new EventEmitter<boolean>();
  updateFiltrosCFEmitter = new EventEmitter<boolean>();
  motivoCancelamentoEmitter = new EventEmitter<any>();
  motivoDesistenciaEmitter = new EventEmitter<any>();
  emConferenciaEmiiter = new EventEmitter<any>();
  cotacaoInternaEmiiter = new EventEmitter<any>();
  reverterCancelamentoEmitter = new EventEmitter<any>();

  getUpdateCotacoesEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  getfiltroCotacaoFuturaEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getFiltroCotacaoEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getDescricaoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getDataFiltroEmitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }
  getFiltroCotacaoFuturaEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getDescricaoCotFuturaFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getDataFiltroCotFuturaEmitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  getUpdateCotacoesFuturaEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }
  getTabCotacaoEmitter(): EventEmitter<TabCotacao> {
    return new EventEmitter<TabCotacao>();
  }
  getUpdateFiltrosEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }
  getUpdateFiltrosCFEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }
  getMotivoCancelamentoEmitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }
  getMotivoDesistenciaEmitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getEmConferenciaEmiiter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }
  getCotacaoInternaEmiiter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getReverterCancelamentoEmitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  constructor(private apiService: ApiService) {}

  async todas(): Promise<Cotacao[]> {
    try {
      let cotacoes: Cotacao[] = [];
      await this.apiService
        .request<Cotacao[]>(`/Cotacao/Todas`, httpVerb.get)
        .then((result) => {
          cotacoes = this.apiService.verificaSeResult<Cotacao[]>(result);
        });
      return cotacoes;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterCotacao(id: number): Promise<Cotacao> {
    try {
      let cotacao = null;
      await this.apiService
        .request<Cotacao>(`/Cotacao/${id}/false`, httpVerb.get)
        .then((result) => {
          cotacao = this.apiService.verificaSeResult<Cotacao>(result);
        });
      return cotacao;
    } catch (error) {
      console.log(error);
    }
  }

  async obterColaboradores(cotacaoId: number): Promise<UsuarioCotacao[]> {
    try {
      let colaboradores = null;
      await this.apiService
        .request<UsuarioCotacao[]>(
          `/Cotacao/Colaboradores/${cotacaoId}`,
          httpVerb.get
        )
        .then((result) => {
          colaboradores =
            this.apiService.verificaSeResult<UsuarioCotacao[]>(result);
        });
      return colaboradores;
    } catch (error) {
      console.log(error);
    }
  }

  async obterPorId(id: any, retornaDto: boolean = false): Promise<any> {
    try {
      let cotacao = null;
      const complemento = `/Cotacao/${id}/${retornaDto}`;
      await this.apiService
        .request<any>(complemento, httpVerb.get)
        .then((result) => {
          cotacao = this.apiService.verificaSeResult<any>(result);
          this.apiService.isBusyEmitter.emit(false);
        });

      return cotacao;
    } catch (error) {
      console.log(error);
    }
  }

  async obterDesistencia(id: number): Promise<any> {
    try {
      let desistencia = null;
      const complemento = `/Desistencia/Cotacao/${id}`;
      await this.apiService
        .request<any>(complemento, httpVerb.get)
        .then((result) => {
          desistencia = this.apiService.verificaSeResult<any>(result);
        });

      return desistencia;
    } catch (error) {
      console.log(error);
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<CotacaoDto>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cotacao?Pagina=${
        paginator.pageIndex + 1
      }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<CotacaoDto>>(complemento, httpVerb.get)
        .then((result) => {
          paginaAtual =
            this.apiService.verificaSeResult<Pagina<CotacaoDto>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async fechadas(paginator: Paginator): Promise<Pagina<Cotacao>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cotacao/Fechadas?Pagina=${
        paginator.pageIndex + 1
      }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<Cotacao>>(complemento, httpVerb.get)
        .then((result) => {
          paginaAtual =
            this.apiService.verificaSeResult<Pagina<Cotacao>>(result);
        });
      paginaAtual.dados = paginaAtual.dados.map((c) => {
        return {
          id: c.id,
          nomeCliente: c.nomeCliente,
          cpfcnpj: c.cpfcnpj,
          produto: c.produto.descricao,
        };
      });
      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async paraEndosso(paginator: Paginator): Promise<Pagina<CotacaoDto>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cotacao/ParaEndosso?Pagina=${
        paginator.pageIndex + 1
      }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<CotacaoDto>>(complemento, httpVerb.get)
        .then((result) => {
          paginaAtual =
            this.apiService.verificaSeResult<Pagina<CotacaoDto>>(result);
        });
      // paginaAtual.dados = paginaAtual.dados.map(c => {
      // return {
      //   id: c.id,
      //   nomeCliente: c.nomeCliente,
      //   cpfcnpj: c.cpfcnpj,
      //   produto: c.produto.descricao
      // }
      // });
      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async filtrar(
    filtro: number,
    dto: FiltroDTO,
    paginator: Paginator
  ): Promise<Pagina<CotacaoDto>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cotacao/Filtrar/${filtro}?Pagina=${
        paginator.pageIndex + 1
      }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<CotacaoDto>>(complemento, httpVerb.post, dto)
        .then((result) => {
          if (result !== null && result !== undefined) {
            paginaAtual = result as Pagina<CotacaoDto>;
          }
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrarCotacaoFutura(
    idStatusCotacaoFutura: number,
    filtro: number,
    dto: FiltroDTO,
    paginator: Paginator
  ): Promise<Pagina<CotacaoDto>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cotacao/Filtrar/${idStatusCotacaoFutura}/${filtro}?Pagina=${
        paginator.pageIndex + 1
      }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<CotacaoDto>>(complemento, httpVerb.post, dto)
        .then((result) => {
          if (result !== null && result !== undefined) {
            paginaAtual = result as Pagina<CotacaoDto>;
          }
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterStatus(): Promise<Status[]> {
    try {
      let status = null;
      await this.apiService
        .request<Status[]>('/Cotacao/Status', httpVerb.get)
        .then((result) => {
          status = this.apiService.verificaSeResult<Status[]>(result);
        });
      return status;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterStatusPorNivel(): Promise<Status[]> {
    try {
      let status = [];
      if (
        this.apiService.UsuarioLogado.nivel === 'ADMINISTRADOR' ||
        this.apiService.UsuarioLogado.nivel === 'ASSESSOR'
      ) {
        return await this.obterStatus();
      }
      let nivel = this.apiService.UsuarioLogado.nivel;
      await this.apiService
        .request<StatusNivel[]>(`/Cotacao/Status/${nivel}`, httpVerb.get)
        .then((result) => {
          let statusNivel =
            this.apiService.verificaSeResult<StatusNivel[]>(result);
          statusNivel.forEach((element) => {
            status.push(element.status);
          });
        });
      return status;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async alterarStatus(cotacaoId: number, statusId: number): Promise<boolean> {
    try {
      let alterou = false;
      await this.apiService
        .request<boolean>(
          `/Cotacao/Status/${cotacaoId}/${statusId}`,
          httpVerb.patch
        )
        .then((result) => {
          alterou = this.apiService.verificaSeResult<boolean>(result);
        });
      return alterou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async obterAndamentos(idCotacao: number): Promise<Andamento[]> {
    try {
      let andamentos = null;
      await this.apiService
        .request<Andamento[]>(`/Cotacao/Andamentos/${idCotacao}`, httpVerb.get)
        .then((result) => {
          andamentos = this.apiService.verificaSeResult<Andamento[]>(result);
        });
      return andamentos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterAssessores(
    paginator: Paginator,
    cotacaoId: number
  ): Promise<Pagina<CotacaoAssessor>> {
    try {
      let pagina = null;
      const complemento = `/Cotacao/Assessores/${cotacaoId}?Pagina=${
        paginator.pageIndex + 1
      }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<CotacaoAssessor>>(complemento, httpVerb.get)
        .then((result) => {
          pagina =
            this.apiService.verificaSeResult<Pagina<CotacaoAssessor>>(result);
        });
      return pagina;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterAssessorias(cotacaoId: number): Promise<CotacaoAssessor[]> {
    try {
      let resultado = null;
      await this.apiService
        .request<CotacaoAssessor[]>(
          `/Cotacao/Assessores/Todos/${cotacaoId}`,
          httpVerb.get
        )
        .then((result) => {
          resultado =
            this.apiService.verificaSeResult<CotacaoAssessor[]>(result);
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarAssessor(
    cotacaoId: number,
    assessor: CotacaoAssessor
  ): Promise<CotacaoAssessor> {
    try {
      let assessor = null;
      await this.apiService
        .request<CotacaoAssessor>(
          `/Cotacao/Assessor/${cotacaoId}`,
          httpVerb.put,
          assessor
        )
        .then((result) => {
          assessor = this.apiService.verificaSeResult<CotacaoAssessor>(result);
        });
      return assessor;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async removerAssessor(assessor: CotacaoAssessor): Promise<boolean> {
    try {
      let excluiu = false;
      await this.apiService
        .request<boolean>(`/Cotacao/Assessor`, httpVerb.delete, assessor)
        .then((result) => {
          excluiu = this.apiService.verificaSeResult<boolean>(result);
        });
      return excluiu;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async removerAndamento(id: number, ehEndosso: boolean): Promise<boolean> {
    try {
      let excluiu = false;
      let numEndosso = ehEndosso ? -1 : 0;
      await this.apiService
        .request<boolean>(
          `/Cotacao/Andamento/${id}/${numEndosso}`,
          httpVerb.delete
        )
        .then((result) => {
          excluiu = this.apiService.verificaSeResult<boolean>(result);
        });
      return excluiu;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async removerAnexo(id: number, ehEndosso: boolean): Promise<boolean> {
    try {
      let excluiu = false;
      let numEndosso = ehEndosso ? -1 : 0;
      await this.apiService
        .request<boolean>(
          `/Cotacao/Andamento/Anexo/${id}/${numEndosso}`,
          httpVerb.delete
        )
        .then((result) => {
          excluiu = this.apiService.verificaSeResult<boolean>(result);
        });
      return excluiu;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async obterAssessorAtual(cotacaoId: number): Promise<CotacaoAssessor> {
    try {
      let assessor = null;
      await this.apiService
        .request<CotacaoAssessor>(
          `/Cotacao/Assessor/${cotacaoId}`,
          httpVerb.get
        )
        .then((result) => {
          assessor = this.apiService.verificaSeResult<boolean>(result);
        });
      return assessor;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditar(cotacao: Cotacao): Promise<Cotacao> {
    try {
      let cotacaoGravada = null;
      await this.apiService
        .request<Cotacao>(`/Cotacao`, httpVerb.put, cotacao)
        .then((result) => {
          cotacaoGravada = this.apiService.verificaSeResult<Cotacao>(result);
        });
      return cotacaoGravada;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditarAndamento(andamento: Andamento): Promise<Andamento> {
    try {
      let result = null;
      await this.apiService
        .request<Andamento>(`/Cotacao/Andamento`, httpVerb.put, andamento)
        .then((res) => {
          result = this.apiService.verificaSeResult<Andamento>(res);
        });
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async desistencia(desistencia: Desistencia): Promise<Desistencia> {
    try {
      let result = null;
      await this.apiService
        .request<Desistencia>(`/Desistencia`, httpVerb.put, desistencia)
        .then((res) => {
          result = this.apiService.verificaSeResult<Desistencia>(res);
        });
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async fechamento(fechamento: Fechamento): Promise<Fechamento> {
    try {
      let result = null;
      await this.apiService
        .request<Fechamento>(`/Fechamento`, httpVerb.put, fechamento)
        .then((res) => {
          result = this.apiService.verificaSeResult<Fechamento>(res);
        });
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterFechamento(cotacaoId: number): Promise<Fechamento> {
    try {
      let fechamento = null;
      await this.apiService
        .request<Fechamento>(`/Cotacao/Fechamento/${cotacaoId}`, httpVerb.get)
        .then((res) => {
          fechamento = this.apiService.verificaSeResult<Fechamento>(res);
        });
      return fechamento;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async chamadosAbertos(): Promise<number> {
    try {
      let total = 0;
      await this.apiService
        .request<number>(`/Cotacao/ChamadosAbertos`, httpVerb.get)
        .then((result) => {
          total = result;
        });
      return total;
    } catch (error) {
      console.log(error);
      return -1;
    }
  }

  async cancelar(cancelamento: CancelamentoDto): Promise<boolean> {
    try {
      let cancelou = false;
      await this.apiService
        .request<boolean>(`/Cotacao/Cancelar`, httpVerb.put, cancelamento)
        .then((res) => {
          cancelou = this.apiService.verificaSeResult<boolean>(res);
        });
      return cancelou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async recusar(cancelamento: CancelamentoDto): Promise<boolean> {
    try {
      let recusou = false;
      await this.apiService
        .request<boolean>(`/Cotacao/Recusar`, httpVerb.put, cancelamento)
        .then((res) => {
          recusou = this.apiService.verificaSeResult<boolean>(res);
        });
      return recusou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async obterResumoStatus(
    periodo: PeriodoDto,
    todoPA: boolean
  ): Promise<ResumoStatusDashboardDto> {
    try {
      let resumo = null;
      await this.apiService
        .request<ResumoStatusDashboardDto>(
          `/Cotacao/ResumoStatus?todoPA=${todoPA}`,
          httpVerb.post,
          periodo
        )
        .then((res) => {
          resumo =
            this.apiService.verificaSeResult<ResumoStatusDashboardDto>(res);
        });
      return resumo;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async minhasCotacoes(
    paginator: Paginator,
    todoPA: boolean
  ): Promise<Pagina<MinhasCotacoesDto>> {
    try {
      let pagina = null;
      await this.apiService
        .request<Pagina<MinhasCotacoesDto>>(
          `/Cotacao/MinhasCotacoes?Pagina=${
            paginator.pageIndex + 1
          }&tamanhoPagina=${paginator.pageSize}&todoPA=${todoPA}`,
          httpVerb.get
        )
        .then((res) => {
          pagina =
            this.apiService.verificaSeResult<Pagina<MinhasCotacoesDto>>(res);
        });
      return pagina;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async cotacoesDiarias(
    periodo: PeriodoDto,
    paginator: Paginator,
    todoPA: boolean
  ): Promise<Pagina<ItemCotacoesDiariasDto>> {
    try {
      let pagina = null;
      await this.apiService
        .request<Pagina<ItemCotacoesDiariasDto>>(
          `/Cotacao/CotacoesDiarias?Pagina=${
            paginator.pageIndex + 1
          }&tamanhoPagina=${paginator.pageSize}&todoPA=${todoPA}`,
          httpVerb.post,
          periodo
        )
        .then((res) => {
          pagina =
            this.apiService.verificaSeResult<Pagina<ItemCotacoesDiariasDto>>(
              res
            );
        });
      return pagina;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  async fechamentosDiarios(
    periodo: PeriodoDto,
    paginator: Paginator,
    todoPA: boolean
  ): Promise<Pagina<ItemFechamentosDiariosDto>> {
    try {
      let pagina = null;
      await this.apiService
        .request<Pagina<ItemFechamentosDiariosDto>>(
          `/Cotacao/FechamentosDiarios?Pagina=${
            paginator.pageIndex + 1
          }&tamanhoPagina=${paginator.pageSize}&todoPA=${todoPA}`,
          httpVerb.post,
          periodo
        )
        .then((res) => {
          pagina =
            this.apiService.verificaSeResult<Pagina<ItemFechamentosDiariosDto>>(
              res
            );
        });
      return pagina;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async qtdeCotacoesAlteradas(): Promise<number> {
    try {
      let total = 0;
      await this.apiService
        .request<number>(`/Cotacao/Alteradas`, httpVerb.get, null, false, false)
        .then((res) => {
          total = res;
        });
      return total;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async qtdeCotacoesFuturasAlteradas(): Promise<number> {
    try {
      let total = 0;
      await this.apiService
        .request<number>(
          `/Cotacao/Futuras/Alteradas`,
          httpVerb.get,
          null,
          false,
          false
        )
        .then((res) => {
          total = res;
        });
      return total;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async obterTempoMedioChamados(
    periodo: PeriodoDto,
    tipo: number,
    aguardandoRetorno: boolean = false
  ): Promise<TempoMedioChamadoDto> {
    try {
      let resultado;
      await this.apiService
        .request<TempoMedioChamadoDto>(
          `/Cotacao/TempoMedioChamados/${tipo}/${aguardandoRetorno}`,
          httpVerb.post,
          periodo,
          false,
          false
        )
        .then((res) => {
          resultado = res;
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async topPrioridades(periodo: PeriodoDto): Promise<TopPrioridadesDto[]> {
    try {
      let resultado;
      await this.apiService
        .request<TopPrioridadesDto[]>(
          `/Cotacao/TopPrioridades`,
          httpVerb.post,
          periodo,
          false,
          false
        )
        .then((res) => {
          resultado = res;
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtroEspecial(
    filtro: FiltroEspecialDto,
    paginator: Paginator,
    ehCotacaoFutura: boolean = false
  ): Promise<Pagina<CotacaoDto>> {
    try {
      let paginaAtual = null;
      const complemento = `/Cotacao/FiltroEspecial/${ehCotacaoFutura}?Pagina=${
        paginator.pageIndex + 1
      }&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService
        .request<Pagina<CotacaoDto>>(complemento, httpVerb.post, filtro)
        .then((result) => {
          paginaAtual =
            this.apiService.verificaSeResult<Pagina<CotacaoDto>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async reverterCancelamento(dados: ReverterCancelamentoDto): Promise<boolean> {
    try {
      let reverteu = false;
      await this.apiService
        .request<boolean>(`/Cotacao/ReverterCancelamento`, httpVerb.post, dados)
        .then((result) => {
          reverteu = this.apiService.verificaSeResult<boolean>(result);
        });
      return reverteu;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
