import { Cupom } from './../../entidades/cupom';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Campanha } from 'src/app/entidades/campanha';
import { ApiService } from 'src/app/services/api/api.service';
import { CampanhaService } from 'src/app/services/campanha/campanha.service';

@Component({
  selector: 'app-sorteio',
  templateUrl: './sorteio.component.html',
  styleUrls: ['./sorteio.component.css', '../../app.component.css']
})
export class SorteioComponent implements OnInit {
  campanha: Campanha;
  sorteados: Cupom[];
  isBusy = false;
  titulo: string;
  displayedColumns: string[] = ['position', 'cupom', 'nome', 'agencia', 'emissao', 'colaborador'];
  
  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              private apiService: ApiService,
              private campanhaService: CampanhaService) { }

  ngOnInit(): void { 
    try {
      //this.apiService.isBusyEmitter.subscribe(isBusy => this.isBusy = isBusy);
    const id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.campanhaService.ObterCampanha(id).then(result => {
      this.campanha = result as Campanha;
      this.titulo = `Resultado da Campanha: ${this.campanha.nome}`;
      this.campanhaService.ObterSorteados(this.campanha.id).then(res => {
        this.sorteados = res;        
      });
    });  
    } catch (error) {
      console.log(error);
    } 
  }
  
  voltar(): void {
    this.router.navigate(['/Campanhas']);
  }
}
