import { AbstractControl, Validators } from '@angular/forms';

export class Validador {
  constructor() { }

  static isValidCpfCnpj() {
    return (control: AbstractControl): Validators => {
      if (control.value == null || control.value === undefined) return null;
      const cpfCNPJ = control.value.replace(".", "").replace("-", "").replace("/", "");
      if (cpfCNPJ.length !== 11 && cpfCNPJ.length !== 14) return true;
      if (cpfCNPJ == "00000000000" ||
          cpfCNPJ == "11111111111" ||
          cpfCNPJ == "22222222222" ||
          cpfCNPJ == "33333333333" ||
          cpfCNPJ == "44444444444" ||
          cpfCNPJ == "55555555555" ||
          cpfCNPJ == "66666666666" ||
          cpfCNPJ == "77777777777" ||
          cpfCNPJ == "88888888888" ||
          cpfCNPJ == "99999999999")
          return { cpfNotValid: true };
      if (cpfCNPJ.length === 11) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpfCNPJ.length < 11) {
          return null;
        }

        for (i = 0; i < cpfCNPJ.length - 1; i++) {
          if (cpfCNPJ.charAt(i) !== cpfCNPJ.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpfCNPJ.substring(0, 9);
          digits = cpfCNPJ.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpfCNPJ.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      else {
        if (cpfCNPJ.length >= 11 && cpfCNPJ.length != 14)
          return true;
        // LINHA 10 - Elimina CNPJs invalidos conhecidos
        if (cpfCNPJ == "00000000000000" ||
          cpfCNPJ == "11111111111111" ||
          cpfCNPJ == "22222222222222" ||
          cpfCNPJ == "33333333333333" ||
          cpfCNPJ == "44444444444444" ||
          cpfCNPJ == "55555555555555" ||
          cpfCNPJ == "66666666666666" ||
          cpfCNPJ == "77777777777777" ||
          cpfCNPJ == "88888888888888" ||
          cpfCNPJ == "99999999999999")
          return { cpfNotValid: true }; // LINHA 21

        // Valida DVs LINHA 23 -
        var tamanho = cpfCNPJ.length - 2
        var numeros = cpfCNPJ.substring(0, tamanho);
        var digitos = cpfCNPJ.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        var i = 0;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
            pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
          return false;

        tamanho = tamanho + 1;
        numeros = cpfCNPJ.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
            pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
          return { cpfNotValid: true }; // LINHA 49

        return null; // LINHA 51
      }
    };
  }

  static isTextEquals(newControl: AbstractControl) {
    return (control: AbstractControl): Validators => {
      if (control.value === null || control.value === undefined) return null;
      if (newControl.value !== control.value) return { equalsText: false }
      return null;
    };
  }

  static isSequenceOf(char: string) {
    return (control: AbstractControl): Validators => {
      let text = control.value;
      if (text == null || text === undefined) return { invalid: true };
      let characters = [...text];
      for (const item of characters) {
        if (item !== char) return null;
      };
      return { invalid: true };
    };
  }

  static isValueRequired(isCurrency: boolean) {
    return (control: AbstractControl): Validators => {
      if (control.value === null || control.value === undefined || control.value === 0) return { invalid: true };
      if (isCurrency) {
        let value = control.value.replace('R$', '');
        if (Number(value) === 0) {
          return { invalid: true };
        }
      }
      else {
        if (control.value === 0) {
          return { invalid: true };
        }
      }
      return null;
    };
  }

  static minimumTelephoneDigits() {
    return (control: AbstractControl): Validators => {
      if (control.value === null || control.value === undefined || control.value === 0) return { invalid: true };
      const telefone = control.value.replace("(", "").replace(")", "").replace("-", "");
      if (telefone.length < 10) {
          return { invalid: true };
      }
      return null;
    };
  }
}
