import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './../../services/api/api.service';
import { AppConfigService } from './../../services/app/app-config.service';


@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AppConfigService,
    ApiService
  ]
})
export class CoreModule { }
