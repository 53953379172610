import { Injectable } from '@angular/core';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { TipoEndosso } from 'src/app/entidades/tipoEndosso';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';
import { MensagemService } from '../mensagem/mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class TipoEndossoService {

  constructor(private apiService: ApiService,
    private mensagemService: MensagemService) { }

  async AdicionarOuEditar(novoEndosso: TipoEndosso): Promise<TipoEndosso> {
    try {
      let tipoendosso = null;
      await this.apiService.request<TipoEndosso>('/TipoEndosso', httpVerb.put, novoEndosso)
        .then(result => {
          if (result !== null && result !== undefined) {
            tipoendosso = (result as TipoEndosso);
          }
        });

      return tipoendosso;
    } catch (error) {
      console.log(error);
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      let deletado = false;
      await this.apiService.request<boolean>(`/TipoEndosso/${id}`, httpVerb.put)
        .then(result => {
          deletado = this.apiService.verificaSeResult<boolean>(result);
        });
      return deletado;
    } catch (error) {
      console.log(error);
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<TipoEndosso>> {
    try {
      let paginaAtual = null;
      const complemento = `/TipoEndosso?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<TipoEndosso>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<TipoEndosso>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage('Erro ao obter Tipos de Endosso.', 'Ok', 5000, 'error');
    }
  }

  async todos(): Promise<TipoEndosso[]> {
    try {
      let usuarios = null;
      await this.apiService.request<TipoEndosso>('/tipoendosso/todos', httpVerb.get)
        .then(result => {
          if (result != null && result !== undefined) {
            usuarios = result;
          }
        });
      return usuarios;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage('Erro ao obter Tipos de Endosso.', 'Ok', 5000, 'error');
    }
  }

  async obterTipoEndosso(id: number): Promise<TipoEndosso> {
    try {
      let tipoRetorno = null;
      await this.apiService.request<TipoEndosso>(`/tipoendosso/${id}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            tipoRetorno = (result as TipoEndosso);
          }
        });
      return tipoRetorno;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage('Erro ao alterar Tipo de Endosso.', 'Ok', 5000, 'error');
    }
  }
}
