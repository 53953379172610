import { MetaCampanha } from './../../entidades/metaCampanha';
import { PosicaoCampanha } from './../../entidades/posicaoCampanha';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { httpVerb } from 'src/app/enum/httpVerb';
import { AgenciaService } from '../agencia/agencia.service';
import { Paginator } from 'src/app/entidades/paginator';
import { Pagina } from 'src/app/entidades/pagina';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';

@Injectable({
  providedIn: 'root'
})
export class MetasCampanhaService {

  constructor(private apiService: ApiService,
    private agenciaService: AgenciaService) { }

  async PosicaoCampanha(id: number): Promise<PosicaoCampanha[]> {
    try {
      let resultado = null;
      await this.apiService.request<PosicaoCampanha[]>(`/metascampanha/posicao/${id}`, httpVerb.post).then(async (result) => {
        resultado = this.apiService.verificaSeResult<PosicaoCampanha[]>(result);
      });

      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterMetaCampanha(id: number): Promise<MetaCampanha> {
    try {
      let metaCampanha: MetaCampanha;
      await this.apiService.request<MetaCampanha>(`/MetasCampanha/${id}`, httpVerb.get).then(result => {
        if (result !== null && result !== undefined) {
          metaCampanha = result as MetaCampanha;
        }
      });
      return metaCampanha;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditar(novaMeta: MetaCampanha): Promise<MetaCampanha> {
    try {
      let meta = null;
      await this.apiService.request<MetaCampanha>('/MetasCampanha', httpVerb.put, JSON.stringify(novaMeta))
        .then(result => {
          if (result !== null && result !== undefined) {
            meta = result as MetaCampanha;
          }
        });

      return meta;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async remover(id: Number): Promise<boolean> {
    try {
      let excluiu = false;
      await this.apiService.request<boolean>(`/MetasCampanha/${id}`, httpVerb.delete)
        .then(result => {
          if (result !== null && result !== undefined) {
            excluiu = result as boolean;
          }
        });

      return excluiu;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<MetaCampanha>> {
    try {
      let paginaAtual = null;
      const complemento = `/MetasCampanha?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<MetaCampanha>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<MetaCampanha>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<MetaCampanha>> {
    try {
      let paginaAtual = null;
      const complemento = `/MetasCampanha/filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<MetaCampanha>>(complemento, httpVerb.post, dto)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<MetaCampanha>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
