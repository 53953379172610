import { CupomComponent } from './cupom.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginGuard } from 'src/app/guard/login.guard';
import { TermoAdesaoComponent } from '../termo-adesao/termo-adesao.component';

const routes: Routes = [
  {
    path: '', component: CupomComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard],
    pathMatch: 'full'
  },
  {
    path: 'Cupom', component: CupomComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Cupom/:id', component: CupomComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Cupom/Termo/:id', component: TermoAdesaoComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  }
]

@NgModule({
  imports:[RouterModule.forChild(routes)],
  exports:[RouterModule]
})
export class CupomRoutingModuleModule { }
