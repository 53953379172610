<div class="tituloDialog">{{ titulo }}</div>
<div mat-dialog-content>
  <div class="row">
    <div class="rowOutline">
      <div><strong>Associado: </strong>{{ termoAdesaoDto?.nomeCliente }}</div>
    </div>
    <div class="rowOutline grow1">
      <div><strong>CNPJ/CPF: </strong>{{ termoAdesaoDto?.cpfCNPJ | CPF }}</div>
    </div>
  </div>
  <div class="row">
    <div class="rowOutline grow1">
      <div><strong>Cupons: </strong>{{ termoAdesaoDto?.qtdeCupons }}</div>
    </div>
    <div class="rowOutline">
      <div><strong>Conta: </strong>{{ termoAdesaoDto?.conta }}</div>
    </div>
    <div class="rowOutline">
      <div><strong>Valor: </strong>{{ termoAdesaoDto?.valorPago | reais }}</div>
    </div>
  </div>
  <div class="row">
    <div class="rowOutline">
      <div>
        <strong>Pagamento: </strong>{{ termoAdesaoDto?.parcelas }}x de
        {{ termoAdesaoDto?.valorParcela | reais }}
      </div>
    </div>
    <div class="rowOutline grow1">
      <div>
        <strong>Data do primeiro débito: </strong
        >{{ termoAdesaoDto?.dataDebito | date : "dd/MM/yyyy" }}
      </div>
    </div>
  </div>
  <div class="boxParcelamento">
    <div class="cardParcelamento" *ngFor="let parcela of data.row">
      <div class="item-right">
        <mat-icon aria-hidden="false" class="yellow" *ngIf="!parcela.foiQuitado"
          >warning</mat-icon
        >
        <mat-icon aria-hidden="false" class="green" *ngIf="parcela.foiQuitado"
          >thumb_up</mat-icon
        >
      </div>
      <div class="row">
        <strong>Parcela:</strong>
        {{ parcela.parcela }}
      </div>
      <div class="row">
        <strong>Vencimento:</strong>
        {{ parcela.vencimento | date: 'dd/MM/yyyy' }}
      </div>
      <div class="row">
        <strong>Valor:</strong>
        {{ parcela.valorParcela | reais }}
      </div>
      <div class="row" *ngIf="!parcela.foiQuitado">
        <mat-checkbox (change)="editarChanged($event, parcela)"
          >Débito Efetuado</mat-checkbox
        >
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Data da Quitação</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            [(ngModel)]="parcela.dataQuitacao"
            (keyup)="appConfigService.onKeyUpEventData($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="parcela?.foiQuitado">
        <strong>Por:</strong>
        {{ getUsuarioQuitacao(parcela?.idUsuarioQuitacao) }}
      </div>
      <div class="item-right" *ngIf="!parcela?.foiQuitado">
        <button
          mat-button
          class="btnOKMini mat-elevation-z8"
          [disabled]="!parcela?.editando || parcela?.dataQuitacao === null"
          type="button"
          (click)="quitarParcela(parcela)"
        >
          Salvar
        </button>
      </div>
      <div class="item-right" *ngIf="parcela.foiQuitado">
        <button mat-button class="btnDeleteMini mat-elevation-z8" type="button" (click)="reverterParcela(parcela)">
          Reverter
        </button>
      </div>
    </div>
  </div>
</div>
<div class="item-right">
  <button
    mat-button
    class="btnCancel mat-elevation-z8"
    type="button"
    (click)="voltarClick()"
  >
    Voltar
  </button>
</div>
