import { TipoCarteira } from './../../../enum/tipoCarteira';
import { enumToArray } from 'src/app/helper/enumToArray';
import { AgenciaService } from './../../../services/agencia/agencia.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Agencia } from 'src/app/entidades/agencia';
import { Usuario } from 'src/app/entidades/usuario';
import { ApiService } from 'src/app/services/api/api.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { UsuarioService } from './../../../services/usuario/usuario.service';

@Component({
  selector: 'app-usuario-detalhe',
  templateUrl: './usuario-detalhe.component.html',
  styleUrls: ['../../../app.component.css', './usuario-detalhe.component.css']
})
export class UsuarioDetalheComponent implements OnInit {
  usuario: Usuario = {
    id: 0,
    nome: '',
    nomeUsuario: '',
    senha: '',
    confirmacaoSenha: '',
    nomeExibicao: '',
    codPA: '',
    nivel: '',
    email: '',
    dataCriacao: undefined,
    dataAtualizacao: undefined,
    senhaProvisoria: false,
    emailConfirmado: false,
    inativo: false,
    tipoCarteira: TipoCarteira.Todos,
    ehColaborador: false,
  };
  niveis: string[];
  agencias: Agencia[];
  tiposCarteira = enumToArray(TipoCarteira);
  isBusy = false;
  formulario: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
    private usuarioService: UsuarioService,
    private agenciaService: AgenciaService,
    private apiService: ApiService,
    private mensagemService: MensagemService,
    private router: Router,
    private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //this.apiService.isBusyEmitter.subscribe(isBusy => this.isBusy = isBusy);
    this.agenciaService.todas().then(result => {
      this.agencias = result as Agencia[];
    });
    this.formulario = this.formBuilder.group({
      id: [0],
      nome: [null, Validators.required],
      nomeUsuario: [null, Validators.required],
      codPA: [null, Validators.required],
      nivel: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      tipoCarteira: [TipoCarteira.Todos, Validators.required],
      ehColaborador: [false, Validators.required]
    });
    const id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (id !== null && id > 0) {
      this.usuarioService.ObterUsuario(id).then(result => {
        this.usuario = result;
        this.formulario.patchValue({
          id: this.usuario.id,
          nome: this.usuario.nome,
          nomeUsuario: this.usuario.nomeUsuario,
          codPA: this.usuario.codPA,
          nivel: this.usuario.nivel,
          email: this.usuario.email,
          tipoCarteira: this.usuario.tipoCarteira,
          ehColaborador: this.usuario.ehColaborador
        });
      });
    }
  }

  onSubmit(): void {
    try {
      this.isBusy = true;
      let usuarioForm = this.formulario.value as Usuario;
      /* Popula o usuário com as alterações do formulário */
      this.usuario.nome = usuarioForm.nome;
      this.usuario.nomeUsuario = usuarioForm.nomeUsuario;
      this.usuario.codPA = usuarioForm.codPA;
      this.usuario.nivel = usuarioForm.nivel;
      this.usuario.email = usuarioForm.email;
      this.usuario.emailConfirmado = true;
      this.usuario.tipoCarteira = usuarioForm.tipoCarteira;
      this.usuario.ehColaborador = usuarioForm.ehColaborador;
      const descricao = this.usuario.id === 0 ? "adicionado" : "alterado";
      this.usuarioService.AdicionarOuEditar(this.usuario).then(
        result => {
          if (result !== null && result !== undefined) {
            this.isBusy = false;
            this.mensagemService.showMessage(`Usuário ${descricao} com sucesso.`,
              'Ok',
              5000,
              'success');
            this.cancelar();
          }
          else {
            this.isBusy = false;
            this.mensagemService.showMessage('Erro ao adicionar/atualizar usuário.',
              'Ok',
              5000,
              'error');
          }
        });
    } catch (error) {
      this.isBusy = false;
      this.mensagemService.showMessage(`Erro ao adicionar/atualizar usuário.`,
        'Ok',
        5000,
        'error');
    }
  }

  popularForm(): void {
    this.formulario.patchValue({
      nome: this.usuario.nome,
      nomeUsuario: this.usuario.nomeUsuario,
      senha: this.usuario.senha,
      codPA: this.usuario.codPA,
      nivel: this.usuario.nivel,
      email: this.usuario.email,
      tipoCarteira: this.usuario.tipoCarteira,
      ehColaborador: this.usuario.ehColaborador
    });
    this.formulario.markAsPristine();
  }

  limparFormulario(): void {
    this.formulario.patchValue({
      nome: null,
      nomeUsuario: null,
      senha: null,
      codPA: null,
      nivel: null,
      email: null,
      TipoCarteira: TipoCarteira.Todos,
      ehColaborador: false
    });
  }

  cancelar(): void {
    this.router.navigate(['/usuarios']);
  }
}
