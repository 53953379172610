import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlterarSenhaComponent } from 'src/app/componentes/usuario/alterar-senha/alterar-senha/alterar-senha.component';
import { UsuarioDetalheComponent } from 'src/app/componentes/usuario/usuario-detalhe/usuario-detalhe.component';
import { UsuarioComponent } from 'src/app/componentes/usuario/usuario.component';
import { LoginGuard } from 'src/app/guard/login.guard';

const usuariosRoutes: Routes = [
  {
    path: '', component: UsuarioComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard],
    pathMatch: 'full'
},
{
    path: 'usuario/novo', component: UsuarioDetalheComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
},
{
    path: 'usuario/:id', component: UsuarioDetalheComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
},
{
    path: 'usuario/edit/senha/:id', component: AlterarSenhaComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
}
];

@NgModule({
  imports: [RouterModule.forChild(usuariosRoutes)],
exports: [RouterModule]
})
export class UsuarioRoutingModule { }
