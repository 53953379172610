import { Injectable } from '@angular/core';
import { Menu } from 'src/app/entidades/menu';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';
import { MensagemService } from '../mensagem/mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private apiService: ApiService,
    private mensagemService: MensagemService) { }

  async obterMenus(): Promise<Menu[]> {
    try {
      if (this.apiService.UsuarioLogado) {
        let menus = null;
        await this.apiService.request<Menu[]>('/Menu', httpVerb.get)
          .then(result => {
            menus = this.apiService.verificaSeResult<Menu[]>(result);
          });
        return menus;
      }
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage('Erro ao obter Menus da Aplicação.', 'Ok', 5000, 'error');
    }
  }
}
