export enum FiltroCupom {
  Todos = 0,
  Nome = 1,
  CNPJ_CPF = 2,
  Email = 3,
  Telefone = 4,
  Por_Nome_Colaborador = 5,
  Por_Nome_Campanha = 6,
  Por_Data_Emissão = 7,
  Por_Quotas = 8,
  Por_Previdência = 9,
  Por_Pontos_SicoobCard = 10,
  Por_Cupom = 11,
  Por_PA = 12,
  Cancelados = 13,
  Pendentes = 14,
  Parcelados = 15,
  Parcelados_Pendente = 16
}
