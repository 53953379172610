import { FiltroService } from './../../services/filtro/filtro.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { Usuario } from 'src/app/entidades/usuario';
import { FiltroUsuario } from 'src/app/enum/filtroUsuario';
import { enumToArray } from 'src/app/helper/enumToArray';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { DialogoComponent } from '../dialogo/dialogo/dialogo.component';
import { ApiService } from './../../services/api/api.service';
import { MensagemService } from './../../services/mensagem/mensagem.service';
import { PaginatorService } from './../../services/Paginator/Paginator.service';
import { UsuarioService } from './../../services/usuario/usuario.service';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css', '../../app.component.css']
})
export class UsuarioComponent implements OnInit {
  isBusy = false;
  pagina: Pagina<Usuario>;
  usuarios: Usuario[];
  myPaginator: Paginator;
  displayedColumns: string[] = ['ativo', 'id', 'nome', 'login', 'pa', 'nivel', 'email', 'reset'];
  dataSource: MatTableDataSource<Usuario>;
  qtdeRegistros: number;
  usuarioSelecionado: Usuario;
  filtros = enumToArray(FiltroUsuario);
  filtroAtual: number;
  textoFiltroAtual: string;

  constructor(private router: Router,
    private apiService: ApiService,
    public usuarioService: UsuarioService,
    private paginatorService: PaginatorService,
    private appConfigService: AppConfigService,
    private dialog: MatDialog,
    private mensagemService: MensagemService,
    public filtroService: FiltroService) { }

  ngOnInit(): void {
    this.paginatorService.paginatorEmitter = this.paginatorService.getPaginatorEmitter();
    /* Emitter´s do componente de Filtros */
    this.usuarioService.filtroUsuarioEmitter = this.usuarioService.getfiltroUsuarioEmitter();
    this.filtroService.filtrarPorDescricaoEmitter = this.usuarioService.getDescricaoFiltroEmitter();
    this.usuarioService.filtroUsuarioEmitter.subscribe(res => {
      this.filtroAtual = res;
      this.myPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: this.appConfigService.itensPorPagina,
        previousPageIndex: 0
      };
      switch (this.filtroAtual) {
        case 0:
        case 4:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
          break;
        case 1:
        case 5:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
          break;
        case 2:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
          break;
        case 3:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
          break;
      }
    });
    this.filtroService.filtrarPorDescricaoEmitter.subscribe(res => {
      this.textoFiltroAtual = res[0];
      const filtroPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: this.appConfigService.itensPorPagina,
        previousPageIndex: 0
      };
      this.filtrarPorDescricao(filtroPaginator);
    });
    this.myPaginator = {
      length: 0,
      pageIndex: 0,
      pageSize: this.appConfigService.itensPorPagina,
      previousPageIndex: 0
    };
    this.paginatorService.paginatorEmitter.subscribe(result => {
      this.myPaginator = JSON.parse(result);
      if (this.filtroAtual <= 3 && this.filtroAtual > 0) {
        this.filtrarPorDescricao(this.myPaginator);
      }
      else if (this.filtroAtual === 4) {
        this.filtrarOutros(this.myPaginator);
      }
      else {
        this.usuarioService.obter(this.myPaginator).then(res => {
          this.configurarPagina(res);
        });
      }
    });
    this.obterUsuarios();
  }

  obterUsuarios(): void {
    this.usuarioService.obter(this.myPaginator).then(res => {
      this.configurarPagina(res);
    });
  }

  filtrarPorDescricao(filtroPaginator: Paginator) {
    if (this.filtroAtual > 0) {
      const dto = { texto: this.textoFiltroAtual } as FiltroDTO;
      this.usuarioService.filtrar(this.filtroAtual, dto, filtroPaginator)
        .then(result => { this.configurarPagina(result); });
    } else {
      this.obterUsuarios();
    }
  }

  filtrarOutros(filtroPaginator: Paginator) {
    const dto = { texto: null } as FiltroDTO;
    this.usuarioService.filtrar(this.filtroAtual, dto, filtroPaginator)
      .then(result => { this.configurarPagina(result); });
  }

  configurarPagina(pagina: Pagina<Usuario>): void {
    if (pagina !== null && pagina !== undefined) {
      this.usuarios = pagina.dados as Usuario[];
    }
    else {
      this.usuarios = null;
    }
    this.dataSource = new MatTableDataSource(this.usuarios);
    this.pagina = pagina;
    this.qtdeRegistros = this.pagina.quantidadeRegistros;
  }

  novoUsuario(): void {
    this.router.navigate(['/usuario/novo']);
  }

  editarUsuario(): void {
    if (this.usuarioSelecionado !== null && this.usuarioSelecionado !== undefined) {
      this.router.navigate([`/usuario/${this.usuarioSelecionado.id}`]);
    }
    else {
      this.mensagemService.showMessage('Selecione um usuário.', 'Ok', 5000, 'error');
    }
  }

  alterarSenha(): void {
    if (this.usuarioSelecionado !== null && this.usuarioSelecionado !== undefined) {
      this.router.navigate([`/usuario/edit/senha/${this.usuarioSelecionado.id}`]);
    }
    else {
      this.mensagemService.showMessage('Selecione um usuário.', 'Ok', 5000, 'error');
    }
  }

  rowClick(row: Usuario): void {
    this.usuarioSelecionado = row;
  }

  resetarSenha(row: Usuario): void {
    const dialogRef = this.dialog.open(DialogoComponent, {
      data: {
        titulo: 'Resetar Senha',
        pergunta: `Deseja relamente resetar a senha do Usuário ${row.nomeUsuario}?`
      },
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.usuarioService.resetarSenha(row as Usuario).then(result => {
          if (result) {
            this.mensagemService.showMessage(`Uma nova senha foi enviada para o email: ${row.email}. Usuário: ${row.nomeUsuario} `, 'Ok', 5000, 'success');
          } else {
            this.mensagemService.showMessage(`Erro ao resetar senha do usuario ${row.nomeUsuario}.`, 'Ok', 5000, 'error');
          }
        });
      }
    });
  }

  ativarDesativar(): void {
    if (this.usuarioSelecionado !== null && this.usuarioSelecionado !== undefined) {
      let ativoInativo = this.usuarioSelecionado.inativo ? "[ Ativo ]" : "[ Inativo ]";
      const dialogRef = this.dialog.open(DialogoComponent, {
        data: {
          titulo: 'Ativar / Desativar Usuário',
          pergunta: `O Usuário ${this.usuarioSelecionado.nome} ficará ${ativoInativo}. Confirma?`
        },
        width: '550px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.usuarioSelecionado.inativo = this.usuarioSelecionado.inativo ? false : true;
          this.usuarioService.AdicionarOuEditar(this.usuarioSelecionado as Usuario).then(result => {
            if (result) {
              this.mensagemService.showMessage(`Usuário: ${this.usuarioSelecionado.nome} não terá acesso ao sistema.`, 'Ok', 5000, 'success');
              this.obterUsuarios();
            } else {
              this.mensagemService.showMessage(`Erro ao tornar o usuário ${this.usuarioSelecionado.nome} inativo.`, 'Ok', 5000, 'error');
            }
          });
        }
      });
    }
    else {
      this.mensagemService.showMessage('Selecione um usuário.', 'Ok', 5000, 'error');
    }
  }
}
