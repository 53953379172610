<div class="container margin-nav mat-elevation-z8">
  <app-titulo [(titulo)]="titulo"></app-titulo>
  <form class="formContainer" [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Identificação do cliente sem registro</mat-label>
        <div class="busca">
          <input hidden formControlName="idClienteSemRegistro" />
          <input matInput id="inputCooperado" type="text" readonly />
          <mat-icon (click)="pesquisaCooperado()">search</mat-icon>
        </div>
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Status</mat-label>
        <mat-select #idStatusNovaCotacao formControlName="idStatusNovaCotacao">
          <mat-select-filter
            *ngIf="idStatusNovaCotacao.focused"
            [displayMember]="'descricao'"
            [placeholder]="'Procurando..'"
            [array]="status"
            (filteredReturn)="statusFiltrados = $event"
          >
          </mat-select-filter>
          <mat-option *ngFor="let status of statusFiltrados" [value]="status.id"
            >{{ status.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Qtde Chamados abertos por Assessor</mat-label>
        <input
          matInput
          #qtdeChamadosAbertosPorAssessor
          type="number"
          formControlName="qtdeChamadosAbertosPorAssessor"
        />
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Qtde Clientes gera visita (padrão)</mat-label>
        <input
          matInput
          #qtdeClientesGeraVisita
          type="number"
          formControlName="qtdeClientesGeraVisita"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Abrir Cotação Futura (dias)</mat-label>
        <input
          matInput
          #QtdeDiasAbrirCotacaoFutura
          type="number"
          formControlName="qtdeDiasAbrirCotacaoFutura"
        />
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Avisar parcelas (dias)</mat-label>
        <input
          matInput
          #DiasLembreteParcelas
          type="number"
          formControlName="diasLembreteParcelas"
        />
      </mat-form-field>
      <mat-checkbox formControlName="pesquisaClienteAgendaPorCarteira"
        >Pesquisa de Clientes na Agenda conforme Carteira</mat-checkbox
      >
      <mat-checkbox formControlName="habilitarComissionadoNaCotacao"
        >Habilita a inclusão de Comissionados nas Cotações</mat-checkbox
      >
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Status para finalizar Chamado no MCI</mat-label>
        <mat-select #statusIdFinalizaChamado formControlName="statusIdFinalizaChamado">
          <mat-select-filter
            *ngIf="statusIdFinalizaChamado.focused"
            [displayMember]="'descricao'"
            [placeholder]="'Procurando..'"
            [array]="statusFinaliza"
            (filteredReturn)="statusFinalizaFiltrados = $event"
          >
          </mat-select-filter>
          <mat-option *ngFor="let status of statusFinalizaFiltrados" [value]="status.id"
            >{{ status.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item-right">
      <button mat-button class="btnOK mat-elevation-z8" type="submit">
        Salvar
      </button>
      <button
        mat-button
        class="btnCancel mat-elevation-z8"
        type="button"
        (click)="cancelar()"
      >
        Cancelar
      </button>
    </div>
  </form>
</div>
