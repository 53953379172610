import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { Component, OnInit, ElementRef, isDevMode } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';
import { MensagemService } from './../../services/mensagem/mensagem.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../app.component.css']
})
export class LoginComponent implements OnInit {
  public naoAutorizado = false;
  isBusy = false;
  formulario: UntypedFormGroup;
  iconePassword: string = 'visibility';
  isVisible: boolean = false;
  exibirMensagem: boolean = true;
  versao: string = '';

  constructor(private formBuilder: UntypedFormBuilder,
    private appConfigService: AppConfigService,
    private apiService: ApiService,
    private loginService: LoginService,
    private mensagemService: MensagemService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.loginService.exibirNavBarEmitter.emit(false);
    this.formulario = this.formBuilder.group({
      nomeusuario: [null, Validators.required],
      senha: [null, Validators.required]
    });

    if (isDevMode()) {
      this.formulario.patchValue({
        nomeusuario: 'lucimaritba',
        senha: '272815'
      });
      this.formulario.markAsPristine();
      this.formulario.markAsTouched();
      this.formulario.markAsDirty();
    }

    this.loginService.isBusyEmitter.subscribe(
      isBusy => this.isBusy = isBusy
    );

    this.loginService.confirmarEmailEmitter.subscribe(
      async (result: boolean) => {
        if (result) {
          this.mensagemService.showMessage('Email não confirmado. Caso não tenha recebido o link de confirmação e/ou já tenha expirado, solicite uma nova confirmação.',
            'Ok',
            10000,
            'error'
          );
          this.exibirMensagem = false
          this.isBusy = false;
          this.router.navigate(['/']);
        }
      }
    );

    this.loginService.usuarioInativoEmitter.subscribe(
      async (result: boolean) => {
        if (result) {
          this.mensagemService.showMessage('Usuário inativo. Login bloqueado!.',
            'Ok',
            10000,
            'error'
          );
          this.exibirMensagem = false
          this.router.navigate(['/']);
        }
      }
    );
    await this.appConfigService.obterVersao().then(res => {
      this.versao = res;
    });
  }

  onSubmit(): void {
    this.isBusy = true;
    this.loginService.efetuarLogin(this.formulario.value).then(
      result => {
        this.naoAutorizado = result;
        if (this.naoAutorizado && this.exibirMensagem) {
          this.mensagemService.showMessage('Verifique suas credenciais ou o usuário não possui permissão de acesso.',
            'Ok',
            5000,
            'error'
          );
        }
      });
  }

  solicitarConfirmacao(): any {

  }

  passwordVisibility(event: any): void {
    if (event.eventPhase === 2) return;
    event.preventDefault();
    this.isVisible = !this.isVisible;
    if (this.isVisible) {
      this.iconePassword = 'visibility_off';
      document.getElementById('inputSenha').setAttribute('type', 'text');
    } else {
      this.iconePassword = 'visibility';
      document.getElementById('inpuntSenha').setAttribute('type', 'password');
    }
    this.formulario.updateValueAndValidity();
  }
}
