<app-spinner></app-spinner>
<app-pesquisa></app-pesquisa>
<app-pdf-viewer></app-pdf-viewer>
<app-nav-bar class="navbar"></app-nav-bar>
<mat-sidenav-container>
  <app-side-nav></app-side-nav>
</mat-sidenav-container>
<app-monitor></app-monitor>
<app-minha-atividade></app-minha-atividade>
<app-aviso></app-aviso>
