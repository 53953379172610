import { BuscaDTO } from 'src/app/dto/buscaDTO';
import { EventEmitter, Injectable } from '@angular/core';
import { DefinicaoColuna } from 'src/app/entidades/definicaoColuna';
import { IFunction } from 'src/app/interface/ifunction';

@Injectable({
  providedIn: 'root',
})
export class PesquisaService {
  pesquisaEventEmitter = new EventEmitter<boolean>();
  tituloEventEmitter = new EventEmitter<string>();
  mensagemEventEmitter = new EventEmitter<string>();
  resultadoEmitter = new EventEmitter<object>();
  filtrosEmitter = new EventEmitter<object>();
  filtroEmitter = new EventEmitter<object>();
  colunasEmitter = new EventEmitter<object>();
  columnsHeadersEmitter = new EventEmitter<DefinicaoColuna[]>();
  descricaoFEmitter = new EventEmitter<object>();
  periodoEmitter = new EventEmitter<object>();
  entidadeServiceEmitter = new EventEmitter<object>();
  buscaEmitter = new EventEmitter<BuscaDTO>();
  dadosFiltroExtraEmitter = new EventEmitter<object>();
  functionFiltroExtraEmitter = new EventEmitter<IFunction>();
  functionEmitter = new EventEmitter<IFunction>();
  moduloCotacaoEmitter = new EventEmitter<boolean>();

  getResultadoEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }

  getPesquisaEventEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }
  getTituloEventEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }
  getMensagemEventEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }
  getFiltrosEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }
  getFiltroEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }
  getColunasEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }
  getColumnsHeadersEmitter(): EventEmitter<DefinicaoColuna[]> {
    return new EventEmitter<DefinicaoColuna[]>();
  }
  getDescricaoFEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }
  getPeriodoEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }
  getEntidadeServiceEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }
  getBuscaEmitter(): EventEmitter<BuscaDTO> {
    return new EventEmitter<BuscaDTO>();
  }
  getDadosFiltroExtraEmitter(): EventEmitter<object> {
    return new EventEmitter<object>();
  }
  getFunctionFiltroExtraEmitter(): EventEmitter<IFunction> {
    return new EventEmitter<IFunction>();
  }
  getFunctionEmitter(): EventEmitter<IFunction> {
    return new EventEmitter<IFunction>();
  }
  getModuloCotacaoEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  constructor() {}
}
