import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/app/guard/login.guard';
import { AgenciaDetalheComponent } from './agencia-detalhe/agencia-detalhe.component';
import { AgenciaComponent } from './agencia.component';

const agenciaRoutes: Routes = [
  {
    path: '', component: AgenciaComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard],
    pathMatch: 'full'
  },
  {
    path: 'agencia', component: AgenciaComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'nova', component: AgenciaDetalheComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: ':codPA', component: AgenciaDetalheComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  }
]

@NgModule({  
  imports: [RouterModule.forChild(agenciaRoutes)],
  exports: [RouterModule]
})
export class AgenciaRoutingModule { }
