import { SorteioComponent } from './../sorteio/sorteio.component';
import { CampanhaComponent } from './campanha.component';
import { CampanhaRoutingModule } from './campanha-routing.module';
import { NgModule } from '@angular/core';
import { DataFormModule } from 'src/app/shared/data-form.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NovoCupomComponent } from './novo-cupom/novo-cupom.component';
import { PosicaoCampanhaComponent } from './posicao-campanha/posicao-campanha.component';
import { CampanhaDetalheComponent } from './campanha-detalhe/campanha-detalhe.component';
import { ParcelamentoCupomComponent } from '../parcelamento-cupom/parcelamento-cupom.component';

@NgModule({
  declarations: [CampanhaComponent,
    NovoCupomComponent,
    SorteioComponent,
    PosicaoCampanhaComponent,
    CampanhaDetalheComponent,
    ParcelamentoCupomComponent],
  imports: [
    SharedModule,
    DataFormModule,
    CampanhaRoutingModule
  ]
})
export class CampanhaModule { }
