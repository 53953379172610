<div class="titulo">Informações Adicionais</div>
<form [formGroup]="formulario" (ngSubmit)="onSubmit()">
  <div *ngFor="let input of inputs">
    <mat-form-field appearance="outline" class="{{ input.name }}">
      <mat-label>{{ input.label }}</mat-label>
      <input
        matInput
        id="{{ getId(input.name) }}"
        formControlName="{{ input.name }}"
        [maxlength]="getMaxLength(input.maxLenght)"
      />
    </mat-form-field>
  </div>
  <div class="item-right">
    <button
      mat-button
      class="btnOK mat-elevation-z8"
      type="submit"
      [disabled]="formulario.status == 'INVALID' || formulario.pristine"
    >
      Ok
    </button>
    <button
      mat-button
      class="btnCancel mat-elevation-z8"
      type="button"
      (click)="cancelar()"
    >
      Cancelar
    </button>
  </div>
</form>
