<div class="filtro">
  <mat-form-field class="tm25" appearance="outline">
    <mat-label>Filtro</mat-label>
    <mat-select
      id="filtro"
      name="filtro"
      [(ngModel)]="filtroSelecionado"
      (ngModelChange)="optionChanged()"
    >
      <mat-option *ngFor="let filtro of filtros" [value]="filtro.id">{{
        filtro.description
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="!exibirFiltroData" class="tm55" appearance="outline">
    <mat-label>Descrição</mat-label>
    <input
      matInput
      id="descricaoFiltro"
      type="text"
      [(ngModel)]="descricao"
      [disabled]="filtroSelecionado === 0"
      (keypress)="onKeyPressEvent($event)"
    />
  </mat-form-field>
  <mat-form-field class="tm15" appearance="outline" *ngIf="exibirFiltroData">
    <mat-label>Data Inicial</mat-label>
    <input
      id="dataInicial"
      matInput
      [matDatepicker]="picker1"
      [(ngModel)]="dataInicial"
      (keyup)="appConfigService.onKeyUpEventData($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="tm15" appearance="outline" *ngIf="exibirFiltroData">
    <mat-label>Data Final</mat-label>
    <input
      id="dataFinal"
      matInput
      [matDatepicker]="picker2"
      [(ngModel)]="dataFinal"
      (keyup)="appConfigService.onKeyUpEventData($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <button mat-button class="btnReturn" type="button" (click)="filtrar()" [disabled]="habilitaBotaoFiltrar()">
    <mat-icon>search</mat-icon>Filtrar
  </button>
</div>
