import { ContaBancaria } from './../../../entidades/contaBancaria';
import { ClienteService } from './../../../services/cliente/cliente.service';
import { UsuarioService } from './../../../services/usuario/usuario.service';
import { Validador } from './../../../validadores/validador';
import { CampanhaService } from './../../../services/campanha/campanha.service';
import { ApiService } from './../../../services/api/api.service';
import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cupom } from 'src/app/entidades/cupom';
import { CupomService } from 'src/app/services/cupom/cupom.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { Campanha } from 'src/app/entidades/campanha';
import { Produto } from 'src/app/entidades/produto';
import { Usuario } from 'src/app/entidades/usuario';
import { Agencia } from 'src/app/entidades/agencia';
import { AgenciaService } from 'src/app/services/agencia/agencia.service';
import { Cliente } from 'src/app/entidades/cliente';
import { DefinicaoColuna } from 'src/app/entidades/definicaoColuna';
import { PesquisaService } from 'src/app/services/pesquisa/pesquisa.service';
import { enumToArray } from 'src/app/helper/enumToArray';
import { FiltroCliente } from 'src/app/enum/filtroCliente';
import { CpfPipe } from 'src/app/pipes/cpf/cpf.pipe';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-novo-cupom',
  templateUrl: './novo-cupom.component.html',
  styleUrls: ['../../../app.component.css', './novo-cupom.component.css']
})
export class NovoCupomComponent implements OnInit, AfterViewInit, OnDestroy {
  private cupom: Cupom;
  private textoCooperadoNome = new Subject<string>();

  step = 0;
  campanhaSelecionada: Campanha;
  produtos: Produto[];
  usuarios: Usuario[];
  ehCooperado = false;
  titulo: string;
  agencias: Agencia[];
  isBusy = false;
  formulario: UntypedFormGroup;
  clientes: Cliente[];
  mascaraCNPJCPF: string;
  contasDoCooperado: ContaBancaria[];

  constructor(private formBuilder: UntypedFormBuilder,
    private cupomService: CupomService,
    private mensagemService: MensagemService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private apiService: ApiService,
    private usuarioService: UsuarioService,
    private campanhaService: CampanhaService,
    private agenciaService: AgenciaService,
    private pesquisaService: PesquisaService,
    private clienteService: ClienteService) { }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.mascaraCNPJCPF = '000.000.000-00||00.000.000/0000-00';
    this.criarFormulario();
    const id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.campanhaService.ObterCampanha(id).then(result => {
      this.campanhaSelecionada = result as Campanha;
      this.titulo = `Cadastro de Cupons - Campanha: ${this.campanhaSelecionada.nome}`;
    });
    this.carregarListas();
    this.pesquisaService.resultadoEmitter.subscribe((res: Cliente) => {
      this.adicionarCooperado(res);
    });
  }

  searchCooperado(valorDigitado: string) {
    this.textoCooperadoNome.next(valorDigitado);
  }

  async carregarListas(): Promise<void> {
    this.produtos = [{ id: 1, descricao: "QUOTAS DE CAPITAL", grupo: null, grupoId: 0, exigirAnexoMCI: false, geraComissao: false, inativo: false, ramoAtividadeId: 0, ramoAtividade: null  },
    //{ id: 2, descricao: "PREVIDÊNCIA", grupo: null, grupoId: 0, exigirAnexoMCI: false, geraComissao: false },
    { id: 3, descricao: "PONTOS SICOOB CARD", grupo: null, grupoId: 0, exigirAnexoMCI: false, geraComissao: false, inativo: false, ramoAtividadeId: 0, ramoAtividade: null }]
    this.agenciaService.todas().then(result => {
      this.agencias = this.apiService.verificaSeResult<Agencia[]>(result);
    });

    /* Clientes Obtém o primeiro Cliente para gerar as colunas da consulta */
    await this.clienteService.obter({
      length: 0,
      pageIndex: 0,
      pageSize: 1,
      previousPageIndex: 0
    }, true).then(res => {
      this.clientes = this.apiService.verificaSeResult<Cliente[]>(res.dados);
    });
  }

  ngAfterViewInit(): void {
  }

  criarFormulario(): void {
    this.formulario = this.formBuilder.group({
      nome: [null, Validators.required],
      cpf_cnpj: [null, Validador.isValidCpfCnpj()],
      email: [null, Validators.email],
      telefone: [null],
      agencia: [null, Validators.required],
      conta: [null, Validators.required],
      produtoId: [null, Validators.required],
      ehCooperado: [false],
      quantidade: [1],
      idColaborador: [this.apiService.UsuarioLogado.id, Validators.required]
    });
    this.usuarioService.ObterColaboradores().then(result => {
      this.usuarios = result;
      this.formulario.patchValue({
        idColaborador: this.apiService.UsuarioLogado.id
      });
      this.formulario.markAsPristine();
    });
  }

  onSubmit(): void {
    try {
      this.isBusy = true;
      this.cupom = this.formulario.value as Cupom;
      this.cupom.ehCooperado = false;
      this.cupom.idCampanha = this.campanhaSelecionada.id;
      this.cupom.dataEmissao = new Date();
      this.cupomService.AdicionarOuEditar(this.cupom, false).then(
        result => {
          if (result !== null && result !== undefined) {
            this.mensagemService.showMessage('Cupom adicionado com sucesso.', 'Ok', 5000, 'success');
            this.limparFormulario();
            this.formulario.markAsPristine();
            //Obtém pdf do cupom gerado
            this.campanhaService.ObterPDFCupom(result.id).then(
              res => {
                if (res !== null && res !== undefined) {
                  this.mensagemService.showMessage('Cupom obtido com sucesso.', 'Ok', 5000, 'success');
                  let fileName = 'Cupom.html';
                  var file = new Blob([res], { type: 'text/html' });
                  var fileUrl = URL.createObjectURL(file);
                  window.open(fileUrl, fileName);
                  this.router.navigate([`/Cupom/Termo/${result.id}`]);
                }
                else {
                  this.mensagemService.showMessage('Erro ao obter Cupom.', 'Ok', 5000, 'error');
                };
              });
          }
          else {
            this.mensagemService.showMessage('Erro ao adicionar Cupom.', 'Ok', 5000, 'error');
          }
          this.isBusy = false;
        });
    } catch (error) {
      this.isBusy = false;
      console.log(error);
      this.mensagemService.showMessage(`Erro ao adicionar o Cupom.`, 'Ok', 5000, 'error');
    }
  }

  popularForm(): void {
    this.formulario.patchValue({
      nome: this.cupom.nome,
      cpf_cnpj: this.cupom.cpF_CNPJ,
      email: this.cupom.email,
      telefone: this.cupom.telefone,
      agencia: this.cupom.agencia,
      conta: this.cupom.conta,
      ehCooperado: this.cupom.ehCooperado,
      quantidade: this.cupom.quantidade,
      idColaborador: this.cupom.idColaborador
    });
    this.formulario.markAsPristine();
  }

  limparFormulario(): void {
    this.formulario.patchValue({
      nome: null,
      cpf_cnpj: null,
      email: null,
      telefone: null,
      agencia: null,
      conta: null,
      produtoId: null,
      ehCooperado: false,
      quantidade: 1,
      idColaborador: this.apiService.UsuarioLogado.id
    });
  }

  cancelar(): void {
    if (this.campanhaService.campanhaVigente)
      this.router.navigate(['/campanhas/vigentes']);
    else
      this.router.navigate(['/campanhas']);
  }

  isCPF(): boolean {
    this.cupom = this.formulario.value;
    return this.cupom.cpF_CNPJ === null ? false : this.cupom.cpF_CNPJ.length > 11 ? false : true;
  }

  getCpfCnpjMask(): string {
    return this.isCPF() ? '000.000.000-00' : '00.000.000/0000-00';
  }

  async pesquisaCooperado(): Promise<void> {
    const definicaoColunas: DefinicaoColuna[] = [
      { definicao: 'id', exibicao: 'Id' },
      { definicao: 'associado', exibicao: 'Nome' },
      { definicao: 'cpF_CNPJ', exibicao: 'CPF / CNPJ' },
      { definicao: 'conta', exibicao: 'Conta' }
    ];
    const colunas = this.clientes?.map(c => {
      return {
        id: c.id,
        associado: c.associado,
        cpF_CNPJ: c.cpF_CNPJ,
        conta: c.conta
      }
    });
    this.pesquisaService.pesquisaEventEmitter.emit(true);
    this.pesquisaService.tituloEventEmitter.emit('Adicionar Cooperado');
    this.pesquisaService.mensagemEventEmitter.emit('Selecione o Cooperado desejado');
    this.pesquisaService.colunasEmitter.emit(colunas);
    this.pesquisaService.functionEmitter.emit(this.clienteService.obter);
    this.pesquisaService.entidadeServiceEmitter.emit(this.clienteService);
    this.pesquisaService.filtrosEmitter.emit(enumToArray(FiltroCliente));
    this.pesquisaService.filtroEmitter.emit(this.clienteService.filtroClienteEmitter);
    this.pesquisaService.descricaoFEmitter.emit(this.clienteService.descricaoFiltroEmitter);
    this.pesquisaService.columnsHeadersEmitter.emit(definicaoColunas);
    this.pesquisaService.moduloCotacaoEmitter.emit(true);
  }

  adicionarCooperado(cooperado: Cliente): void {
    let contas = cooperado.contas;
    let conta = contas !== null ? contas[0].numeroConta : cooperado.conta;
    let codPA = contas !== null ? contas[0].codPA : '';
    let inputCooperado = document.getElementById('inputCooperado');
    if (inputCooperado !== null && inputCooperado !== undefined) {
      (<any>inputCooperado).value = cooperado.associado;
    }
    this.formulario.patchValue({
      clienteId: cooperado.id
    });
    this.mascaraCNPJCPF = null;
    let documento = new CpfPipe().transform(cooperado.cpF_CNPJ);
    this.formulario.get('cpf_cnpj').setValue(documento);
    this.formulario.get('nome').setValue(cooperado.associado);
    this.formulario.get('telefone').setValue(cooperado.telefoneCelular);
    this.formulario.get('agencia').setValue(codPA);
    this.formulario.get('conta').setValue(conta);
    this.contasDoCooperado = contas?.filter(x => x.ativa);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
