import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { MenuService } from './../../services/menu/menu.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Usuario } from 'src/app/entidades/usuario';
import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { LoginService } from 'src/app/services/login/login.service';
import { Menu } from 'src/app/entidades/menu';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css', '../../app.component.css']
})
export class SideNavComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public exibirNavBar: boolean;
  public top: number;
  public usuario: Usuario;
  public menus: Menu[];

  constructor(private appConfigService: AppConfigService,
    private loginService: LoginService,
    private apiService: ApiService,
    private menuService: MenuService) { }

  async ngOnInit(): Promise<void> {
    this.appConfigService.menuClearEmitter.subscribe(clear => {
      if (clear) {
        this.menus = null;
      }
    });
    this.usuario = this.apiService.UsuarioLogado;
    this.appConfigService.sidNavClickEmitter.subscribe(exibir => {
      if (!exibir) {
        this.sidenav.close();
        return;
      }
      if (this.sidenav.opened) this.sidenav.close();
      else this.sidenav.open();
    });
    this.loginService.exibirNavBarEmitter.subscribe(async exibir => {
      this.exibirNavBar = exibir;
      this.top = exibir ? 76 : 0;
      if (exibir && !this.menus)
        await this.menuService.obterMenus().then(res => {
          this.menus = this.apiService.verificaSeResult<Menu[]>(res);
        });
    });
    this.loginService.usuarioLogadoEmitter.subscribe(usuario => {
      this.usuario = usuario;
    });
  }

  close() {
    this.appConfigService.pararTimerEmitter.emit(true);
    this.appConfigService.limparFiltroEmitter.emit(true);
    this.sidenav.close();
  }

  async logout() {
    await this.loginService.logOut();
  }
}
