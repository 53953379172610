import { AssessoriaComponent } from './../componentes/assessoria/assessoria.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from 'src/app/componentes/login/login.component';
import { SharedModule } from './shared.module';
import { ParcelamentoCupomDetalheComponent } from '../componentes/parcelamento-cupom/parcelamento-cupom-detalhe/parcelamento-cupom-detalhe.component';

@NgModule({
  declarations: [
    LoginComponent,
    AssessoriaComponent,
    ParcelamentoCupomDetalheComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class DataFormModule { }
