import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Campanha } from 'src/app/entidades/campanha';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { TermoAdesaoDto } from 'src/app/dto/termoAdesaoDto';
import { Usuario } from 'src/app/entidades/usuario';
import { TipoPagamentoCupom } from 'src/app/enum/tipoPagamentoCupom';
import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { CampanhaService } from 'src/app/services/campanha/campanha.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';
import { ParcelamentoCupomService } from 'src/app/services/parcelamento-cupom/parcelamento-cupom.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { FiltroParcelamentoCupom } from 'src/app/enum/filtroParcelamentoCupom';
import { enumToArray } from 'src/app/helper/enumToArray';
import { FiltroService } from 'src/app/services/filtro/filtro.service';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { ParcelamentoCupom } from 'src/app/entidades/parcelamentoCupom';
import { ParcelamentoCupomDetalheComponent } from './parcelamento-cupom-detalhe/parcelamento-cupom-detalhe.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-parcelamento-cupom',
  templateUrl: './parcelamento-cupom.component.html',
  styleUrls: ['./parcelamento-cupom.component.css', '../../app.component.css']
})
export class ParcelamentoCupomComponent implements OnInit {
  displayedColumns: string[] = ['icones','id', 'dataEmissao', 'cooperado', 'cpf_cnpj', 'conta', 'pa', 'quantidade', 'colaborador', 'valor', 'parcelas', 'valorParcela', 'quitarParcela'];
  pagina: Pagina<TermoAdesaoDto>;
  termos: TermoAdesaoDto[];
  myPaginator: Paginator;
  dataSource: MatTableDataSource<TermoAdesaoDto>;
  qtdeRegistros: number;
  campanhaSelecionada: Campanha;
  termoSelecionado: TermoAdesaoDto;
  usuarios: Usuario[];
  titulo: string;
  filtroAtual: number;
  textoFiltroAtual: string;
  filtros = enumToArray(FiltroParcelamentoCupom);
  dataInicial: Date;
  dataFinal: Date;
  filtrandoPorData: boolean;
  dataUltimaAtualizacao: Date;
  private filtroPaginator = {
    length: 0,
    pageIndex: 0,
    pageSize: this.appConfigService.itensPorPagina,
    previousPageIndex: 0
  };

  constructor(private apiService: ApiService,
    private appConfigService: AppConfigService,
    private campanhaService: CampanhaService,
    public parcelamentoCupomService: ParcelamentoCupomService,
    private paginatorService: PaginatorService,
    private usuarioService: UsuarioService,
    private filtroService: FiltroService,
    private mensagemService: MensagemService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private el: ElementRef,
    private renderer: Renderer2) {}


  async ngOnInit(): Promise<void> {
    this.paginatorService.paginatorEmitter = this.paginatorService.getPaginatorEmitter();
    this.myPaginator = {
      length: 0,
      pageIndex: 0,
      pageSize: this.appConfigService.itensPorPagina,
      previousPageIndex: 0
    };
    const idCampanha = this.activatedRoute.snapshot.url.map(u => u.path)[2];
    await this.campanhaService.ObterCampanha(Number(idCampanha)).then(res => {
      this.campanhaSelecionada = res as Campanha;
     });
    this.titulo = `Parcelamento dos Cupons da Campanha ${this.campanhaSelecionada.nome}`;
    await this.obterDados();
    this.paginatorService.paginatorEmitter.subscribe(result => {
      this.myPaginator = JSON.parse(result);
      this.filtroPaginator = JSON.parse(result);
      if (this.filtroAtual === undefined) {
        this.parcelamentoCupomService.obter(this.campanhaSelecionada.id, this.myPaginator).then(res => {
          this.configurarPagina(res);
        });
      } else {
        if (!this.filtrandoPorData) {
          const dto = { texto: this.textoFiltroAtual } as FiltroDTO;
          this.parcelamentoCupomService.filtrar(this.campanhaSelecionada.id, this.filtroAtual, dto, this.filtroPaginator)
            .then(res => { this.configurarPagina(res); });
        } else {
          const dto = { dataInicial: this.dataInicial, dataFinal: this.dataFinal } as FiltroDTO;
          this.parcelamentoCupomService.filtrar(this.campanhaSelecionada.id, this.filtroAtual, dto, this.filtroPaginator)
            .then(result => { this.configurarPagina(result); });
        }
      }
    });
    this.parcelamentoCupomService.filtroParcelamentoCupomEmitter.subscribe(res => {
      this.filtroAtual = res;
      this.myPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: this.appConfigService.itensPorPagina,
        previousPageIndex: 0
      };
      this.filtroPaginator = this.myPaginator;
      switch (this.filtroAtual) {
        case 0:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
          this.filtroService.exibirFiltroDataEmitter.emit(false);
          this.filtrandoPorData = false;
          break;
        case 1:
        case 2:
        case 3:
        case 4:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
          this.filtroService.exibirFiltroDataEmitter.emit(false);
          this.filtrandoPorData = false;
          break;
        case 5:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
          this.filtroService.exibirFiltroDataEmitter.emit(true);
          this.filtrandoPorData = true;
      }
    });

    this.filtroService.filtrarPorDescricaoEmitter = this.filtroService.getFiltrarPorDescricaoEmitter();
    this.filtroService.filtrarPorDescricaoEmitter.subscribe(res => {
      this.textoFiltroAtual = res[0];
      this.filtrarPorDescricao(this.filtroPaginator);
    });
    this.filtroService.filtrarPorDataEmitter = this.filtroService.getFiltrarPorDataEmitter();
    this.filtroService.filtrarPorDataEmitter.subscribe(res => {
      this.dataInicial = res[0].dataInicial;
      this.dataFinal = res[0].dataFinal;
      this.filtrarPorData(this.filtroPaginator);
    });
  }

  filtrarPorDescricao(filtroPaginator: Paginator) {
    if (this.filtroAtual > 0) {
      const dto = { texto: this.textoFiltroAtual } as FiltroDTO;
      this.parcelamentoCupomService.filtrar(this.campanhaSelecionada.id, this.filtroAtual, dto, filtroPaginator)
        .then(result => { this.configurarPagina(result); });
    } else {
      this.obterParcelamentos();
    }
  }
  filtrarPorData(filtroPaginator: Paginator): void {
    if (this.filtroAtual > 0) {
      const dto = { dataInicial: this.dataInicial, dataFinal: this.dataFinal } as FiltroDTO;
      this.parcelamentoCupomService.filtrar(this.campanhaSelecionada.id, this.filtroAtual, dto, filtroPaginator)
        .then(result => { this.configurarPagina(result); });
    }
  }

  async obterParcelamentos(): Promise<void> {
    await this.parcelamentoCupomService.obter(this.campanhaSelecionada.id, this.myPaginator).then(res => {
      this.pagina = this.apiService.verificaSeResult<Pagina<TermoAdesaoDto>>(res);
      this.configurarPagina(this.pagina);
    });
  }

  async obterDados(): Promise<void> {
    /* DATA ÚLTIMA ATUALIZAÇÃO */
    await this.parcelamentoCupomService.obterDataUltimaAtualizacao(this.campanhaSelecionada.id).then(res => {
      this.dataUltimaAtualizacao = res as Date;
    });
    /* PARCELAMENTOS */
    this.obterParcelamentos();
    /* USUÁRIOS */
    await this.usuarioService.Todos().then(res => {
      this.usuarios = this.apiService.verificaSeResult<Usuario[]>(res);
     });
  }

  configurarPagina(pagina: Pagina<TermoAdesaoDto>): void {
    if (pagina !== null && pagina !== undefined) {
      this.termos = pagina.dados as TermoAdesaoDto[];
      this.dataSource = new MatTableDataSource(this.termos);
      this.pagina = pagina;
      this.qtdeRegistros = this.pagina.quantidadeRegistros;
    }
    else {
      this.termos = null;
    }
  }

  getColaborador(id: string): string {
    let usuario = this.usuarios?.filter(u => u.id === Number(id))[0];
    return usuario?.nome;
  }

  getPagamento(tipoPagamento: TipoPagamentoCupom): string {
    if (tipoPagamento === TipoPagamentoCupom.Parcelado) {
      return 'À vista';
    } else {
      return 'Parcelado';
    }
  }

  voltar(): void {
    this.router.navigate([`/campanhas/vigentes`]);
  }

  rowClick(row: TermoAdesaoDto): void {
    this.termoSelecionado = row;
  }

  async parcelamento(agrupamento: string): Promise<void> {
    let tela: MatDialogRef<any>;
    let parcelamentoCupons: ParcelamentoCupom[];
    await this.parcelamentoCupomService.obterParcelamento(this.campanhaSelecionada.id, agrupamento).then(res => {
      parcelamentoCupons = this.apiService.verificaSeResult<ParcelamentoCupom[]>(res);
    });
    let info = {
      row: parcelamentoCupons,
      titulo: `Parcelamento dos Cupons`,
    };
    tela = this.dialog.open(ParcelamentoCupomDetalheComponent, {
      data: info,
      disableClose: true,
      autoFocus: true,
    });
    tela.afterClosed().subscribe(res => {
      this.obterDados();
    });
  }

  styleUltimaAtualizacao(row: any) {
    // if (row.qtdeCupons > 1)
    //   return "1px groove green";
    return '';
  }

  obterIconePendentes(row: any): string {
    if (row.pendente)
      return 'warning';

    return null;
  }

  obterIconeQuitados(row: any): string {
    if (!row.pendente)
      return 'thumb_up';

    return null;
  }


  obterIconeUltimaAtualizacao(row: any): string {
    if (row.dataUltimaAtualizacao === this.dataUltimaAtualizacao)
      return 'edit';

    return null;
  }
}
