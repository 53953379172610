import { EndossoService } from './../../services/endosso/endosso.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TabCotacao } from 'src/app/enum/tabCotacao';
import { CotacaoService } from 'src/app/services/cotacao/cotacao.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { DialogoComponent } from '../dialogo/dialogo/dialogo.component';
import { TipoCancelamento } from 'src/app/enum/tipoCancelamento';
import { CancelamentoDto } from 'src/app/dto/cancelamentoDto';
import { ApiService } from 'src/app/services/api/api.service';
import { CupomService } from 'src/app/services/cupom/cupom.service';
import { ReverterCancelamentoDto } from 'src/app/dto/reverterCancelamentoDto';
import { TipoChamado } from 'src/app/enum/tipoChamado';

@Component({
  selector: 'app-cancelamento',
  templateUrl: './cancelamento.component.html',
  styleUrls: ['./cancelamento.component.css', '../../app.component.css']
})
export class CancelamentoComponent implements OnInit {
  entidade: any;
  formulario: UntypedFormGroup;
  titulo: string;
  cotacaoOuEndosso: string;
  dataCriacao: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private cotacaoService: CotacaoService,
    private endossoService: EndossoService,
    private cupomService: CupomService,
    private pergunta: MatDialog,
    private mensagemService: MensagemService) { }

  ngOnInit(): void {
    this.entidade = this.data.row.row !== undefined ? this.data.row.row : this.data.row;
    this.titulo = this.data.titulo
    this.cotacaoOuEndosso = this.data.tipo === "CUPOM" ? null : this.data.tipo === TabCotacao.SolicitacaoCotacao ? 'Cotação' : 'Endosso';
    if (this.data.tipo !== undefined) {
      this.dataCriacao = this.data.tipo === TabCotacao.SolicitacaoCotacao ? this.entidade.dataCriacao : this.entidade.dataEndosso;
    }
    this.criarFormulario();
  }

  criarFormulario(): void {
    if (this.data.tipoCancelamento === TipoCancelamento.Outros) {
      this.formulario = this.formBuilder.group({
        statusId: [0],
        entidadeId: [0],
        motivoCancelamento: [null, Validators.required],
        cotacaoId: [0],
        endossoId: [0],
        cancelaTodosCupons: [false]
      });
    } else {
      this.formulario = this.formBuilder.group({
        statusId: [this.data.statusId],
        entidadeId: [this.entidade.id],
        motivoCancelamento: [null, Validators.required],
        cotacaoId: [0],
        endossoId: [0],
        cancelaTodosCupons: [false]
      });
    }
  }

  getPergunta(tipo: TipoCancelamento): string {
    switch  (this.data.tipoCancelamento) {
      case TipoCancelamento.Cancelamento:
        return `Atenção! Confirma o cancelamento?`
      case TipoCancelamento.Recusa:
        return 'Confirma chamado sem aceitação?'
      case TipoCancelamento.Outros:
        return 'Confirma a exclusão?'
      default:
        return 'Confirma reversão do Cancelamento?'
    }
  }

  onSubmit(): void {
    const dialog = this.pergunta.open(DialogoComponent, {
      data: {
        titulo: this.data.tipo === TipoCancelamento.Outros ? 'Exclusão' : `Cancelamento`,
        pergunta: this.getPergunta(this.data.tipoCancelamento)
      },
      width: '550px'
    });
    dialog.afterClosed().subscribe(async (result) => {
      if (result) {
        if (this.data.tipoCancelamento === 3) {
          this.pergunta.closeAll();
          /* Reverte o cancelamento */
          let reverterDto: ReverterCancelamentoDto = {
            entidadeId: Number(this.formulario.get('entidadeId').value),
            motivoReversao: this.formulario.get('motivoCancelamento').value,
            tipo: this.data.tipo === TabCotacao.SolicitacaoCotacao ? TipoChamado.Cotacao : TipoChamado.Endosso
          };
          await this.cotacaoService.reverterCancelamento(reverterDto).then(res => {
            if (res) {
              this.mensagemService.showMessage(`Cancelamento revertido com sucesso! O Status voltou para ABERTO`, 'Ok', 5000, 'success');
              this.cotacaoService.updateCotacoesEmitter.emit(true);
            } else {
              this.mensagemService.showMessage(`Erro ao reverter cancelamento!`, 'Ok', 5000, 'error');
            }
          });
          return;
        }
        /* Outros Cancelamentos ou Exclusões com informação de motivo */
        if (this.data.tipoCancelamento === 4) {
          this.data.row.motivoExclusao = this.formulario.get('motivoCancelamento').value;
          this.pergunta.closeAll();
          return;
        }
        let cancelamento = this.formulario.value as CancelamentoDto;
        if (cancelamento.statusId === null || cancelamento.statusId === undefined) cancelamento.statusId = 0;
        cancelamento.idUsuarioCancelamento = this.apiService.UsuarioLogado.id;
        switch (this.data.tipo) {
          case TabCotacao.SolicitacaoCotacao:
            cancelamento.cotacaoId = this.entidade.id;
            if (this.data?.tipoCancelamento === TipoCancelamento.Cancelamento) {
              await this.cotacaoService.cancelar(cancelamento).then(result => {
                if (result) {
                  this.mensagemService.showMessage(`Cancelamento informado com sucesso!`, 'Ok', 5000, 'success');
                }
                else {
                  this.mensagemService.showMessage(`Erro ao informar Cancelamento.`, 'Ok', 5000, 'error');
                }
              });
            } else {
              await this.cotacaoService.recusar(cancelamento).then(result => {
                if (result) {
                  this.mensagemService.showMessage(`Chamado atualizado com sucesso!`, 'Ok', 5000, 'success');
                }
                else {
                  this.mensagemService.showMessage(`Erro ao atualizar chamado.`, 'Ok', 5000, 'error');
                }
              });
            }
            break;
          case TabCotacao.SolicitacaoEndosso:
            cancelamento.endossoId = this.entidade.id;
            if (this.data?.tipoCancelamento === TipoCancelamento.Cancelamento) {
              await this.endossoService.cancelar(cancelamento).then(result => {
                if (result) {
                  this.mensagemService.showMessage(`Cancelamento informado com sucesso!`, 'Ok', 5000, 'success');
                }
                else {
                  this.mensagemService.showMessage(`Erro ao informar Cancelamento.`, 'Ok', 5000, 'error');
                }
              });
            } else {
              await this.endossoService.recusar(cancelamento).then(result => {
                if (result) {
                  this.mensagemService.showMessage(`Endosso recusado com sucesso!`, 'Ok', 5000, 'success');
                }
                else {
                  this.mensagemService.showMessage(`Erro ao Recusar Endosso.`, 'Ok', 5000, 'error');
                }
              });
            }
            break;
          default:
            if (this.data?.tipoCancelamento === TipoCancelamento.Cancelamento) {
              cancelamento.agrupamentoCupom = this.data.row.agrupamento;
              await this.cupomService.cancelar(cancelamento).then(result => {
                if (result) {
                  this.mensagemService.showMessage(`Cupom cancelado com sucesso!`, 'Ok', 5000, 'success');
                }
                else {
                  this.mensagemService.showMessage(`Erro ao informar Cancelamento.`, 'Ok', 5000, 'error');
                }
              });
            }
        }
      }
      this.pergunta.closeAll();
    });
  }

  getCliente(): string {
    if (this.data.tipo === "CUPOM") {
      return this.data.row.nome;
    }
    return this.data.tipo === TabCotacao.SolicitacaoCotacao ? this.entidade.cliente.associado : this.entidade.nomeAssociado;
  }

  cancelClick(): string {
    this.pergunta.closeAll();
    return 'cancel';
  }

  validaTipo(): boolean {
    return this.data.tipoCancelamento != TipoCancelamento.Outros;
  }
}
