import { TextDialogComponent } from './componentes/text-dialog/text-dialog.component';
import { AgenciaModule } from './componentes/agencia/agencia.module';
import { CupomModule } from './componentes/cupom/cupom.module';
import { CampanhaModule } from './componentes/campanha/campanha.module';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './componentes/home/home.module';
import { LoginModule } from './componentes/login/login.module';
import { NavBarComponent } from './componentes/nav-bar/nav-bar.component';
import { UsuarioModule } from './componentes/usuario/usuario.module';
import { CoreModule } from './core/core/core.module';
import { SharedModule } from './shared/shared.module';
import { PhoneMaskDirective } from './diretivas/phone-mask.directive';
import { DialogoComponent } from './componentes/dialogo/dialogo/dialogo.component';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { FooterComponent } from './componentes/footer/footer.component';
import { AgendaItemComponent } from './componentes/agenda/agenda-item/agenda-item.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideNavComponent } from './componentes/side-nav/side-nav.component';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { DndDirective } from './diretivas/dnd.directive';
import { CancelamentoComponent } from './componentes/cancelamento/cancelamento.component';
import { PortuguesDateAdapter } from './PortuguesDateAdapter';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { ParametrosComponent } from './componentes/parametros/parametros.component';
import { IapComponent } from './componentes/iap/iap.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LogComponent } from './componentes/log/log.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { LogDetailComponent } from './componentes/log/log-detail/log-detail.component';
import { TempoMedioDetalhadoComponent } from './componentes/tempo-medio-detalhado/tempo-medio-detalhado.component';
import { InterceptorModule } from './interceptor/interceptor.module';


export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    PhoneMaskDirective,
    DialogoComponent,
    FooterComponent,
    AgendaItemComponent,
    SideNavComponent,
    DndDirective,
    CancelamentoComponent,
    TextDialogComponent,
    ParametrosComponent,
    IapComponent,
    LogComponent,
    LogDetailComponent,
    TempoMedioDetalhadoComponent
  ],
  imports: [
    InterceptorModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    LoginModule,
    HomeModule,
    UsuarioModule,
    CampanhaModule,
    CupomModule,
    AgenciaModule,
    MatNativeDateModule,
    MatListModule,
    MatSidenavModule,
    MatExpansionModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production, //!isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
    })
],
  exports: [
    MatSidenavModule,
    MatListModule,
    MatExpansionModule
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter, useClass: PortuguesDateAdapter,
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
