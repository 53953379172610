import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ParcelamentoCupom } from 'src/app/entidades/parcelamentoCupom';
import { QuitarParcelaDto } from 'src/app/dto/quitarParcelaDto';
import { TermoAdesaoDto } from 'src/app/dto/termoAdesaoDto';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Periodo } from 'src/app/entidades/periodo';

@Injectable({
  providedIn: 'root'
})
export class ParcelamentoCupomService {
  filtroParcelamentoCupomEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();
  dataFiltroEmitter = new EventEmitter<Periodo>();

  constructor(private apiService: ApiService) { }

  async obter(idCampanha: number, paginator: Paginator): Promise<Pagina<TermoAdesaoDto>> {
    try {
      let paginaAtual = null;
      const complemento = `/ParcelamentoCupom/ObterParcelamentos/${idCampanha}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<TermoAdesaoDto>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<TermoAdesaoDto>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterDataUltimaAtualizacao(idCampanha: number): Promise<Date> {
    try {
      let resultado = null;
      const complemento = `/ParcelamentoCupom/UltimaAtualizacao/${idCampanha}`;
      await this.apiService.request<Date[]>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            resultado = (result as Date[]);
          }
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterParcelamento(idCampanha: number, agrupamento: string): Promise<ParcelamentoCupom[]> {
    try {
      let resultado = null;
      const complemento = `/ParcelamentoCupom/Obter/${idCampanha}/${agrupamento}`;
      await this.apiService.request<ParcelamentoCupom[]>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            resultado = (result as ParcelamentoCupom[]);
          }
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async gerarParcelamento(idCampanha: number, agrupamento: string): Promise<boolean> {
    try {
      let resultado = null;
      const complemento = `/ParcelamentoCupom/Gerar/${idCampanha}/${agrupamento}`;
      await this.apiService.request<boolean>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            resultado = (result as boolean);
          }
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async quitarParcela(dto: QuitarParcelaDto): Promise<boolean> {
    try {
      let resultado = null;
      const complemento = `/ParcelamentoCupom/QuitarParcela`;
      await this.apiService.request<boolean>(complemento, httpVerb.post, JSON.stringify(dto))
        .then(result => {
          if (result !== null && result !== undefined) {
            resultado = (result as boolean);
          }
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async reverterParcela(dto: QuitarParcelaDto): Promise<boolean> {
    try {
      let resultado = null;
      const complemento = `/ParcelamentoCupom/ReverterParcela`;
      await this.apiService.request<boolean>(complemento, httpVerb.post, JSON.stringify(dto))
        .then(result => {
          if (result !== null && result !== undefined) {
            resultado = (result as boolean);
          }
        });
      return resultado;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrar(idCampanha: number, filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<TermoAdesaoDto>> {
    try {
      let paginaAtual = null;
      const complemento = `/ParcelamentoCupom/filtrar/${idCampanha}/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<TermoAdesaoDto>>(complemento, httpVerb.post, dto)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<TermoAdesaoDto>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
