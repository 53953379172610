import { FiltroDTO } from './../../entidades/filtroDTO';
import { EventEmitter, Injectable } from '@angular/core';
import { Cupom } from 'src/app/entidades/cupom';
import { DadosAdesao } from 'src/app/entidades/dadosAdesao';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';
import { MensagemService } from '../mensagem/mensagem.service';
import { Periodo } from 'src/app/entidades/periodo';
import { CupomAgrupadoDto } from 'src/app/dto/cupomAgrupadoDto';
import { CancelamentoDto } from 'src/app/dto/cancelamentoDto';

@Injectable({
  providedIn: 'root'
})
export class CupomService {
  filtroCupomEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();
  dataFiltroEmitter = new EventEmitter<Periodo>();

  getFiltroCupomEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getdescricaoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }
  getDataFiltroEmitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  constructor(private apiService: ApiService,
    private mensagemService: MensagemService) { }

  async AdicionarOuEditar(novoCupom: Cupom, editando: boolean): Promise<Cupom> {
    try {
      let cupom = null;
      await this.apiService.request<Cupom>('/Cupom', httpVerb.post, novoCupom)
        .then(result => {
          if (result !== null && result !== undefined) {
            cupom = (result as Cupom);
          }
        });
      if (cupom !== null && cupom !== undefined) {
        const mensagem = editando ? 'alterado' : 'cadastrado';
        this.mensagemService.showMessage(`Cupom ${mensagem} com sucesso!`, 'Ok', 5000, 'success');
      }
      return cupom;
    } catch (error) {
      console.log(error);
      const mensagem = editando ? 'alterado' : 'cadastrado';
      this.mensagemService.showMessage(`Erro ao ${mensagem} novo Cupom.`, 'Ok', 5000, 'error');
    }
  }

  async Obter(idCampanha: number, paginator: Paginator): Promise<Pagina<Cupom>> {
    try {
      let paginaAtual = null;
      const complemento = `/cupom/campanha/${idCampanha}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Cupom>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Cupom>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async ObterCupom(id: number): Promise<Cupom> {
    try {
      let cupomRetorno = null;
      await this.apiService.request<Cupom>(`/cupom/${id}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            cupomRetorno = (result as Cupom);
          }
        });
      return cupomRetorno;
    } catch (error) {
      console.log(error);
    }
  }

  async obterCupomAgrupado(id: number): Promise<CupomAgrupadoDto> {
    try {
      let cupomRetorno = null;
      await this.apiService.request<CupomAgrupadoDto>(`/cupom/EstaAgrupado/${id}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            cupomRetorno = (result as CupomAgrupadoDto);
          }
        });
      return cupomRetorno;
    } catch (error) {
      console.log(error);
    }
  }

  async imprimirTermoAdesao(dadosAdesao: DadosAdesao): Promise<any> {
    try {
      let pdfRetorno = null;
      await this.apiService.request<any>(`/Campanha/TermoAdesao`, httpVerb.post, dadosAdesao, true)
        .then(result => {
          if (result !== null && result !== undefined) {
            pdfRetorno = result;
          }
        });
      return pdfRetorno;
    } catch (error) {
      console.log(error);
    }
  }

  async adicionarTermoAdesao(dadosAdesao: DadosAdesao): Promise<DadosAdesao> {
    try {
      let retorno = null;
      await this.apiService.request<DadosAdesao>(`/Campanha/TermoAdesao`, httpVerb.put, dadosAdesao, false)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async EnviarPorEmail(idCupom: number): Promise<boolean> {
    try {
      let enviado = false;
      await this.apiService.request<boolean>(`/cupom/${idCupom}`, httpVerb.put)
        .then(result => {
          enviado = result;
        });

      return enviado;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async Excluir(id: number): Promise<boolean> {
    try {
      let excluiu = false;
      await this.apiService.request<boolean>(`/cupom/${id}`, httpVerb.delete)
        .then(result => {
          excluiu = result;
        });
      return excluiu;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async filtrar(filtro: number, idCampanha: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<Cupom>> {
    try {
      let paginaAtual = null;
      const complemento = `/cupom/filtrar/${filtro}/${idCampanha}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Cupom>>(complemento, httpVerb.post, JSON.stringify(dto))
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Cupom>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async quitar(cupom: Cupom): Promise<Blob> {
    try {
      let retorno = null;
      await this.apiService.request<Blob>(`/Cupom/Quitar`, httpVerb.post, cupom, true)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async quitarCupons(cupons: number[]): Promise<Blob> {
    try {
      let retorno = null;
      await this.apiService.request<boolean>(`/Cupom/Quitar/Cupons`, httpVerb.post, cupons, true)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
    }
  }

  async cancelar(cancelamento: CancelamentoDto): Promise<boolean> {
    try {
      let cancelou = false;
      await this.apiService.request<boolean>(`/Cupom/Cancelar/${cancelamento.entidadeId}`, httpVerb.put, cancelamento).then(res => {
        cancelou = this.apiService.verificaSeResult<boolean>(res);
      });
      return cancelou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
