import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { InputBase } from 'src/app/entidades/inputBase';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogoComponent } from '../dialogo/dialogo/dialogo.component';

@Component({
  selector: 'app-informacoes-adicionais',
  templateUrl: './informacoes-adicionais.component.html',
  styleUrls: ['./informacoes-adicionais.component.css']
})
export class InformacoesAdicionaisComponent implements OnInit {
  formulario: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogoComponent>,
    @Inject(MAT_DIALOG_DATA) public inputs: InputBase[]) { }

  ngOnInit(): void {
    this.criarFormulario();
  }

  criarFormulario(): void {
    let formGroup = new UntypedFormGroup({
      campos: new UntypedFormArray([])
    })
    this.inputs.forEach(element => {
      let validator = element.required ? [Validators.required, element.email ? Validators.email : ''] : [] as Validators;
      formGroup.addControl(element.name, new UntypedFormControl(element.name, validator));
      formGroup.get(element.name).setValue('');
    });
    this.formulario = formGroup;
  }

  getId(name: string): string {
    return `input${name}`;
  }

  getClass(input: InputBase): string {
    return `{'width': ${input.width}px !important;}`;
  }

  onSubmit(): void {
    this.dialogRef.close(this.formulario);
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  getMaxLength(maxLenght: number): number {
    return maxLenght === 0 ? 100 : maxLenght;
  }
}
