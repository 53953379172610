import { DadosFiltroExtra } from './../../entidades/dadosFiltroExtra';
import { CpfPipe } from './../../pipes/cpf/cpf.pipe';
import { DefinicaoColuna } from './../../entidades/definicaoColuna';
import { Component, OnInit, AfterViewChecked, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { FiltroService } from 'src/app/services/filtro/filtro.service';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';
import { PesquisaService } from 'src/app/services/pesquisa/pesquisa.service';
import { BuscaDTO } from 'src/app/dto/buscaDTO';
import { IFunction } from 'src/app/interface/ifunction';
import { ApiService } from 'src/app/services/api/api.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';

@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.css', '../../app.component.css']
})
export class PesquisaComponent implements OnInit, AfterViewChecked, OnDestroy {
  titulo = '';
  mensagem: string;
  pesquisando = false;
  moduloCotacao = false;
  entidadeService: any;
  filtros: any;
  lista: any;
  dataSource: any;
  filtroEmitter: EventEmitter<any>;
  descricaoFiltroEmitter: EventEmitter<any>;
  periodoEmitter: EventEmitter<any>;
  filtroAtual: number;
  textoFiltroAtual: string;
  myPaginator: Paginator;
  qtdeRegistros: number;
  pagina: Pagina<any>;
  iniciou = false;
  linhaSelecionada: any;
  filtroExtraSelecionado: any;
  displayedColumns: any;
  columns: Array<any>;
  colunas: any;
  definicaoColunas: DefinicaoColuna[];
  temFiltroExtra: boolean;
  dadosFiltroExtra: DadosFiltroExtra;
  getListFunction: IFunction = undefined;
  functionFiltroExtra: IFunction = undefined;
  filtroExtraHabilitado: boolean;

  constructor(private pesquisaService: PesquisaService,
    private appConfigService: AppConfigService,
    private apiService: ApiService,
    private mensagemService: MensagemService,
    private paginatorService: PaginatorService,
    public filtroService: FiltroService) { }

  ngOnDestroy(): void {}

  ngAfterViewChecked(): void {
    this.myPaginator = {
      length: 0,
      pageIndex: 0,
      pageSize: 15,
      previousPageIndex: 0
    };
    if (this.pesquisando && !this.iniciou) {
      this.inciarPesquisa();
      this.iniciou = true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.paginatorService.paginatorPesquisaEmitter = this.paginatorService.getPaginatorEmitter();
    await this.inciarPesquisa();
  }

  async inciarPesquisa(): Promise<void> {
    try {
      if (this.temSubscricao()) return;
      this.pesquisaService.pesquisaEventEmitter = this.pesquisaService.getPesquisaEventEmitter();
      this.pesquisaService.tituloEventEmitter = this.pesquisaService.getTituloEventEmitter();
      this.pesquisaService.mensagemEventEmitter = this.pesquisaService.getMensagemEventEmitter();
      this.pesquisaService.filtrosEmitter = this.pesquisaService.getFiltroEmitter();
      this.pesquisaService.functionEmitter = this.pesquisaService.getFunctionEmitter();
      this.pesquisaService.columnsHeadersEmitter = this.pesquisaService.getColumnsHeadersEmitter();
      this.pesquisaService.colunasEmitter = this.pesquisaService.getColunasEmitter();
      this.pesquisaService.descricaoFEmitter = this.pesquisaService.getDescricaoFEmitter();
      this.pesquisaService.periodoEmitter = this.pesquisaService.getPeriodoEmitter();
      this.pesquisaService.entidadeServiceEmitter = this.pesquisaService.getEntidadeServiceEmitter();
      this.pesquisaService.moduloCotacaoEmitter = this.pesquisaService.getModuloCotacaoEmitter();
      this.pesquisaService.dadosFiltroExtraEmitter = this.pesquisaService.getFiltroEmitter();
      this.pesquisaService.functionFiltroExtraEmitter = this.pesquisaService.getFunctionFiltroExtraEmitter();
      this.pesquisaService.buscaEmitter = this.pesquisaService.getBuscaEmitter();
      this.pesquisaService.pesquisaEventEmitter.subscribe(pesquisando => {
        sessionStorage.setItem('searching', 'true');
        this.pesquisando = pesquisando;
      });

      this.pesquisaService.tituloEventEmitter.subscribe(texto => {
        this.titulo = `[*] ${texto}`;
      });
      this.pesquisaService.mensagemEventEmitter.subscribe(texto => {
        this.mensagem = texto;
      });
      this.pesquisaService.filtrosEmitter.subscribe(filtros => {
        this.filtros = filtros;
      });
      this.pesquisaService.filtroEmitter.subscribe((filtro: EventEmitter<any>) => {
        this.filtroEmitter = filtro;
      });
      this.pesquisaService.functionEmitter.subscribe(func => {
        this.getListFunction = func;
      });
      this.pesquisaService.columnsHeadersEmitter.subscribe(cols => {
        this.definicaoColunas = cols;
      });
      this.pesquisaService.colunasEmitter.subscribe(colunas => {
        this.colunas = colunas;
      });
      this.pesquisaService.descricaoFEmitter.subscribe((descEmitter: EventEmitter<any>) => {
        this.descricaoFiltroEmitter = descEmitter;
      });
      this.pesquisaService.periodoEmitter.subscribe((periodoEmitter: EventEmitter<any>)=> {
        this.periodoEmitter = periodoEmitter;
      });
      this.pesquisaService.entidadeServiceEmitter.subscribe(es => {
        this.entidadeService = es;
      });
      this.pesquisaService.moduloCotacaoEmitter.subscribe(mc => {
        this.moduloCotacao = mc
      });
      this.pesquisaService.dadosFiltroExtraEmitter.subscribe((d: DadosFiltroExtra) => {
        this.dadosFiltroExtra = d;
        if (this.dadosFiltroExtra) {
          this.temFiltroExtra = true;
          this.filtroExtraSelecionado = this.dadosFiltroExtra.filtroPadrao
          this.filtroExtraChanged();
        }
        else this.temFiltroExtra = false;
      });
      this.pesquisaService.functionFiltroExtraEmitter.subscribe(func => {
        this.functionFiltroExtra = func;
      });
      this.filtroService.pesquisaFiltroEmitter = this.filtroService.getPesquisaFiltroEmitter();
      this.filtroService.pesquisaFiltroEmitter.subscribe(res => {
        let inputDescricao = document.getElementById('descricaoFiltro');
        this.filtroAtual = Number(sessionStorage.getItem('_fsel'));
        this.textoFiltroAtual =  (<any>inputDescricao).value;
        const filtroPaginator = {
          length: 0,
          pageIndex: 0,
          pageSize: 15,
          previousPageIndex: 0
        };
        this.filtrarPorDescricao(filtroPaginator);
      });
      this.pesquisaService.buscaEmitter.subscribe(res => {
        const busca = res as BuscaDTO;
        this.filtroAtual = busca.filtro;
        this.textoFiltroAtual = busca.texto;
        this.filtroEmitter.emit(busca.filtro);
        this.descricaoFiltroEmitter.emit(busca.texto);
        setTimeout(() => {
          let inputDescricao = document.getElementById('descricaoFiltro');
          if (inputDescricao !== null && inputDescricao !== undefined) {
            (<any>inputDescricao).value = busca.texto;
          }
        }, 250);
      });
      /* Emitter´s do componente de Filtros */
      this.filtroEmitter?.subscribe(res => {
        this.filtroAtual = res;
        this.myPaginator = {
          length: 0,
          pageIndex: 0,
          pageSize: 15,
          previousPageIndex: 0
        };
        switch (this.filtroAtual) {
          case 0:
            this.textoFiltroAtual = "";
            this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
            break;
          case 1:
            this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
            break;
          case 2:
            this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
            break;
          default:
            this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
        }
      });
      this.descricaoFiltroEmitter?.subscribe(res => {
        let inputDescricao = document.getElementById('descricaoFiltro');
        this.textoFiltroAtual = res !== null || res !== undefined ? res : (<any>inputDescricao).value;
        const filtroPaginator = {
          length: 0,
          pageIndex: 0,
          pageSize: 15,
          previousPageIndex: 0
        };
        this.filtrarPorDescricao(filtroPaginator);
      });
      this.myPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: 15,
        previousPageIndex: 0
      };
    } finally {
      await this.obterLista();
    }
  }

  async filtrarPorDescricao(filtroPaginator: Paginator): Promise<void> {
    if (this.filtroAtual > 0) {
      const dto = { texto: this.textoFiltroAtual } as FiltroDTO;
      this.entidadeService.filtrar(this.filtroAtual, dto, filtroPaginator, this.moduloCotacao)
        .then(result => { this.configurarPagina(result); });
    } else {
      this.filtroExtraSelecionado = null;
      this.filtroExtraHabilitado = false;
      await this.obterLista();
    }
  }

  async obterLista(): Promise<void> {
    if (this.getListFunction !== undefined) {
      await this.getListFunction(this.myPaginator, this.moduloCotacao).then(res => {
        this.configurarPagina(res);
      });
    }
    this.paginatorService.paginatorPesquisaEmitter = this.paginatorService.getPaginatorPesquisaEmitter();
      this.paginatorService.paginatorPesquisaEmitter?.subscribe(async result => {
        this.myPaginator = JSON.parse(result);
        if (this.textoFiltroAtual !== null) {
          if (this.filtroExtraHabilitado)
            await this.filtroExtraChanged();
          else
            await this.filtrarPorDescricao(this.myPaginator);
        } else {
          this.getListFunction(this.myPaginator, this.moduloCotacao).then(res => {
            this.configurarPagina(res);
          });
        }
      });

    let columns = this.colunas?.reduce((columns, row) => {
      return [...columns, ...Object.keys(row)]
    }, []).reduce((columns, column) => {
      return columns.includes(column)
        ? columns
        : [...columns, column]
    }, []);
    // Describe the columns for <mat-table>.
    this.columns = columns?.map(column => {
      return {
        columnDef: column,
        header: this.definicaoColunas.filter(x => x.definicao === column)[0].exibicao,
        cell: (element: any) => `${element[column] ? element[column] : ``}`
      }
    });
    this.displayedColumns = this.columns?.map(c => c.columnDef);
  }

  configurarPagina(pagina: Pagina<any>): void {
    if (pagina !== null && pagina !== undefined) {
      this.lista = (pagina.dados !== undefined && pagina.dados !== null) ? pagina.dados : pagina;
    }
    else {
      this.lista = null;
    }
    this.dataSource = new MatTableDataSource(this.lista);
    this.pagina = pagina;
    this.qtdeRegistros = this.pagina?.quantidadeRegistros;
  }

  rowClick(row: any): void {
    this.linhaSelecionada = row;
  }

  onYesClick(): void {
    this.pesquisaService.resultadoEmitter.emit(this.linhaSelecionada);
    this.onCancelClick();
  }

  onCancelClick(): void {
    this.limparPesquisa();
    sessionStorage.removeItem('searching');
    this.appConfigService.limparFiltroEmitter.emit(true);
  }

  temSubscricao(): boolean {
    let result = false;
    result = this.pesquisaService.pesquisaEventEmitter?.observers.length > 0;
    result = this.pesquisaService.tituloEventEmitter?.observers.length > 0;
    result = this.pesquisaService.mensagemEventEmitter?.observers.length > 0;
    result = this.pesquisaService.filtrosEmitter?.observers.length > 0;
    result = this.pesquisaService.filtroEmitter?.observers.length > 0;
    result = this.pesquisaService.functionEmitter?.observers.length > 0;
    result = this.pesquisaService.columnsHeadersEmitter?.observers.length > 0;
    result = this.pesquisaService.colunasEmitter?.observers.length > 0;
    result = this.pesquisaService.descricaoFEmitter?.observers.length > 0;
    result = this.pesquisaService.periodoEmitter?.observers.length > 0;
    result = this.pesquisaService.entidadeServiceEmitter?.observers.length > 0;
    result = this.pesquisaService.buscaEmitter.observers?.length > 0;
    result = this.pesquisaService.moduloCotacaoEmitter.observers?.length > 0;
    if (this.filtroEmitter !== null && this.filtroEmitter !== undefined) result = this.filtroEmitter?.observers.length > 0 ?? true;
    if (this.descricaoFiltroEmitter !== null && this.descricaoFiltroEmitter !== undefined) result = this.descricaoFiltroEmitter?.observers.length > 0 ?? true;
    return result;
  }

  limparPesquisa(): void {
    this.titulo = '';
    this.textoFiltroAtual = '';
    this.mensagem = undefined;
    this.pesquisando = false;
    this.filtros = undefined;
    this.dataSource = undefined;
    this.definicaoColunas = undefined;
    this.filtroEmitter = undefined;
    this.descricaoFiltroEmitter = undefined;
    this.periodoEmitter = undefined;
    this.iniciou = false;
    this.linhaSelecionada = undefined;
    this.qtdeRegistros = 0;
  }

  formataCelula(valor: string): string {
    if (!Number.isNaN(Number(valor))) {
      if (valor.length === 11 || valor.length === 14) return new CpfPipe().transform(valor);
    }
    return valor;
  }

  async filtroExtraChanged(): Promise<void> {
    if (this.functionFiltroExtra !== undefined) {
      this.filtroExtraHabilitado = true;
      await this.functionFiltroExtra(this.myPaginator, this.filtroExtraSelecionado).then(res => {
        this.configurarPagina(res);
      });
    }
  }

  getCampoValor(dado: any): object {
    return dado.id;
  }

  getCampoExibir(dado: any): string {
    return dado.nome;
  }
}
