import { EventEmitter, Injectable } from '@angular/core';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Log } from 'src/app/entidades/log';
import { MCI } from 'src/app/entidades/mci';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { Periodo } from 'src/app/entidades/periodo';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  filtroLogEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();
  dataFiltroEmitter = new EventEmitter<Periodo>();

  getFiltroLogEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getDescricaoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getDataFiltroEmitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  constructor(private apiService: ApiService) { }

  async obter(paginator: Paginator): Promise<Pagina<Log>> {
    try {
      let paginaAtual = null;
      const complemento = `/Log?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request(complemento, httpVerb.get).then(result => {
        paginaAtual = this.apiService.verificaSeResult<Pagina<Log>>(result);
      });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async todos(): Promise<Log[]> {
    try {
      let logs: Log[];
      await this.apiService.request('/Logs', httpVerb.get).then(result => {
        logs = this.apiService.verificaSeResult<Log[]>(result);
      });
    } catch (error) {
      console.log(error);
      return null
    }
  }

  async obterLog(id: number): Promise<Log> {
    try {
      let log: Log;
      await this.apiService.request(`/Log/${id}`, httpVerb.get).then(result => {
        log = this.apiService.verificaSeResult<Log>(result);
      });
      return log;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<Log>> {
    try {
      let paginaAtual = null;
      const complemento = `/Log/filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Log>>(complemento, httpVerb.post, dto)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<Log>>(result);
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionar(log: Log): Promise<Log> {
    try {
      let result = null;
      await this.apiService.request<Log>('/Log', httpVerb.put, log).then(res => {
        result = this.apiService.verificaSeResult<Log>(res);
      });
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
