import { TabCotacao } from './../../enum/tabCotacao';
import { CotacaoService } from 'src/app/services/cotacao/cotacao.service';
import { FiltroService } from './../../services/filtro/filtro.service';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Periodo } from 'src/app/entidades/periodo';
import { AppConfigService } from 'src/app/services/app/app-config.service';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['../../app.component.css', './filtro.component.css']
})
export class FiltroComponent implements OnInit {
  @Input() filtros: any;
  @Input() dataSource: any;
  @Input() filtroEmitter: EventEmitter<number>;
  @Input() descricaoEmitter: EventEmitter<string>;
  @Input() periodoDataEmitter: EventEmitter<Periodo>;
  @Input() tabPesquisa: TabCotacao;

  filtroSelecionado: number;
  descricao: string;
  dataInicial: Date;
  dataFinal: Date;
  exibirDescricao: boolean;
  exibirFiltroData: boolean;
  tabSelecionada: TabCotacao;
  inicializarEmitters = true;
  init_core: any;

  constructor(public filtroService: FiltroService,
    private appConfigService: AppConfigService,
    private cotacaoService: CotacaoService) { }

  ngOnInit(): void {
      this.filtroService.verificaSeEstaFiltrandoEmitter = this.filtroService.getVerificaSeEstaFiltrandoEmitter();
      if (this.tabPesquisa === null || this.tabPesquisa === undefined) {
        this.filtroService.exibirDescricaoFiltroEmitter = this.filtroService.getExibirDescricaoFiltroEmitter();
        this.filtroService.exibirFiltroDataEmitter = this.filtroService.getExibirFiltroDataEmitter();
        this.cotacaoService.tabCotacaoEmitter = this.cotacaoService.getTabCotacaoEmitter();
      }
      this.cotacaoService.tabCotacaoEmitter.subscribe((tab: TabCotacao) => {
        this.tabSelecionada = tab;
        this.tabPesquisa = tab;
      });
      this.filtroService.exibirDescricaoFiltroEmitter.subscribe((res: boolean) => {
        if (this.tabPesquisa === this.tabSelecionada)
          this.exibirDescricao = res
      });
      this.filtroService.exibirFiltroDataEmitter.subscribe((res: boolean) => {
        if (this.tabPesquisa === this.tabSelecionada)
          this.exibirFiltroData = res;
      });
    this.exibirDescricao = false;
    this.exibirFiltroData = false;
    this.filtroSelecionado = 0;
  }

  filtrar(): void {
    let pesquisandoNoFormulario = sessionStorage.getItem('searching');
    if (pesquisandoNoFormulario == 'true') {
      this.filtroService.pesquisaFiltroEmitter.emit(true);
      return;
    }
    sessionStorage.setItem('_fsel', this.filtroSelecionado.toString());
    if (this.descricao !== null && this.descricao !== undefined) sessionStorage.setItem('_fdesc', this.descricao);
    let paginator = sessionStorage.getItem('_pagina');
    if (this.exibirDescricao && !this.exibirFiltroData) {
      this.descricaoEmitter.emit(this.descricao);
      this.filtroService.filtrarPorDescricaoEmitter.emit([this.descricao, this.filtroSelecionado, paginator] as any);
      sessionStorage.setItem("_fdesc", this.descricao);
    } else if (!this.exibirDescricao && this.exibirFiltroData) {
      const periodo = { dataInicial: this.dataInicial, dataFinal: this.dataFinal };
      this.periodoDataEmitter.emit(periodo);
      this.filtroService.filtrarPorDataEmitter.emit([periodo, this.filtroSelecionado, paginator] as any);
      sessionStorage.setItem("_fdata", JSON.stringify(periodo));
    } else {
      this.filtroService.filtrarPorDescricaoEmitter.emit([this.descricao, this.filtroSelecionado, paginator] as any)
    }
  }

  habilitaBotaoFiltrar(): boolean {
    if (this.descricao === null || this.descricao === undefined || this.descricao === '') {
      if (this.exibirDescricao && (this.descricao === null || this.descricao?.length === 0)) return true;
    }
    if (this.exibirFiltroData) {
      if (this.dataInicial === null || this.dataFinal === null) return true;
    }
    return false;
  }

  optionChanged(): void {
    let tab = sessionStorage.getItem('tab');
    this.filtroEmitter.emit(this.filtroSelecionado);
    sessionStorage.getItem(`_fa${tab}`);
    sessionStorage.setItem("_fsel", this.filtroSelecionado.toString());
    sessionStorage.setItem("_filtrando", this.filtroSelecionado > 0 ? "true" : "false");
    sessionStorage.removeItem(`_fa${tab}`)
    this.descricao = null;
    if (!this.exibirFiltroData) {
      this.dataInicial = null;
      this.dataFinal = null;
    }
  }

  verificaDados(): void {
    if (this.descricao === null || this.descricao === undefined) {
      this.descricao = (document.getElementById('descricaoFiltro') as any)?.value;
    }
  }

  onKeyPressEvent(event: any): void {
    if (event.key === 'Enter') {
      this.filtrar();
    }
  }
}
