import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoLogin } from 'src/app/entidades/infoLogin';
import { Usuario } from 'src/app/entidades/usuario';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { Validador } from 'src/app/validadores/validador';
import { MensagemService } from './../../../../services/mensagem/mensagem.service';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['../../../../app.component.css', './alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {
  infoLogin: InfoLogin;
  isBusy = false;
  formulario: UntypedFormGroup;
  usuario: Usuario;

  constructor(private formBuilder: UntypedFormBuilder,
    private usuarioService: UsuarioService,
    private mensagemService: MensagemService,
    private router: Router,
    private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.formulario = this.formBuilder.group({
      nomeUsuario: [null, Validators.required],
      senha: [null, Validators.required],
      senhaAntiga: [null, Validators.required],
      confirmacao: [null]
    });
    this.formulario.get('confirmacao').setValidators([Validators.required, Validador.isTextEquals(this.formulario.get('senha'))]);
    const id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.usuarioService.ObterUsuario(id).then(result => {
      this.usuario = result;
      this.formulario.patchValue({ nomeUsuario: this.usuario.nomeUsuario });
    });
  }

  onSubmit(): void {
    try {
      const novaSenha = this.formulario.get('senha').value;
      const confirmacao = this.formulario.get('confirmacao').value;
      if (confirmacao !== novaSenha) {
        this.mensagemService.showMessage('Nova Senha e Confirmaçao não conferem. Tente novamente!',
          'Ok',
          5000,
          'error');
      }
      else {
        this.isBusy = true;
        this.infoLogin = this.formulario.value as InfoLogin;
        this.infoLogin.alterandoSenha = true;
        this.usuarioService.AlterarSenha(this.infoLogin).then(
          result => {
            if (result) {
              this.mensagemService.showMessage('Senha alterada com sucesso.',
                'Ok',
                5000,
                'success');

              this.isBusy = false;
              this.limparFormulario();
              this.cancelar();
            }
            else {
              this.mensagemService.showMessage('Erro ao alterar senha. Verifique se as informou corretamente!',
                'Ok',
                5000,
                'error');
            }
          });
      }
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage(`Erro ao alterar a senha do usuário.`,
        'Ok',
        5000,
        'error');
    }
  }

  cancelar(): void {
    if (this.usuario.nivel === 'ADMINISTRDOR') {
      this.router.navigate(['/usuarios']);
    }
    else {
      this.router.navigate(['/']);
    }
  }

  limparFormulario(): void {
    this.formulario.patchValue({
      nomeUsuario: null,
      senha: null,
      senhaAntiga: null,
      confirmacao: null
    });
  }
}
