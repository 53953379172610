import { Component, OnInit } from '@angular/core';
import { MinhaAtividadeDto } from 'src/app/dto/minhaAtividadeDto';
import { Usuario } from 'src/app/entidades/usuario';
import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-minha-atividade',
  templateUrl: './minha-atividade.component.html',
  styleUrls: ['./minha-atividade.component.css', '../../app.component.css']
})
export class MinhaAtividadeComponent implements OnInit {
  usuario: Usuario;
  minhasAtividades: MinhaAtividadeDto[];
  podeExibir: boolean;

  constructor(private apiService: ApiService,
              private appConfigService: AppConfigService,
              private usuarioService: UsuarioService) {}

  async ngOnInit(): Promise<void> {
    this.appConfigService.minhasAtividadesEmitter.subscribe(async res => {
      this.usuario = this.apiService.UsuarioLogado;
      if (res)
        await this.obterMinhasAtividades().then(() => { this.exibir(true)});
        this.exibir(res);
    });
  }

  async obterMinhasAtividades(): Promise<void> {
    await this.usuarioService.minhaAtividade().then(result => {
      this.minhasAtividades = result as MinhaAtividadeDto[];
    });
  }

  exibir(yesNo: boolean): void {
    this.podeExibir = yesNo;
  }

  cotacaoOuEndosso(atv: MinhaAtividadeDto): string {
    if (atv.tipo === 'COTAÇÃO') return atv.quantidade > 1 ? 'cotaçôes' : 'cotação'
    else return atv.quantidade > 1 ? 'endossos' : 'endosso'
  }
}
