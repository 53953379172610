import { EventEmitter, Injectable } from '@angular/core';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { Usuario } from 'src/app/entidades/usuario';
import { httpVerb } from 'src/app/enum/httpVerb';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { InfoLogin } from './../../entidades/infoLogin';
import { ApiService } from './../api/api.service';
import { MinhaAtividadeDto } from 'src/app/dto/minhaAtividadeDto';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  filtroUsuarioEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();

  getDescricaoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getfiltroUsuarioEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  constructor(private apiService: ApiService,
    private mensagemService: MensagemService) { }

  async AdicionarOuEditar(novoUsuario: Usuario): Promise<Usuario> {
    try {
      let usuario = null;
      await this.apiService.request<Usuario>('/Usuario', httpVerb.post, novoUsuario)
        .then(result => {
          if (result !== null && result !== undefined) {
            usuario = (result as Usuario);
          }
        });

      return usuario;
    } catch (error) {
      console.log(error);
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<Usuario>> {
    try {
      let paginaAtual = null;
      const complemento = `/usuario?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Usuario>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Usuario>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage('Erro ao obter usuários.', 'Ok', 5000, 'error');
    }
  }

  async Todos(): Promise<Usuario[]> {
    try {
      let usuarios = null;
      await this.apiService.request<Usuario>('/usuario/todos', httpVerb.get)
        .then(result => {
          if (result != null && result !== undefined) {
            usuarios = result;
          }
        });
      return usuarios;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage('Erro ao obter usuários.', 'Ok', 5000, 'error');
    }
  }

  async ObterColaboradores(): Promise<Usuario[]> {
    try {
      let usuarios = null;
      await this.apiService.request<Usuario[]>('/usuario/colaboradores', httpVerb.get).then(result => {
        usuarios = this.apiService.verificaSeResult<Usuario[]>(result);
      });
      return usuarios;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async ObterUsuario(id: number): Promise<Usuario> {
    try {
      let usuarioRetorno = null;
      await this.apiService.request<Usuario>(`/usuario/${id}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            usuarioRetorno = (result as Usuario);
          }
        });
      return usuarioRetorno;
    } catch (error) {
      console.log(error);
      this.mensagemService.showMessage('Erro ao alterar usuário.', 'Ok', 5000, 'error');
    }
  }

  async AlterarSenha(infoLogin: InfoLogin): Promise<boolean> {
    try {
      let alterado = false;
      await this.apiService.request<boolean>('/Usuario/novaSenha', httpVerb.put, infoLogin)
        .then(result => { alterado = result; });
      return alterado;
    }
    catch (error) {
      console.log(error);
      this.mensagemService.showMessage(`Erro ao alterar senha.`, 'Ok', 5000, 'error');
    }
  }

  async resetarSenha(usuario: Usuario): Promise<boolean> {
    try {
      let executou = false;
      await this.apiService.request<boolean>(`/Usuario/Reset/${usuario.id}`, httpVerb.put)
        .then(result => { executou = result; });
      return executou;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<Usuario>> {
    try {
      let paginaAtual = null;
      const complemento = `/usuario/filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Usuario>>(complemento, httpVerb.post, dto)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Usuario>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async logOff(): Promise<boolean> {
    try {
      let executou = false;
      await this.apiService.request<boolean>(`/usuario/LogOff`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            executou = (result as boolean);
          }
        });
      return executou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async minhaAtividade(): Promise<MinhaAtividadeDto[]> {
    try {
      let minhaAtividade = [];
      await this.apiService.request<MinhaAtividadeDto[]>(`/usuario/MinhaAtividade`, httpVerb.get, null, false, false)
        .then(result => {
          if (result !== null && result !== undefined) {
            minhaAtividade = (result as MinhaAtividadeDto[]);
          }
        });
      return minhaAtividade;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
