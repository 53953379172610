import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Aviso } from 'src/app/entidades/aviso';
import { httpVerb } from 'src/app/enum/httpVerb';

@Injectable({
  providedIn: 'root'
})
export class AvisoService {
  avisosEmitter = new EventEmitter<boolean>();

  constructor(private apiService: ApiService) {}

  async avisosNaoLidos(idUsuario: number): Promise<Aviso[]> {
    try {
      let avisos: Aviso[];
      await this.apiService.request<Aviso[]>(`/Aviso/NaoLidos/${idUsuario}`, httpVerb.get, null, false, false).then(result => {
        avisos = this.apiService.verificaSeResult<Aviso[]>(result);
      });
      return avisos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async marcarLeitura(idAviso: number, idUsuario: number): Promise<Aviso[]> {
    try {
      let avisos: Aviso[];
      await this.apiService.request<Aviso[]>(`/Aviso/MarcarLeitura/${idAviso}/${idUsuario}`, httpVerb.get, null, false, false).then(result => {
        avisos = this.apiService.verificaSeResult<Aviso[]>(result);
      });
      return avisos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
