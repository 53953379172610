<div class="tituloDialog">{{ titulo }}</div>
<form class="formContainer" [formGroup]="formulario">
  <div class="row">
    <mat-form-field appearance="outline" class="grow1">
      <mat-label>Data</mat-label>
      <input matInput formControlName="data" [readonly]="true" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Usuário</mat-label>
      <input matInput formControlName="usuario" [readonly]="true" />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Descrição</mat-label>
      <textarea
        class="text-area"
        matInput
        #inputDescricao
        formControlName="descricao"
        cdkAutosizeMinRows="30"
        cdkAutosizeMaxRows="30"
        [readonly]="true"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="item-right">
    <button
      mat-button
      class="btnCancel mat-elevation-z8"
      type="button"
      (click)="cancelClick()"
    >
      Fechar
    </button>
  </div>
</form>
