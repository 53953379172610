<mat-toolbar
  *ngIf="exibirNavBar"
  id="topNav"
  class="toolbar fixed-top scrolled"
>
  <mat-toolbar-row>
    <div (click)="openSidenav()">
      <img
        src="../../../assets/imagens/menu_sicoob.svg"
        alt="Logotipo do Sicoob"
        class="imgButton"
      />
    </div>
    <img
      class="logo-nav"
      [routerLink]="['']"
      src="../../../assets/imagens/logoGestao.jpg"
    />
    <div class="versao">{{ getVersao() }}</div>
    <div class="ambiente">{{ ambiente }}</div>
    <div class="spacer"></div>
    <div class="monitor" *ngIf="notificar">
      <mat-icon (click)="exibirMonitor()">notifications</mat-icon>
    </div>
    <div class="logged">
      <div class="col">
        <div class="user">{{ usuario.nomeExibicao }}</div>
        <div class="userEmail">
          <div>{{ usuario.nivel }} | {{ usuario.codPA }}</div>
          <div>{{ usuario.email }}</div>
        </div>
      </div>
      <div class="logout">
        <span class="material-icons click" (click)="logOut()">logout</span>
        <div>Sair</div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
