<div class="footer" *ngIf="usuarioAutenticado">
    <div class="footer-flex">
        <div class="footer-block">
            <div class="footer-item">
                <h5><b>Gestão Credipontal</b></h5>
            </div>
        </div>
        <div class="footer-block">
            <div class="footer-item">
                <a><b>Links úteis:</b></a>
                <a href="http://www.sicoobcredipontal.com.br/">Credipontal</a>
                <a href="http://www.sicoobcentralcecremge.com.br">Central Cecremge</a>
                <a href="http://www.sicoob.com.br">Sicoob</a>
                <a href="http://sipag.com.br">Sipag</a>
            </div>
        </div>
        <div class="footer-block">
            <div class="footer-item">
                <a><b>Cooperativa de Crédito de Livre Admissão do Pontal do Triângulo</b></a>
                <a>Rua Trinta e Seis nº 800 - bairro Progresso</a>
                <a>38.302-008 - Ituiutaba-MG</a>
                <a><mat-icon>phone</mat-icon>  34-3271-4300</a>
            </div>
        </div>
    </div>
    <div class="footer-copy">
        © 2021 Copyright: Gestão Credipontal - Contatos: (34) 9-9973-1503
    </div>
</div>