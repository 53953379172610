<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="'Usuário'"></app-titulo>
  <form class="formContainer" [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Nome do usuário</mat-label>
        <input matInput #inputNomeDoUsuario formControlName="nome" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Login</mat-label>
        <input matInput #inputId formControlName="nomeUsuario" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Código PA</mat-label>
        <mat-select #agencia formControlName="codPA">
          <mat-option *ngFor="let agencia of agencias" [value]="agencia?.codPA"
            >{{ agencia?.codPA }} - {{ agencia?.cidade }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nível</mat-label>
        <mat-select formControlName="nivel">
          <mat-option value="ADMINISTRADOR">Administrador</mat-option>
          <mat-option value="ASSESSOR">Assessor</mat-option>
          <mat-option value="OPERADOR">Operador</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tipo de Carteira</mat-label>
        <mat-select #tipoCarteira formControlName="tipoCarteira">
          <mat-option *ngFor="let t of tiposCarteira" [value]="t.id"
            >{{ t.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox formControlName="ehColaborador">É Colaborador</mat-checkbox>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>e-mail</mat-label>
        <input matInput #inputEmail formControlName="email" />
      </mat-form-field>
    </div>
    <div class="item-right">
      <button
        mat-button
        class="btnOK mat-elevation-z8"
        [disabled]="formulario.status == 'INVALID' || formulario.pristine"
        type="submit"
      >
        Salvar
      </button>
      <button
        mat-button
        class="btnCancel mat-elevation-z8"
        type="button"
        (click)="cancelar()"
      >
        Cancelar
      </button>
    </div>
  </form>
</div>
