import { Usuario } from './../../../entidades/usuario';
import { UsuarioService } from './../../../services/usuario/usuario.service';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Log } from 'src/app/entidades/log';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-log-detail',
  templateUrl: './log-detail.component.html',
  styleUrls: ['./log-detail.component.css', '../../../app.component.css']
})
export class LogDetailComponent implements OnInit {
  formulario: UntypedFormGroup;
  titulo: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private usuarioService: UsuarioService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder) { }

  async ngOnInit(): Promise<void> {
    this.formulario = this.formBuilder.group({
      data: [null],
      usuario: [null],
      descricao: [null]
    });
    if (this.data.usuario === null || this.data.usuario === undefined) {
      this.apiService.IgnoraBusy = true;
      await this.usuarioService.ObterUsuario(this.data.usuarioId).then(res => {
        this.data.usuario = res as Usuario;
        this.apiService.IgnoraBusy = false;
      });
    }
    this.formulario.patchValue({
      data: this.datePipe.transform(this.data?.data, 'dd/MM/yyyy HH:mm:ss'),
      usuario: this.data?.usuario?.nome,
      descricao: this.data?.descricao
    });
    this.formulario.markAsPristine();
    this.titulo = this.data.titulo === null || this.data.titulo === undefined ? `Log nº ${this.data?.id}` : this.data.titulo;
  }

  cancelClick(): string {
    this.dialog.closeAll();
    return 'cancel';
  }
}
