<div class="pagina-pesquisa" *ngIf="pesquisando">
  <div class="container-pesquisa mat-elevation-z8">
    <app-titulo [titulo]="titulo"></app-titulo>

    <div class="left" *ngIf="temFiltroExtra">
      <mat-form-field class="tm75" appearance="outline">
        <mat-label>{{ dadosFiltroExtra?.tituloCombo }}</mat-label>
        <mat-select
          id="filtroExtra"
          name="filtroExtra"
          [(ngModel)]="filtroExtraSelecionado"
          (ngModelChange)="filtroExtraChanged()"
        >
          <mat-option
            *ngFor="let dado of dadosFiltroExtra?.dados"
            [value]="getCampoValor(dado)"
            >{{ getCampoExibir(dado) }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <app-filtro
      [(filtros)]="filtros"
      [dataSource]="dataSource"
      [(filtroEmitter)]="filtroEmitter"
      [(descricaoEmitter)]="descricaoFiltroEmitter"
      [(periodoDataEmitter)]="periodoEmitter"
    ></app-filtro>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="table-pesquisa">
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.columnDef"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ formataCelula(column.cell(row)) }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ selectedRow: row == linhaSelecionada }"
          (click)="rowClick(row)"
        ></tr>
      </table>
    </div>
    <app-paginator [(length)]="qtdeRegistros" [pageSize]="15"></app-paginator>
    <mat-divider></mat-divider>
    <div class="botao-pesquisa">
      <button
        mat-button
        class="btnOK mat-elevation-z8"
        [disabled]="linhaSelecionada === null || linhaSelecionada === undefined"
        (click)="onYesClick()"
      >
        Confirmar
      </button>
      <button
        mat-button
        class="btnCancel mat-elevation-z8"
        (click)="onCancelClick()"
      >
        Cancelar
      </button>
    </div>
  </div>
</div>
