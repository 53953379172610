<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="titulo"></app-titulo>
  <div class="item-center" fxLayout="column" fxLayoutAlign="start center">
    <button
      *ngIf="usuario.nivel === 'ADMINISTRADOR' && !campanhasVigentes"
      mat-fab
      class="btnNew"
      (click)="novaCampanha()"
      matTooltip="Adiciona nova Campanha"
      matTooltipClass="tooltip-blue"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      *ngIf="usuario.nivel === 'ADMINISTRADOR' && !campanhasVigentes"
      mat-fab
      class="btnEdit"
      (click)="editarCampanha()"
      matTooltip="Edita os dados da Campanha Selecionada"
      matTooltipClass="tooltip-blue"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-fab
      class="btnEdit"
      (click)="visualizarCupons()"
      matTooltip="Visualizar Cupons da Campanha"
      matTooltipClass="tooltip-blue"
      aria-label="Visualiza Cupons da Campanha"
    >
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      mat-fab
      class="btnWhite"
      (click)="termoAdesao()"
      matTooltip="Termo de Adesão à Campanha"
      matTooltipClass="tooltip-blue"
      aria-label="TermoAdesao"
    >
      <img class="imgRow" src="../../../assets/imagens/termo.png" />
    </button>
    <button
      mat-fab
      class="btnReturn"
      (click)="parcelamentoCupom()"
      matTooltip="Parcelamento dos Cupons"
      matTooltipClass="tooltip-blue"
      aria-label="Parcelamentos"
    >
      <mat-icon>attach_money</mat-icon>
    </button>
    <button
      *ngIf="usuario.nivel === 'ADMINISTRADOR' || usuario.nivel === 'ASSESSOR'"
      mat-fab
      class="btnNew"
      (click)="relatorioCSV()"
      matTooltip="Relatório da Campanha"
      matTooltipClass="tooltip-blue"
      aria-label="Relatório da Campanha"
    >
      <mat-icon>description</mat-icon>
    </button>
    <button
      mat-fab
      class="btnReport"
      (click)="posicaoCampanha()"
      matTooltip="Exibe a posição atual da Campanha em relação às metas"
      matTooltipClass="tooltip-blue"
      aria-label="Posição da Campanha"
    >
      <mat-icon>bar_chart</mat-icon>
    </button>
    <button
      mat-fab
      class="btnReport"
      *ngIf="usuario.nivel === 'ADMINISTRADOR' || usuario.nivel === 'ASSESSOR'"
      [matMenuTriggerFor]="menu"
      #tooltip="matTooltip"
      matTooltip="Obtém relatórios para impressão"
      matTooltipClass="tooltip-blue"
    >
      <mat-icon>print</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="parcelamentoCupons()">
          Cupons Parcelados
        </button>
        <button mat-menu-item (click)="cuponsCancelados()">
          Cupons Cancelados
        </button>
        <button mat-menu-item (click)="detalhesCampanha()">
          Detalhamento da Campanha
        </button>
      </mat-menu>
    </button>
  </div>
  <table mat-table [dataSource]="dataSource">
    <!-- Coluna Id -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <!-- Coluna Nome -->
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Campanha</th>
      <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
    </ng-container>

    <!-- Coluna Descrição -->
    <ng-container matColumnDef="descricao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
      <td mat-cell *matCellDef="let row">{{ row.descricao }}</td>
    </ng-container>

    <!-- Coluna Data Início -->
    <ng-container matColumnDef="data_inicio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de Início</th>
      <td mat-cell *matCellDef="let row">
        {{ row.dataInicio | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- Coluna Data Fim -->
    <ng-container matColumnDef="data_fim">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Final</th>
      <td mat-cell *matCellDef="let row">
        {{ row.dataFim | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- Coluna Encerrada -->
    <ng-container matColumnDef="encerrada">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Encerrada</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.encerrada">check</mat-icon>
      </td>
    </ng-container>

    <!-- Coluna Sorteio -->
    <ng-container matColumnDef="sorteio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sorteio</th>
      <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
        <button
          mat-button
          class="btnReturn"
          *ngIf="row.encerrada"
          (click)="sorteio(row)"
          matTooltip="Visualiza o Resultado da Campanha"
          matTooltipClass="tooltip-blue"
          aria-label="Visualiza Sorteio"
        >
          <mat-icon>star_border</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ selectedRow: row == campanhaSelecionada }"
      (click)="rowClick(row)"
    ></tr>
  </table>
  <app-paginator [(length)]="qtdeRegistros"></app-paginator>
</div>

<div
  *ngIf="sorteando === true"
  class="item"
  fxLayout="column"
  fxLayoutAlign="end center"
>
  <mat-label
    >Deseja realmente executar o Sorteio da Campanha
    {{ campanhaSelecionada.nome }}?</mat-label
  >
  <button mat-button class="btnNew" (click)="sortear()">Sim</button>
  <button mat-button class="btnView" (click)="cancelarSorteio()">Não</button>
</div>
