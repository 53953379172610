import { environment } from './../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Credenciais } from 'src/app/entidades/credenciais';
import { Usuario } from 'src/app/entidades/usuario';
import { httpVerb } from 'src/app/enum/httpVerb';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { ApiToken } from './../../entidades/token';
import { Parametros } from 'src/app/entidades/parametro';
import { PeriodoDto } from 'src/app/dto/periodoDto';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private result: any;
  private credenciais: Credenciais;
  private usuarioLogado: Usuario;
  private token: string;
  private versaoSite: string;
  private parametros: Parametros;
  private idStatusPadrao: number;
  private ignoraBusy: boolean;
  private strDocument: string;
  private documentIsShowing: boolean;
  private localId: string;
  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '20rem',
    minHeight: '5rem',
    placeholder: 'Insira o texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: "3",
    fonts: [
      {class: 'arial', name: 'Arial'}
    ],
    toolbarHiddenButtons: [
      [
        'heading'
      ],
      [
        'link',
        'unlink',
        'fontSize',
        'customClasses',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
    customClasses: [
      {
        name: "Citação",
        class: "quote",
      },
      {
        name: 'Texto vermelho',
        class: 'redText'
      },
      {
        name: "Título",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  /* Emitters de Update */
  topPrioridadesEmitter = new EventEmitter<PeriodoDto>();

  isBusyEmitter = new EventEmitter<boolean>();
  pdfViewerEmitter = new EventEmitter<boolean>();

  constructor(private appConfigService: AppConfigService,
    private http: HttpClient,
    private mensagemService: MensagemService) { }

  get StrDocument(): string {
    return this.strDocument;
  }

  get PdfViewerIsShowing(): boolean {
    return this.documentIsShowing;
  }

  set LocalId(id: string) {
    this.localId = id;
  }

  get LocalId(): string {
    return this.localId;
  }

  set PdfViewerIsShowing(yesNo: boolean) {
    this.documentIsShowing = yesNo;
  }

  get EditorConfig() {
    return this.editorConfig;
  }

  getTextFromHtml(text: string): string {
    const parser = new DOMParser();
    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(text, 'text/html');
    // Extract the text content from the parsed document
    const textContent = doc.body.textContent || "";
    return textContent.trim(); // Trim any leading or trailing whitespace
    //return text.replace(/(<([^>]+)>)/ig, ' ');
  }

  async DataBase(): Promise<Date> {
    try {
      let dataBase = null;
      await this.request<string>('/Index/DataBase', httpVerb.get)
        .then(result => {
          let str = result;
          dataBase = new Date(Number(`${str.substring(6)}`), Number(`${str.substring(3, 5)}`) - 1, Number(`${str.substring(0, 2)}`));
        });
      return dataBase;
    } catch (error) {
      console.log(error);
      return new Date(2021, 0, 1, 0, 0, 0, 0)
    }
  }

  set StrDocument(document: string) {
    this.strDocument = document;
  }

  get Token(): string {
    return this.token;
  }

  get VersaoSite(): string {
    return this.versaoSite;
  }

  set VersaoSite(versao: string) {
    this.versaoSite = versao;
  }

  set Credenciais(credenciais: Credenciais) {
    this.credenciais = credenciais;
    this.appConfigService.credenciais = credenciais;
  }

  get Credenciais(): Credenciais {
    return this.credenciais;
  }

  set UsuarioLogado(usuario: Usuario) {
    this.usuarioLogado = usuario;
    sessionStorage.setItem("userCode", this.usuarioLogado.id.toExponential());
  }

  set IgnoraBusy(ignorar: boolean) {
    this.ignoraBusy = ignorar;
  }

  get UsuarioLogado(): Usuario {
    return this.usuarioLogado;
  }

  set Parametros(parametros: Parametros) {
    this.parametros = parametros;
    this.idStatusPadrao = this.parametros.idStatusNovaCotacao;
  }

  get Parametros(): Parametros {
    return this.parametros;
  }

  set IdStatusPadrao(idStatusPadrao: number) {
    this.IdStatusPadrao = idStatusPadrao;
  }

  get IdStatusPadrao(): number {
    return this.idStatusPadrao;
  }

  async getVersaoSite(): Promise<string> {
    try {
      let versao = '';
      await this.request<string>('/Index/VersaoSite', httpVerb.get).then(res =>{
        versao = res as string;
      });
      this.versaoSite = versao;
      return versao;
    } catch (e) {
      console.log(e);
    }
  }

  async getToken(credenciais: Credenciais): Promise<void> {
    if (!this.ignoraBusy) this.isBusyEmitter.emit(true);
    try {
      await this.request<ApiToken>('/token', httpVerb.post, JSON.stringify(credenciais))
        .then(result => {
          if (result !== null && result !== undefined) {
            this.token = (result as ApiToken).access_token;
            this.versaoSite = (result as ApiToken).versao_site;
          } else {
            this.token = null;
          }
        });
      if (this.token !== null && this.token !== undefined) {
        this.appConfigService.setAuthorization(this.token);
      } else {
        this.appConfigService.setAuthorization(null);
      }
      if (!this.ignoraBusy) this.isBusyEmitter.emit(false);
    } catch (error) {
      console.log(error);
    }
    finally {
      sessionStorage.setItem("token_gestao", this.token);
    }
  }

  async adicionarOuEditarParametros(parametros: Parametros): Promise<boolean> {
    try {
      let url = '/Parametros';
      await this.request<Parametros>(url, httpVerb.put, parametros).then(res => {
        this.parametros = this.verificaSeResult<Parametros>(res);
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async obterAmbiente(): Promise<any> {
    return await firstValueFrom(this.http?.get(`${this.appConfigService.apiBaseUrl}/index/ambiente`, this.appConfigService.requestOptions));
  }

  async request<T>(complementoUri: string, verbo: httpVerb, body?: any, responseTypeBlob: boolean = false, emitWaiting: boolean = true) {
    try {
      if (emitWaiting) {
        if (!this.ignoraBusy) this.isBusyEmitter.emit(true);
      }
      if (complementoUri !== '/token') this.appConfigService.setAuthorization(sessionStorage.getItem("token_gestao"));
      let chave = sessionStorage.getItem('token_gestao');
      if ((chave === null || chave === undefined) && complementoUri !== '/token') {
        return;
      }
      let requestOptions = responseTypeBlob ? this.appConfigService.requestOptionsForBlob : this.appConfigService.requestOptions;
      switch (verbo) {
        case httpVerb.get: {
          this.result = await firstValueFrom(this.http?.get(this.appConfigService.apiBaseUrl + complementoUri,
            requestOptions));
          break;
        }
        case httpVerb.post: {
          this.result = await firstValueFrom(this.http?.post(this.appConfigService.apiBaseUrl + complementoUri,
            body, requestOptions));
          break;
        }
        case httpVerb.put: {
          this.result = await firstValueFrom(this.http?.put(this.appConfigService.apiBaseUrl + complementoUri,
            body, requestOptions));
          break;
        }
        case httpVerb.patch: {
          this.result = await firstValueFrom(this.http?.patch(this.appConfigService.apiBaseUrl + complementoUri,
            body, requestOptions));
          break;
        }
        case httpVerb.delete: {
          this.result = await firstValueFrom(this.http?.delete(this.appConfigService.apiBaseUrl + complementoUri,
            requestOptions));
          break;
        }
        default: {
          break;
        }
      }
      if (!this.ignoraBusy) this.isBusyEmitter.emit(false);
      return this.result;
    } catch (error) {
      console.log(error);
      if (!this.ignoraBusy) this.isBusyEmitter.emit(false);
      const erro: HttpErrorResponse = error;
      this.getErrorMessage(`${erro.status}`);
      console.log(`[ApiService]: ${erro.status}`);
    }
  }

  getErrorMessage(status: string): void {
    let mensagem = null;
    switch (status) {
      case '400':
        mensagem = `${status} - Requisição inválida.`;
        break;
      case '401':
        mensagem = `${status} - Não autorizado.`;
        break;
      case '403':
        mensagem = `${status} - Proibido`;
        break;
      case '404':
        mensagem = `${status} - Não encontrado`;
        break;
      case '405':
        mensagem = `${status} - Método não permitido.`;
        break;
      case '407':
        mensagem = `${status} - Autenticação de proxy necessária.`;
        break;
      case '408':
        mensagem = `${status} - Tempo de requisição esgotou (timout).`;
        break;
      case '500':
        mensagem = `${status} - Erro interno do servidor.`;
        break;
      case '502':
        mensagem = `${status} - Bad Gateway.`;
        break;
      case '503':
        mensagem = `${status} - Serviço indisponível.`;
        break;
      default:
        mensagem = `HTTP Error ${status}`;
        break;
    }
    this.mensagemService.showMessage(mensagem, 'Ok', 5000, 'error');
  }

  async obterEnderecoCEP(cep: string): Promise<any> {
    try {
      let config = this.appConfigService.getAppConfig();
      let endPoint = config.endPointConsultaCEP;

      //Nova variável "cep" somente com dígitos.
      var res = cep.replace(/\D/g, '');

      //Verifica se campo cep possui valor informado.
      if (res != "") {

        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;

        //Valida o formato do CEP.
        if (validacep.test(res)) {
          return await this.request<any>(`/index/cep/${res}`, httpVerb.get);
        } else {
          return null;
        }
      }
    } catch (error) {
      return null;
    }
  }

  verificaSeResult<T>(result: any): T {
    let retorno: any;
    let notIsNull: boolean
    if (result !== null && result !== undefined) {
      notIsNull = true;
    } else {
      notIsNull = false;
    }
    if (notIsNull) {
      retorno = result as T;
    } else {
      retorno = null;
    }
    return retorno;
  }
}
