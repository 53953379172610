import { ControleParcela } from './../../entidades/controleParcela';
import { EventEmitter, Injectable } from '@angular/core';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';
import { PrimeiraParcelaDto } from 'src/app/dto/primeiraParcelaDto';
import { Periodo } from 'src/app/entidades/periodo';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';

@Injectable({
  providedIn: 'root'
})
export class ControleParcelasService {
  filtroParcelaEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();
  dataFiltroEmitter = new EventEmitter<Periodo>();

  getFiltroParcelaEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  getDescricaoFiltroEmitter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getdataFiltroEmitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  constructor(private apiService: ApiService) { }

  async todas(): Promise<ControleParcela[]> {
    try {
      let parcelas: ControleParcela[];
      await this.apiService.request<ControleParcela[]>(`/Parcelas/Todas`, httpVerb.get).then(result => {
        parcelas = this.apiService.verificaSeResult<ControleParcela[]>(result);
      });
      return parcelas;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditar(novaParcela: ControleParcela): Promise<ControleParcela> {
    try {
      let ControleParcela = null;
      await this.apiService.request<ControleParcela>('/Parcelas', httpVerb.put, novaParcela)
        .then(result => {
          if (result !== null && result !== undefined) {
            ControleParcela = result as ControleParcela;
          }
        });
      return ControleParcela;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<ControleParcela>> {
    try {
      let paginaAtual = null;
      const complemento = `/Parcelas?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<ControleParcela>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<ControleParcela>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      let excluiu = false;
      await this.apiService.request<boolean>(`/Parcelas/${id}`, httpVerb.delete)
        .then(result => {
          if (result !== null && result !== undefined) {
            excluiu = result;
          }
        });
      return excluiu;
    } catch (error) {
      console.log(error);
    }
  }

  async obterParcela(id: number): Promise<ControleParcela> {
    try {
      let ControleParcela = null;
      await this.apiService.request<ControleParcela>(`/Parcelas/${id}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            ControleParcela = (result as ControleParcela);
          }
        });
      return ControleParcela;
    } catch (error) {
      console.log(error);
    }
  }

  async notificar(id: number): Promise<boolean> {
    try {
      let result = false;
      await this.apiService.request<boolean>(`/Parcelas/Notificar/${id}`, httpVerb.get).then(res => {
        result = res;
      });
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async baixar(id: number): Promise<boolean> {
    try {
      let result = false;
      await this.apiService.request<boolean>(`/Parcelas/Baixar/${id}`, httpVerb.get).then(res => {
        result = res;
      });
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async semSaldo(id: number): Promise<boolean> {
    try {
      let result = false;
      await this.apiService.request<boolean>(`/Parcelas/SemSaldo/${id}`, httpVerb.get).then(res => {
        result = res;
      });
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async listaParcelas(paginator: Paginator): Promise<PrimeiraParcelaDto[]> {
    try {
      let pagina = null;
      await this.apiService.request<Pagina<PrimeiraParcelaDto>>(`/Parcelas/PrimeirasParcelas?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`, httpVerb.get).then(res => {
        pagina = this.apiService.verificaSeResult<Pagina<PrimeiraParcelaDto>>(res);
      });
      return pagina;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<ControleParcela>> {
    try {
      let paginaAtual = null;
      const complemento = `/Parcelas/Filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<ControleParcela>>(complemento, httpVerb.post, dto)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<ControleParcela>);
          }
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
