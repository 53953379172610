<table mat-table [dataSource]="dsPrioridades">
  <!-- Coluna Atendente -->
  <ng-container matColumnDef="atendente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Atendente</th>
    <td mat-cell *matCellDef="let row">
      {{ row?.atendenteNome }}
    </td>
  </ng-container>

  <!-- Coluna Prioridade Alta -->
  <ng-container matColumnDef="prioridadeAlta">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
      <div class="img-top-prioridades">
        <img
          src="../../../assets/imagens/prioridade_alta.png"
          class="prioridade"
        />Alta
      </div>
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row?.quantidadeAlta | number }}
    </td>
  </ng-container>

  <!-- Coluna Prioridade Média -->
  <ng-container matColumnDef="prioridadeMedia">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
      <div class="img-top-prioridades">
        <img
          src="../../../assets/imagens/prioridade_media.png"
          class="prioridade"
        />Média
      </div>
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row?.quantidadeMedia | number }}
    </td>
  </ng-container>

  <!-- Coluna Prioridade Baixa -->
  <ng-container matColumnDef="prioridadeBaixa">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
      <div class="img-top-prioridades">
        <img
          src="../../../assets/imagens/prioridade_baixa.png"
          class="prioridade"
        />
        Baixa
      </div>
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row?.quantidadeBaixa | number }}
    </td>
  </ng-container>

  <!-- Coluna Total -->
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
      Total
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row?.quantidade | number }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: colunasGrid"
    [ngClass]="{ selectedRow: row == itemSelecionado }"
    (click)="rowClick(row)"
  ></tr>
</table>
