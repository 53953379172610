import { ValidationResult } from './../../entidades/validationResult';
import { IAPProduto } from './../../entidades/iap_produto';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { UtilService } from '../util/util.service';
import { httpVerb } from 'src/app/enum/httpVerb';
import { Paginator } from 'src/app/entidades/paginator';
import { Pagina } from 'src/app/entidades/pagina';
import { StrIAPProdutos } from 'src/app/entidades/strIAPProdutos';

@Injectable({
  providedIn: 'root'
})
export class IapServiceService {

  constructor(private apiService: ApiService) { }

  async todos(): Promise<IAPProduto[]> {
    try {
      let iaps: IAPProduto[];
      this.apiService.request<IAPProduto[]>(`/IAP/Todos`, httpVerb.get).then(result => {
        iaps = this.apiService.verificaSeResult<IAPProduto[]>(result);
      });
      return iaps;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<IAPProduto>> {
    try {
      let paginaAtual = null;
      const complemento = `/IAP?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<IAPProduto>>(complemento, httpVerb.get).then(result => {
        paginaAtual = this.apiService.verificaSeResult<Pagina<IAPProduto>>(result);
      });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterIAPProduto(clienteId: number): Promise<IAPProduto> {
    try {
      let iap = null;
      await this.apiService.request<IAPProduto>(`/IAP/${clienteId}`, httpVerb.get).then(result => {
        iap = this.apiService.verificaSeResult<IAPProduto>(result);
      });
      return iap;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterStrIAP(): Promise<StrIAPProdutos> {
    try {
      let iap = null;
      await this.apiService.request<StrIAPProdutos>(`/IAP/StrIAP`, httpVerb.get).then(result => {
        iap = this.apiService.verificaSeResult<StrIAPProdutos>(result);
      });
      return iap;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterArquivosIAP(): Promise<string[]> {
    try {
      let arquivos = null;
      await this.apiService.request<string[]>(`/Parametros/IAPs/Diretorio`, httpVerb.get).then(result => {
        arquivos = this.apiService.verificaSeResult<string[]>(result);
      });
      return arquivos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async validarIAPs(arquivo: string): Promise<ValidationResult> {
    try {
      let vr = null;
      await this.apiService.request<ValidationResult>(`/Parametros/IAPs/Validar`, httpVerb.post, arquivo).then(result => {
        vr = this.apiService.verificaSeResult<ValidationResult>(result);
      });
      return vr;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async updateIAPs(arquivo: string): Promise<boolean> {
    try {
      let executou = false;
      await this.apiService.request<ValidationResult>(`/Parametros/IAPs/Validar`, httpVerb.post, arquivo).then(result => {
        executou = this.apiService.verificaSeResult<boolean>(result);
      });
      return executou;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
