import { MensagemService } from './../../services/mensagem/mensagem.service';
import { MonitorService } from './../../services/monitor/monitor.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/entidades/usuario';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ApiService } from './../../services/api/api.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { ControleVersaoService } from 'src/app/services/controle-versao/controle-versao.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['../../app.component.css', './nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  public usuario: Usuario;
  public exibirNavBar: boolean;
  public loggedIn: boolean;
  public ambiente: string;
  notificar: boolean;

  constructor(public apiService: ApiService,
    private loginService: LoginService,
    private router: Router,
    private monitorService: MonitorService,
    private mensagemService: MensagemService,
    private usuarioService: UsuarioService,
    private appConfigService: AppConfigService,
    private controleVersaoService: ControleVersaoService) { }

  async ngOnInit(): Promise<void> {
    this.loginService.exibirNavBarEmitter.subscribe(
      async exibir => {
        if (this.apiService.UsuarioLogado === null || this.apiService.UsuarioLogado === undefined) return;
        const arrayNome = this.apiService.UsuarioLogado.nome.split(' ');
        this.usuario = this.apiService.UsuarioLogado;
        if (this.usuario.emailConfirmado) {
          if (arrayNome.length > 1) {
            this.usuario.nomeExibicao = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;
          } else {
            this.usuario.nomeExibicao = this.usuario.nomeUsuario;
          }
          this.usuario.nomeExibicao = this.usuario.nomeExibicao.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });

          this.exibirNavBar = exibir,
            this.loggedIn = this.usuario !== null && this.usuario !== undefined;
        }
        this.obterAmbiente();
        if (exibir) {
          /* Verificar se há dados a mostrar */
          await this.monitorService.obterDados().then(res => {
            this.notificar = res !== null && res !== undefined;
          });
        }
      }
    );



    this.loginService.logOutEmitter.subscribe(
      (logout: boolean) => {
        this.loggedIn = !logout;
        this.router.navigate(['/login']);
      }
    );
  }

  async obterAmbiente(): Promise<void> {
    this.apiService.obterAmbiente().then(res => {
      this.ambiente = res === 'hom' ? 'Homologação' : '';
    });
  }

  async logOut(): Promise<void> {
    await this.usuarioService.logOff().then(async res => {
      if (res) {
        await this.loginService.logOut();
      } else {
        this.mensagemService.showMessage('Erro ao gravar saída do Usuário. Contate o suporte!', 'Ok', 3000, 'error');
      }
    });
  }

  openSidenav(): void {
    this.appConfigService.sidNavClickEmitter.emit(true);
  }

  exibirMonitor(): void {
    this.appConfigService.monitorDadosEmitter.emit(true);
  }

  getVersao() {
    if (this.apiService.VersaoSite === undefined) {
      this.apiService.getVersaoSite();
    }
    return `v${this.apiService.VersaoSite}`;
  }
}
