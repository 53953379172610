import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/entidades/dialogData';

@Component({
  selector: 'app-dialogo',
  templateUrl: './dialogo.component.html',
  styleUrls: ['./dialogo.component.css']
})
export class DialogoComponent {

  constructor(public dialog: MatDialogRef<DialogoComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { } 

  onNoClick(): void {
    this.dialog.close();
  }
  
  onYesClick(): string {
    return 'yes';
  }
}
