<div class="box-atividades margin-nav mat-elevation-z8" *ngIf="podeExibir">
  <div class="titulo">Avisos Importantes!</div>
  <div class="container-avisos" *ngIf="avisos !== null && avisos !== undefined">
    <div class="row" *ngFor="let aviso of avisos">
      <div class="card-aviso mat-elevation-z8">
        <div class="row">
          <mat-icon class="yellow">campaign</mat-icon> Aviso nº: <strong>{{aviso.id}}</strong>
        </div>
        <br>
        <div [ngClass]="aviso.prioridade === 1 ? 'prioridade-alta' :  aviso.prioridade === 2 ? 'prioridade-media' : 'prioridade-baixa'">
          <strong>{{ aviso.descricao }}</strong>
        </div>
        <div class="item-right">
          <mat-checkbox *ngIf="aviso.confirmarLeitura" (click)="confirmarLeitura(aviso.id)">Marcar como lido</mat-checkbox>
          <mat-icon *ngIf="!aviso.confirmarLeitura">closed</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="item-right">
    <button
      mat-button
      class="btn-fit-green-outline"
      (click)="podeExibir = false"
    >
      Ok
    </button>
  </div>
</div>
