import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { HomeComponent } from './home.component';
import { DataFormModule } from "../../shared/data-form.module";

@NgModule({
  declarations: [HomeComponent],
  imports: [
    SharedModule,
    DataFormModule
  ]
})
export class HomeModule { }
