import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MonitorDto } from 'src/app/dto/monitorDto';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { TipoStatus } from 'src/app/enum/tipoStatus';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {
  monitor: MonitorDto;
  exibirMonitor: boolean;
  estouCiente: boolean;
  infoCotacoes: string;
  infoEndossos: string;

  constructor(private appConfigService: AppConfigService,
    private monitorService: MonitorService) { }

  ngOnInit(): void {
    this.estouCiente = true;
    this.appConfigService.monitorDadosEmitter.subscribe(async (yes) => {
      this.estouCiente = false;
      if (yes) {
        await this.monitorService.obterDados().then(res => {
          this.monitor = res;
          this.obterInfo(false);
          this.obterInfo(true);
        });
      }
      else {
        this.exibirMonitor = yes;
        this.estouCiente = true;
      }
    });
  }

  obterInfo(endossos: boolean): void {
    let info = '';
    if (endossos) {
      info = 'Encontrado(s)';
    } else {
      info = 'Encontrada(s)';
    }
    let qtdeAguardandoBoleto = endossos ? this.monitor?.monitorEndossos.filter(x => x.tipoStatus === TipoStatus.Aguardando_Boleto).length : this.monitor?.monitorCotacoes.filter(x => x.tipoStatus === TipoStatus.Aguardando_Boleto).length;
    let qtdeAguardandoRetorno = endossos ? this.monitor?.monitorEndossos.filter(x => x.tipoStatus === TipoStatus.Aguardando_Retorno).length : this.monitor?.monitorCotacoes.filter(x => x.tipoStatus === TipoStatus.Aguardando_Retorno).length;
    let qtdeAguardandoVistoria = endossos ? this.monitor?.monitorEndossos.filter(x => x.tipoStatus === TipoStatus.Aguardando_Vistoria).length : this.monitor?.monitorCotacoes.filter(x => x.tipoStatus === TipoStatus.Aguardando_Vistoria).length;
    let item = endossos ? "Endosso(s)" : "Cotação(ões)";
    if (qtdeAguardandoBoleto > 0) info += ` ${qtdeAguardandoBoleto} ${item} Aguardando Boleto`;
    if (qtdeAguardandoRetorno > 0) info += ` ${qtdeAguardandoRetorno} ${item} Aguardando Retorno`;
    if (qtdeAguardandoVistoria > 0) info += ` ${qtdeAguardandoVistoria} ${item} Aguardando Vistoria`;
    if (endossos) this.infoEndossos = `${info}.`;
    else this.infoCotacoes = `${info}.`;
    if (this.infoCotacoes === 'Encontrada(s).') this.infoCotacoes = '';
    if (this.infoEndossos === 'Encontrado(s).') this.infoEndossos = '';
  }
}
