import { EventEmitter, Injectable } from '@angular/core';
import { Periodo } from 'src/app/entidades/periodo';
import { TabCotacao } from 'src/app/enum/tabCotacao';
import { AppConfigService } from '../app/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class FiltroService {
  exibirDescricaoFiltroEmitter = new EventEmitter<boolean>();
  exibirFiltroDataEmitter = new EventEmitter<boolean>();
  verificaSeEstaFiltrandoEmitter = new EventEmitter<number>();
  filtroAtivoEmitter = new EventEmitter<string>();
  filtrarPorDescricaoEmitter = new EventEmitter<any>();
  filtrarPorDataEmitter = new EventEmitter<Periodo>();

  //Gets
  getExibirDescricaoFiltroEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  getExibirFiltroDataEmitter(): EventEmitter<boolean> {
    return new EventEmitter<boolean>();
  }

  getFiltrarPorDescricaoEmitter() {
    return new EventEmitter<any>();
  }

  getFiltrarPorDataEmitter() {
    return new EventEmitter<Periodo>();
  }

  getPesquisaFiltroEmitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getFiltroAtivo1Emiiter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getFiltrarPorDescricao1Emitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getFiltrarPorData1Emitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  getFiltroAtivo2Emiiter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getFiltrarPorDescricao2Emitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getFiltrarPorData2Emitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  getFiltroAtivo3Emiiter(): EventEmitter<string> {
    return new EventEmitter<string>();
  }

  getFiltrarPorDescricao3Emitter(): EventEmitter<any> {
    return new EventEmitter<any>();
  }

  getFiltrarPorData3Emitter(): EventEmitter<Periodo> {
    return new EventEmitter<Periodo>();
  }

  getVerificaSeEstaFiltrandoEmitter(): EventEmitter<number> {
    return new EventEmitter<number>();
  }

  /* Emitter para Filtrar dentro da Pesquisa */
  pesquisaFiltroEmitter = new EventEmitter<any>();

  /* Eventos para as tabelas */
  filtroAtivo1Emiiter = new EventEmitter<string>();
  filtrarPorDescricao1Emitter = new EventEmitter<any>();
  filtrarPorData1Emitter = new EventEmitter<Periodo>();

  filtroAtivo2Emiiter = new EventEmitter<string>();
  filtrarPorDescricao2Emitter = new EventEmitter<any>();
  filtrarPorData2Emitter = new EventEmitter<Periodo>();

  filtroAtivo3Emiiter = new EventEmitter<string>();
  filtrarPorDescricao3Emitter = new EventEmitter<any>();
  filtrarPorData3Emitter = new EventEmitter<Periodo>();

  filtroSelecionado: number;
  descricao: string;
  dataInicial: Date;
  dataFinal: Date;
  exibirDescricao: boolean;
  exibirFiltroData: boolean;
  tabSelecionada: TabCotacao;
  inicializarEmitters = true;
  init_core: any;
  initCore = null;

  constructor(private appConfigService: AppConfigService) {
    this.appConfigService.limparFiltroEmitter.subscribe(() => {
      let returnTo = sessionStorage.getItem('_returnTo');
      let tab = sessionStorage.getItem('tab');
      if (tab === returnTo) {
        sessionStorage.removeItem('_returnTo');
        return;
      }
      sessionStorage.setItem('_filtrando', "false");
      sessionStorage.removeItem('_fsel');
      sessionStorage.removeItem('_fdesc');
      sessionStorage.removeItem(`_fa${tab}`);
      sessionStorage.removeItem('_last_id');
      sessionStorage.removeItem('_sdesc');
      sessionStorage.removeItem('_pagina');
      sessionStorage.removeItem('_fdata');
      sessionStorage.removeItem('_fe');
      this.filtroSelecionado = 0;
      this.descricao = undefined;
    });
    this.verificaSeEstaFiltrandoEmitter.subscribe((idTab: number) => {
      switch (idTab) {
        case 1:
          this.filtroAtivoEmitter = this.filtroAtivo2Emiiter;
          this.filtrarPorDescricaoEmitter = this.filtrarPorDescricao2Emitter;
          this.filtrarPorDataEmitter = this.filtrarPorData2Emitter;
          break;
        case 2:
          this.filtroAtivoEmitter = this.filtroAtivo3Emiiter;
          this.filtrarPorDescricaoEmitter = this.filtrarPorDescricao3Emitter;
          this.filtrarPorDataEmitter = this.filtrarPorData3Emitter;
          break;
        default:
          this.filtroAtivoEmitter = this.filtroAtivo1Emiiter;
          this.filtrarPorDescricaoEmitter = this.filtrarPorDescricao1Emitter;
          this.filtrarPorDataEmitter = this.filtrarPorData1Emitter;
          break;
      }
      //console.log(`--------------------| Verificando se tem filtro na aba ${idTab === 0 ? 'Cotações' : idTab === 1 ? 'Cotações Fururas' : 'Endossos'}  |---------------`);
      sessionStorage.removeItem('_last_id');
      let filtrando = sessionStorage.getItem('_filtrando') === 'true';
      let filtro = sessionStorage.getItem('_fdesc');
      let filtroAtual = Number(sessionStorage.getItem('_fsel'));
      let filtroAtivo = sessionStorage.getItem(`_fa${idTab}`);
      let fdata = JSON.parse(sessionStorage.getItem('_fdata'));
      if (filtroAtivo !== null && filtroAtivo !== undefined) {
        this.filtroAtivoEmitter.emit(filtroAtivo);
        return;
      }
      else if (filtrando && (fdata !== null && fdata !== undefined)) {
        this.dataInicial = fdata.dataInicial;
        this.dataFinal = fdata.dataFinal;
        this.filtrarPorDataEmitter.emit({ dataInicial: this.dataInicial, dataFinal: this.dataFinal });
        return;
      } else if (filtroAtual !== null && filtroAtual !== undefined && filtro !== null && filtro !== 'undefined' && filtrando !== null && filtrando !== undefined) {
        if (filtrando) {
          this.exibirDescricao = true;
          this.filtroSelecionado = filtroAtual;
          this.optionChanged();
          this.descricao = filtro;
        }
      } else {
        this.filtroSelecionado = 0;
      }
      this.filtrar();
    });
    this.initCore = sessionStorage.getItem('init_core');
   }

   filtrar(): void {
    sessionStorage.setItem('_fsel', this.filtroSelecionado?.toString());
    if (this.descricao !== null && this.descricao !== undefined) sessionStorage.setItem('_fdesc', this.descricao);
    let paginator = sessionStorage.getItem('_pagina');
    if (this.exibirDescricao && !this.exibirFiltroData) {
      this.filtrarPorDescricaoEmitter.emit([this.descricao, this.filtroSelecionado, paginator] as any);
      sessionStorage.setItem("_fdesc", this.descricao);
    } else if (!this.exibirDescricao && this.exibirFiltroData) {
      const periodo = { dataInicial: this.dataInicial, dataFinal: this.dataFinal };
      sessionStorage.setItem("_fdata", JSON.stringify(periodo));
      this.filtrarPorDataEmitter.emit(periodo);
    } else {
      this.filtrarPorDescricaoEmitter.emit([this.descricao, this.filtroSelecionado, paginator] as any)
    }
  }

  optionChanged(): void {
    let tab = sessionStorage.getItem('tab');
    this.filtrarPorDescricaoEmitter.emit(this.filtroSelecionado);
    sessionStorage.getItem(`_fa${tab}`);
    sessionStorage.setItem("_fsel", this.filtroSelecionado.toString());
    sessionStorage.setItem("_filtrando", this.filtroSelecionado > 0 ? "true" : "false");
    sessionStorage.removeItem(`_fa${tab}`)
    this.descricao = null;
    if (!this.exibirFiltroData) {
      this.dataInicial = null;
      this.dataFinal = null;
    }
  }
}
