<div
  #monitor
  class="box-monitor"
  *ngIf="!estouCiente && (infoCotacoes || infoEndossos)"
>
  <div>
    <mat-icon>notifications</mat-icon>
    <div><strong> Atenção!</strong></div>
    <div>{{ infoCotacoes }}</div>
    <div>{{ infoEndossos }}</div>
  </div>
  <div class="ciente">
    <mat-checkbox [(ngModel)]="estouCiente">Estou ciente!</mat-checkbox>
  </div>
</div>
