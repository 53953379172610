import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  regexpNumber = ['/', '.', '-'];
  constructor() { }

  ehNulo(obj: any): boolean {
    return obj === null || obj === undefined;
  }

  soNumeros(obj: any): string {
    let result = obj;
    this.regexpNumber.forEach(element => {
      while (result.includes(element)) {
        result = result.replace(element, '');
      }
    });
    return result;
  }

  getStrTimeFromDate(data: Date, format: string): string {
    let hora = String(new Date(data).getHours()-3).padStart(2, '0');
    let minutos = String(new Date(data).getMinutes()).padStart(2, '0');
    let segundos = String(new Date(data).getSeconds()).padStart(2, '0');
    if (format = 'hh:mm:ss') {
      return `${hora}:${minutos}:${segundos}`;
    } else {
      return `${hora}:${minutos}`;
    }
  }
}
