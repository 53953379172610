import { Injectable } from '@angular/core';
import { PeriodoDto } from 'src/app/dto/periodoDto';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  constructor(private apiService: ApiService) { }
  urlRelatorio: string;

  async obterRelatorioPorPeriodo(periodo: PeriodoDto, link: string, params: any = undefined): Promise<Blob> {
    try {
      let retorno = null as any;
      this.urlRelatorio = link;
      if (params !== undefined) {
        this.urlRelatorio = this.urlRelatorio.replace('{param}', params);
      }
      await this.apiService.request<Blob>(this.urlRelatorio, httpVerb.post, periodo, true)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
      return null as any;
    }
  }

  async obterRelatorio(link: string, params: any = undefined): Promise<Blob> {
    try {
      let retorno = null as any;
      this.urlRelatorio = link;
      if (params !== undefined) {
        this.urlRelatorio = this.urlRelatorio.replace('{param}', params);
      }
      await this.apiService.request<Blob>(this.urlRelatorio, httpVerb.get, null, true)
        .then(result => {
          if (result !== null && result !== undefined) {
            retorno = result;
          }
        });
      return retorno;
    } catch (error) {
      console.log(error);
      return null as any;
    }
  }
}
