<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="titulo"></app-titulo>
  <br />
  <div class="item-center">
    <div>
      <h2 class="item-left">IAP (.csv)</h2>
      <div class="infoMini item-left">Selecione o arquivo para validar:</div>
      <div class="dropListContainer">
        <div class="dropList">
          <div
            class="dropBox"
            *ngFor="let item of arquivos"
            (click)="validarArquivo(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</div>
