import { Component, OnInit } from '@angular/core';
import { PeriodoDto } from 'src/app/dto/periodoDto';
import { TopPrioridadesDto } from 'src/app/dto/topPrioridadesDto';
import { ApiService } from 'src/app/services/api/api.service';
import { CotacaoService } from 'src/app/services/cotacao/cotacao.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-top-prioridades',
  templateUrl: './top-prioridades.component.html',
  styleUrls: ['./top-prioridades.component.css', '../../app.component.css']
})
export class TopPrioridadesComponent implements OnInit {
  topPrioridades: TopPrioridadesDto[];
  itemSelecionado: TopPrioridadesDto;
  colunasGrid: string[] = ['atendente', 'prioridadeAlta', 'prioridadeMedia', 'prioridadeBaixa', 'total'];
  dsPrioridades: MatTableDataSource<TopPrioridadesDto>;

  constructor(private apiService: ApiService,
    private cotacaoService: CotacaoService) { }

  ngOnInit(): void {
    this.apiService.topPrioridadesEmitter.subscribe(async (periodo) => {
      await this.obterPrioridades(periodo);
    });
  }

  async obterPrioridades(periodo: PeriodoDto): Promise<void> {
    /* Top Prioridades */
    await this.cotacaoService.topPrioridades(periodo).then(tp => {
      this.topPrioridades = this.apiService.verificaSeResult<TopPrioridadesDto[]>(tp);
      this.dsPrioridades = new MatTableDataSource(this.topPrioridades);
    });
  }

  rowClick(row: any): void {
    this.itemSelecionado = row;
  }
}
