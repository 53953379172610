export enum TipoStatus {
  Geral = 1,
  Desistencia = 2,
  Fechamento = 3,
  Aguardando_Boleto = 4,
  Solicitando_Fechamento = 5,
  Cancelamento = 6,
  Cotacao_Futura = 7,
  Aguardando_Retorno = 8,
  Aguardando_Vistoria = 9,
  Finalizacao = 10,
  Assistencia = 11,
  Conferencia = 12,
  Recusado = 13,
  Cotando_Interno = 14,
  Acompanhamento = 15,
  Sem_Aceitação = 16,
  Sinistro = 17
}
