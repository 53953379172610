<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="titulo"></app-titulo>
  <form class="formContainer" [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Nome do Cooperado</mat-label>
        <div class="busca">
          <input
            [readonly]="isReadOnly()"
            matInput
            id="inputCooperado"
            formControlName="nomeCliente"
            (keyup)="searchCooperado($event.target.value)"
          />
          <mat-icon (click)="pesquisaCooperado()">search</mat-icon>
        </div>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>CPF / CNPJ</mat-label>
        <input
          matInput
          #inputCPF
          type="text"
          formControlName="cpfCNPJ"
          [mask]="mascaraCNPJCPF"
          [readonly]="isReadOnly()"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>e-mail</mat-label>
        <input
          matInput
          #inputEmail
          formControlName="email"
          [readonly]="isReadOnly()"
        />
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Telefone</mat-label>
        <input
          matInput
          #inputTelefone
          type="tel"
          [mask]="'(00) 00000-0000'"
          formControlName="telefone"
          maxlength="15"
          [readonly]="isReadOnly()"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Código PA</mat-label>
        <mat-select formControlName="agencia">
          <mat-option
            [disabled]="isReadOnly()"
            *ngFor="let agencia of agencias"
            value="{{ agencia?.codPA }}"
            >{{ agencia?.codPA }} - {{ agencia?.cidade }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Produto</mat-label>
        <mat-select formControlName="produtoId">
          <mat-option
            [disabled]="isReadOnly()"
            *ngFor="let produto of produtos"
            [value]="produto.id"
            >{{ produto.descricao }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Conta</mat-label>
        <input
          matInput
          #inputConta
          formControlName="conta"
          [readonly]="isReadOnly()"
        />
      </mat-form-field>
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Quantidade</mat-label>
        <input
          matInput
          #inputQuantidade
          type="number"
          formControlName="qtdeCupons"
          (change)="atualizaValor()"
          [readonly]="isReadOnly()"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Colaborador</mat-label>
        <mat-select formControlName="colaborador">
          <mat-option
            [disabled]="isReadOnly()"
            *ngFor="let usuario of usuarios"
            [value]="usuario"
            >{{ usuario.nome }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-accordion
        class="headers-align mat-elevation-z8"
        *ngIf="contasDoCooperado?.length >= 1"
      >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Contas Bancárias </mat-panel-title>
            <mat-panel-description>
              Contas ativas do Cooperado
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngFor="let conta of contasDoCooperado" class="cupom-contas">
            <label>Número: {{ conta?.numeroConta }}</label>
            <label>PA: {{ conta?.codPA }}</label>
            <label>Modalidade: {{ conta?.modalidade }}</label>
            <label>Tipo: {{ conta?.tipo }}</label>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="row">
      <mat-form-field class="grow1" appearance="outline">
        <mat-label>Valor ($) </mat-label>
        <input
          currencyMask
          matInput
          readonly
          #inputValor
          formControlName="valorPago"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow1">
        <mat-label>Forma de Pagamento</mat-label>
        <mat-select
          #tipoPagamento
          formControlName="tipoPagamento"
          (ngModelChange)="tipoPagamentoChange($event)"
        >
          <mat-option
            *ngFor="let tipo of tiposPagamento"
            [value]="tipo.id"
            [disabled]="isReadOnly()"
            >{{ tipo.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="row" *ngIf="pagamentoParcelado">
        <mat-form-field class="grow1 right" appearance="outline">
          <mat-label>Parcelas</mat-label>
          <input
            matInput
            #inputParcelas
            type="number"
            formControlName="parcelas"
            (change)="verificarParcela()"
            [readonly]="isReadOnly()"
          />
        </mat-form-field>
        <mat-form-field class="grow1" appearance="outline">
          <mat-label>Valor da Parcela ($) </mat-label>
          <input
            currencyMask
            matInput
            #inputValorParcela
            formControlName="valorParcela"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            (change)="verificarParcela()"
            [readonly]="isReadOnly()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="grow1">
          <mat-label>Data do Débito </mat-label>
          <input
            matInput
            formControlName="dataDebito"
            [matDatepicker]="picker"
            (keyup)="appConfigService.onKeyUpEventData($event)"
            [readonly]="isReadOnly()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            [disabled]="isReadOnly()"
          >
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="item-right">
      <button
        mat-button
        class="btnOK mat-elevation-z8"
        type="button"
        [disabled]="
          dadosAdesao === null ||
          dadosAdesao === undefined ||
          formulario.status == 'INVALID'
        "
        (click)="imprimirTermo()"
      >
        Imprimir
      </button>
      <button
        mat-button
        class="btnOK mat-elevation-z8"
        [disabled]="formulario.status == 'INVALID' || formulario.pristine"
        type="submit"
      >
        Salvar
      </button>
      <button
        mat-button
        class="btnCancel mat-elevation-z8"
        type="button"
        (click)="cancelar()"
      >
        Cancelar
      </button>
    </div>
  </form>
</div>
