import { ApiToken } from './../../entidades/token';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { addDays, getDate } from 'date-fns';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { numbers } from '@material/menu';
import { Credenciais } from 'src/app/entidades/credenciais';
import { Parametros } from 'src/app/entidades/parametro';
import { Usuario } from 'src/app/entidades/usuario';
import { httpVerb } from 'src/app/enum/httpVerb';
import { AppConfigService } from '../app/app-config.service';
import { ApiService } from './../api/api.service';
import { ControleVersaoService } from '../controle-versao/controle-versao.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private usuarioAutenticado = false;
  private naoAutorizado = false;

  exibirNavBarEmitter = new EventEmitter<boolean>();
  isBusyEmitter = new EventEmitter<boolean>();
  usuarioLogadoEmitter = new EventEmitter<Usuario>();
  confirmarEmailEmitter = new EventEmitter<boolean>();
  usuarioInativoEmitter = new EventEmitter<boolean>();
  logOutEmitter = new EventEmitter<boolean>();

  constructor(private router: Router,
    private apiService: ApiService,
    private appConfigService: AppConfigService,
    private usuarioService: UsuarioService,
    private controleVersaoService: ControleVersaoService) { }

  async efetuarLogin(credenciais: Credenciais): Promise<boolean> {
    try {
      await this.apiService.getToken(credenciais);
      if (this.apiService.Token === null || this.apiService.Token === undefined) {
        this.naoAutorizado = true;
        this.usuarioAutenticado = false;
        this.exibirNavBarEmitter.emit(false);
        this.isBusyEmitter.emit(false);
      }
      else {
        await this.obterUsuarioLogado(credenciais);
        await this.obterParametros();
        if (!this.apiService.UsuarioLogado.emailConfirmado) {
          this.usuarioAutenticado = false;
          this.confirmarEmailEmitter.emit(true);
        }
        else if (this.apiService.UsuarioLogado.inativo) {
          this.usuarioAutenticado = false;
          this.usuarioInativoEmitter.emit(true);
        }
        else if (this.apiService.UsuarioLogado.senhaProvisoria) {
          this.usuarioAutenticado = true;
          this.naoAutorizado = false;
          this.exibirNavBarEmitter.emit(true);
          this.isBusyEmitter.emit(false);
          this.router.navigate([`/usuario/edit/senha/${this.apiService.UsuarioLogado.id}`]);
          this.apiService.Credenciais = credenciais;
        }
        else {
          this.usuarioAutenticado = true;
          this.naoAutorizado = false;
          this.appConfigService.atualizarSiteEmiiter.emit(this.apiService.VersaoSite);
          this.exibirNavBarEmitter.emit(true);
          this.isBusyEmitter.emit(false);
          this.router.navigate(['/']);
          this.apiService.Credenciais = credenciais;
        }
      }
      this.isBusyEmitter.emit(false);
      return this.naoAutorizado;
    } catch (error) {
      this.naoAutorizado = true;
      this.isBusyEmitter.emit(false);
      return this.naoAutorizado;
    }
    finally {
      this.setSessionsVariables(credenciais);
    }
  }

  setSessionsVariables(credenciais: Credenciais) {
    let codigoAutorizadao = this.naoAutorizado ? (Number(new Date().getDate()) - 31).toExponential() : (Number(new Date().getDate()) - 61).toExponential();
    let codigoAutehenticacao = !this.usuarioAutenticado ? (Number(new Date().getDate()) + 31).toExponential() : (Number(new Date().getDate()) + 61).toExponential();
    sessionStorage.setItem("AuthorizedKey", codigoAutorizadao);
    sessionStorage.setItem("AuthenticateKey", codigoAutehenticacao);
    sessionStorage.setItem("Usuário", credenciais.nomeusuario);
  }

  get codigoAutorizacao(): string {
    return (Number(new Date().getDate()) - 61).toExponential();
  }

  get codigoAutenticacao(): string {
    return (Number(new Date().getDate()) + 61).toExponential();
  }

  async logOut(): Promise<any> {
    try {
      this.appConfigService.menuClearEmitter.emit(true);
      this.appConfigService.pararTimerEmitter.emit(true);
      this.appConfigService.sidNavClickEmitter.emit(false);
      this.naoAutorizado = true;
      this.usuarioAutenticado = false;
      this.exibirNavBarEmitter.emit(false);
      this.isBusyEmitter.emit(false);
      this.logOutEmitter.emit(true);
      //localStorage.clear();
      sessionStorage.clear();
      this.appConfigService.monitorDadosEmitter.emit(false);
      this.appConfigService.minhasAtividadesEmitter.emit(false);
    } catch (error) {
      console.log(error);
    }
  }

  getSessionVariables(key: string): string {
    return sessionStorage.getItem(key);
  }

  usuarioEstaAutenticado(): boolean {

    return this.usuarioAutenticado;
  }

  // public setUsuarioAutenticado(value: boolean): void {
  //   this.usuarioAutenticado = value;
  // }

  async obterUsuarioLogado(credenciais: Credenciais): Promise<void> {
    try {
      await this.apiService.request<Usuario>('/usuario/nome/' + credenciais.nomeusuario,
        httpVerb.get).then(async result => {
          const usuario = result;
          this.apiService.UsuarioLogado = usuario;
          this.usuarioLogadoEmitter.emit(this.apiService.UsuarioLogado);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async obterParametros(): Promise<void> {
    try {
      await this.apiService.request<Parametros>('/Parametros/Todos', httpVerb.get).then(result => {
        this.apiService.Parametros = this.apiService.verificaSeResult<Parametros>(result)[0] as Parametros;
      });
    } catch (error) {
      console.log(error);
    }
  }
}
