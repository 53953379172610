<div class="box-atividades margin-nav mat-elevation-z8"
    *ngIf="podeExibir">
    <div>
        <div class="bemVindo center">
            Olá <b> <br>{{ usuario?.nomeExibicao }}</b>
            <br>
        </div>
    </div>
    <div *ngIf="minhasAtividades?.length > 0">
        <div class="atividades">
            Você possui ...
        </div>
        <div class="minhas-atividades atividades" [ngStyle]="{'margin-top': '10px'}">
            <div *ngFor="let atv of minhasAtividades">
                {{atv.quantidade}} {{cotacaoOuEndosso(atv)}} - <b>{{atv.statusDescricao | lowercase}}</b>
            </div>
        </div>
    </div>
    <div *ngIf="minhasAtividades?.length === 0">
        <div class="atividades">
            <br>
            <div class="emoji">;)</div>
            <div class="center">Sem atividades no momento!</div>
        </div>
    </div>
    <br>
    <div class="atividades item-right">
        Bons negócios!
    </div>
    <div class="item-right">
        <button mat-button class="btn-fit-green-outline" (click)="podeExibir = false">Ok
        </button>
    </div>
</div>
