<div class="container margin-nav mat-elevation-z8">
  <app-titulo [titulo]="'Alteração de Senha do usuário'"></app-titulo>

  <form class="formContainer" [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div class="outline-box">
      <div class="row">
        <mat-form-field class="grow1" appearance="outline">
          <mat-label>Login</mat-label>
          <input matInput #inputLogin formControlName="nomeUsuario" />
        </mat-form-field>
        <mat-form-field class="grow1" appearance="outline">
          <mat-label>Senha Provisória</mat-label>
          <input
            type="password"
            matInput
            #inputSenhaAntiga
            formControlName="senhaAntiga"
          />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="grow1" appearance="outline">
          <mat-label>Nova Senha</mat-label>
          <input
            type="password"
            matInput
            #inputNovaSenha
            formControlName="senha"
          />
        </mat-form-field>
        <mat-form-field class="grow1" appearance="outline">
          <mat-label>Confirmação</mat-label>
          <input
            type="password"
            matInput
            #inputConfirmar
            formControlName="confirmacao"
          />
          <mat-error>Nova senha não confere!</mat-error>
        </mat-form-field>
      </div>
      <div class="item-right">
        <button
          mat-button
          class="btnOK mat-elevation-z8"
          [disabled]="formulario.status == 'INVALID' || formulario.pristine"
          type="submit"
        >
          Alterar
        </button>
        <button
          mat-button
          class="btnCancel mat-elevation-z8"
          type="button"
          (click)="cancelar()"
        >
          Cancelar
        </button>
      </div>
    </div>
  </form>
</div>
