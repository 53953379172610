import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MensagemService {

  constructor(private snackBar: MatSnackBar) { }

  showMessage(message: string, btnAction: string, duration: number, className: string): void {
    this.snackBar.open(message, btnAction, {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration,
      panelClass: [className]
    });
  }
}
