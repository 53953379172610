import { Injectable, EventEmitter } from '@angular/core';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { Produto } from 'src/app/entidades/produto';
import { httpVerb } from 'src/app/enum/httpVerb';
import { ApiService } from '../api/api.service';
import { MensagemService } from '../mensagem/mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {
  filtroProdutosEmitter = new EventEmitter<number>();
  descricaoFiltroEmitter = new EventEmitter<string>();

  constructor(private apiService: ApiService) { }

  async todos(): Promise<Produto[]> {
    try {
      let produtos: Produto[];
      await this.apiService.request<Produto[]>(`/Produto/Todos`, httpVerb.get).then(result => {
        if (result !== null && result !== undefined) {
          produtos = result as Produto[];
        }
      });
      return produtos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async adicionarOuEditar(novoProduto: Produto): Promise<Produto> {
    try {
      let produto = null;
      await this.apiService.request<Produto>('/Produto', httpVerb.post, novoProduto)
        .then(result => {
          if (result !== null && result !== undefined) {
            produto = (result as Produto);
          }
        });
      return produto;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<Produto>> {
    try {
      let paginaAtual = null;
      const complemento = `/produto?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Produto>>(complemento, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Produto>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterProduto(id: number): Promise<Produto> {
    try {
      let produto = null;
      await this.apiService.request<Produto>(`/produto/${id}`, httpVerb.get)
        .then(result => {
          if (result !== null && result !== undefined) {
            produto = (result as Produto);
          }
        });
      return produto;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async filtrar(filtro: number, dto: FiltroDTO, paginator: Paginator): Promise<Pagina<Produto>> {
    try {
      let paginaAtual = null;
      const complemento = `/Produto/filtrar/${filtro}?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<Produto>>(complemento, httpVerb.post, dto)
        .then(result => {
          if (result !== null && result !== undefined) {
            paginaAtual = (result as Pagina<Produto>);
          }
        });

      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async delete(id: number): Promise<boolean> {
    try {
      let excluiu = null;
      await this.apiService.request<boolean>(`/produto/${id}`, httpVerb.delete)
        .then(result => {
          if (result !== null && result !== undefined) {
            excluiu = (result as boolean);
          }
        });
      return excluiu;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
