import { TermoAdesaoComponent } from './../termo-adesao/termo-adesao.component';
import { CampanhaDetalheComponent } from './campanha-detalhe/campanha-detalhe.component';
import { SorteioComponent } from './../sorteio/sorteio.component';
import { NovoCupomComponent } from './novo-cupom/novo-cupom.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/app/guard/login.guard';
import { CampanhaComponent } from './campanha.component';
import { PosicaoCampanhaComponent } from './posicao-campanha/posicao-campanha.component';
import { ParcelamentoCupomComponent } from '../parcelamento-cupom/parcelamento-cupom.component';

const campanhaRoutes: Routes = [
  {
    path: '', component: CampanhaComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard],
    pathMatch: 'full'
  },
  {
    path: 'Campanhas/nova', component: CampanhaDetalheComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Campanhas/:id', component: CampanhaDetalheComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Campanhas', component: CampanhaComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'vigentes', component: CampanhaComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'NovoCupom/:id', component: NovoCupomComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Posicao/:id', component: PosicaoCampanhaComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Sorteio/:id', component: SorteioComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Campanha/Termo/:id/:vigentes', component: TermoAdesaoComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'Campanha/Parcelamentos/:id', component: ParcelamentoCupomComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  }
]

@NgModule({
  imports: [RouterModule.forChild(campanhaRoutes)],
  exports: [RouterModule]
})
export class CampanhaRoutingModule { }
