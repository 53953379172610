import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { LoginService } from './services/login/login.service';
import { MensagemService } from './services/mensagem/mensagem.service';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { ViewEncapsulation } from '@angular/core';
import { ViewportScroller, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ControleVersaoService } from './services/controle-versao/controle-versao.service';
registerLocaleData(localeFr, 'BRL');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnDestroy {
  title = 'credipontal-negocios';
  localId: any;
  @ViewChild('scroll') scroll: ElementRef;

  constructor(private appConfigService: AppConfigService,
    private usuarioService: UsuarioService,
    private mensagemService: MensagemService,
    private loginService: LoginService,
    private controleVersaoService: ControleVersaoService) {}

  ngOnDestroy(): void {}

  async ngOnInit(): Promise<void> {
    await this.loadConfig();
    let context = this;
    window.addEventListener("beforeunload", function (e) {
      context.logoutOnClose();
    });
    sessionStorage.setItem('init_core', 'ce2befac-8caa-49a7-9757-4d29feaedc48');
    this.appConfigService.limparFiltroEmitter = this.appConfigService.getLimparFiltroEmitter();
    this.appConfigService.limparFiltroEmitter.subscribe(() => {
      let returnTo = sessionStorage.getItem('_returnTo');
      let tab = sessionStorage.getItem('tab');
      if (tab === returnTo) {
        sessionStorage.removeItem('_returnTo');
        //return;
      }
      sessionStorage.setItem('_filtrando', "false");
      sessionStorage.removeItem('_fsel');
      sessionStorage.removeItem('_fdesc');
      sessionStorage.removeItem(`_fa${tab}`);
      sessionStorage.removeItem('_last_id');
      sessionStorage.removeItem('_sdesc');
      sessionStorage.removeItem('_pagina');
      sessionStorage.removeItem('_fdata');
      sessionStorage.removeItem('_fe');
    });
  }

  async logoutOnClose(): Promise<void> {
    let chave = sessionStorage.getItem('AuthenticateKey');
    if (chave !== null && chave !== undefined) {
      await this.usuarioService.logOff().then(async res => {
        if (res) {
          await this.loginService.logOut();
        } else {
          this.mensagemService.showMessage('Erro ao gravar saída do Usuário. Contate o suporte!', 'Ok', 3000, 'error');
        }
      });
    }
  }

  async loadConfig(): Promise<void> {
    await this.appConfigService.loadConfig().then(result => {
      sessionStorage.setItem("AppConfig", JSON.stringify(result));
      this.appConfigService.setAppConfig(result);
    });
  }

  async obterVersao(): Promise<number> {
    let versao = undefined;
    await this.appConfigService.obterVersao().then(res => {
      versao = res.version;
    });
    return versao;
  }
}
