import { CotacaoComponent } from './../cotacao/cotacao.component';
import { RealPipe } from 'src/app/pipes/real/real.pipe';
import { TopPrioridadesDto } from './../../dto/topPrioridadesDto';
import { TempoMedioChamadoDto } from './../../entidades/tempoMedioChamado';
import { ItemMinhasCotacoesDto } from './../../dto/itemMinhasCotacoesDto';
import { TopProdutosDto } from './../../dto/topProdutosDto';
import { AgenciaService } from './../../services/agencia/agencia.service';
import { ResumoStatusDashboardDto } from 'src/app/dto/resumoStatusDashboardDto';
import { AppConfigService } from './../../services/app/app-config.service';
import { Component, OnInit, OnDestroy, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/entidades/usuario';
import { ApiService } from 'src/app/services/api/api.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { Pagina } from 'src/app/entidades/pagina';
import { MatTableDataSource } from '@angular/material/table';
import { Paginator } from 'src/app/entidades/paginator';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';
import { CotacaoService } from 'src/app/services/cotacao/cotacao.service';
import { StatusDashboardDto } from 'src/app/dto/statusDashboardDto';
import { PeriodoDto } from 'src/app/dto/periodoDto';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartType } from 'chart.js';
import { MciService } from 'src/app/services/mci/mci.service';
import { ResumoMCIPorPADto } from 'src/app/dto/resumoMCIPorPADto';
import { ResumoMCIPorGerenteDto } from 'src/app/dto/resumoMCIPorGerenteDto';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { ProdutoService } from 'src/app/services/produto/produto.service';
import { DateAdapter } from '@angular/material/core';
import { TopAtendentesDto } from 'src/app/dto/topAtendentesDto';
import { ControleParcelasService } from 'src/app/services/controle-parcelas/controle-parcelas.service';
import { ItemPrimeiraParcelaDto } from 'src/app/dto/itemPrimeiraParcelaDto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogoComponent } from '../dialogo/dialogo/dialogo.component';
import { TipoChamado } from 'src/app/enum/tipoChamado';
import { ItemFechamentosDiariosDto } from 'src/app/dto/itemFechamentosDiariosDto';
import { RelatoriosComponent } from '../relatorios/relatorios.component';
import { TempoMedioDetalhadoComponent } from '../tempo-medio-detalhado/tempo-medio-detalhado.component';
import { ControleVersaoService } from 'src/app/services/controle-versao/controle-versao.service';
import { DeviceUUID } from 'src/assets/js/device-uuid.min.js';
import { AvisoService } from 'src/app/services/aviso/aviso.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../app.component.css']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  pageSizeOptions: number[];
  pageSizeGrafico: number[] = [7, 14, 21, 30, 100];
  locale: string;
  usuario: Usuario;
  isBusy: boolean;
  paginaMinhasCotacoes: Pagina<ItemMinhasCotacoesDto>;
  paginaPrimeiraParcela: Pagina<ItemPrimeiraParcelaDto>;
  minhasCotacoes: ItemMinhasCotacoesDto[];
  primeirasParcelas: ItemPrimeiraParcelaDto[];
  colunasMeusChamados: string[] = ['id', 'data', 'nomeCliente', 'status', 'visualizar'];
  colunasPrimeiraParcela: string[] = ['id', 'vencimento', 'nomeCliente', 'pa', 'valor', 'visualizar'];
  dsTempoMedioChamados: MatTableDataSource<TempoMedioChamadoDto>;
  dsMinhasCotacoes: MatTableDataSource<ItemMinhasCotacoesDto>;
  itemCotacaoSelecionado: ItemMinhasCotacoesDto;
  itemParcelaSelecionado: ItemPrimeiraParcelaDto;
  dsPrimeiraParcela: MatTableDataSource<ItemPrimeiraParcelaDto>;
  qtdeRegistrosMinhasCotacoes: number = 0;
  qtdeRegistrosPrimeiraParcela: number = 0;
  qtdeRegistrosCotacoesDiarias: number = 0;
  permissaoADM: boolean;
  permissaoGerente: boolean;
  permissaoAssessor: boolean;
  tempoMedioChamados: TempoMedioChamadoDto;
  mediaAguardandoRetorno: TempoMedioChamadoDto;
  topPrioridades: TopPrioridadesDto[];
  localId: any;

  myPaginator1: Paginator = {
    length: 0,
    pageIndex: 0,
    pageSize: this.appConfigService.itensPorPagina,
    previousPageIndex: 0
  };
  myPaginator2 = {
    length: 0,
    pageIndex: 0,
    pageSize: this.appConfigService.itensPorPagina,
    previousPageIndex: 0
  };
  myPaginator3 = {
    length: 0,
    pageIndex: 0,
    pageSize: 7,
    previousPageIndex: 0
  };
  todoPeriodo: boolean = false;
  todoPA: boolean = false;
  dataInicial: Date;
  dataFinal: Date;
  statusDashboard: StatusDashboardDto;
  tituloDashboard: string;
  tituloDashboard1: string;
  tituloDashboard2: string;
  ultimaAtualizacao: string;
  stopTimer: boolean = false;;
  barChartLabels1: Label[] = [];
  barChartData1: ChartDataSets[];
  barChartLabels2: Label[] = [];
  barChartData2: ChartDataSets[];
  barChartLabels3: Label[] = [];
  barChartData3: ChartDataSets[];
  barChartLabels4: Label[] = [];
  barChartData4: ChartDataSets[];
  barChartLabels5: Label[] = [];
  barChartData5: ChartDataSets[];

  public barChartType1: ChartType = 'bar';
  public barChartType2: ChartType = 'horizontalBar';
  public barChartType3: ChartType = 'horizontalBar';
  public barChartType4: ChartType = 'horizontalBar';
  public barChartType5: ChartType = 'line';

  public barChartOptions1 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartOptions2 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartOptions3 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartOptions4 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartOptions5 = {
    scaleShowVerticalLines: true,
    responsive: true
  };

  setTimer = () => {
    setTimeout(() => {
      if (this.stopTimer) return;
      this.ultimaAtualizacao = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
      this.todoPeriodo = false;
      this.apiService.IgnoraBusy = true;
      this.setDataPadrao();
      setTimeout(() => {
        this.obterResumos();
      }, 250);
      this.setTimer();
    }, 60000);
  }

  constructor(private dateAdapter: DateAdapter<Date>,
    private apiService: ApiService,
    private mciService: MciService,
    private cotacaoService: CotacaoService,
    private controleParcelasService: ControleParcelasService,
    private usuarioService: UsuarioService,
    private produtoService: ProdutoService,
    public appConfigService: AppConfigService,
    private router: Router,
    private mensagemService: MensagemService,
    private agenciaService: AgenciaService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private paginatorService: PaginatorService,
    private controleVersaoService: ControleVersaoService,
    private realPipe: RealPipe,
    private avisoService: AvisoService) {
    this.locale = 'pt-BR';
    this.dateAdapter.setLocale('pt-BR');
    let urlNavigation = sessionStorage.getItem("urlNavigation");
    if (urlNavigation !== null && urlNavigation !== undefined) {
      sessionStorage.removeItem("urlNavigation");
      this.router.navigate([urlNavigation], { relativeTo: this.route, skipLocationChange: true });
    }
    var uuid = new DeviceUUID().get();
    this.localId = uuid;
  }
  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.pararTimerChanged(true);
  }

  async atualizarSite(versaoSite: string): Promise<void> {
    let versao = null;
    await this.controleVersaoService.obterControleVersao(this.localId).then(v => {
      versao = v;
    });
    let versaoLocal = localStorage.getItem('versao');
    if (versaoLocal !== versaoSite || versaoLocal === 'null') {
      if (versaoLocal === 'null' || versaoSite === 'null') {
        versaoLocal = "0";
        versaoSite = "0";
      }
      await this.controleVersaoService.atualizarVersao(this.localId, versaoSite);
      this.apiService.LocalId = this.localId;
      localStorage.setItem('versao', versao.versaoAtual);
      await this.mensagemService.showMessage('Site atualizado ...','Ok', 5000, 'success');
      window.location.reload();
    } else {
      if (versao.versaoAtual === undefined) {
        this.mensagemService.showMessage(`Atenção! Site desatualizado. As novas funcionalidades podem não estar disponíveis. Limpe os dados de  navegação e faça login novamente!`, 'Ok', 8000, 'warning');
      }
    }
    localStorage.setItem('versao', this.apiService.VersaoSite);
  }


  async ngOnInit(): Promise<void> {
    this.tempoMedioChamados = this.getTempoMedioDefault();
    this.pageSizeOptions = [5, 10, 25, 50, 100];
    this.usuario = this.apiService.UsuarioLogado;
    this.permissaoADM = this.usuario?.nivel === 'ADMINISTRADOR';
    this.permissaoAssessor = this.usuario?.nivel === 'ASSESSOR';
    this.permissaoGerente = this.usuario?.nivel === 'OPERADOR';
    this.appConfigService.pararTimerEmitter.subscribe(parar => {
      this.pararTimerChanged(parar);
    });
    this.ultimaAtualizacao = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
    if (this.usuario?.nivel === 'ADMINISTRADOR' || this.usuario?.nivel === 'ASSESSOR') {
      this.tituloDashboard = 'Cotações e Endossos';
      this.tituloDashboard1 = 'Minha Atuação';
      this.tituloDashboard2 = 'Minhas Cotações';
    } else if (this.usuario?.nivel === 'OPERADOR' && this.todoPA) {
      this.tituloDashboard = `Cotações e Endossos do ${this.usuario?.codPA}`;
      this.tituloDashboard1 = `Atuações do ${this.usuario?.codPA}`;
      this.tituloDashboard2 = this.tituloDashboard;
    } else {
      this.tituloDashboard = 'Minhas Cotações e Endossos';
      this.tituloDashboard1 = 'Minha Atuação';
      this.tituloDashboard2 = this.tituloDashboard;
    }
    this.verificaUsuario();
    this.setDataPadrao();
    await this.obterResumos();
    this.setTimer();
    if (sessionStorage.getItem('userCode') !== null && sessionStorage.getItem('userCode') !== undefined) this.avisoService.avisosEmitter.emit(true);
    setTimeout(() => {
      if (sessionStorage.getItem('userCode') !== null && sessionStorage.getItem('userCode') !== undefined) {
        this.appConfigService.minhasAtividadesEmitter.emit(true);
      }
    }, 5000);
    this.appConfigService.atualizarSiteEmiiter.subscribe(async (versao: string) => {
      await this.atualizarSite(versao);
    });
  }

  getTempoMedioDefault(): TempoMedioChamadoDto {
    return {
      tipo: TipoChamado.Cotacao,
      periodo: undefined,
      qtdeCotacoes: 0,
      totalCotacoes: '0',
      mediaCotacoes: '0',
      qtdeEndossos: 0,
      totalEndossos: '0',
      mediaEndossos: '0',
      qtdeChamados: 0,
      totalChamados: '0',
      mediaGeral: '0'
    };
  }

  getMargin(): string {
    return '25%';
  }

  async getPaginaCotacoes(event: any): Promise<void> {
    this.myPaginator1 = event;
    await this.cotacaoService.minhasCotacoes(this.myPaginator1, this.todoPA).then(result => {
      let minhasCotacoes = this.apiService.verificaSeResult<Pagina<ItemMinhasCotacoesDto>>(result);
      this.configurarPaginaCotacoes(minhasCotacoes);
    });
  }

  async getPaginaParcelas(event: any): Promise<void> {
    await this.controleParcelasService.listaParcelas(event).then(result => {
      let parcelas = this.apiService.verificaSeResult<Pagina<ItemPrimeiraParcelaDto>>(result);
      this.configurarPaginaParcelas(parcelas);
    });
  }

  verificaUsuario(): void {
    if (this.usuario?.senhaProvisoria) {
      this.router.navigate([`/usuario/edit/senha/${this.usuario?.id}`]);
    }
  }

  todoPeriodoChanged(event: any): void {
    if (event) {
      this.dataInicial = undefined;
      this.dataFinal = undefined;
    }
  }

  todoPAChanged(event: any): void {
    if (this.usuario?.nivel === 'OPERADOR' && this.todoPA) {
      this.tituloDashboard = `Cotações do ${this.usuario?.codPA}`;
      this.tituloDashboard1 = `Atuações do ${this.usuario?.codPA}`;
      this.tituloDashboard2 = this.tituloDashboard;
    } else {
      this.tituloDashboard = 'Minhas Cotações';
      this.tituloDashboard1 = `Minha Atuação`;
      this.tituloDashboard2 = this.tituloDashboard;
    }
  }

  async getPeriodo(): Promise<PeriodoDto> {
    let dtInicial = this.dataInicial;
    let dtFinal = this.dataFinal;

    if ((dtInicial !== null && dtInicial !== undefined) && (dtFinal !== null && dtFinal !== undefined)) {
      //this.todoPeriodo = false;
      let check = document.getElementById('todoPeriodoCheck');
      if (check) (<any>check).value = false;
    }

    if (this.todoPeriodo) {
      dtInicial = await this.apiService.DataBase();
      dtFinal = await this.apiService.DataBase();
    }
    let periodo = { dataInicial: dtInicial, dataFinal: dtFinal } as PeriodoDto;
    return periodo;
  }

  async obterResumos(): Promise<void> {
    let periodo = await this.getPeriodo();
    if ((periodo.dataFinal === null || periodo.dataFinal === null) || (periodo.dataFinal === undefined || periodo.dataFinal === undefined)) {
      await this.mensagemService.showMessage('Informe o período!', 'Ok', 3000, 'warning');
      return;
    }
    this.apiService.topPrioridadesEmitter.emit(await this.getPeriodo());
    await this.cotacaoService.obterResumoStatus(periodo, this.todoPA).then(res => {
      if (res) {
        let resumo = res as any as ResumoStatusDashboardDto;
        if (resumo.resumoStatus === null || resumo.resumoStatus === undefined) return;
        this.statusDashboard = {
          idAbertos: resumo.statusDashboard.idAbertos,
          qtdeAbertos: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idAbertos)[0]?.totalStatus ?? 0,
          idAguardandoVistoria: resumo.statusDashboard.idAguardandoVistoria,
          qtdeAguardandoVistoria: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idAguardandoVistoria)[0]?.totalStatus ?? 0,
          idSolicitandoFechamento: resumo.statusDashboard.idSolicitandoFechamento,
          qtdeSolicitandoFechamento: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idSolicitandoFechamento)[0]?.totalStatus ?? 0,
          idSolicitandoAlteracao: resumo.statusDashboard.idSolicitandoAlteracao,
          qtdeSolicitandoAlteracao: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idSolicitandoAlteracao)[0]?.totalStatus ?? 0,
          idAguardandoBoleto: resumo.statusDashboard.idAguardandoBoleto,
          qtdeAguardandoBoleto: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idAguardandoBoleto)[0]?.totalStatus ?? 0,
          idCotandoInterno: resumo.statusDashboard.idCotandoInterno,
          qtdeCotandoInterno: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idCotandoInterno)[0]?.totalStatus ?? 0,
          idPendencias: resumo.statusDashboard.idPendencias,
          qtdePendencias: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idPendencias)[0]?.totalStatus ?? 0,
          idFechados: resumo.statusDashboard.idFechados,
          qtdeFechados: resumo.resumoStatus.filter(x => x.statusId === resumo.statusDashboard.idFechados)[0]?.totalStatus ?? 0
        } as StatusDashboardDto;
      } else {
        this.mensagemService.showMessage('Erro ao obter resumo dos Status.', 'Ok', 5000, 'error');
        this.statusDashboard = null;
      }
    });
    return;
    await this.getPaginaCotacoesDiarias(this.myPaginator3, this.todoPA);
    if (this.permissaoADM || this.permissaoAssessor) await this.gerarGrafico1(periodo);
    if (this.permissaoADM || this.permissaoAssessor) await this.gerarGrafico2(periodo);
    if (this.permissaoADM || this.permissaoAssessor || this.permissaoGerente) await this.gerarGrafico3(periodo);
    if (this.permissaoADM || this.permissaoAssessor || this.permissaoGerente) await this.gerarGrafico4(periodo);
    await this.obterMinhasCotacoes();
    if (this.permissaoADM || this.permissaoAssessor) await this.obterPrimeiraParcela();
    /* Tempo Médio dos Chamados */
    await this.cotacaoService.obterTempoMedioChamados(periodo, TipoChamado.Todos).then(tmc => {
      if (tmc)
        this.tempoMedioChamados = this.apiService.verificaSeResult<TempoMedioChamadoDto>(tmc);
      else
        this.tempoMedioChamados = this.getTempoMedioDefault();
    });
    /* Tempo Médio Aguardando Retorno */
    await this.cotacaoService.obterTempoMedioChamados(periodo, TipoChamado.Todos, true).then(tmc => {
      if (tmc)
        this.mediaAguardandoRetorno = this.apiService.verificaSeResult<TempoMedioChamadoDto>(tmc);
      else
        this.mediaAguardandoRetorno = this.getTempoMedioDefault();
    });
  }

  async obterPrimeiraParcela(): Promise<void> {
    return;
    await this.controleParcelasService.listaParcelas(this.myPaginator2).then(result => {
      let parcelas = this.apiService.verificaSeResult<Pagina<ItemPrimeiraParcelaDto>>(result);
      this.configurarPaginaParcelas(parcelas);
    });
  }

  configurarPaginaParcelas(pagina: Pagina<ItemPrimeiraParcelaDto>): void {
    if (pagina !== null && pagina !== undefined) {
      this.primeirasParcelas = pagina.dados as ItemPrimeiraParcelaDto[];
    }
    else {
      this.primeirasParcelas = null;
    }
    this.dsPrimeiraParcela = new MatTableDataSource(this.primeirasParcelas);
    this.paginaPrimeiraParcela = pagina;
    this.qtdeRegistrosPrimeiraParcela = this.paginaPrimeiraParcela?.quantidadeRegistros;
  }

  async obterMinhasCotacoes(): Promise<void> {
    return;
    await this.cotacaoService.minhasCotacoes(this.myPaginator1, this.todoPA).then(result => {
      let minhasCotacoes = this.apiService.verificaSeResult<Pagina<ItemMinhasCotacoesDto>>(result);
      this.configurarPaginaCotacoes(minhasCotacoes);
    });
  }

  configurarPaginaCotacoes(pagina: Pagina<ItemMinhasCotacoesDto>): void {
    if (pagina !== null && pagina !== undefined) {
      this.minhasCotacoes = pagina.dados as ItemMinhasCotacoesDto[];
    }
    else {
      this.minhasCotacoes = null;
    }
    this.dsMinhasCotacoes = new MatTableDataSource(this.minhasCotacoes);
    this.paginaMinhasCotacoes = pagina;
    this.qtdeRegistrosMinhasCotacoes = this.paginaMinhasCotacoes?.quantidadeRegistros;
  }

  async gerarGrafico1(periodo: PeriodoDto): Promise<void> {
    this.barChartLabels1 = [];
    this.barChartData1 = null;
    let eixoY = [];
    let resumo: ResumoMCIPorPADto[];
    await this.mciService.obterProdutividadePorPA(periodo).then(async result => {
      resumo = this.apiService.verificaSeResult<ResumoMCIPorPADto[]>(result);
      resumo?.forEach(x => {
        let codPA = x.codPA;
        if (this.barChartLabels1.indexOf(codPA) === -1) {
          this.barChartLabels1.push(codPA);
        }
      });

      resumo?.forEach(x => {
        eixoY.push([x.quantidade]);
      });
      let agencias = null;
      await this.agenciaService.todas().then(res => {
        agencias = res;
      });
      let dataItem: number[][] = [];
      let dataLabel: string[] = [];


      let array: number[] = [];
      this.barChartLabels1.forEach(y => {
        let item = resumo.filter(j => j.codPA === y)[0]?.quantidade;
        array.push(item);
      });
      dataItem.push(array);

      agencias.forEach(h => {
        dataLabel.push(h.codPA);
      });
      let dados: ChartDataSets[] = [];

      dados.push({
        data: dataItem[0],
        fill: false,
        label: 'Quantidade',
        backgroundColor: '#47C41570',
        hoverBackgroundColor: '#ff634770',
      } as ChartDataSets);

      if (this.barChartData1 === null) this.barChartData1 = [];
      this.barChartData1 = dados;
    });
  }

  async gerarGrafico2(periodo: PeriodoDto): Promise<void> {
    this.barChartLabels2 = [];
    this.barChartData2 = null;
    let eixoY = [];
    let resumo: ResumoMCIPorGerenteDto[];
    await this.mciService.obterProdutividadePorGerente(periodo).then(async result => {
      resumo = this.apiService.verificaSeResult<ResumoMCIPorGerenteDto[]>(result).sort((x, y) => y.quantidade - x.quantidade);
      resumo?.forEach(x => {
        let nome = `${x.gerenteNome.split(' ')[0]} - ${x.codPA}`;
        if (this.barChartLabels2.indexOf(x.gerenteNome) === -1) {
          this.barChartLabels2.push(nome);
        }
      });

      resumo?.forEach(x => {
        eixoY.push([x.quantidade]);
      });
      let gerentes = null;
      await this.usuarioService.ObterColaboradores().then(res => {
        gerentes = res.filter(n => n.nivel === 'OPERADOR');
      });
      let dataItem: number[][] = [];
      let dataLabel: string[] = [];

      let array: number[] = [];
      this.barChartLabels2.forEach(y => {
        let item = resumo.filter(j => `${j.gerenteNome.split(' ')[0]} - ${j.codPA}` === y)[0]?.quantidade;
        array.push(item);
      });
      dataItem.push(array);

      gerentes.forEach(h => {
        dataLabel.push(h.gerenteNome);
      });
      let dados: ChartDataSets[] = [];

      dados.push({
        data: dataItem[0],
        axis: 'y',
        fill: false,
        label: 'Quantidade',
        backgroundColor: '#00364170',
        hoverBackgroundColor: '#ff634770',
      } as ChartDataSets);

      if (this.barChartData2 === null) this.barChartData2 = [];
      this.barChartData2 = dados;
    });
  }

  async gerarGrafico3(periodo: PeriodoDto): Promise<void> {
    this.barChartLabels3 = [];
    this.barChartData3 = null;
    let eixoY = [];
    let resumo: TopProdutosDto[];
    await this.mciService.topProdutos(periodo).then(async result => {
      resumo = this.apiService.verificaSeResult<TopProdutosDto[]>(result).sort((x, y) => y.quantidade - x.quantidade);
      resumo?.forEach(x => {
        if (this.barChartLabels3.indexOf(x.produtoNome) === -1) {
          this.barChartLabels3.push(x.produtoNome);
        }
      });

      resumo?.forEach(x => {
        eixoY.push([x.quantidade]);
      });
      let produtos = null;
      await this.produtoService.todos().then(res => {
        produtos = res;
      });
      let dataItem: number[][] = [];
      let dataLabel: string[] = [];

      let array: number[] = [];
      this.barChartLabels3.forEach(y => {
        let item = resumo.filter(j => j.produtoNome === y)[0]?.quantidade;
        array.push(item);
      });
      dataItem.push(array);

      produtos.forEach(h => {
        dataLabel.push(h.gerenteNome);
      });
      let dados: ChartDataSets[] = [];

      dados.push({
        data: dataItem[0],
        axis: 'y',
        fill: false,
        label: 'Quantidade',
        backgroundColor: '#1CEBF670',
        hoverBackgroundColor: '#ff634770',
      } as ChartDataSets);

      if (this.barChartData3 === null) this.barChartData3 = [];
      this.barChartData3 = dados;
    });
  }

  async gerarGrafico4(periodo: PeriodoDto): Promise<void> {
    this.barChartLabels4 = [];
    this.barChartData4 = null;
    let eixoY = [];
    let resumo: TopAtendentesDto[];
    await this.mciService.topAtendentes(periodo).then(async result => {
      resumo = this.apiService.verificaSeResult<TopAtendentesDto[]>(result).sort((x, y) => y.quantidade - x.quantidade);
      resumo?.forEach(x => {
        if (this.barChartLabels4.indexOf(x.atendenteNome) === -1) {
          this.barChartLabels4.push(x.atendenteNome);
        }
      });

      resumo?.forEach(x => {
        eixoY.push([x.quantidade]);
      });
      let atendentes = null;
      await this.usuarioService.ObterColaboradores().then(res => {
        atendentes = res;
      });
      let dataItem: number[][] = [];
      let dataLabel: string[] = [];

      let array: number[] = [];
      this.barChartLabels4.forEach(y => {
        let item = resumo.filter(j => j.atendenteNome === y)[0]?.quantidade;
        array.push(item);
      });
      dataItem.push(array);

      atendentes.forEach(h => {
        dataLabel.push(h.atendenteNome);
      });
      let dados: ChartDataSets[] = [];

      dados.push({
        data: dataItem[0],
        axis: 'y',
        fill: false,
        label: 'Quantidade',
        backgroundColor: '#40418470',
        hoverBackgroundColor: '#ff634770',
      } as ChartDataSets);

      if (this.barChartData4 === null) this.barChartData4 = [];
      this.barChartData4 = dados;
    });
  }

  async obterFechamentosDiarios(periodo: PeriodoDto, paginacao: Paginator, todoPA: boolean): Promise<Pagina<ItemFechamentosDiariosDto>> {
    return;
    let resumo = null;
    await this.cotacaoService.fechamentosDiarios(periodo, paginacao, todoPA).then(async result => {
      resumo = this.apiService.verificaSeResult<Pagina<ItemFechamentosDiariosDto>>(result);
    });
    return resumo;
  }

  async getPaginaCotacoesDiarias(event: Paginator, todoPA: boolean): Promise<void> {
    return;
    let periodo = await this.getPeriodo();
    let resumo = await this.obterFechamentosDiarios(periodo, event, todoPA);
    this.qtdeRegistrosCotacoesDiarias = resumo?.quantidadeRegistros;
    await this.gerarGrafico5(periodo, event, todoPA);
  }

  async gerarGrafico5(periodo: PeriodoDto, paginator: Paginator, todoPA: boolean): Promise<void> {
    this.barChartLabels5 = [];
    this.barChartData5 = null;
    let eixoY = [];
    let resumo: Pagina<ItemFechamentosDiariosDto>;
    resumo = await this.obterFechamentosDiarios(periodo, paginator, todoPA);
    resumo?.dados?.forEach(x => {
      if (this.barChartLabels5.indexOf(new Date(x.data).toLocaleDateString()) === -1) {
        this.barChartLabels5.push(new Date(x.data).toLocaleDateString());
      }
    });

    resumo?.dados?.forEach(x => {
      eixoY.push([x.quantidade]);
    });
    let dataItem: number[][] = [];
    let dataLabel: string[] = [];

    let array: number[] = [];
    let total = 0;
    this.barChartLabels5.forEach(y => {
      let item = resumo?.dados?.filter(j => new Date(j.data).toLocaleDateString() === y.toLocaleString())[0]?.quantidade;
      total = total + item;
      array.push(item);
    });
    dataItem.push(array);

    array = [];
    this.barChartLabels5.forEach(y => {
      let item = resumo?.dados?.filter(j => new Date(j.data).toLocaleDateString() === y.toLocaleString())[0]?.premioLiquido;
      array.push(item);
    });
    dataItem.push(array);

    resumo?.dados?.forEach(h => {
      dataLabel.push(new Date(h.data).toLocaleDateString());
    });
    let dados: ChartDataSets[] = [];

    dados.push({
      data: dataItem[0],
      axis: 'y',
      fill: false,
      label: 'Quantidade',
      borderColor: '#79ba4e',
      backgroundColor: '#79ba4e',
      pointBackgroundColor: '#BED630',
      pointBorderColor: '#BED630'
    } as ChartDataSets);

    dados.push({
      data: dataItem[1],
      axis: 'y',
      fill: false,
      label: 'Prêmio Líquido',
      borderColor: '#003641',
      backgroundColor: '#003641',
      pointBackgroundColor: '#BED630',
      pointBorderColor: '#BED630'
    } as ChartDataSets);

    if (this.barChartData5 === null) this.barChartData5 = [];
    this.barChartData5 = dados;
  }

  setDataPadrao(): void {
    this.todoPeriodoChanged(false);
    this.dataInicial = new Date();
    this.dataFinal = new Date();
    //this.dataInicial.setDate(this.dataInicial.getDate() - 7);
  }

  pararTimerChanged(event: boolean): void {
    //let check = document.getElementById('pararTimerCheck');
    if (event) {
      this.stopTimer = true;
      this.apiService.IgnoraBusy = false;
    } else {
      this.stopTimer = false;
      this.apiService.IgnoraBusy = true;
      this.setTimer();
    }
  }

  abrirCotacao(row: ItemMinhasCotacoesDto): void {
    this.router.navigate([`/cotacao/${row.id}`]);
  }

  rowClick(row: ItemMinhasCotacoesDto): void {
    this.itemCotacaoSelecionado = row;
  }

  rowClickParcela(row: ItemPrimeiraParcelaDto): void {
    this.itemParcelaSelecionado = row;
  }
  getCellColor(row: ItemMinhasCotacoesDto): string {
    return row.status.cor;
  }

  async avisarParcela(row: ItemPrimeiraParcelaDto): Promise<void> {
    const dialogRef = this.dialog.open(DialogoComponent, {
      data: {
        titulo: 'Notificar por e-mail',
        pergunta: `A notificação será enviada para o ${row.codPA}. Confirma?`
      },
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'yes') {
        await this.controleParcelasService.notificar(row.id).then(result => {
          if (result) {
            this.mensagemService.showMessage(`Alerta enviado para o ${row.codPA}`, 'Ok', 5000, 'success');
          } else {
            this.mensagemService.showMessage(`Não foi possível enviar alerta para o ${row.codPA}`, 'Ok', 5000, 'error');
          }
        });
      }
    });
  }

  getStyle(row: ItemPrimeiraParcelaDto): string {
    if (new Date(row.vencimento) < new Date()) {
      return 'red';
    } else {
      return 'black';
    }
  }

  getAnimation(row: ItemPrimeiraParcelaDto): string {
    if (new Date(row.vencimento) < new Date()) {
      return 'blinker 1s step-start infinite';
    } else {
      return '';
    }
  }

  visualizarTempoChamado(): void {
    /*const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      titulo: 'Tempo Médio da Abertura ao Fechamento - Detalhamento',
      todoPeriodo: this.todoPeriodo,
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal
    };
    let tDialog = this.dialog.open(TempoMedioDetalhadoComponent, dialogConfig);
    tDialog.afterClosed().subscribe(result => { });*/
    this.mensagemService.showMessage("Em desenvolvimento", "OK", 5000, "success");
  }
}
