import { BtnInfoComponent } from './buttom/btn-info/btn-info.component';
import { InformacoesAdicionaisComponent } from './../componentes/informacoes-adicionais/informacoes-adicionais.component';
import { SafePipe } from './../pipes/safe/safe.pipe';
import { MonitorComponent } from './../componentes/monitor/monitor.component';
import { DragDropComponent } from './../componentes/drag-drop/drag-drop.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { PesquisaComponent } from './../componentes/pesquisa/pesquisa.component';
import { SpinnerComponent } from './../componentes/spinner/spinner.component';
import { CpfPipe } from './../pipes/cpf/cpf.pipe';
import { RealPipe } from './../pipes/real/real.pipe';
import { BtnCrudComponent } from './../componentes/btn-crud/btn-crud.component';
import { TituloComponent } from './../componentes/titulo/titulo.component';
import { FiltroComponent } from '../componentes/filtro/filtro.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PaginatorComponent } from './../componentes/paginator/paginator/paginator.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ChartsModule } from 'ng2-charts';
import { SharedPaginatorIntl } from './sharedPaginatorIntl';
import { MatSelectFilterModule } from 'mat-select-filter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BarChartComponent } from '../componentes/chart/bar-chart/bar-chart.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { PdfViewerComponent } from '../componentes/pdf-viewer/pdf-viewer.component';
import { TopPrioridadesComponent } from '../componentes/top-prioridades/top-prioridades.component';
import { DadosCotacaoComponent } from '../componentes/endosso/dados-cotacao/dados-cotacao.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MinhaAtividadeComponent } from '../componentes/minha-atividade/minha-atividade.component';
import { MarkBoldPipe } from '../pipes/markBold/markBold.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AvisoComponent } from '../componentes/aviso/aviso.component';
import {MatRadioModule} from '@angular/material/radio';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    PaginatorComponent,
    FiltroComponent,
    TituloComponent,
    BtnCrudComponent,
    RealPipe,
    MarkBoldPipe,
    CpfPipe,
    SafePipe,
    SpinnerComponent,
    PesquisaComponent,
    BarChartComponent,
    DragDropComponent,
    MonitorComponent,
    PdfViewerComponent,
    TopPrioridadesComponent,
    InformacoesAdicionaisComponent,
    BtnInfoComponent,
    DadosCotacaoComponent,
    MinhaAtividadeComponent,
    AvisoComponent
  ],
  imports: [
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxMaskModule,
    MatDividerModule,
    MatDatepickerModule,
    ChartsModule,
    MatSelectFilterModule,
    DragDropModule,
    NgxCurrencyModule,
    MatTabsModule,
    MatExpansionModule,
    ColorPickerModule,
    HttpClientModule,
    AngularEditorModule,
    MatDialogModule,
    MatRadioModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PaginatorComponent,
    FiltroComponent,
    TituloComponent,
    BtnCrudComponent,
    SpinnerComponent,
    PesquisaComponent,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxMaskModule,
    MatDialogModule,
    MatDatepickerModule,
    RealPipe,
    MarkBoldPipe,
    CpfPipe,
    SafePipe,
    ChartsModule,
    MatSelectFilterModule,
    DragDropModule,
    BarChartComponent,
    DragDropComponent,
    NgxCurrencyModule,
    MatTabsModule,
    MatExpansionModule,
    MonitorComponent,
    PdfViewerComponent,
    TopPrioridadesComponent,
    InformacoesAdicionaisComponent,
    BtnInfoComponent,
    DadosCotacaoComponent,
    ColorPickerModule,
    MinhaAtividadeComponent,
    HttpClientModule,
    AngularEditorModule,
    AvisoComponent,
    MatRadioModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: SharedPaginatorIntl },
    { provide: RealPipe, useClass: RealPipe },
    { provide: MarkBoldPipe, useClass: MarkBoldPipe }
  ]
})
export class SharedModule {

}
