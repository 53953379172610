
import { ParametrosComponent } from './componentes/parametros/parametros.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'src/app/componentes/login/login.component';
import { LoginGuard } from 'src/app/guard/login.guard';
import { HomeComponent } from './componentes/home/home.component';
import { IapComponent } from './componentes/iap/iap.component';
import { LogComponent } from './componentes/log/log.component';

const routes: Routes = [
  {
    path: 'logs',
    component: LogComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'endosso',
    loadChildren: () => import('./componentes/endosso/endosso.module').then(m => m.EndossoModule)
  },
  {
    path: 'tiposEndosso',
    loadChildren: () => import('./componentes/tipo-endosso/tipo-endosso.module').then(m => m.TipoEndossoModule)
  },
  {
    path: 'validarIAP',
    component: IapComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'carteira',
    loadChildren: () => import('./componentes/carteira/carteira.module').then(m => m.CarteiraModule)
  },
  {
    path: 'parametros',
    component: ParametrosComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: 'seguradoras',
    loadChildren: () => import('./componentes/seguradora/seguradora.module').then(m => m.SeguradoraModule)
  },
  {
    path: 'metasCampanha',
    loadChildren: () => import('./componentes/metas-campanha/metas-campanha.module').then(m => m.MetasCampanhaModule)
  },
  {
    path: 'status',
    loadChildren: () => import('./componentes/status/status.module').then(m => m.StatusModule)
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./componentes/relatorios/relatorios.module').then(m => m.RelatoriosModule)
  },
  {
    path: 'parcelas',
    loadChildren: () => import('./componentes/controle-parcelas/controle-parcelas.module').then(m => m.ControleParcelasModule)
  },
  {
    path: 'corretoras',
    loadChildren: () => import('./componentes/corretora/corretora.module').then(m => m.CorretoraModule)
  },
  {
    path: 'andamento',
    loadChildren: () => import('./componentes/andamentos/andamento.module').then(m => m.AndamentoModule)
  },
  {
    path: 'cotacao',
    loadChildren: () => import('./componentes/cotacao/cotacao.module').then(m => m.CotacaoModule)
  },
  {
    path: 'agenda',
    loadChildren: () => import('./componentes/agenda/agenda-module').then(m => m.AgendaModule)
  },
  {
    path: 'workshop',
    loadChildren: () => import('./componentes/workshop/workshop.module').then(m => m.WorkshopModule)
  },
  {
    path: 'produto',
    loadChildren: () => import('./componentes/produtos/produtos.module').then(m => m.ProdutosModule)
  },
  {
    path: 'agencia',
    loadChildren: () => import('./componentes/agencia/agencia.module').then(m => m.AgenciaModule)
  },
  {
    path: 'mci',
    loadChildren: () => import('./componentes/mci/mci.module').then(n => n.MciModule)
  },
  {
    path: 'metasProdutos',
    loadChildren: () => import('./componentes/metas-produtos/metas-produtos.module').then(n => n.MetasProdutosModule)
  },
  {
    path: 'grupoProdutos',
    loadChildren: () => import('./componentes/grupo-produtos/grupo-produtos.module').then(n => n.GrupoProdutosModule)
  },
  {
    path: 'reclamacoes',
    loadChildren: () => import('./componentes/reclamacao/reclamacao.module').then(n => n.ReclamacaoModule)
  },
  {
    path: 'campanhas',
    loadChildren: () => import('./componentes/campanha/campanha.module').then(n => n.CampanhaModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./componentes/usuario/usuario.module').then(n => n.UsuarioModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard],
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
