import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ControleVersao } from 'src/app/entidades/controleversao';
import { httpVerb } from 'src/app/enum/httpVerb';
import { Paginator } from 'src/app/entidades/paginator';
import { Pagina } from 'src/app/entidades/pagina';

@Injectable({
  providedIn: 'root'
})
export class ControleVersaoService {

  constructor(private apiService: ApiService) { }

  async todos(): Promise<ControleVersao[]> {
    try {
      let ControleVersaos: ControleVersao[];
      await this.apiService.request<ControleVersao[]>(`/ControleVersao/Todos`, httpVerb.get).then(result => {
        ControleVersaos = this.apiService.verificaSeResult<ControleVersao[]>(result);
      });
      return ControleVersaos;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obter(paginator: Paginator): Promise<Pagina<ControleVersao>> {
    try {
      let paginaAtual = null;
      const complemento = `/ControleVersao?Pagina=${paginator.pageIndex + 1}&tamanhoPagina=${paginator.pageSize}`;
      await this.apiService.request<Pagina<ControleVersao>>(complemento, httpVerb.get)
        .then(result => {
          paginaAtual = this.apiService.verificaSeResult<Pagina<ControleVersao>>(result);
        });
      return paginaAtual;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async obterControleVersao(idEquipamento: string): Promise<ControleVersao> {
    try {
      let controleVersao = undefined;
      await this.apiService.request<ControleVersao>(`/ControleVersao/${idEquipamento}`, httpVerb.get).then(result => {
        controleVersao = this.apiService.verificaSeResult<ControleVersao>(result);
      });
      return controleVersao;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async atualizarVersao(idEquipamento: string, versao: string): Promise<boolean> {
    try {
      let atualizou = false;
      await this.apiService.request<ControleVersao>(`/ControleVersao/${idEquipamento}/${versao}`, httpVerb.put).then(result => {
        atualizou = this.apiService.verificaSeResult<boolean>(result);
      });
      return atualizou;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
