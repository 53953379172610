import { AgenciaComponent } from './agencia.component';
import { AgenciaRoutingModule } from './agencia-routing.module';
import { NgModule } from '@angular/core';
import { DataFormModule } from 'src/app/shared/data-form.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgenciaDetalheComponent } from './agencia-detalhe/agencia-detalhe.component';

@NgModule({
  declarations: [AgenciaComponent, AgenciaDetalheComponent],
  imports: [
    SharedModule, 
    DataFormModule,
    AgenciaRoutingModule
  ]
})
export class AgenciaModule { }
