<div class="tituloDialog">{{ titulo }}</div>
<div mat-dialog-content>
  <section>
    <form
      class="formContainer"
      [formGroup]="formulario"
      (ngSubmit)="onSubmit()"
    >
      <div class="rowOutline" *ngIf="cotacaoOuEndosso !== null && validaTipo()">
        <mat-label
          ><strong>{{ cotacaoOuEndosso }} nº </strong>{{ entidade.id }} -
          {{ dataCriacao | date : "dd/MM/yyyy" }}
        </mat-label>
      </div>
      <div class="rowOutline" *ngIf="validaTipo()">
        <mat-label><strong>Associado: </strong>{{ getCliente() }}</mat-label>
      </div>
      <mat-checkbox formControlName="cancelaTodosCupons" *ngIf="data.tipo === 'CUPOM' && data.row">Cancelar todos os cupons que não foram quitados!</mat-checkbox>
      <br />
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Motivo</mat-label>
          <textarea
            class="textArea"
            matInput
            #inputDescricao
            formControlName="motivoCancelamento"
            cdkAutosizeMinRows="30"
            cdkAutosizeMaxRows="30"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="item-right">
        <button
          mat-button
          class="btnOK mat-elevation-z8"
          [disabled]="formulario.status === 'INVALID' || formulario.pristine"
          type="submit"
        >
          Salvar
        </button>
        <button
          mat-button
          class="btnCancel mat-elevation-z8"
          type="button"
          (click)="cancelClick()"
        >
          Cancelar
        </button>
      </div>
    </form>
  </section>
</div>
