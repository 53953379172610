import { FiltroCupom } from './../../enum/filtroCupom';
import { enumToArray } from 'src/app/helper/enumToArray';
import { DialogoComponent } from './../dialogo/dialogo/dialogo.component';
import { CampanhaService } from './../../services/campanha/campanha.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Campanha } from 'src/app/entidades/campanha';
import { Cupom } from 'src/app/entidades/cupom';
import { Pagina } from 'src/app/entidades/pagina';
import { Paginator } from 'src/app/entidades/paginator';
import { ApiService } from 'src/app/services/api/api.service';
import { AppConfigService } from 'src/app/services/app/app-config.service';
import { CupomService } from 'src/app/services/cupom/cupom.service';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { PaginatorService } from 'src/app/services/Paginator/Paginator.service';
import { Usuario } from 'src/app/entidades/usuario';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FiltroDTO } from 'src/app/entidades/filtroDTO';
import { FiltroService } from 'src/app/services/filtro/filtro.service';
import { InformacoesAdicionaisComponent } from '../informacoes-adicionais/informacoes-adicionais.component';
import { InputBase } from 'src/app/entidades/inputBase';
import { TipoCancelamento } from 'src/app/enum/tipoCancelamento';
import { CancelamentoComponent } from '../cancelamento/cancelamento.component';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-cupom',
  templateUrl: './cupom.component.html',
  styleUrls: ['../../app.component.css', './cupom.component.css']
})
export class CupomComponent implements OnInit {
  isBusy = false;
  usuario: Usuario;
  pagina: Pagina<Cupom>;
  cupons: Cupom[];
  myPaginator: Paginator;
  displayedColumns: string[] = ['select','strCupom', 'nome', 'cpF_CNPJ', 'colaborador', 'pa', 'dataEmissao', 'telefone', 'email', 'termo', 'cupom', 'send_email', 'remove'];
  dataSource: MatTableDataSource<Cupom>;
  qtdeRegistros: number;
  cupomSelecionado: Cupom;
  campanhaSelecionada: Campanha;
  filtros = enumToArray(FiltroCupom);
  filtroAtual: number;
  textoFiltroAtual: string;
  titulo: string;
  dataInicial: Date;
  dataFinal: Date;
  filtroPaginator = {
    length: 0,
    pageIndex: 0,
    pageSize: this.appConfigService.itensPorPagina,
    previousPageIndex: 0
  };
  inputs: InputBase[] = [];
  selection = new SelectionModel<number>(true, [], true);
  multiSelecao = false;

  constructor(private router: Router,
    public apiService: ApiService,
    public cupomService: CupomService,
    private campanhaService: CampanhaService,
    private paginatorService: PaginatorService,
    private appConfigService: AppConfigService,
    private activeRoute: ActivatedRoute,
    private mensagemService: MensagemService,
    private dialog: MatDialog,
    public filtroService: FiltroService) { }

  ngOnInit(): void {
    this.paginatorService.paginatorEmitter = this.paginatorService.getPaginatorEmitter();
    /* Emitter´s do componente de Filtros */
    this.cupomService.filtroCupomEmitter = this.cupomService.getFiltroCupomEmitter();
    this.cupomService.filtroCupomEmitter.subscribe(res => {
      this.filtroAtual = res;
      sessionStorage.setItem("_fdesc", "");
      this.myPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: this.appConfigService.itensPorPagina,
        previousPageIndex: 0
      };
      switch (this.filtroAtual) {
        case 0:
        case 8:
        case 9:
        case 10:
        case 13:
        case 14:
        case 15:
        case 16:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
          this.filtroService.exibirFiltroDataEmitter.emit(false);
          break;
        case 7:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(false);
          this.filtroService.exibirFiltroDataEmitter.emit(true);
          break;
        default:
          this.filtroService.exibirDescricaoFiltroEmitter.emit(true);
          this.filtroService.exibirFiltroDataEmitter.emit(false);
          break;
      }
    });
    this.filtroService.filtrarPorDescricaoEmitter = this.filtroService.getFiltrarPorDescricaoEmitter();
    this.filtroService.filtrarPorDataEmitter = this.filtroService.getFiltrarPorDataEmitter();
    this.filtroService.filtrarPorDescricaoEmitter.subscribe(res => {
      this.textoFiltroAtual = sessionStorage.getItem('_fdesc');
      this.filtrarPorDescricao(this.filtroPaginator);
    });
    this.filtroService.filtrarPorDataEmitter.subscribe(res => {
      this.dataInicial = res.dataInicial;
      this.dataFinal = res.dataFinal;
      this.filtrarPorData(this.filtroPaginator);
    });
    /*************************************/
    this.usuario = this.apiService.UsuarioLogado;
    if (this.usuario.nivel === 'OPERADOR') {
      this.displayedColumns = ['select', 'strCupom', 'nome', 'cpF_CNPJ', 'colaborador', 'pa', 'dataEmissao', 'telefone', 'email', 'termo', 'cupom', 'send_email', 'remove'];
    } else {
      this.displayedColumns = ['select', 'strCupom', 'nome', 'cpF_CNPJ', 'colaborador', 'pa', 'dataEmissao', 'email', 'termo', 'cupom', 'send_email', 'remove'];
    }
    // Obtém a campanha selecinada
    const id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.campanhaService.ObterCampanha(id).then(result => {
      this.campanhaSelecionada = result as Campanha;
      this.titulo = `Cupons Gerados | Campanha: ${this.campanhaSelecionada.nome}`;
      this.myPaginator = {
        length: 0,
        pageIndex: 0,
        pageSize: this.appConfigService.itensPorPagina,
        previousPageIndex: 0
      };
      this.obterCupons();
      this.paginatorService.paginatorEmitter.subscribe(result => {
        this.myPaginator = JSON.parse(result);
        if (this.filtroAtual > 0) {
          if (this.filtroAtual !== 7) {
            this.filtrarPorDescricao(this.myPaginator);
          } else {
            this.filtrarPorData(this.myPaginator);
          }
        } else {
          this.obterCupons();
        }
      });
    });
  }

  filtrarPorDescricao(filtroPaginator: Paginator) {
    sessionStorage.setItem('_pagina', JSON.stringify(filtroPaginator));
    if (this.filtroAtual > 0) {
      const dto = { texto: this.textoFiltroAtual } as FiltroDTO;
      this.cupomService.filtrar(this.filtroAtual, this.campanhaSelecionada.id, dto, filtroPaginator)
        .then(result => { this.configurarPagina(result); });
    } else {
      this.obterCupons();
    }
  }

  filtrarPorData(filtroPaginator: Paginator): void {
    if (this.filtroAtual > 0) {
      const dto = { dataInicial: this.dataInicial, dataFinal: this.dataFinal } as FiltroDTO;
      this.cupomService.filtrar(this.filtroAtual, this.campanhaSelecionada.id, dto, filtroPaginator)
        .then(result => { this.configurarPagina(result); });
    }
  }

  async obterCupons(): Promise<void> {
    await this.cupomService.Obter(this.campanhaSelecionada.id, this.myPaginator).then(res => {
      this.configurarPagina(res);
    });
  }

  configurarPagina(pagina: Pagina<Cupom>): void {
    if (pagina !== null && pagina !== undefined) {
      this.cupons = pagina.dados as Cupom[];
      this.dataSource = new MatTableDataSource(this.cupons);
      this.pagina = pagina;
      this.qtdeRegistros = this.pagina.quantidadeRegistros;
    }
    else {
      this.cupons = null;
    }
  }

  rowClick(row: Cupom): void {
    this.cupomSelecionado = row;
  }

  voltar(): void {
    if (this.campanhaService.campanhaVigente)
      this.router.navigate(['/campanhas/vigentes']);
    else
      this.router.navigate(['/Campanhas']);
  }

  termoAdesao(row: Cupom): void {
    this.router.navigate([`/Cupom/Termo/${row.id}`]);
  }

  async sendEmail(row: Cupom): Promise<void> {
    this.cupomSelecionado = row;
    this.inputs = [];
    if (row.email === null || row.email === undefined || row.email.trim() === '') {
      let input = { label: 'e-mail', name: 'email', maxLenght: 100, required: true, width: 500, email: true } as InputBase;
      this.inputs.push(input);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = this.inputs;
      let tDialog = this.dialog.open(InformacoesAdicionaisComponent, dialogConfig);
      tDialog.afterClosed().subscribe(async (formulario) => {
        if (formulario === null || formulario === undefined) return;
        let email = formulario.get('email').value;
        this.cupomSelecionado.email = email;
        await this.cupomService.AdicionarOuEditar(this.cupomSelecionado, true).then(res => {
          if (!res) {
            this.mensagemService.showMessage('Erro ao alterar e-mail.', 'Ok', 2000, 'error');
            return;
          } else {
            this.enviarEmail(this.cupomSelecionado);
          }
        });
      });
    }
    else {
      this.enviarEmail(this.cupomSelecionado);
    }
  }

  async enviarEmail(row: Cupom): Promise<void> {
    this.isBusy = true;
    this.cupomService.EnviarPorEmail(row.id).then(result => {
      this.isBusy = false;
      if (result) {
        this.mensagemService.showMessage('Email enviado com sucesso.', 'Ok', 5000, 'success');
      }
      else {
        this.mensagemService.showMessage('Erro ao enviar email.', 'Ok', 5000, 'error');
      }
    });
  }

  async emitirCupom(idCupom: number): Promise<void> {
    this.campanhaService.emitirCupom(idCupom).then(
      result => {
        if (result !== null && result !== undefined) {
          this.mensagemService.showMessage('Cupom obtido com sucesso.', 'Ok', 5000, 'success');
          let file = new File([result], "Cupom.pdf", { type: (<any>result).type });
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.apiService.StrDocument = (reader.result as string);
            this.apiService.pdfViewerEmitter.emit(true);
          };
        }
        else {
          this.mensagemService.showMessage('Erro ao obter Cupom.', 'Ok', 5000, 'error');
        };
      });
  }

  async cancelar(row: Cupom): Promise<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialog: MatDialogRef<any>;
    let titulo = `Cancelamento do Cupom ${row.strCupom}`;
    let info = {
      row: row,
      tipo: "CUPOM",
      titulo: titulo,
      tipoCancelamento: TipoCancelamento.Cancelamento
    }
    dialogConfig.data = info;
    dialog = this.dialog.open(CancelamentoComponent, dialogConfig);
    dialog.afterClosed().subscribe(res => {
      this.obterCupons();
    });
  }

  quitarCupom(row: Cupom): void {
    const dialogRef = this.dialog.open(DialogoComponent, {
      data: {
        titulo: 'Informar Quitação do Cupom',
        pergunta: `Deseja relamente informar a quitação do cupom nº ${row.strCupom} em nome de ${row.nome}? Um novo número será gerado, confirma?`
      },
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.cupomService.quitar(row).then(res => {
          if (res) {
            this.obterCupons();
            this.mensagemService.showMessage('Cupom obtido com sucesso.', 'Ok', 5000, 'success');
            let file = new File([res], "Cupom.pdf", { type: (<any>res).type });
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.apiService.StrDocument = (reader.result as string);
              this.apiService.pdfViewerEmitter.emit(true);
            };
          } else {
            this.mensagemService.showMessage(`Erro ao informar quitação do Cupom nº ${row.strCupom} em nome de ${row.nome}.`, 'Ok', 5000, 'error');
          }
        });
      }
    });
  }

  getColumnColor(row: Cupom): string {
    if (row?.strCupom === 'PENDENTE')
      return '#07AC0E';
    if (row?.strCupom === 'PARCELADO')
      return '#db202f';
    if (row?.strCupom === 'PARCELADO**')
      return '#ffac33';
    if (row?.cancelado)
      return "#ff0000";

    return '#5B5D5B';
  }

  habilitaEmail(row: any): boolean {
    if (row.cancelado) return false;
    if (row.strCupom === 'PENDENTE') return false;
    if (!row.foiParcelado && row.foiQuitado) return true;
    if (row.foiParcelado && row.foiQuitado) return true;
    return false;
  }

  habilitaQuitacao(row: any): boolean {
    if (this.apiService.UsuarioLogado.nivel !== 'ADMINISTRADOR') return false;
    if (row.cancelado) return false;
    if (row.strCupom === 'PENDENTE') return true;
    if (row.foiParcelado && !row.foiQuitado) return true;
    return false;
  }

  habilitaCupom(row: any): boolean {
    if (row.cancelado) return false;
    if (row.strCupom === 'PENDENTE') return false;
    if (!row.foiParcelado && row.foiQuitado) return true;
    if (row.foiParcelado && row.foiQuitado) return true;
    return false;
  }

  selectHandler(event: any, row: Cupom): void {
    this.selection.toggle(row.id);
    this.rowClick(row);
  }

  async habilitarSelecao(): Promise<void> {
    this.multiSelecao = !this.multiSelecao;
    this.selection.clear();
  }

  getNomeBotaoQuitacao(): string {
    return this.multiSelecao ? 'Cancelar' : 'Quitar Cupons';
  }

  async quitarCupons(): Promise<void> {
    const dialogRef = this.dialog.open(DialogoComponent, {
      data: {
        titulo: 'Informar Quitação do Cupom',
        pergunta: `Deseja relamente informar a quitação dos cupons selecionados?`
      },
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'yes') {
        await this.cupomService.quitarCupons(this.selection.selected).then(async res => {
          if (res) {
            this.mensagemService.showMessage('Quitação em massa efetuada com sucesso!.', 'Ok', 5000, 'success');
            await this.obterCupons();
            this.habilitarSelecao();
          }
          else {
            this.mensagemService.showMessage('Ocorrerão erros ao quitas os cupons selecionados!.', 'Ok', 5000, 'error');
          }
        });
      }
    });
  }

  selectAll(event: any): void {
    if (!event.checked)
      this.selection.clear();
    else this.selection.select(...this.cupons.filter(row => !row.cancelado && !row.foiQuitado).map((row) =>  row.id));
  }

  habilitarCheckbox(row: Cupom): boolean {
    if (row.cancelado) return false;
    if (row.foiQuitado) return false;
    if (row.strCupom === 'PARCELADO**') return false;
    return this.multiSelecao;
  }
}
