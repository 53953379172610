type StandardEnum = { [id: string]: number | string;[nu: number]: string; }

export function enumToArray<T extends StandardEnum>(enm: T): { id: number; description: string }[] {
  return Object.entries(enm).reduce((accum, kv) => {
    if (typeof kv[1] === 'number') {
      while (kv[0].includes('_')) {
        kv[0] = kv[0].replace('_', ' ')
      };
      accum.push({ id: kv[1], description: kv[0] })
    }
    return accum
  }, [])
}
